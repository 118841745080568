import {
  ActionIcon,
  Badge,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  Group,
  Menu,
  rem,
  Select,
  SelectProps,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandGrammarly,
  IconBuildingSkyscraper,
  IconCalendar,
  IconCaretDown,
  IconChartCandle,
  IconCheck,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconSearch,
  IconTransferIn,
  IconTransferOut,
  IconX,
} from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import "../../../Styles/tab.component.css";
import * as xlsx from "xlsx";
import { toast } from "react-toastify";
import CreateDataView from "./CreateDataView";
import EditDataView from "./EditDataView";
import DetailDataView from "./DetailDataView";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { getVendorSelect } from "../../../service/getSelectApi";
import { DatePickerInput } from "@mantine/dates";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green } from "../../../const/variables";
import { DefectiveItem } from "../../../model/TblFaultyItemList";
import { useDebouncedState } from "@mantine/hooks";
import { getValueById } from "../../../_base/helper/FunctionHelper";
import { IconCubeSend } from "@tabler/icons-react";

const DefectiveGoodsList = () => {
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<DefectiveItem[]>([]);
  const [dataTemp, setDataTemp] = useState<DefectiveItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [pagination] = useState(paginationBase);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);
  const [dataItemStatusSelect] = useState<any[]>([
    { label: "Test lỗi", value: "TEST_LOI" },
    { label: "Đang gửi NCC", value: "DANG_GUI_NCC" },
    { label: "Chờ test", value: "CHO_TEST" },
    { label: "Test OK", value: "TEST_OK" },
    { label: "Gửi lại NCC", value: "GUI_LAI_NCC" },
  ]);
  const [dataDebtStatusSelect] = useState<any[]>([
    { label: "Nợ khách", value: "NO_KHACH" },
    { label: "Đã trả khách", value: "DA_TRA_KHACH" },
    { label: "Nợ hàng kho", value: "NO_HANG_KHO" },
    { label: "Đã trả hàng kho", value: "DA_TRA_HANG_KHO" },
    { label: "NCC nợ", value: "NCC_NO" },
    { label: "Đã nhập từ NCC", value: "DA_NHAP_TU_NCC" },
  ]);
  const [editViewId, setEditViewId] = useState<string | number | null>(null);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [toPageCreate, setToPageCreate] = useState(false);
  const [toPageEdit, setToPageEdit] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  //search
  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    vendorName: "",
    itemStatus: "",
    debtStatus: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState("", 500);

  const columns = React.useMemo<MRT_ColumnDef<DefectiveItem>[]>(
    () => [
      {
        accessorKey: "errorNumber",
        header: "Số phiếu tiếp nhận hàng lỗi",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Tooltip label="Xem chi tiết">
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                color={renderedCellValue === null ? "red" : green.base}
              >
                {renderedCellValue}
              </Badge>
            </Tooltip>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={350}>
            <Tooltip label={row.original.itemName}>
              <Text size="12.5px" fw={500} truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "serial",
        header: "Mã serial",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute6",
        header: "Trạng thái sản phẩm",
        Cell: ({ row }) => (
          <Badge
            color={getColorStatus(row.original.attribute6?.toString() ?? null)}
            radius={"sm"}
          >
            {getValueById(
              row.original.attribute6?.toString() ?? "",
              dataItemStatusSelect,
              "label"
            )}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "vendorName",
        header: "Nhà cung cấp",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute1",
        header: "Loại hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute8",
        header: "Mô tả lỗi",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Số phiếu nhập",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Số phiếu xuất",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "attribute7",
        header: "Trạng thái nợ",
        Cell: ({ row }) => (
          <Badge
            color={getColorDebtStatus(row.original.attribute7)}
            radius={"sm"}
            variant="light"
            bd={`1px solid ${getColorDebtStatus(row.original.attribute7)}`}
          >
            {getValueById(
              row.original.attribute7?.toString() ?? "",
              dataDebtStatusSelect,
              "label"
            )}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày tạo phiếu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      // {
      //   accessorKey: "action",
      //   header: "Thao tác",
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      //   size: 10,
      //   Cell: ({ row }) => (
      //     <Box
      //       style={{
      //         display: "flex",
      //         alignItems: "center",
      //         gap: "10px",
      //       }}
      //     >
      //       <Tooltip label="Chi tiết">
      //         <ActionIcon variant="light" aria-label="Settings" color="cyan">
      //           <IconEye size={20} stroke={1.5} />
      //         </ActionIcon>
      //       </Tooltip>
      //     </Box>
      //   ),
      // },
    ],
    [dataItemStatusSelect, dataDebtStatusSelect]
  );

  const getColorStatus = (value: string | null) => {
    switch (value) {
      case "TEST_LOI":
        return "#f21616";
      case "DANG_GUI_NCC":
        return "#099cff";
      case "CHO_TEST":
        return "#d9d02f";
      case "TEST_OK":
        return "#2bdd66";
      case "GUI_LAI_NCC":
        return "#f06418";
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  };

  const getColorDebtStatus = (value: string | null) => {
    switch (value) {
      case "NO_KHACH":
      case "NO_HANG_KHO":
      case "NCC_NO":
        return "#f21616";
      case "DA_TRA_KHACH":
      case "DA_NHAP_TU_NCC":
      case "DA_TRA_HANG_KHO":
        return "#2bdd66";
      case null:
        return "rgba(255, 255, 255, 0)";
    }
  };

  const optionColorItemStatus: Record<
    "Test lỗi" | "Đang gửi NCC" | "Chờ test" | "Test OK" | "Gửi lại NCC",
    string
  > = {
    "Test lỗi": "#f21616",
    "Đang gửi NCC": "#099cff",
    "Chờ test": "#d9d02f",
    "Test OK": "#2bdd66",
    "Gửi lại NCC": "#f06418",
  };

  const renderSelectOptionItemStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorItemStatus[
            option.label as
              | "Test lỗi"
              | "Đang gửi NCC"
              | "Chờ test"
              | "Test OK"
              | "Gửi lại NCC"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const optionColorDebtStatus: Record<
    | "Nợ khách"
    | "Đã trả khách"
    | "Nợ hàng kho"
    | "Đã trả hàng kho"
    | "NCC nợ"
    | "Đã nhập từ NCC",
    string
  > = {
    "Nợ khách": "#f21616",
    "Đã trả khách": "#2bdd66",
    "Nợ hàng kho": "#f21616",
    "Đã trả hàng kho": "#2bdd66",
    "NCC nợ": "#f21616",
    "Đã nhập từ NCC": "#2bdd66",
  };

  const renderSelectOptionDebtStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorDebtStatus[
            option.label as
              | "Nợ khách"
              | "Đã trả khách"
              | "Nợ hàng kho"
              | "Đã trả hàng kho"
              | "NCC nợ"
              | "Đã nhập từ NCC"
          ]
        }
        radius="sm"
        variant="light"
        bd={`1px solid ${
          optionColorDebtStatus[
            option.label as
              | "Nợ khách"
              | "Đã trả khách"
              | "Nợ hàng kho"
              | "Đã trả hàng kho"
              | "NCC nợ"
              | "Đã nhập từ NCC"
          ]
        }`}
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const handleCreate = () => {
    setToPageCreate(!toPageCreate);
    fetchData();
  };

  const handleEdit = () => {
    setToPageEdit(!toPageEdit);
    fetchData();
  };

  function createItem() {
    handleCreate();
  }

  function editItem(id: string | number) {
    setEditViewId(id);
    handleEdit();
  }

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-bang-ke-hang-loi.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.vendorName) {
      url += `&VendorName=${search.vendorName}`;
    }

    if (search.itemStatus) {
      url += `&ItemStatus=${search.itemStatus}`;
    }

    if (search.debtStatus) {
      url += `&DebtStatus=${search.debtStatus}`;
    }

    try {
      const response = await repositoryMdm.get<
        MessageResponse<DefectiveItem[]>
      >(`/api/v1/TblWarrantyReceiveTicket/get-list-defective-item${url}`);
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(response.data);
        setRowCount(result.length);
        setSelectIds([]);
        Promise.all([getDataVendorSelect()]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
    startCountdown();
  };

  const getDataVendorSelect = async () => {
    const getData = await getVendorSelect();
    setDataVendorSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Chi tiết bảng kê hàng lỗi !</Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (165 + headerHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex justify={"space-between"} wrap={"wrap"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify="end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconTransferIn size={14} />}
              color="blue"
              variant="outline"
            >
              Nhập BH NCC
            </Button>
            <Button
              leftSection={<IconTransferOut size={14} />}
              color="blue"
              variant="outline"
            >
              Xuất BH NCC
            </Button>
            <Button
              leftSection={<IconCubeSend size={14} />}
              color="green"
              variant="outline"
            >
              Xuất trả khách
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 12, lg: 2.75 }}>
            <DatePickerInput
              type="range"
              size="sm"
              placeholder="Từ ngày - Đến ngày"
              locale="vi"
              valueFormat="DD/MM/YYYY"
              leftSection={<IconCalendar size={"22px"} color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "startDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "endDate"
                );
              }}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Nhà cung cấp"
              data={dataVendorSelect}
              leftSection={<IconBuildingSkyscraper color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              comboboxProps={{ width: 425 }}
              onChange={(e) => handleChangeSearchValue(e ?? "", "vendorName")}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái sản phẩm"
              data={dataItemStatusSelect}
              leftSection={<IconChartCandle color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "itemStatus")}
              renderOption={renderSelectOptionItemStatus}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <Select
              placeholder="Trạng thái nợ"
              data={dataDebtStatusSelect}
              leftSection={<IconBrandGrammarly color="#15aabf" />}
              searchable
              clearable
              nothingFoundMessage="Không tìm thấy dữ liệu !"
              onChange={(e) => handleChangeSearchValue(e ?? "", "debtStatus")}
              renderOption={renderSelectOptionDebtStatus}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
            <TextInput
              placeholder="Nhập từ khóa"
              leftSection={<IconSearch color="#15aabf" />}
              defaultValue={keySearch}
              onChange={(e) => setKeySearch(e.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 6, md: 6, lg: 1.25 }}>
            <Button
              variant="outline"
              color="blue"
              leftSection={<IconSearch size={14} />}
              onClick={fetchData}
              w={"100%"}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "errorNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      // pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {toPageCreate ? (
        <CreateDataView
          onClose={setDeleteViewStatus}
          handleCreate={createItem}
        />
      ) : toPageEdit && editViewId ? (
        <EditDataView
          id={editViewId}
          onClose={setDeleteViewStatus}
          handleEdit={editItem}
        />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default DefectiveGoodsList;
