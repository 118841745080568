import {
  Badge,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Input,
  Menu,
  rem,
  Select,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconCaretDown,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconSearch,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import { TblOutwardGetList } from "../../../model/TblOutWard";
import { getTblInventoryTransactionTypeOutWard } from "../../../service/getSelectApi";
import { green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";

const InOutWarehouseOutWard = () => {
  const navigate = useNavigate();
  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblOutwardGetList[]>([]);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const dataInventoryTransactionType = [
    { label: "Điều Chuyển Chi Nhánh", value: "DIEU_CHUYEN_CHI_NHANH" },
    { label: "Điều Chuyển Nội Bộ", value: "DIEU_CHUYEN_NOI_BO" },
    { label: "Điều Chuyển Nội Bộ Serial", value: "DIEU_CHUYEN_NOI_BO_SERIAL" },
    { label: "Xuất Hủy", value: "XUAT_HUY" },
    { label: "Xuất Điều Chỉnh", value: "XUAT_DIEU_CHINH" },
    { label: "Xuất Trả Nhà Cung Cấp", value: "XUAT_TRA_NCC" },
    {
      label: "Điều Chuyển Chi Nhánh Serial",
      value: "DIEU_CHUYEN_CHI_NHANH_SERIAL",
    },
    {
      label: "Đơn Hàng",
      value: "DON_HANG",
    },
  ];

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [search, setSearch] = useState({
    keySearch: "",
    sourceType: "",
  });

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "order",
        header: "STT",
        size: 1,
        Cell: ({ row }) => {
          return row.index + 1;
        },
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        id: "requestTransfer",
        header: "Yêu Cầu Xuất Kho",
        columns: [
          {
            accessorKey: "sourceStatus",
            header: "Trạng thái",
            size: 20,
            Cell: ({ renderedCellValue }) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnActions: false,
            enableColumnFilter: false,
          },
          {
            accessorKey: "sourceCreateDate",
            header: "Thời gian tạo",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceFinishDate",
            header: "Thời gian hoàn thành",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceCode",
            header: "Mã yêu cầu xuất kho",
            size: 20,
            Cell: ({ renderedCellValue, row }: any) => (
              <Tooltip label="Xem chi tiết">
                <Badge
                  radius="sm"
                  variant="dot"
                  size="lg"
                  style={{ cursor: "pointer" }}
                  color={renderedCellValue === null ? "red" : green.base}
                  onDoubleClick={() =>
                    handleDetailRequest(
                      row.original.sourceType,
                      row.original.sourceCode
                    )
                  }
                >
                  {renderedCellValue === null
                    ? "Không tồn tại"
                    : renderedCellValue}
                </Badge>
              </Tooltip>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceCreateDep",
            header: "Phòng ban",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceCreateAddress",
            header: "Địa điểm làm việc",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceCreateRole",
            header: "Chức danh",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceCreateName",
            header: "Họ & Tên",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "sourceType",
            header: "Lý do",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "note1",
            header: "Ghi chú",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
        ],
      },
      {
        id: "delivery",
        header: "Giao hàng",
        columns: [
          {
            accessorKey: "deliveryCode",
            header: "GH",
            size: 20,
            Cell: ({ renderedCellValue }) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnActions: false,
            enableColumnFilter: false,
          },
          {
            accessorKey: "deliveryStatus",
            header: "Trạng thái",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryCreateDate",
            header: "Thời gian tiếp nhận",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryFinishDate",
            header: "Thời gian hoàn thành",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryCreateDep",
            header: "Phòng ban",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryCreateAddress",
            header: "Địa điểm làm việc",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryCreateRole",
            header: "Chức danh",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryCreateName",
            header: "Họ & Tên",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "deliveryDescription",
            header: "Ghi chú",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
        ],
      },
      {
        id: "outWard",
        header: "Bên xuất",
        columns: [
          {
            accessorKey: "owCreateDate",
            header: "Thời gian tạo",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owFinishDate",
            header: "Thời gian hoàn thành",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owCode",
            header: "Phiếu xuất",
            size: 20,
            Cell: ({ renderedCellValue, row }: any) => (
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "red" : green.base}
                onDoubleClick={() => {
                  if (row?.original?.owCode) {
                    detailOutWard(
                      row?.original?.owId,
                      row?.original?.sourceType,
                      true
                    );
                  }
                }}
              >
                {renderedCellValue === null
                  ? "Không tồn tại"
                  : renderedCellValue}
              </Badge>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owDep",
            header: "Phòng ban",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owAddress",
            header: "Địa điểm làm việc",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owFromInv",
            header: "Kho",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owCreateRole",
            header: "Chức danh",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owCreateName",
            header: "Họ & Tên",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "owDescription",
            header: "Ghi chú",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "gnhh",
            header: "GNHH",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "lechhang",
            header: "Lệch hàng",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
        ],
      },
      {
        id: "inWard",
        header: "Bên nhận",
        columns: [
          {
            accessorKey: "iwCreateDate",
            header: "Thời gian tạo",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwFinishDate",
            header: "Thời gian hoàn thành",
            Cell: ({ renderedCellValue }: any) => (
              <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwCode",
            header: "Phiếu nhập",
            size: 20,
            Cell: ({ renderedCellValue, row }: any) => (
              <Badge
                radius="sm"
                variant="dot"
                size="lg"
                style={{ cursor: "pointer" }}
                color={renderedCellValue === null ? "red" : green.base}
                onDoubleClick={async () => {
                  if (row?.original?.iwCode) {
                    detailInward(
                      row?.original?.sourceType,
                      row?.original?.iwId,
                      true,
                      true
                    );
                  }
                }}
              >
                {renderedCellValue === null
                  ? "Không tồn tại"
                  : renderedCellValue}
              </Badge>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "other",
            header: "Khác",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "fullname",
            header: "Họ & tên",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "number",
            header: "SĐT",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "a1",
            header: "Tỉnh/Thành",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "a2",
            header: "Quận/Huyện",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "a3",
            header: "Phường/Xã",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "a4",
            header: "Địa chỉ cụ thể",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "a5",
            header: "COD",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwDep",
            header: "Phòng ban",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwAddress",
            header: "Địa điểm làm việc",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwToInv",
            header: "Kho",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwCreateRole",
            header: "Chức danh",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwCreateName",
            header: "Họ & Tên",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "iwDescription",
            header: "Ghi chú",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "gnhh",
            header: "GNHH",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
          {
            accessorKey: "lechhang",
            header: "Lệch hàng",
            size: 20,
            Cell: ({ renderedCellValue }: any) => (
              <Text size="sm">{renderedCellValue}</Text>
            ),
            enableColumnFilter: false,
            enableColumnActions: false,
          },
        ],
      },
      {
        accessorKey: "lechhang1",
        header: "Lệch hàng",
        size: 1,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "canhbao1",
        header: "Cảnh báo",
        size: 1,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "giamsat1",
        header: "Giám sát",
        size: 1,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
    ],

    []
  );

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-quan-ly-xuat-nhap.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  // Detail:
  const detailOrder = async (sourceCode: string) => {
    const url = `/api/v1/CreateSellItem/details?orderNumber=${sourceCode}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);
    const data = response?.data;

    if (response && response.success) {
      const isReturnOrder = sourceCode.includes("DT");
      navigate(isReturnOrder ? "/sell/return-order" : "/sell/view-order", {
        state: {
          id: data?.orderHeader.id,
          orderNumber: data?.orderHeader.orderNumber,
          orderType: data?.orderHeader.orderTypeId.toString(),
          actionType: "view",
          ...(isReturnOrder && { action: "tra" }),
        },
      });
    }
  };

  const detailRequestTransfer = (sourceCode: string) => {
    navigate("/warehouse-config/detail-request-transfer", {
      state: { sourceCode: sourceCode },
    });
  };

  const detailOutWard = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean
  ) => {
    navigate("/warehouse-config/detail-warehouse-out-ward", {
      state: { id: id, type: type, isLocked: isLocked },
    });
  };

  const detailInward = (
    id: string | number,
    type: string | number | null,
    isLocked: boolean,
    detail: boolean
  ) => {
    navigate("/warehouse-config/edit-warehouse-in-ward-plus", {
      state: {
        id: id,
        sourceType: type,
        isLocked: isLocked,
        detail: detail,
      },
    });
  };

  const handleDetailRequest = (sourceType: string, sourceCode: string) => {
    switch (sourceType) {
      case "XUAT_BAN":
        detailOrder(sourceCode);
        break;
      default:
        detailRequestTransfer(sourceCode);
        break;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = !_q.desc;
      }
      url = url + "&Sort=" + _softing + "&IsOrder=" + isOrder;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblItemTransaction/transaction-management${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      } else setData([]);
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "order",
          "mrt-row-select",
          "sourceStatus",
          "sourceCreateDate",
          "sourceFinishDate",
          "sourceCode",
        ],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableFilters: false,
    enableSorting: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: () => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
              onKeyDown={async (e) => {
                if (e.key === "Enter") {
                  await fetchData();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.25 }}>
            <Select
              placeholder={"Loại chứng từ gốc"}
              searchable
              clearable
              leftSection={<IconFileTypography color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataInventoryTransactionType}
              nothingFoundMessage={"Loading..."}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "sourceType")
              }
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.25 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default InOutWarehouseOutWard;
