import {
  Badge,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  Menu,
  Select,
  SelectProps,
  Title,
  Tooltip,
  rem,
  Text,
  Box,
  ActionIcon,
} from "@mantine/core";
import {
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconGenderFemale,
  IconGenderMale,
  IconGenderThird,
  IconPlus,
  IconSearch,
  IconUsers,
  IconUsersGroup,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import {
  cobalt_blue,
  green,
  navy_blue,
  orange,
  pink,
  teal,
  white,
} from "../../../const/variables";
import { TblDMCustomer } from "../../../model/TblDMCustomerSell";
import { getTblDmCustomerGroupSelect } from "../../../service/getSelectApi";
import {
  formatDateTime,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import CreateDataView from "./CreateDataView";
import { modals } from "@mantine/modals";
import EditDataView from "./EditDataView";
import DetailDataView from "./DetailDataView";

const CustomerList = () => {
  //#region State
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDMCustomer[]>([]);
  const [, setDataTemp] = useState<TblDMCustomer[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [dataTblCustomerGroup, setDataTblCustomerGroup] = useState<{
    isLoad: boolean;
    data: any[];
  }>({
    isLoad: false,
    data: [],
  });

  const [search, setSearch] = useState({
    type: "",
    groupId: "",
    provinceId: "",
    keySearch: "",
  });

  // Grid span
  const gridSpan = { base: 6, sm: 4, md: 3, lg: 3, xl: 2 };

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "code",
        header: "MSKH",
        size: 30,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },

      // {
      //   accessorKey: "a",
      //   header: "Thời gian đăng ký",
      //   size: 30,
      //   Cell: ({ renderedCellValue }: any) => (
      //     <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
      //   ),
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      // },

      // {
      //   accessorKey: "b",
      //   header: "Kết nối lâu dài",
      //   size: 30,
      //   enableSorting: false,
      //   enableColumnActions: false,
      //   enableColumnFilter: false,
      // },

      {
        accessorKey: "groupName",
        header: "Nhóm khách hàng",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px" fw={"bold"}>
            {renderedCellValue}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },

      {
        accessorKey: "companyName",
        header: "Tên doanh nghiệp",
        Cell: ({ renderedCellValue, row }: any) => (
          <Text size="12.5px">
            {row.original.type?.toString() === "1"
              ? "Khách hàng cá nhân"
              : row.original.companyName}
          </Text>
        ),
        size: 30,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },

      {
        accessorKey: "taxCode",
        header: "Mã số thuế",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <Text size="12.5px">
            {renderedCellValue === null ? "Không tồn tại" : renderedCellValue}
          </Text>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },

      {
        accessorKey: "name",
        header: "Họ và tên",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "sex",
        header: "Giới tính",
        Cell: ({ renderedCellValue }: any) => (
          <>
            <Badge variant="light" color={getColorOfGender(renderedCellValue)}>
              <Flex align={"center"}>{renderGender(renderedCellValue)}</Flex>
            </Badge>
          </>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "birthOfDate",
        header: "Ngày sinh nhật",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTime(renderedCellValue)}</>
        ),
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "telephoneNumber",
        header: "Số điện thoại",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 30,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "provinceName",
        header: "Tỉnh/Thành phố",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "districtName",
        header: "Quận/Huyện",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "communeName",
        header: "Phường/Xã",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "address",
        header: "Địa chỉ cụ thể",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "description",
        header: "Ghi chú",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-khach-hang.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const dataCustomerGrouplSelect = async () => {
    try {
      const getData = await getTblDmCustomerGroupSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblCustomerGroup((prev) => ({
        ...prev,
        data: mappedData,
      }));
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.groupId) {
      url += `&GroupId=${search.groupId}`;
    }

    if (search.provinceId) {
      url += `&ProvinceId=${search.provinceId}`;
    }

    if (search.type) {
      url += `&Type=${search.type}`;
    }

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    try {
      const response = await repositoryPos.get(
        `/api/v1/TblDmCustomer/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  function createItem() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Tạo mới khách hàng!</Title>
        </>
      ),
      size: "auto",
      children: <CreateDataView onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }
  function editItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Sửa khách hàng!</Title>
        </>
      ),
      size: "auto",
      children: <EditDataView id={id} onClose={setDeleteViewStatus} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }
  function detailItem(id: string | number) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xem chi tiết khách hàng!</Title>
        </>
      ),
      size: "auto",
      children: <DetailDataView id={id} />,
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const optionColorsAccount: Record<"Cá nhân" | "Doanh nghiệp", string> = {
    "Cá nhân": orange.vivid,
    "Doanh nghiệp": navy_blue.base,
  };

  const renderSelectOptionType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={optionColorsAccount[option.label as "Cá nhân" | "Doanh nghiệp"]}
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const getColorOfGender = (genderId: number | string) => {
    switch (genderId) {
      case 1:
        return "green";
      case 2:
        return pink.base;
      case 3:
        return teal.base;
      default:
        return "gray";
    }
  };

  const renderGender = (genderId: number | string) => {
    switch (genderId) {
      case 1:
        return (
          <>
            <IconGenderMale size={14} color={"green"}></IconGenderMale>
            Nam
          </>
        );
      case 2:
        return (
          <>
            <IconGenderFemale size={14} color={pink.base}></IconGenderFemale>
            Nữ
          </>
        );
      case 3:
        return (
          <>
            <IconGenderThird size={14} color={teal.base}></IconGenderThird>
            Khác
          </>
        );
      default:
        return <>Không xác định</>;
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (dataTblCustomerGroup.isLoad && dataTblCustomerGroup.data?.length === 0)
      dataCustomerGrouplSelect();
  }, [dataTblCustomerGroup.isLoad]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus, pagination]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (180 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,

    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "code"],
        right: ["action"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: true,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      onDoubleClick: async () => await detailItem(row.original?.id),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      <>
        <div
          ref={headerRef}
          style={{
            width: "100%",
            border: "1px solid #dee2e6",
            padding: "10px",
          }}
        >
          <_breadcrumb></_breadcrumb>
          <Grid>
            <Grid.Col span={{ base: 12, sm: 8, md: 8, lg: 8, xl: 8 }}>
              <Grid align="center" gutter={"xs"}>
                <Grid.Col span={gridSpan}>
                  <Select
                    size="sm"
                    placeholder="Loại khách hàng"
                    searchable
                    clearable
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    data={[
                      {
                        value: "1",
                        label: "Cá nhân",
                      },
                      {
                        value: "2",
                        label: "Doanh nghiệp",
                      },
                    ]}
                    w={"100%"}
                    leftSection={
                      <IconUsers
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconUsers>
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(e?.toString() ?? "", "type");
                    }}
                    renderOption={renderSelectOptionType}
                  />
                </Grid.Col>

                <Grid.Col span={gridSpan}>
                  <Select
                    flex={1}
                    placeholder="Nhóm khách hàng"
                    searchable
                    clearable
                    comboboxProps={{
                      transitionProps: { transition: "pop", duration: 200 },
                    }}
                    w={"100%"}
                    data={dataTblCustomerGroup.data}
                    leftSection={
                      <IconUsersGroup
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconUsersGroup>
                    }
                    onClick={() =>
                      setDataTblCustomerGroup((prev) => ({
                        ...prev,
                        isLoad: true,
                      }))
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(e?.toString() ?? "", "groupId");
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={gridSpan}>
                  <Input
                    flex={1}
                    placeholder="Nhập từ khóa tìm kiếm"
                    type="text"
                    w={"100%"}
                    leftSection={
                      <IconSearch
                        size={"20"}
                        color={cobalt_blue.base}
                      ></IconSearch>
                    }
                    onChange={(e) => {
                      handleChangeSearchValue(
                        e.target.value ?? "",
                        "keySearch"
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={gridSpan}>
                  <Button
                    leftSection={<IconSearch size={14} />}
                    color="blue"
                    variant="outline"
                    onClick={async () => {
                      await fetchData();
                    }}
                  >
                    Tìm kiếm
                  </Button>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 4, md: 4, lg: 4, xl: 4 }}>
              <Flex
                gap="md"
                justify="flex-end"
                align="center"
                direction="row"
                wrap="wrap"
              >
                <Button
                  leftSection={<IconPlus size={14} />}
                  color="blue"
                  variant="outline"
                  onClick={async () => {
                    createItem();
                  }}
                >
                  Thêm mới
                </Button>
                <Menu shadow="md" width={200}>
                  <Menu.Target>
                    <Button
                      // size="xs"
                      rightSection={
                        <IconCaretDown
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                    >
                      Chức năng
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      leftSection={
                        <IconFileExport
                          style={{ width: rem(14), height: rem(14) }}
                        />
                      }
                      onClick={handleExport}
                    >
                      Export Excel
                    </Menu.Item>
                    <Tooltip label={"Chức năng đang update"}>
                      <Menu.Item
                        leftSection={
                          <IconFileUpload
                            style={{ width: rem(14), height: rem(14) }}
                          />
                        }
                      >
                        Import Excel
                      </Menu.Item>
                    </Tooltip>
                  </Menu.Dropdown>
                </Menu>
              </Flex>
            </Grid.Col>
          </Grid>
        </div>
        <div>
          <MantineReactTable table={table} />
        </div>
      </>
    </>
  );
};
export default CustomerList;
