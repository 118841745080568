import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Select,
  Text,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _localization } from "../../../config/location";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { TblWarrantyItem } from "../../../model/TblWarrantyItem";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { modals } from "@mantine/modals";
import OutsideProduct from "./OutsideProduct";

const ItemList = ({
  onSelect,
  dataItem,
  isDetail,
  onDelete,
  actionView,
  setDataWarrantyItem,
  serviceType,
  setFormValues,
}: ItemListProps) => {
  //#region state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblWarrantyItem[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);

  const [serial, setSerial] = useState("");
  const [optionScan, setOptionScan] = useState(false);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "serial",
        header: "Serial",
        size: 30,
      },
      {
        accessorKey: "parentSerial",
        header: "Serial cha",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue ?? "Không tồn tại"}</>
        ),
      },
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ row }) => (
          <Box w={300}>
            <Tooltip label={row.original.itemName}>
              <Text size="11.5px" truncate="end" h={15}>
                {row.original.itemName}
              </Text>
            </Tooltip>
          </Box>
        ),
        size: 250,
      },
      {
        accessorKey: "buyingDate",
        header: "Ngày mua",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "orderCode",
        header: "Hóa đơn",
        size: 30,
      },
      {
        accessorKey: "warrantyTerm",
        header: "Hạn bảo hành",
        Cell: (row) => <>{row.renderedCellValue} tháng</>,
        size: 30,
      },
      {
        accessorKey: "serviceType",
        header: "Loại dịch vụ",
        Cell: ({ row }) => (
          <Select
            value={
              actionView !== "DETAIL"
                ? row.original.serviceType
                  ? row.original.serviceType
                  : "Bảo hành"
                : serviceType !== undefined
                ? serviceType[row.index].serviceType?.toString()
                : null
            }
            data={[
              { label: "Bảo hành", value: "Bảo hành" },
              { label: "Hỗ trợ", value: "Hỗ trợ" },
              { label: "Dịch vụ", value: "Dịch vụ" },
            ]}
            disabled={actionView === "DETAIL"}
            onChange={(e) =>
              setDataWarrantyItem((prev: any) =>
                prev.map((item: any, index: number) =>
                  index === row.index ? { ...item, serviceType: e } : item
                )
              )
            }
          />
        ),
        size: 30,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {optionScan ? (
              <Tooltip label="Điền thông tin sản phẩm">
                <ActionIcon
                  variant="light"
                  color="green"
                  onClick={() => modalOutsideProduct(row.index)}
                >
                  <IconEdit size={20} stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={() => {
                  deleteData(row.original.serial ?? "");
                }}
                disabled={actionView === "DETAIL"}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [serviceType, optionScan]
  );

  const deleteData = (serial: string) => {
    setData((prev) => prev.filter((e) => e?.serial !== serial));
    onSelect((prev: any) => prev.filter((e: any) => e?.serial !== serial));
    onDelete((prev: any) => prev.filter((e: any) => e?.serial !== serial));
  };

  const modalOutsideProduct = (index: number) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin sản phẩm</Title>
        </>
      ),
      size: "auto",
      children: (
        <OutsideProduct
          setDataWarrantyItem={setDataWarrantyItem}
          index={index}
          dataItem={dataItem}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const getDataItem = async () => {
    if (serial.length > 0) {
      const res = await repositoryPos.get(
        `/api/v1/TblInventorySerialOnHand/get-list?KeySearch=${serial}&Take=100`
      );

      if (res && res?.success) {
        const result = res.data;
        setSerial("");
      } else {
        setSerial("");
      }
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList?.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (onSelect) onSelect(data);
  }, [data]);

  useEffect(() => {
    setData(
      dataItem.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => JSON.stringify(t) === JSON.stringify(value))
      )
    );
  }, [dataItem]);

  useEffect(() => {
    if (dataItem.length === 0) {
      setOptionScan(false);
    }
  }, [dataItem]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (460 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data: dataItem,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef}>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Text p={"xs"} fw={"bold"}>
            Thông tin sản phẩm
          </Text>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: () =>
      !isDetail && (
        <Flex gap={"md"} align={"center"}>
          <TextInput
            placeholder="Nhập mã serial hoặc quét mã"
            value={serial}
            onChange={(e) => setSerial(e.currentTarget.value)}
          />
          <Button disabled={optionScan} color={"green"}>
            Quét mã
          </Button>
          <Text size="sm" c={"#BFBFBF"} fs={"italic"}>
            hoặc
          </Text>
          <Button
            color="blue"
            onClick={() => {
              setOptionScan(true);
              setSerial("");
              setDataWarrantyItem((prev: TblWarrantyItem[]) => [
                ...prev,
                {
                  itemId: null,
                  itemName: null,
                  itemCode: null,
                  orderId: null,
                  orderCode: null,
                  serial: serial,
                  parentSerial: null,
                  warrantyTerm: 0,
                  customerId: null,
                  phone: null,
                  customerName: null,
                  saleChanelId: null,
                  buyingDate: null,
                  serviceType: "Bảo hành",
                },
              ]);
              setFormValues((prev: TblWarrantyServiceRequestForm) => ({
                ...prev,
                attribute6: "Hàng ngoài",
              }));
            }}
          >
            Quét hàng ngoài
          </Button>
        </Flex>
      ),
    renderBottomToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.itemId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnVisibility: { id: false, action: !isDetail },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "11px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

type ItemListProps = {
  onSelect?: any;
  isDetail?: boolean;
  dataItem: TblWarrantyItem[];
  onDelete: any;
  actionView?: any;
  setDataWarrantyItem?: any;
  serviceType?: any;
  setFormValues?: any;
};
export default ItemList;
