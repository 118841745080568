import "../../Styles/tab.component.css";

import {Paper, Tabs} from "@mantine/core";
import {useState} from "react";
import BreadcrumbLayout1 from "../../_base/component/_layout/_breadcrumb";
import DebtGroupList from "./DebtGroupList";


const Commodities = () => {
    const [activeTab, setActiveTab] = useState<string | null>("customerGroup");
    console.log(activeTab);

    return (
        <>
            <div
                style={{
                    border: "1px solid #dee2e6",
                    padding: "0px 10px",
                }}
            >
                <BreadcrumbLayout1></BreadcrumbLayout1>
            </div>
            <Paper shadow="md" radius="lg">
                <Tabs p={"xs"} value={activeTab} onChange={setActiveTab}>
                    <Tabs.List>
                        <Tabs.Tab value="customerGroup">Danh mục mã nhóm khách hàng</Tabs.Tab>
                        <Tabs.Tab value="vendorGroup">Danh mục mã nhà cung cấp</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="customerGroup">
                        {activeTab === "customerGroup" ? <DebtGroupList type={1}/> : <></>}
                    </Tabs.Panel>
                    <Tabs.Panel value="vendorGroup">
                        {activeTab === "vendorGroup" ? <DebtGroupList type={2}/> : <></>}
                    </Tabs.Panel>

                </Tabs>
            </Paper>
        </>
    );
};

export default Commodities;
