import { Button, Flex, Grid, GridCol, Input } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { useForm } from "@mantine/form";
type TypeCategoryCombination = {
  itemId: number;
  itemCode: string;
  itemName: string;
  maHang: string;
  tenHang: string;
  maLinhVuc: string;
  tenLinhVuc: string;
  maNganh: string;
  tenNganh: string;
  maLoai: string;
  tenLoai: string;
  maChung: string;
  tenChung: string;
  maNhom: string;
  tenNhom: string;
  maModel: string;
  tenModel: string | null;
  maHangSx: string | null;
  tenHangSx: string | null;
};

const CategoryCombination = () => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TypeCategoryCombination[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const formSeachInput = useForm({
    mode: "uncontrolled",
    initialValues: {
      keySearch: "",
    },
  });

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);

    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex}&Take=${pagination.pageSize}`;

    if (formSeachInput.getValues().keySearch) {
      url += `&KeySearch=${formSeachInput.getValues().keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDmItemCategoryCombination/get-list${url}`
      );
      if (response && response.httpStatusCode === 200) {
        let result = response.data;
        setIsLoading(false);
        setData(result);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
    }
  };
  const columns = React.useMemo<MRT_ColumnDef<TypeCategoryCombination>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã Sản Phẩm",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "itemName",
        header: "Tên Sản Phẩm",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maHang",
        header: "Mã Hàng",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenHang",
        header: "Tên Hàng",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maLinhVuc",
        header: "Mã Lĩnh Vực",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenLinhVuc",
        header: "Tên Lĩnh Vực",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maNganh",
        header: "Mã Ngành",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNganh",
        header: "Tên Ngành",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maLoai",
        header: "Mã Loại",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenLoai",
        header: "Tên Loại",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maChung",
        header: "Mã Chung",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenChung",
        header: "Tên Chung",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maNhom",
        header: "Mã Nhóm",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenNhom",
        header: "Tên Nhóm",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maModel",
        header: "Mã Model",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenModel",
        header: "Tên Model",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "maHangSx",
        header: "Mã Hãng Sản Xuất",
        size: 30,
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
      {
        accessorKey: "tenHangSx",
        header: "Tên Hãng Sản Xuất",
        Cell: ({ renderedCellValue }) => <>{renderedCellValue}</>,
      },
    ],
    [pagination?.pageSize, pagination?.pageIndex]
  );

  const table = useMantineReactTable({
    columns,
    data: data || [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,

    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
        // right: ["action"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },
    enableRowSelection: false,
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontSize: "11.5px",
        padding: "4px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });
  useEffect(() => {
    fetchData();
  }, [pagination]);
  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (200 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);
  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
        </Flex>
        <form
          onSubmit={formSeachInput.onSubmit(async () => await fetchData())}
          style={{ flex: 1 }}
        >
          <Grid mt={10}>
            <GridCol span={{ base: 6, sm: 4, md: 1.5 }}>
              <Input
                w={"100%"}
                placeholder="Từ khoá"
                type="text"
                leftSection={<IconSearch color="#15aabf" />}
                key={formSeachInput.key("keySearch")}
                {...formSeachInput.getInputProps("keySearch")}
              />
            </GridCol>
            <GridCol span={{ base: 4, sm: 2, md: 1 }}>
              <Button
                type="submit"
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
              >
                Tìm kiếm
              </Button>
            </GridCol>
          </Grid>
        </form>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default CategoryCombination;
