import { Badge, NumberFormatter } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { _timerDelayClick } from "../../_base/_const/_constVar";
import { _localization } from "../../config/location";
import { paginationBase } from "../../_base/model/_base/BaseTable";
import { green } from "../../const/variables";

const ListProductDetail = ({
  dataDetail,
  isDetail,
}: {
  dataDetail: any;
  isDetail: any;
}) => {
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "Không xác định" : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "serial",
        header: "Số serial",
      },
      {
        accessorKey: "primaryUomCode",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quality",
        header: "Số lượng",
      },
      {
        accessorKey: "discountRate",
        header: "Chiết khấu ( % )",
      },
      {
        accessorKey: "discountAmount",
        header: "Tiền chiết khấu",
      },
      {
        accessorKey: "discountAdded",
        header: "Giảm thêm đơn",
      },
      {
        accessorKey: "priceFinal",
        header: "Tiền sau CK",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "vatRate",
        header: "VAT (%)",
        // enableEditing: false,
      },
      {
        accessorKey: "vatAmount",
        header: "Tiền VAT",
        // enableEditing: false,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "totalLineAmount",
        header: "Thành tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "lineTyle",
        header: "Loại mặt hàng",
      },
      {
        accessorKey: "status",
        header: "Trạng thái",
      },
      {
        accessorKey: "description",
        header: "Ghi chú",
      },
    ],
    []
  );

  //data and fetching state
  const [data] = useState<any[]>([dataDetail]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState(paginationBase);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectIds, setSelectIds] = useState<string[]>([]);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  const table = useMantineReactTable({
    columns,
    data: dataDetail ? dataDetail : [],
    positionToolbarAlertBanner: "bottom",
    columnFilterDisplayMode: "popover",
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: false,
    enablePagination: true,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["itemCode"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: !isDetail ? 225 : "275px",
        minHeight: !isDetail ? 100 : "275px",
      },
    },
    enableStickyHeader: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    rowCount,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    mantineTableProps: () => ({
      striped: true,
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};

export default ListProductDetail;
