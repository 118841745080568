import {
  ActionIcon,
  Anchor,
  Badge,
  Box,
  Breadcrumbs,
  Button,
  ComboboxItem,
  Flex,
  Grid,
  GridCol,
  Input,
  Menu,
  Select,
  Text,
  Tooltip,
  rem,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useHotkeys } from "@mantine/hooks";
import {
  IconCaretDown,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileTypography,
  IconFileUpload,
  IconPlus,
  IconSearch,
  IconTimeDuration15,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";

import { useNavigate } from "react-router";
import {
  _timerDelayClick,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  getTblInventoryTransactionTypeInWard,
  getTblInventoryTransactionTypeOutWard,
} from "../../../service/getSelectApi";

import { IconFilePlus } from "@tabler/icons-react";
import { useSearchParams } from "react-router-dom";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { SelectListItemBase } from "../../../_base/model/_base/SelectListItemBase";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { green, peach, sky_blue, teal } from "../../../const/variables";
import { TblRequestInventoryTransactionList } from "../../../model/TblRequestInventoryTransaction";

function getColorSourceType(text: any) {
  switch (text) {
    case "XUAT_DIEU_CHUYEN_CHI_NHANH":
      return "#f06418";
    case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
      return "#ffab09";
    case "XUAT_HUY":
      return "#d9d02f";
    case "XUAT_DIEU_CHINH":
      return "#6bd731";
    case "XUAT_DIEU_CHUYEN_NOI_BO":
      return "#09b8ff";
    case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
      return "#1f32c4";
    case "XUAT_TRA_NCC":
      return "#2bdd66";
    case "XUAT_BAN":
      return teal.base;
    case "NOI_BO":
      return "#656a7e";
    case null:
      return "rgba(0, 0, 0, 0)";
  }
}

function getColorStatus(text: any) {
  switch (text) {
    case true:
      return "teal";
    case false:
      return "red";
    default:
      return "yellow";
  }
}

function getStatus(text: any) {
  switch (text) {
    case "1":
      return "Tạo mới";
    case "2":
      return "Lưu tạm";
    case "3":
      return "Hoàn thành (Khoá)";
    default:
      return "Tạo mới";
  }
}

const RequestInventoryTransaction = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("Type");

  // Reference data from another table

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblRequestInventoryTransactionList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);

  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [pagination, setPagination] = useState(paginationBase);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useState({
    keySearch: "",
    fromDate: "",
    toDate: "",
    sourceType: "",
  });

  const breadcrumbs = React.useMemo(
    () => [
      {
        link: "/",
        title: "Trang chủ",
      },
      {
        title: "Cấu hình kho hàng",
      },
      {
        title: "Danh sách đơn cần xuất",
      },
    ],
    []
  );

  const items = React.useMemo(
    () =>
      breadcrumbs?.map((item, index) =>
        index === 0 ? (
          <Anchor href={item.link} key={index}>
            {item.title}
          </Anchor>
        ) : (
          <p key={index}>{item.title}</p>
        )
      ),
    [breadcrumbs]
  );

  const columns = React.useMemo<
    MRT_ColumnDef<TblRequestInventoryTransactionList>[]
  >(
    () => [
      {
        accessorKey: "order",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 1,
        enableSorting: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "sourceCode",
        header: "Mã chứng từ gốc",
        Cell: ({ renderedCellValue }) => (
          <Badge
            color={renderedCellValue === null ? "red" : green.base}
            radius="sm"
            variant="dot"
            size="lg"
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      // {
      //   accessorKey: "sourceCodeParent",
      //   header: "Mã chứng từ gốc cha",
      // },
      {
        accessorKey: "sourceTypeName",
        header: "Loại phiếu",
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Badge color={getColorSourceType(row.original.sourceType)}>
              {renderedCellValue}
            </Badge>
          </>
        ),
      },
      {
        accessorKey: "createDate",
        header: "Ngày yêu cầu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
      },
      {
        accessorKey: "exportAddress",
        header: "Bên xuất",
        size: 30,
      },
      {
        accessorKey: "receiveAddress",
        header: "Bên nhận",
        Cell: ({ renderedCellValue }: any) => <>{renderedCellValue}</>,
        size: 30,
      },
      {
        accessorKey: "receiver",
        header: "Người nhận",
        size: 30,
      },
      {
        accessorKey: "statusCode",
        header: "Trạng thái",
        size: 20,
        Cell: ({ row }) => <>{row.original.statusCode}</>,
      },

      {
        accessorKey: "note",
        header: "Ghi chú",
        size: 30,
      },

      {
        accessorKey: "action",
        header: "Thao tác",
        size: 10,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Tạo phiếu">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="blue"
                disabled={
                  !["KHOA", "DON_HANG_HOAN_THANH"].includes(
                    row.original?.statusCode ?? ""
                  ) || row.original?.sourceTranCode === "DA_XUAT"
                }
                onClick={async () => {
                  createData(row.original.sourceCode, row.original.sourceType);
                }}
              >
                <IconFilePlus size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                disabled={
                  !row.original?.sourceTranCode ||
                  row.original?.sourceTranCode !== "DANG_XUAT"
                }
                onClick={async () => {
                  await editItem(row);
                }}
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const editItem = async (row: any) => {
    navigate("/warehouse-config/edit-warehouse-out-ward", {
      state: {
        sourceCode: row.original?.sourceCode,
        type: row.original?.sourceType,
      },
    });
  };

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-phieu-xuat-kho.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const createData = (
    sourceCode?: string | null,
    sourceType?: string | null
  ) => {
    // if (typeParam === "2") {

    // } else if (typeParam === "1") {
    //   if (sourceCode) {
    //     if (sourceType === "PO") {
    //       navigate("/warehouse-config/create-warehouse-purchase-in-ward", {
    //         state: { sourceCode: sourceCode, sourceType: sourceType },
    //       });
    //     } else {
    //       navigate("/warehouse-config/create-warehouse-in-ward", {
    //         state: { sourceCode: sourceCode, sourceType: sourceType },
    //       });
    //     }
    //   } else {
    //     if (sourceType === "PO") {
    //       navigate("/warehouse-config/create-warehouse-purchase-in-ward");
    //     } else {
    //       navigate("/warehouse-config/create-warehouse-in-ward");
    //     }
    //   }
    // }
    if (sourceCode) {
      navigate("/warehouse-config/create-warehouse-out-ward", {
        state: { sourceCode: sourceCode, sourceType: sourceType },
      });
    } else {
      navigate("/warehouse-config/create-warehouse-out-ward");
    }
  };

  //   const editData = (id: string | number) => {
  //     navigate("/warehouse-config/edit-warehouse-out-ward", {
  //       state: { id: id },
  //     });
  //   };

  useHotkeys([
    [
      "F11",
      () => {
        createData();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }&Type=${2}`;

    if (search.keySearch) {
      url += `&KeySearch=${search.keySearch}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (search.fromDate) {
      url += `&FromDate=${search.fromDate}`;
    }

    if (search.toDate) {
      url += `&ToDate=${search.toDate}`;
    }

    let _softing = "";
    let isOrder = false;
    if (sorting && sorting.length > 0) {
      let _q = sorting[0];
      if (_q) {
        _softing = _q.id;
        isOrder = _q.desc;
      }
      url = url + "&sort=" + _softing + "&isOrder=" + isOrder;
    }

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblRequestInventoryTransactionList[]>
      >(
        `/api/v1/RequestInventoryTransaction/get-list-request-transaction${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setIsLoading(false);
        setData(result || []);
        setRowCount(response.totalCount);
        setSelectIds([]);
        table.resetRowSelection();
      } else {
        setData([]);
        setRowCount(0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsRefetching(false);
      setIsLoading(false);
    }
    startCountdown();
  };

  const fetchdataInventoryTransactionType = async () => {
    const response: SelectListItemBase[] =
      await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({
        value: type.value.toString(),
        label: type.text,
      }))
    );
  };

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [sorting, pagination]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    // getRowId: (row) => row.transactionId?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "order", "sourceCode"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 12px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
      sorting,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
  });

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <Breadcrumbs key={"Breadcrumbs"}>{items}</Breadcrumbs>
          <Flex gap="md" align="center" direction="row" wrap="wrap">
            <Button
              leftSection={<IconPlus size={14} />}
              color={sky_blue.base}
              variant="outline"
              onClick={() => {
                createData();
              }}
            >
              Tạo mới
            </Button>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>

        <Grid mt={"10px"}>
          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.75 }}>
            <Input
              w={"100%"}
              placeholder="Từ khoá"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </GridCol>

          <GridCol span={{ base: 6, sm: 4, md: 2, lg: 1.75 }}>
            <Select
              placeholder={"Loại chứng từ gốc"}
              searchable
              clearable
              leftSection={<IconFileTypography color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataInventoryTransactionType}
              nothingFoundMessage={"Không có dữ liệu"}
              onChange={(value) =>
                handleChangeSearchValue(value || "", "sourceType")
              }
              onClick={() => {
                if (dataInventoryTransactionType.length === 0) {
                  fetchdataInventoryTransactionType();
                }
              }}
            />
          </GridCol>

          <GridCol span={{ base: 8, sm: 6, md: 3.35, lg: 2.5 }}>
            <DatePickerInput
              // type="multiple"
              type="range"
              size="sm"
              allowSingleDateInRange
              placeholder="Chọn khoảng ngày"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              locale="vi"
              // value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => {
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[0]) ?? "",
                  "fromDate"
                );
                handleChangeSearchValue(
                  formatDateNotTimeZone(e[1]) ?? "",
                  "toDate"
                );
              }}
              clearable
            />
          </GridCol>

          <GridCol span={{ base: 4, sm: 4, md: 1.5, lg: 1.25 }}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </GridCol>
        </Grid>
      </div>

      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default RequestInventoryTransaction;
