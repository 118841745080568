//#region import
import {
  ActionIcon,
  Box,
  Button,
  CSSProperties,
  Card,
  Collapse,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  Menu,
  NumberFormatter,
  NumberInput,
  Switch,
  Table,
  Text,
  TextInput,
  Textarea,
  Title,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  useDebouncedCallback,
  useDebouncedState,
  useDisclosure,
  useHotkeys,
} from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconCaretDown,
  IconCheck,
  IconCreditCard,
  IconDiscount,
  IconMaximize,
  IconMaximizeOff,
  IconPlus,
  IconPrinter,
  IconSearch,
  IconTrash,
  IconWindow,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_Row,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../../_base/socket/ToplicList";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import EditableTextWithOptions from "../../../common/selectValue";
import { _localizationSales } from "../../../config/location";
import { cobalt_blue, orange } from "../../../const/variables";
import { DepartmentModel } from "../../../model/DepartmentModel";
import { EmployeeModel } from "../../../model/Employee";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  OrderHeader,
  dataOrderDetailTable,
} from "../../../model/SaleOrderModel";
import { ProductModel } from "../../../model/TblDMItem";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import {
  ItemDetailPriceModel,
  TblItemPromotionModel,
  itemAddOnPromotionModel,
} from "../../../model/TblItem";
import "../../../Styles/TableBodyCell.module.css";
import { LineType, OrderStatus } from "../orderStatus";
import PrintInvoice from "../PrintInvoice";
import { exchangeReasons, returnReasons } from "../ReturnOrder/ReasonOption";
import CustomerInformation from "./ModalComponent/ModalCustomerInformation";
import ModalDeliveryInformation from "./ModalComponent/ModalDeliveryInformation";
import { PaymentMethod } from "./ModalComponent/ModalPayment";
import { PaymentMethodMb } from "./ModalComponent/ModalPaymentMb";
import Voucher from "./ModalComponent/Voucher";
import {
  TableSelectProps,
  customerCheck,
  dataCreateSalesOrder,
} from "./Model/modelRetailOrder";
import { store } from "./ReduxToolkit/store";
import { TblDebtGroup } from "../../../model/TblDebtGroup";
import AddFormDebt from "../AddFormDebt/AddFormDebt";

const RetailOrder = () => {
  //#region   khai báo state
  const navigate = useNavigate();
  const location = useLocation();
  // state lưu tất cả các giá trị tiền
  const [listSerial, setListSerial] =
    useState<{ value: string; label: string }[]>();
  const [totalKM, setTotalKM] = useState(0);
  const [totalXGDetail, setTotalXGDetail] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0); // Tổng tiền hàng
  const [payableAmount, setPayableAmount] = useState(0); // Số tiền cần thanh toán
  const [cashPayment, setCashPayment] = useState(0); // Tiền mặt
  const [bankTransfer, setBankTransfer] = useState(0); // Chuyển khoản
  const [cardPayment, setCardPayment] = useState(0); // Cà thẻ
  const [vnpayPayment, setVnpayPayment] = useState(0); // VNPay
  const [totalPaid, setTotalPaid] = useState(0); // Tổng
  const [deferredPayment, setDeferredPayment] = useState(0); // Thanh toán sau
  const [currentTime, setCurrentTime] = useState(new Date());
  const [contractcode, setContractcode] = useState("");
  const [listEmployeeSales, setListEmployeeSales] = useState<any[]>([]);
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [branchId, setBranchId] = useState<number>();
  const [branchName, setBranchName] = useState<string>("");
  const [employeeSalesId, setEmployeeSalesId] = useState("");
  const [listDepartment, setListDepartment] = useState<DepartmentModel[]>([]);
  const [departmentId, setDepartmentId] = useState<Number>();
  const [employeeSalesName, setEmployeeSalesName] = useState("");
  const [department, setDepartment] = useState("");
  const [orderStatus, setOrderStatus] = useState<string>("noCreate");
  const [shipping, setShipping] = useState("Nhận tại cửa hàng");
  const [isCOD, setIsCOD] = useState("COD");
  const [saleChanelList, setSaleChanelList] = useState<any[]>();
  const [saleChanelId, setSaleChanelId] = useState<number>();
  const [saleChanel, setSaleChanel] = useState("Khách đến showroom");
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [listAdd, setListAdd] = useState<dataOrderDetailTable[]>([]);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataProducts, setDataProducts] = useState<ProductModel[]>([]);
  const [valueDebounced, setValueDebounced] = useDebouncedState("", 500);
  const [paymentDone, setPaymentDone] = useState<string[]>([]);
  const [userLogin, setUserLogin] = useState<any>();
  const [idPrint, setIdPrint] = useState<any>([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [loading, { close: closeLoading, open: openLoading }] =
    useDisclosure(false);
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);

  //#region  state object
  const [dataDeliveryInformation, setDataDeliveryInformation] = useState<any>();
  const [dataPayment, setDataPayment] = useState<any>();
  const [orderHeader, setOrderHeader] = useState<OrderHeader>({
    id: 0, // ID của đơn hàng - OK
    orderNumber: "", // Số đơn hàng - đã ghép vào field "Mã đơn hàng"
    orderTypeId: 2, // Loại đơn hàng (2 là đơn hàng lẻ)
    orderDate: "2024-01-01T01:00:00.000Z", // Ngày đặt hàng - đã ghép vào field "Thời gian"
    orderStatus: "DON_HANG_TAM", // Trạng thái đơn hàng (pending: đang chờ) -đã ghép vào trạng thái góc trên phải
    note: "", // Ghi chú đơn hàng - đã ghép vào field "Ghi chú"
    orgId: 0, // ID tổ chức
    invOrgId: 0, // ID tổ chức liên quan đến kho hàng
    deptId: 0, // ID phòng ban
    divisionId: 0, // ID bộ phận
    saleChanelId: 0, // ID kênh bán hàng
    sourceOrderNumber: "", // Số đơn hàng nguồn
    sourceDocument: "", // Tài liệu nguồn
    sourceInvoices: "", // Hóa đơn nguồn
    custId: 0, // ID khách hàng
    custGroupId: 0, // ID nhóm khách hàng
    contactPersonId: 0, // ID người liên hệ
    billToLocationId: 0, // ID địa điểm hóa đơn
    shipToLocationId: 0, // ID địa điểm giao hàng
    invoiceSerial: "", // Số serial hóa đơn
    invoiceNumber: "", // Số hóa đơn
    invoiceDate: "2024-07-16T04:10:09.167Z", // Ngày hóa đơn
    taxCode: "", // Mã số thuế
    taxAddr: "", // Địa chỉ thuế
    amountTotal: 0, // Tổng số tiền
    amountDiscount: 0, // Số tiền giảm giá
    amountVoucher: 0, // Số tiền voucher
    amountVat: 0, // Số tiền thuế VAT
    currentcyCode: "", // Mã tiền tệ
    currentcyRate: 0, // Tỷ giá tiền tệ
    amountPaid: 0, // Số tiền đã thanh toán
    amountRemaining: 0, // Số tiền còn lại
    paymentType: "", // Loại thanh toán
    paymentStatus: "", // Trạng thái thanh toán
    paymentMethod: "", // Phương thức thanh toán
    paymentDue: 0, // Hạn thanh toán
    saleId: 0, // ID người bán hàng
    managerId: 0, // ID quản lý
    approveId: 0, // ID người duyệt
    approveNote: "", // Ghi chú người duyệt
    rejectNote: "", // Ghi chú từ chối
    shipngType: "", // Loại vận chuyển
    shipingVendor: "", // Nhà cung cấp vận chuyển
    shippingCode: "", // Mã vận chuyển
    shippingStatus: "", // Trạng thái vận chuyển
    shippingPriority: "", // Ưu tiên vận chuyển
    shippingDate: "2024-07-16T04:10:09.167Z", // Ngày vận chuyển
    shippingNote: "", // Ghi chú vận chuyển
    technicalChecked: 0, // Kiểm tra kỹ thuật
    technicalNote: "", // Ghi chú kỹ thuật
    clientId: 0, // ID khách hàng
    createBy: 0, // ID người tạo
    createDate: "2024-07-16T04:10:09.167Z", // Ngày tạo
    lastUpdateBy: 0, // ID người cập nhật cuối cùng
    lastUpdateDate: "2024-07-16T04:10:09.167Z", // Ngày cập nhật cuối cùng
    salesShift: "", // Ca bán hàng
    printInvoiceCheck: 0, // Kiểm tra in hóa đơn
    technicalRequired: 0,
    contractcode: "",
    discountAdded: 0,
    discountAddedTotal: 0,
    orderNumberLink: "",
  });

  const [customerCheck, setCustomerCheck] = useState<customerCheck>({
    code: "", // Mã khách hàng,
    taxCode: "", // mã số thuế
    name: "", // họ tên khách hàng
    telephoneNumber: "", // số Đt
    email: "", // email
    sex: null, // 0 nữ 1 nam
    birthOfDate: null, // ngày sinh
    address: "", // địa chỉ
    id: 0,
    contact: "",
    fax: "",
    type: 0,
    active: false,
    description: "",
    groupId: 0,
    province: 0,
    district: 0,
    commune: 0,
  });

  const [debtObject, setDebtObject] = useState<TblDebtGroup>({
    id: "",
    groupCode: null,
    groupName: null,
    taxCode: null,
    address: null,
    phone: null,
    contactPerson: null,
    classification: null,
    classificationName: null,
    debtTerm: null,
    debtLimit: null,
    type: null,
    typeName: null,
    createBy: null,
    createByName: null,
    lastUpdateBy: null,
    lastCreateByName: null,
    lastUpdateDate: null,
    createDate: null,
  });

  const deliveryFee =
    listAdd.length > 0 ? dataDeliveryInformation?.deliveryFee || 0 : 0;

  //#region handle FUNC

  function containsDT(input: string): boolean {
    return input.includes("DT");
  }

  function getGroupCode(input: string) {
    return input.split("||")[1];
  }

  const handleCheckIsSuccessValidateItem = (listAdd: any[]): boolean => {
    return listAdd
      .filter((item) => item?.attribute11 === undefined)
      .every((item) => item?.attribute14 && item?.attribute2);
  };

  const handleRenderOptionListAdd = (
    listAdd: any[]
  ): { value: string; label: string }[] => {
    return listAdd
      .filter((item) => item.lineTyle === "HT" && item.attribute13)
      .map((item) => ({
        value: item.attribute13,
        label: item.attribute13,
      }));
  };

  const handleCheckPayableAmount = (listAdd: any[]) => {
    let priceTotal = 0;

    if (location?.state?.actionType === "doi") {
      listAdd?.forEach((item) => {
        if (item?.attribute14 || item?.attribute2) {
          // Tính giá sản phẩm và cộng thêm attribute11 và attribute12
          const itemPrice =
            (item?.priceOnPriceList || 0) * (item?.quality || 0);
          const additionalAmount =
            (parseFloat(item?.attribute5) || 0) +
            (parseFloat(item?.attribute12) || 0);
          priceTotal += itemPrice + additionalAmount;
        }
      });
    } else {
      listAdd?.forEach((item) => {
        priceTotal += (item?.priceOnPriceList || 0) * (item?.quality || 0);
      });
    }

    return priceTotal;
  };

  const handleCheckBackgroundColor = (type: string): string => {
    switch (type) {
      case "G":
        return "#E5E6E7"; // Màu xám
      case "H":
        return "#E5E6E7"; // Màu xám
      case "T":
        return "#E5E6E7"; // Màu đỏ cho sp trả
      default:
        return "white"; // Màu xám nếu không khớp với loại nào
    }
  };

  const handleApprovedSalesOrder = async () => {
    if (!orderHeader.custId) {
      NotificationExtension.Warn("Chưa có thông tin khách hàng !");
      return;
    }
    const response = await repositoryPos.post<
      MessageResponse<dataCreateSalesOrder>
    >("/api/v1/CreateSellItem/approve", {
      orderDetail: listAdd,
      orderHeader: {
        ...orderHeader,
        invOrgId: branchId ? branchId : 488,
        orgId: branchId ? branchId : 488,
        saleChanelId: saleChanelId ? saleChanelId : 67,
        saleId: employeeSalesId,
        deptId: departmentId,
        // printInvoiceCheck: vatOption === "Không" ? 0 : 1,
        attribute2: accountantId, //Nguyễn Trang Quyên-0009
        contractcode: contractcode,
        // technicalRequired: handleGetTechnicialRequireId(requestServices),
        orderTypeId: 2,
        branchId: branchId ? branchId : 488,
        amountTotal: payableAmount,
        amountDiscount: totalKM,
        amountVat: 0,
        discountAdded: totalXGDetail,
        attribute1: JSON.stringify(dataDeliveryInformation),
        createDate: orderHeader?.orderDate,
        approveStatus: "2",
      },
      isApprove: true,
    });

    if (response && response.success) {
      setOrderStatus("DON_HANG_DA_DUYET_GIAM");
      NotificationExtension.Success("Duyệt giảm giá thành công !");
      // navigate("/sell/sales-order-list");
      SocketExtension.SendMessageToTopic<any>({
        topic: CREATEORDERSUCCESS,
        data: "",
      });
    } else {
      NotificationExtension.Fails(response?.message);
    }
  };

  const getTotalQuality = (items: dataOrderDetailTable[]): number => {
    return items?.reduce((total, item) => total + (item.quality || 0), 0);
  };

  const handleChangeQuantity = async (e: any, row: any) => {
    if (location?.state?.actionType === "edit") {
      // const checkQuantity = await repositoryPos.post<
      //   MessageResponse<ProductModel[]>
      // >(`/api/v1/CreateSellItem/check-item-virtual`, {
      //   id: row?.original?.itemId,
      //   quantity: e || 0,
      //   invId: row?.original?.subInvId,
      // });
      // console.log("checkQuantity", checkQuantity);
      // if (!checkQuantity?.data) {
      //   NotificationExtension.Warn(
      //     "Số lượng vừa nhập lớn hơn tồn hàng trong kho !"
      //   );
      // }
    } else {
      const url = `/api/v1/TblItem/search-sell?key=${row?.original?.id}`;
      const searchResult = await repositoryPos.get<
        MessageResponse<ProductModel[]>
      >(url);

      const tonAo =
        searchResult?.data?.find((item) => {
          return item?.warehouse === row?.original?.warehouse;
        })?.tonao || 0;

      if (Number(e) > tonAo) {
        NotificationExtension.Warn(
          "Số lượng vừa nhập lớn hơn tồn hàng trong kho !"
        );
      }
    }

    setListAdd((prevData) => {
      const dataNew = prevData.map((item, i) =>
        i === row.index
          ? {
              ...item,
              quality: e || null,
              totalLineAmount: row?.original?.priceFinal * e,
            }
          : item
      );
      const dataFinal = dataNew?.map((item) =>
        row?.original?.listIdGift.includes(item?.id)
          ? {
              ...item,
              quality: e || null,
            }
          : item
      );

      return dataFinal;
    });
  };

  // hàm check sản phẩm đã chọn hay chưa
  const handleCheckChooseProduct = (product: any, listAdd: any[]): boolean => {
    const found = listAdd.some(
      (item) => item.id === product.id && item.warehouse === product.warehouse
    );
    return found;
  };

  const handleGetTechnicialRequireId = (name: string) => {
    const options = [
      "Không",
      "Lắp ráp(mới)",
      "Kiểm tra(mới)",
      "Lắp ráp(mới) + Kiểm tra(mới)",
    ];

    return options?.findIndex((item) => item === name) ?? 0;
  };

  const handleGetTechnicialRequireName = (id: number) => {
    const options = [
      "Không",
      "Lắp ráp(mới)",
      "Kiểm tra(mới)",
      "Lắp ráp(mới) + Kiểm tra(mới)",
    ];

    return options[id];
  };

  const handleChangePrice = (value: any, key: string) => {
    switch (key) {
      case "cashPayment":
        setCashPayment(value);
        break;
      case "bankTransfer":
        setBankTransfer(value);
        break;
      case "cardPayment":
        setCardPayment(value);
        break;
      case "vnpayPayment":
        setVnpayPayment(value);
        break;
    }
  };

  const handleChooseCustomer = (value: any) => {
    setCustomerCheck({
      ...customerCheck,
      code: value?.code,
      name: value?.name,
      telephoneNumber: value?.telephoneNumber,
      id: value?.id,
      type: value?.type,
      province: value?.province,
      district: value?.district,
      commune: value?.commune,
      address: value?.address,
    });
    setOrderHeader({ ...orderHeader, custId: value?.id });
  };

  const handlerRenderOptionSelect = (object: any) => {
    if (object) {
      const newObject = object?.map((item: any) => {
        return item?.name;
      });
      return newObject;
    }
  };

  // Hàm nhận vào payType và trả về payAmount
  const getPayAmountByPayType = (payType: string): number | undefined => {
    console.log("payType", payType);
    const payment = dataPayment.find((p: any) => p.PayType === payType);
    return payment ? payment.PayAmount : undefined;
  };

  const handlerRenderOptionSelectAccountant = (object: any) => {
    if (object) {
      const newObject = object?.map((item: any) => {
        return item?.fullname;
      });
      return newObject;
    }
  };

  const formatDateString = (date: Date) => {
    const adjustedDate = date.setHours(date.getHours());
    return moment(new Date(adjustedDate)).format("DD-MM-YYYY HH:mm:ss");
  };

  const headerRef = React.useRef<HTMLDivElement>(null);

  const fetchItemSell = async () => {
    const keySearch = valueDebounced.toLowerCase();
    const url = `/api/v1/TblItem/search-sell?inv=${branchId}&key=${keySearch}`;
    try {
      const searchResult = await repositoryPos.get<
        MessageResponse<ProductModel[]>
      >(url);
      if (searchResult?.data) {
        setDataProducts(searchResult.data);
      } else {
        setDataProducts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataProducts([]);
    }
  };

  const handleSelectProduct = async (product: ProductModel, index: number) => {
    try {
      if (branchId) {
        const callapi = await repositoryPos.post<
          MessageResponse<ItemDetailPriceModel>
        >(
          `/api/v1/TblPriceListDetail/detail-price?id=${product?.id}&BranchId=${branchId}`
        );
        if (callapi?.success) {
          const callapiPromotion = await repositoryPos.post<
            MessageResponse<TblItemPromotionModel[]>
          >(`/api/v1/TblItem/item-promotion`, {
            id: product.id,
            price: callapi?.data?.listPrice || 0,
            branch: branchId,
          });

          const discountRate =
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountPercent) ||
            0;
          const discountAmount =
            (callapiPromotion?.data &&
              callapiPromotion?.data.length > 0 &&
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                ?.discountAmount) ||
            0;

          let idPromote = 0;
          let idPromoteHT = 0;

          if (callapiPromotion?.data) {
            idPromote =
              callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]?.id || 0;
            idPromoteHT =
              callapiPromotion?.data[0]?.tblPromotionItemGift[0]?.id || 0;
          }

          const addData: dataOrderDetailTable = {
            id: product?.id,
            itemCode: product?.productCode,
            itemName: product?.productName,
            primaryUomCode: product?.unit || "",
            orgId: product?.warehouseId || 0,
            warehouse: product?.warehouse || "",
            priceType: "Giá niêm yết",
            quality: product?.quantity || 1,
            discountRate: discountRate,
            discountAmount: discountAmount,
            discountAdded: 0,
            priceFinal: discountRate
              ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
              : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
            vatRate: callapi.data?.vatRate || 0,
            vatAmount: callapi.data?.vatAmount || 0,
            totalLineAmount: discountRate
              ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
              : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0,
            note: product?.notes ?? "",
            lineTyle: "HH",
            orderHeaderId: 0,
            deptId: 0,
            itemId: product?.id,
            linkToItemId: 0,
            uomCode: "",
            priceListId: callapi.data?.idPriceLis || 0,
            priceOnPriceList: callapi?.data?.listPrice || 0,
            idPromote: idPromote || 0,
            idPromoteList: "",
            linkToLineId: 0,
            printInvoiceCheck: 0,
            promotePostPaid: "",
            promoteClearCheck: 0,
            promoteClearDate: "",
            prometeClearPersionId: 0,
            prometeClearClientId: "",
            clientId: 0,
            workShiftId: 0,
            createBy: 0,
            createDate: "",
            lastUpdateBy: 0,
            lastUpdateDate: "",
            subInvId: product?.warehouseId || 0,
            listIdPromotion: [
              callapiPromotion?.data &&
              callapiPromotion.data.length > 0 &&
              typeof callapiPromotion.data[0].tblPromotion.id === "number"
                ? callapiPromotion.data[0].tblPromotion.id
                : null,
            ].filter((id): id is number => id !== null),
            listIdAddOn: [],
            listIdGift:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemGift &&
                callapiPromotion?.data[0]?.tblPromotionItemGift.map(
                  (item) => item.idItemGift
                )) ||
              [],
            promotionDiscountPercent:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                  ?.discountPercent) ||
              0,
            promotionDiscountAmount:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
                  ?.discountAmount) ||
              0,
            promotionItemGift:
              (callapiPromotion?.data &&
                callapiPromotion?.data.length > 0 &&
                callapiPromotion?.data[0]?.tblPromotionItemGift.map((item) => ({
                  itemCode: item?.itemForSellModel.productCode,
                  itemName: item?.itemForSellModel.productName,
                  primaryUomCode: item?.itemForSellModel.unit || "",
                  quality: 1,
                  warehouse: item?.itemForSellModel.warehouse,
                }))) ||
              [],
          };

          if (
            callapiPromotion?.data &&
            callapiPromotion?.data[0]?.tblPromotionItemGift?.length > 0
          ) {
            const itemGif =
              callapiPromotion?.data[0]?.tblPromotionItemGift[0]
                ?.itemForSellModel;

            const dataItemGif: dataOrderDetailTable = {
              id: itemGif?.id || 0,
              itemCode: itemGif?.productCode || "",
              itemName: itemGif?.productName || "",
              primaryUomCode: itemGif?.unit || "",
              orgId: itemGif?.warehouseId || 0,
              warehouse: itemGif?.warehouse || "",
              priceType: "Giá niêm yết",
              quality: 1,
              discountRate: 0,
              discountAmount: 0,
              discountAdded: 0,
              priceFinal: 0,
              vatRate: 0,
              vatAmount: 0,
              totalLineAmount: 0,
              note: "",
              // HH hàng hoá, KM khuyến mai, DV dịch vụ
              lineTyle: "KM",
              orderHeaderId: 0,
              deptId: 0,
              itemId: itemGif?.id || 0,
              linkToItemId: 0,
              uomCode: "",
              priceListId: 0,
              priceOnPriceList: 0,
              idPromote: idPromoteHT || 0,
              idPromoteList: "",
              linkToLineId: 0,
              printInvoiceCheck: 0,
              promotePostPaid: "",
              promoteClearCheck: 0,
              promoteClearDate: "",
              prometeClearPersionId: 0,
              prometeClearClientId: "",
              clientId: 0,
              workShiftId: 0,
              createBy: 0,
              createDate: "",
              lastUpdateBy: 0,
              lastUpdateDate: "",
              subInvId: itemGif?.warehouseId || 0,
              listIdPromotion: [],
              listIdAddOn: [],
              listIdGift: [],
              promotionDiscountPercent: 0,
              promotionDiscountAmount: 0,
            };
            setListAdd((prevList) => {
              // Nếu index === -1, thêm addData vào đầu mảng
              if (index === -1) {
                return [addData, ...prevList, dataItemGif]; // Thêm addData vào đầu, và dataItemGif vào cuối
              } else {
                // Nếu index khác -1, cập nhật phần tử tại vị trí index và thêm dataItemGif
                const newList = [...prevList];
                newList[index] = addData;
                newList.push(dataItemGif); // Thêm dataItemGif vào cuối mảng
                return newList;
              }
            });
            return;
          }

          setListAdd((prevList) => {
            if (index === -1) {
              return [addData, ...prevList];
            } else {
              const newList = [...prevList];
              newList[index] = addData;
              return newList;
            }
          });
        }
        setIsCreating(false);
        table.setEditingRow(null);
      }
    } catch (error) {
      console.error("Error in handleSelectProduct:", error);
    }
  };

  const handleSelectProductAddOn = async (product: itemAddOnPromotionModel) => {
    try {
      if (branchId) {
        const callapi = await repositoryPos.post<
          MessageResponse<ItemDetailPriceModel>
        >(
          `/api/v1/TblPriceListDetail/detail-price?id=${product?.idItemAddOn}&BranchId=${branchId}`
        );
        if (callapi?.success) {
          const addData: dataOrderDetailTable = {
            id: product?.idItemAddOn || 0,
            itemCode: product?.itemForSellModel?.productCode || "",
            itemName: product?.itemForSellModel?.productName || "",
            primaryUomCode: product?.itemForSellModel?.unit || "",
            orgId: product?.itemForSellModel?.warehouseId || 0,
            warehouse: product?.itemForSellModel?.warehouse || "",
            priceType: "Giá niêm yết",
            quality: 1,
            discountRate: product.discountPercent || 0,
            discountAmount: product.discountAmount || 0,
            discountAdded: 0,
            priceFinal: product.discountPercent
              ? callapi?.data?.listPrice -
                (callapi?.data?.listPrice * product.discountPercent) / 100
              : callapi?.data?.listPrice - (product.discountAmount || 0),
            vatRate: callapi.data?.vatRate || 0,
            vatAmount: callapi.data?.vatAmount || 0,
            totalLineAmount: product.discountPercent
              ? callapi?.data?.listPrice -
                (callapi?.data?.listPrice * product.discountPercent) / 100
              : callapi?.data?.listPrice - (product.discountAmount || 0),
            note: "",
            // HH hàng hoá, KM khuyến mai, DV dịch vụ
            lineTyle: "HK",
            orderHeaderId: 0,
            deptId: 0,
            itemId: product?.idItemAddOn || 0,
            linkToItemId: 0,
            uomCode: "",
            priceListId: callapi.data?.idPriceLis || 0,
            priceOnPriceList: callapi.data?.listPrice || 0,
            idPromote: product?.id || 0,
            idPromoteList: "",
            linkToLineId: 0,
            printInvoiceCheck: 0,
            promotePostPaid: "",
            promoteClearCheck: 0,
            promoteClearDate: "",
            prometeClearPersionId: 0,
            prometeClearClientId: "",
            clientId: 0,
            workShiftId: 0,
            createBy: 0,
            createDate: "",
            lastUpdateBy: 0,
            lastUpdateDate: "",
            subInvId: product?.itemForSellModel?.warehouseId || 0,
            listIdPromotion: [],
            listIdAddOn: [],
            listIdGift: [],
            promotionDiscountPercent: 0,
            promotionDiscountAmount: 0,
          };
          setListAdd((prevList) => [...prevList, addData]);
        }

        table.setEditingRow(null);
      }
    } catch (error) {
      console.error("Error in handleSelectProductAddOn:", error);
    }
  };

  const handleCallApiPayment = async () => {
    try {
      // Tạo mảng paymentCommands
      const paymentCommands = [
        {
          // Tiền mặt
          payType: "02",
          payAmount: cashPayment,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
        {
          // QR MB
          payType: "01",
          payAmount: bankTransfer,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
        {
          // VN pay
          payType: "04",
          payAmount: vnpayPayment,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
        {
          // Cà thẻ
          payType: "03",
          payAmount: cardPayment,
          casherId: userLogin?.id,
          payeeId: customerCheck?.id,
        },
      ].filter((payment) => payment.payAmount > 0); // Lọc các phương thức thanh toán có payAmount > 0

      const response = await repositoryPos.post<
        MessageResponse<dataCreateSalesOrder>
      >("/api/v1/CreateSellItem/payment", {
        paymentCommands: paymentCommands,
        orderNumber: orderHeader?.orderNumber,
      });

      if (response?.success) {
        setOrderStatus("DON_HANG_HOAN_THANH");
        NotificationExtension.Success("Hoàn tất thanh toán thành công");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    modals.closeAll();
  };

  const handleDeleteOrder = async (orderNumber: string) => {
    const res = await repositoryPos.get<MessageResponse<boolean>>(
      `/api/v1/CreateSellItem/delete?orderNumber=${orderNumber}`
    );
    if (res?.success) {
      NotificationExtension.Success("Từ chối duyệt thành công !");
      setOrderStatus("DON_HANG_HUY");
    } else NotificationExtension.Fails("Từ chối duyệt thất bại !");
    modals.closeAll();
  };

  const handleClickCancelSalesOrder = () => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Xác nhận hủy đơn hàng !</Title>
        </>
      ),

      size: "xs",
      children: (
        <Flex justify={"end"} gap={20} mt={"sm"}>
          <Button
            color="gray"
            leftSection={<IconWindow size={18} />}
            onClick={() => {
              modals.closeAll();
            }}
          >
            Huỷ
          </Button>
          <Button
            leftSection={<IconCheck size={18} />}
            onClick={() => {
              navigate("/sell/sales-order-list");
              modals.closeAll();
            }}
          >
            Xác nhận
          </Button>
        </Flex>
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const handleGetNameEmployee = (Employee: EmployeeModel[]) => {
    const dataName = Employee?.map((item: any) => {
      return item?.fullName;
    });
    return dataName;
  };

  const handleGetNameDepartment = (Department: DepartmentModel[]) => {
    const dataName = Department?.map((item: any) => {
      return item?.name;
    });
    return dataName;
  };

  const handleGetNameBranch = (Branch: any[]) => {
    const dataName = Branch?.map((item: any) => {
      return item?.text;
    });
    return dataName;
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  const fetchDataCreate = async () => {
    const url = `/api/v1/CreateSellItem/create?codePrefix=DH`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response?.success) {
        setOrderHeader(response.data.orderHeader);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getDeliveryMethodCode(value: string) {
    switch (value) {
      case "Nhận tại cửa hàng":
        return "KHONG";
      case "Nội bộ":
        return "HACOM";
      case "Đối tác vận chuyển":
        return "DOI_TAC_VAN_CHUYEN";
      default:
        return null; // Giá trị mặc định nếu không có giá trị phù hợp
    }
  }

  const handleCreateSalesOrder = useDebouncedCallback(
    async (isLock: boolean) => {
      if (
        !handleCheckIsSuccessValidateItem(listAdd) &&
        location?.state?.actionType === "doi"
      ) {
        NotificationExtension.Warn(
          "Vui lòng điền trường hợp đổi , Serial đổi !"
        );
        return;
      }
      const isShippingRequired = shipping !== "Nhận tại cửa hàng";
      const isMissingDeliveryInfo = [
        dataDeliveryInformation?.customerId,
        dataDeliveryInformation?.telephoneNumber,
        dataDeliveryInformation?.customerProvinceId,
        dataDeliveryInformation?.customerDistrictId,
        dataDeliveryInformation?.customerCommuneId,
        dataDeliveryInformation?.customerAddress,
        dataDeliveryInformation?.deliveryAppointmentTime,
      ].some((field) => !field);

      if (isShippingRequired && isMissingDeliveryInfo) {
        NotificationExtension.Warn("Vui lòng điền đầy đủ thông tin giao hàng!");
        return;
      }

      if (!orderHeader.custId) {
        NotificationExtension.Warn("Chưa có thông tin khách hàng!");
        return;
      }

      if (!accountantId) {
        NotificationExtension.Warn("Chưa chọn kế toán!");
        return;
      }
      let newListAdd: dataOrderDetailTable[];
      if (location?.state?.actionType === "doi") {
        newListAdd = listAdd
          .map((item) => {
            return {
              ...item,
              totalLineAmount: item.priceFinal * item.quality,
            };
          })
          .filter((i) => {
            return (
              i?.attribute11 !== "G" &&
              i?.attribute11 !== "H" &&
              i?.attribute11 !== "T"
            );
          });
      } else {
        newListAdd = listAdd.map((item) => {
          return {
            ...item,
            totalLineAmount: item.priceFinal * item.quality,
          };
        });
      }

      openLoading();
      const response = await repositoryPos.post<
        MessageResponse<dataCreateSalesOrder>
      >("/api/v1/CreateSellItem/create", {
        orderDetail: newListAdd,
        orderHeader: {
          ...orderHeader,
          shipngType: shipping,
          shipingVendor: getDeliveryMethodCode(shipping),
          invOrgId: branchId ? branchId : 488,
          orgId: branchId ? branchId : 488,
          saleChanelId: saleChanelId ? saleChanelId : 67,
          saleId: employeeSalesId,
          deptId: departmentId,
          // printInvoiceCheck: vatOption === "Không" ? 0 : 1,
          attribute2: accountantId, //Nguyễn Trang Quyên-0009
          contractcode: contractcode,
          // technicalRequired: handleGetTechnicialRequireId(requestServices),
          orderTypeId: 2,
          branchId: branchId ? branchId : 488,
          amountTotal: payableAmount,
          amountDiscount: totalKM,
          amountVat: 0,
          attribute1: JSON.stringify({
            ...dataDeliveryInformation,
            branchId: branchId,
          }),
          createDate: orderHeader?.orderDate,
          orderNumberLink: location?.state?.orderNumberOld,
          attribute8: `${debtObject?.groupName}||${debtObject?.groupCode}`,
        },
        isRetun: location?.state?.actionType === "doi",
        isLock: isLock,
      });

      if (response && response.success) {
        setOrderStatus(isLock ? "DON_HANG_CHOT" : "DON_HANG_TAM");
        NotificationExtension.Success(
          isLock ? "Chốt đơn hàng thành công !" : "Lưu đơn hàng thành công !"
        );
        SocketExtension.SendMessageToTopic<any>({
          topic: CREATEORDERSUCCESS,
          data: "",
        });
      }
      closeLoading();
    },
    1000
  );

  const handleEditSalesOrder = useDebouncedCallback(async (isLock: boolean) => {
    const isShippingRequired = shipping !== "Nhận tại cửa hàng";
    const isMissingDeliveryInfo = [
      dataDeliveryInformation?.customerId,
      dataDeliveryInformation?.telephoneNumber,
      dataDeliveryInformation?.customerProvinceId,
      dataDeliveryInformation?.customerDistrictId,
      dataDeliveryInformation?.customerCommuneId,
      dataDeliveryInformation?.customerAddress,
      dataDeliveryInformation?.deliveryAppointmentTime,
    ].some((field) => !field);

    if (isShippingRequired && isMissingDeliveryInfo) {
      NotificationExtension.Warn("Vui lòng điền đầy đủ thông tin giao hàng!");
      return;
    }

    if (!orderHeader.custId) {
      NotificationExtension.Warn("Chưa có thông tin khách hàng!");
      return;
    }

    if (!accountantId) {
      NotificationExtension.Warn("Chưa chọn kế toán!");
      return;
    }

    const newListAdd = listAdd.map((item) => {
      return {
        ...item,
        totalLineAmount: item.priceFinal * item.quality,
      };
    });
    openLoading();
    const response = await repositoryPos.post<
      MessageResponse<dataCreateSalesOrder>
    >("/api/v1/CreateSellItem/edit", {
      orderDetail: newListAdd,
      orderHeader: {
        ...orderHeader,
        shipngType: shipping,
        shipingVendor: getDeliveryMethodCode(shipping),
        invOrgId: branchId ? branchId : 488,
        orgId: branchId ? branchId : 488,
        saleChanelId: saleChanelId ? saleChanelId : 67,
        saleId: employeeSalesId,
        deptId: departmentId,
        // printInvoiceCheck: vatOption === "Không" ? 0 : 1,
        attribute2: accountantId, //Nguyễn Trang Quyên-0009
        contractcode: contractcode,
        // technicalRequired: handleGetTechnicialRequireId(requestServices),
        orderTypeId: 2,
        branchId: branchId ? branchId : 488,
        amountTotal: payableAmount,
        amountDiscount: totalKM,
        amountVat: 0,
        attribute1: JSON.stringify({
          ...dataDeliveryInformation,
          branchId: branchId,
        }),
        createDate: orderHeader?.orderDate,
        orderNumberLink: location?.state?.orderNumberOld,
        attribute8: `${debtObject?.groupName}||${debtObject?.groupCode}`,
      },
      isLock: isLock,
    });

    if (response && response.success) {
      setOrderStatus(isLock ? "DON_HANG_CHOT" : "DON_HANG_TAM");

      NotificationExtension.Success(
        isLock ? "Chốt đơn hàng thành công !" : "Lưu đơn hàng thành công !"
      );
      SocketExtension.SendMessageToTopic<any>({
        topic: CREATEORDERSUCCESS,
        data: "",
      });
      closeLoading();
      return true;
    } else {
      NotificationExtension.Fails("Lưu đơn hàng thất bại !");
      closeLoading();
      return false;
    }
  }, 1000);

  //#region render ui
  const TableSelect: React.FC<TableSelectProps> = ({
    dataProducts,
    onSelectProduct,
    rowIndex,
  }: any) => {
    return (
      <Table.ScrollContainer mah={300} minWidth={300} type="native">
        <Table striped withTableBorder>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Chọn</Table.Th>
              <Table.Th>ID</Table.Th>
              <Table.Th>Mã sản phẩm</Table.Th>
              <Table.Th>Tên sản phẩm</Table.Th>
              <Table.Th>ĐVT</Table.Th>
              <Table.Th>Tồn kho</Table.Th>
              <Table.Th>SL đề xuất</Table.Th>
              <Table.Th>SL khả dụng</Table.Th>
              <Table.Th>Mã kho</Table.Th>
              <Table.Th>Kho</Table.Th>
            </Table.Tr>
          </Table.Thead>
          {dataProducts?.length > 0 ? (
            <Table.Tbody>
              {dataProducts?.map((product: any, index: any) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Button
                      size="xs"
                      variant="filled"
                      onClick={() => {
                        onSelectProduct(product, rowIndex);
                        table.setCreatingRow(null);
                      }}
                      disabled={
                        handleCheckChooseProduct(product, listAdd) ||
                        product.tonao <= 0
                      }
                    >
                      {handleCheckChooseProduct(product, listAdd)
                        ? "Đã chọn"
                        : "Chọn"}
                    </Button>
                  </Table.Td>
                  <Table.Td>{product.id}</Table.Td>
                  <Table.Td>{product.productCode}</Table.Td>
                  <Table.Td>{product.productName}</Table.Td>
                  <Table.Td>{product.unit}</Table.Td>
                  <Table.Td>{product.tons}</Table.Td>
                  <Table.Td>{product.tons - product.tonao}</Table.Td>
                  <Table.Td>{product.tonao}</Table.Td>
                  <Table.Td>{product.wareHouseCode}</Table.Td>
                  <Table.Td>{product.warehouse}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text>Hãy nhập ID hoặc mã sản phẩm!</Text>
            </Box>
          )}
        </Table>
      </Table.ScrollContainer>
    );
  };
  const handleOnClickPrint = (id: any) => {
    setIsPrinting(true);
    setIdPrint(id);
  };
  const titleSell = () => {
    return (
      <>
        <_breadcrumb></_breadcrumb>
        <Group align="center" p={"0 10 10 10"} justify="space-between">
          <Flex gap={"xs"}>
            <Button
              color={"blue"}
              size="xs"
              style={{
                pointerEvents: "auto",
              }}
              onClick={() => {
                navigate("/sell/retail-order");
                window.location.reload();
              }}
            >
              Tạo mới
            </Button>
            <Tooltip
              label={isCollapse ? "Mở rộng" : "Thu gọn"}
              position="bottom"
            >
              <Group align="center">
                <Switch
                  size="sm"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onChange={toggleCollapse}
                  label={isCollapse ? "Thu gọn (Ctrl+M)" : "Mở rộng (Ctrl+M)"}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
              </Group>
            </Tooltip>
          </Flex>
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <OrderStatus status={orderStatus || "noCreate"}></OrderStatus>
          </Flex>

          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            {orderStatus === "DON_HANG_HOAN_THANH" ? (
              <>
                <Button
                  leftSection={<IconPrinter size={20} />}
                  variant="outline"
                  color="indigo"
                  style={{
                    cursor: "pointer",
                    pointerEvents: "auto",
                  }}
                  size="xs"
                  onClick={() => handleOnClickPrint(location?.state?.id)}
                >
                  In hóa đơn
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              color="blue"
              size="xs"
              onClick={() => {
                table.setCreatingRow(true);
                setIsCreating(true);
              }}
            >
              Thêm sản phẩm
            </Button>
            <Box
              style={{
                ...getStyle(),
              }}
            >
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button
                    size="xs"
                    rightSection={
                      <IconCaretDown
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Chức năng mặt hàng
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>Chức năng</Menu.Label>
                  <Menu.Item>
                    <Button
                      disabled={
                        orderStatus !== "DON_HANG_TAM" &&
                        orderStatus !== "DON_HANG_CHOT"
                      }
                      color={"red"}
                      size="xs"
                      onClick={() => handleClickCancelSalesOrder()}
                    >
                      Hủy đơn
                    </Button>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Box>
          </Flex>
        </Group>
      </>
    );
  };

  function getStyle(includePayment = false): CSSProperties {
    const isDisabled =
      location?.state?.actionType === "view" ||
      location?.state?.actionType === "approved" ||
      orderStatus === "DON_HANG_HOAN_THANH" ||
      (includePayment && location?.state?.actionType === "payment") ||
      (orderStatus === "DON_HANG_CHOT" &&
        location?.state?.actionType !== "payment");

    return {
      opacity: isDisabled ? 0.9 : 1,
      pointerEvents: isDisabled ? "none" : "auto",
    };
  }

  const formOrderHeader = () => {
    return (
      <Box
        style={{
          padding: 5,
          height: "100%",
          position: "relative",
          ...getStyle(),
        }}
      >
        <Box
          style={{
            ...getStyle(true),
          }}
        >
          <Grid>
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>
                  Mã đơn hàng:<span style={{ color: "red" }}>*</span>
                </Text>
                <Text fw={500}>{orderHeader?.orderNumber}</Text>
              </Flex>
              {(location?.state?.orderNumberOld ||
                orderHeader?.orderNumberLink) && (
                <Flex align="center" gap="xs">
                  <Text style={{ flex: "0 0 100px" }}>
                    Mã ĐH cũ:<span style={{ color: "red" }}>*</span>
                  </Text>
                  <Text fw={500}>
                    {orderHeader?.orderNumberLink
                      ? orderHeader?.orderNumberLink
                      : location?.state?.orderNumberOld}
                  </Text>
                </Flex>
              )}
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Chi nhánh:"
                  value={branchName}
                  options={handleGetNameBranch(listBranch) || []}
                  onChange={(value: any) => {
                    setBranchName(value);
                    const BranchId = listBranch.find((item) => {
                      return item.text == value;
                    });
                    setBranchId(Number(BranchId?.value));
                    setListAdd([]);
                    setDataProducts([]);
                  }}
                  width="250px"
                />
              </Flex>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Bộ phận:"
                  value={department}
                  options={handleGetNameDepartment(listDepartment)}
                  onChange={(value) => {
                    setDepartment(value);
                    const departmentId = listDepartment?.find((item) => {
                      return item?.name === value;
                    });
                    setDepartmentId(departmentId?.departmentId);
                  }}
                  width="280px"
                />
              </Flex>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="NV bán hàng:"
                  value={employeeSalesName}
                  options={handleGetNameEmployee(listEmployeeSales) || []}
                  onChange={(value: any) => {
                    setEmployeeSalesName(value);
                    const saleId = listEmployeeSales.find((item) => {
                      return item.fullName === value;
                    });
                    setEmployeeSalesId(saleId?.id);
                  }}
                  width="250px"
                />
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>Mã ĐTCN:</Text>
                <Box style={{ position: "relative", maxWidth: "200px" }}>
                  {debtObject?.groupName ? (
                    <Tooltip label={debtObject?.groupName} color="blue">
                      <Text fw={500} truncate>
                        {debtObject?.groupName
                          ? debtObject?.groupName
                          : "Tìm đối tượng công nợ"}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text fw={500} truncate>
                      {debtObject?.groupName
                        ? debtObject?.groupName
                        : "Tìm đối tượng công nợ"}
                    </Text>
                  )}
                  <IconSearch
                    size={20}
                    style={{
                      position: "absolute",
                      right: -30,
                      top: 0,
                      cursor: "pointer",
                      color: cobalt_blue.base,
                    }}
                    onClick={openFormDebt}
                  />
                </Box>
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>
                  Khách hàng:<span style={{ color: "red" }}>*</span>
                </Text>
                <Box style={{ position: "relative", maxWidth: "200px" }}>
                  {customerCheck?.name ? (
                    <Tooltip label={customerCheck?.name}>
                      <Text fw={500} truncate>
                        {customerCheck?.name
                          ? customerCheck?.name
                          : "Tìm khách hàng"}
                      </Text>
                    </Tooltip>
                  ) : (
                    <Text fw={500} truncate>
                      {customerCheck?.name
                        ? customerCheck?.name
                        : "Tìm khách hàng"}
                    </Text>
                  )}
                  <IconSearch
                    size={20}
                    style={{
                      position: "absolute",
                      right: -30,
                      top: 0,
                      cursor: "pointer",
                      color: cobalt_blue.base,
                    }}
                    onClick={openFormCustomer}
                  />
                </Box>
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>
                  Mã số KH:<span style={{ color: "red" }}>*</span>
                </Text>
                <Flex
                  align="center"
                  style={{ flex: "1 1 auto", position: "relative" }}
                >
                  <Text style={{ flex: "0 0 100px", fontWeight: "500" }}>
                    {customerCheck?.code}
                  </Text>
                </Flex>
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>
                  Số điện thoại:<span style={{ color: "red" }}>*</span>
                </Text>
                <Text style={{ flex: "0 0 100px", fontWeight: "500" }}>
                  {customerCheck?.telephoneNumber}
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>
                  Thời gian tạo: <span style={{ color: "red" }}>*</span>
                </Text>
                <Text fw={500}>{formatDateString(currentTime)}</Text>
              </Flex>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Kênh bán:"
                  value={saleChanel}
                  options={handlerRenderOptionSelect(saleChanelList)}
                  onChange={(value) => {
                    setSaleChanel(value);
                    const id = saleChanelList?.find((item) => {
                      return item.name === value;
                    });
                    setSaleChanelId(id.id);
                  }}
                  width="280px"
                />
              </Flex>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Kế toán BH:"
                  value={accountantName}
                  options={handlerRenderOptionSelectAccountant(dataAccountant)}
                  onChange={(value) => {
                    setAccountantName(value);
                    const id = dataAccountant?.find((item) => {
                      return item.fullname === value;
                    });
                    setAccountantId(id.id);
                  }}
                  width="280px"
                />
              </Flex>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 100px" }}>
                  Loại KH:<span style={{ color: "red" }}>*</span>
                </Text>
                <Text fw={500}>
                  {customerCheck?.type
                    ? customerCheck?.type === 1
                      ? "Khách lẻ"
                      : "Khách doanh nghiệp"
                    : ""}
                </Text>
              </Flex>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Xuất VAT:"
                  value={
                    (orderHeader?.printInvoiceCheck ?? 0) === 0 ? "Không" : "Có"
                  }
                  options={["Có", "Không"]}
                  onChange={(e) =>
                    setOrderHeader((prev) => ({
                      ...prev,
                      printInvoiceCheck: e === "Không" ? 0 : 1,
                    }))
                  }
                />
              </Flex>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Yêu cầu DV:"
                  value={handleGetTechnicialRequireName(
                    orderHeader?.technicalRequired ?? 0
                  )}
                  options={[
                    "Không",
                    "Lắp ráp(mới)",
                    "Kiểm tra(mới)",
                    "Lắp ráp(mới) + Kiểm tra(mới)",
                  ]}
                  onChange={(e) =>
                    setOrderHeader((prev) => ({
                      ...prev,
                      technicalRequired: handleGetTechnicialRequireId(e) ?? 0,
                    }))
                  }
                />
              </Flex>
              <Flex align="center" gap="xs" mt={-8}>
                <Text style={{ flex: "0 0 100px" }}>Mã hợp đồng:</Text>
                <TextInput
                  value={contractcode}
                  onChange={(e) => setContractcode(e.target.value)}
                  variant="unstyled"
                  size="md"
                  fs={"14px"}
                  fw={"500"}
                  placeholder="Nhập mã đợp đồng"
                />
              </Flex>
            </Grid.Col>
          </Grid>
          <Divider my="sm" mt={0} />
          <Grid mt={10}>
            <Grid.Col span={6}>
              <Flex align="center" gap="xs">
                <EditableTextWithOptions
                  required
                  label="Giao hàng:"
                  value={shipping}
                  options={[
                    "Nhận tại cửa hàng",
                    "Nội bộ",
                    "Đối tác vận chuyển",
                  ]}
                  onChange={setShipping}
                />
              </Flex>
            </Grid.Col>
            {(shipping === "Nội bộ" || shipping === "Đối tác vận chuyển") && (
              <Grid.Col span={6}>
                <Flex>
                  <Text style={{ flex: "0 0 100px" }}>Thông tin giao:</Text>
                  <Button
                    ml={10}
                    size="xs"
                    onClick={() => {
                      if (listAdd.length > 0) {
                        openModalDeliveryInformation();
                      } else {
                        NotificationExtension.Warn(
                          "Chưa có sản phẩm không nhập được thông tin giao hàng !"
                        );
                      }
                    }}
                  >
                    Nhập thông tin
                  </Button>
                </Flex>
                {shipping === "Đối tác vận chuyển" && (
                  <Flex align="center" gap="xs">
                    <Text style={{ flex: "0 0 100px" }}>Phí vận chuyển:</Text>
                    <Text fw={"500"}>
                      <NumberFormatter
                        value={deliveryFee}
                        thousandSeparator="."
                        decimalSeparator=","
                        suffix=" ₫"
                      />
                    </Text>
                  </Flex>
                )}
              </Grid.Col>
            )}
          </Grid>
        </Box>
        <Divider my={"sm"} />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Xin giảm trên tổng đơn:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={
                  totalXGDetail > 0 ||
                  location?.state?.actionType === "payment" ||
                  location?.state?.actionType === "view" ||
                  location?.state?.actionType === "approved" ||
                  listAdd.length === 0
                    ? true
                    : false
                }
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={orderHeader?.discountAddedTotal || ""}
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) =>
                  setOrderHeader({
                    ...orderHeader,
                    discountAddedTotal: Number(e),
                  })
                }
                clampBehavior="strict"
                min={1}
                max={10000000}
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my="sm" />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng tiền hàng:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={
                    containsDT(orderHeader?.orderNumber)
                      ? orderHeader?.amountTotal
                      : totalAmount || 0
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng giảm trừ:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={
                    totalKM +
                      (totalXGDetail ?? 0) +
                      (orderHeader?.discountAddedTotal ?? 0) || 0
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pt={0} pb={0}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Số tiền cần thanh toán:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"} style={{ color: "red" }}>
                <NumberFormatter
                  style={{ fontSize: "17px" }}
                  value={
                    containsDT(orderHeader?.orderNumber)
                      ? orderHeader?.amountTotal
                      : payableAmount +
                        Number(deliveryFee) -
                        orderHeader?.amountPaid
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                />
                {` (Đã VAT) `}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my={"sm"} />
        <Grid>
          <Grid.Col span={12} pt={0} pb={0} h={"fit-content"}>
            <Text fs={"16px"} fw={"500"}>
              Thanh toán trước:
            </Text>
          </Grid.Col>
          <Grid.Col span={12} pb={0} h={"fit-content"}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tiền mặt:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={location?.state?.actionType !== "payment"}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={
                  dataPayment ? getPayAmountByPayType("02") : cashPayment || ""
                }
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "cashPayment")}
                clampBehavior="strict"
                min={1}
                max={10000000000}
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pb={0} h={"fit-content"}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Chuyển khoản:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={location?.state?.actionType !== "payment"}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={
                  dataPayment ? getPayAmountByPayType("01") : bankTransfer || ""
                }
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "bankTransfer")}
                min={1}
                max={100000000}
                clampBehavior="strict"
              />
              {!(
                location?.state?.actionType == "approved" ||
                location?.state?.actionType !== "payment"
              ) && (
                <IconCreditCard
                  style={{
                    cursor: "pointer",
                    color: cobalt_blue.base, // Adjust color if needed
                  }}
                  onClick={() => openModalPaymentMb(bankTransfer)}
                />
              )}
            </Flex>
          </Grid.Col>
          {saleChanel !== "Khách đến showroom" && (
            <Grid.Col span={12} pb={0} h={"fit-content"}>
              <Flex align="center" gap="xs">
                <Text style={{ flex: "0 0 150px" }}>VNPay:</Text>
                <Text fw={500} style={{ width: "25%" }}></Text>
                <NumberInput
                  disabled={location?.state?.actionType !== "payment"}
                  variant="unstyled"
                  autoFocus
                  style={{ height: 35 }}
                  size="md"
                  fw={"500"}
                  placeholder={"Nhập số tiền"}
                  hideControls
                  value={
                    dataPayment
                      ? getPayAmountByPayType("04")
                      : vnpayPayment || ""
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                  onChange={(e) => handleChangePrice(e, "vnpayPayment")}
                  min={1}
                  max={100000000}
                  clampBehavior="strict"
                />
                {!(location?.state?.actionType !== "payment") && (
                  <IconCreditCard
                    style={{
                      cursor: "pointer",
                      color: cobalt_blue.base, // Adjust color if needed
                    }}
                    onClick={() => openModalPayment(vnpayPayment || 0)}
                  />
                )}
              </Flex>
            </Grid.Col>
          )}
          <Grid.Col span={12} pb={0} h={"fit-content"}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Thanh toán thẻ:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <NumberInput
                disabled={location?.state?.actionType !== "payment"}
                variant="unstyled"
                autoFocus
                style={{ height: 35 }}
                size="md"
                fw={"500"}
                placeholder={"Nhập số tiền"}
                hideControls
                value={
                  dataPayment ? getPayAmountByPayType("03") : cardPayment || ""
                }
                thousandSeparator="."
                decimalSeparator=","
                onChange={(e) => handleChangePrice(e, "cardPayment")}
                min={1}
                max={100000000}
                clampBehavior="strict"
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} pb={0} h={"fit-content"}>
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 150px" }}>Tổng:</Text>
              <Text fw={500} style={{ width: "25%" }}></Text>
              <Text fw={"500"}>
                <NumberFormatter
                  value={totalPaid || 0}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={12} py={0} h={"fit-content"}>
            <Flex align="center" gap="xs" mt={10}>
              <Text style={{ flex: "0 0 150px", fontWeight: "500" }}>
                Thanh toán sau:
              </Text>
              <Box style={{ width: "25%" }}>
                <EditableTextWithOptions
                  isCod
                  label=""
                  value={isCOD}
                  options={["COD", "Công nợ", "Trả góp"]}
                  onChange={setIsCOD}
                />
              </Box>
              <Text fw={"500"} style={{ color: "red" }}>
                <NumberFormatter
                  style={{ fontSize: "17px" }}
                  value={
                    containsDT(orderHeader?.orderNumber)
                      ? orderHeader?.amountTotal
                      : deferredPayment +
                        Number(deliveryFee) -
                        orderHeader?.amountPaid
                  }
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider my={"sm"} />
        <Box>
          <Grid mt={2}>
            <Grid.Col span={12}>
              <Flex gap="xs" align="center">
                <Text>Ghi chú đơn hàng:</Text>
                <Textarea
                  readOnly={location?.state?.actionType === "payment"}
                  style={{ flex: 1 }}
                  value={orderHeader?.note ? orderHeader?.note : ""}
                  onChange={(e) =>
                    setOrderHeader({ ...orderHeader, note: e.target.value })
                  }
                />
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
        <Flex
          // pos={"absolute"} bottom={10} left={10}
          columnGap={5}
        >
          {orderStatus === "DON_HANG_TAM" ? (
            <Flex mt={10} gap={"xs"}>
              <>
                {location?.state?.actionType !== "doi" && (
                  <Button
                    color="blue"
                    size="sm"
                    loading={loading}
                    disabled={
                      listAdd.length === 0 &&
                      location?.state?.actionType !== "doi"
                    }
                    onClick={() => handleEditSalesOrder(false)}
                  >
                    Lưu
                  </Button>
                )}
                <Button
                  color="green"
                  size="sm"
                  loading={loading}
                  disabled={
                    listAdd.length === 0 &&
                    location?.state?.actionType !== "doi"
                  }
                  onClick={() => handleEditSalesOrder(true)}
                >
                  Chốt đơn
                </Button>
              </>
            </Flex>
          ) : (
            orderStatus !== "DON_HANG_HOAN_THANH" && (
              <>
                {location?.state?.actionType === "payment" ? (
                  <Button
                    color="green"
                    size="sm"
                    disabled={payableAmount === 0 || deferredPayment < 0}
                    onClick={() => openModalPaymentBase()}
                    mt={10}
                  >
                    Hoàn tất thanh toán
                  </Button>
                ) : (
                  <>
                    <Flex mt={10} gap={"xs"}>
                      {location?.state?.actionType !== "doi" && (
                        <Button
                          color="cyan"
                          size="sm"
                          loading={loading}
                          disabled={orderStatus === "DON_HANG_CHOT"}
                          onClick={() => handleCreateSalesOrder(false)}
                        >
                          Lưu
                        </Button>
                      )}
                    </Flex>
                    <Flex mt={10} gap={"xs"}>
                      <Button
                        color="green"
                        size="sm"
                        loading={loading}
                        disabled={orderStatus === "DON_HANG_CHOT"}
                        onClick={() => handleCreateSalesOrder(true)}
                      >
                        Chốt đơn
                      </Button>
                    </Flex>
                  </>
                )}
              </>
            )
          )}
          {location?.state?.actionType === "approved" &&
            orderStatus === "DON_HANG_CHO_DUYET_GIAM" && (
              <>
                <Flex mt={10} gap={"xs"}>
                  <Button
                    color="cyan"
                    size="sm"
                    disabled={payableAmount === 0}
                    onClick={() => handleApprovedSalesOrder()}
                    style={{ pointerEvents: "auto" }}
                  >
                    Duyệt giảm
                  </Button>
                </Flex>
                <Flex mt={10} gap={"xs"}>
                  <Button
                    color="red"
                    size="sm"
                    disabled={payableAmount === 0}
                    onClick={() => handleDeleteOrder(orderHeader?.orderNumber)}
                    style={{ pointerEvents: "auto" }}
                  >
                    Không duyệt
                  </Button>
                </Flex>
              </>
            )}
        </Flex>
      </Box>
    );
  };

  //#region openConfirmModal
  function openFormCustomer() {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Thông tin danh sách khách hàng</Title>
        </>
      ),
      size: "90vw",
      children: (
        <CustomerInformation
          handleChooseCustomer={handleChooseCustomer}
          navigate={navigate}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function openFormDebt() {
    modals.openConfirmModal({
      title: <Title order={5}>Danh Sách Đối Tượng Công Nợ</Title>,
      size: "90vw",
      children: (
        <AddFormDebt
          handleChooseDebt={(value: any) => {
            setDebtObject(value);
          }}
          navigate={navigate}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  function openFormVoucher(row: MRT_Row<dataOrderDetailTable>) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Màn hình chọn chính sách khuyến mãi</Title>
        </>
      ),
      size: "70vw",
      children: (
        <Voucher
          branchId={branchId || 0}
          idItem={row?.id}
          price={row.original.priceOnPriceList}
          handleSelectProductAddOn={handleSelectProductAddOn}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const openModalPayment = (priceVnPay: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Thanh toán VN PAY</Title>,
      children: (
        <PaymentMethod
          price={priceVnPay}
          productCode={orderHeader?.orderNumber}
        />
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  const openModalPaymentMb = (price: number) =>
    modals.openConfirmModal({
      title: <Title order={5}>Thanh toán chuyển khoản</Title>,
      children: (
        <PaymentMethodMb
          orderNumber={orderHeader.orderNumber}
          setPaymentDone={setPaymentDone}
          paymentDone={paymentDone}
          price={price}
          productCode={orderHeader?.orderNumber}
        />
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });

  const openDeleteConfirmModal = (row: MRT_Row<dataOrderDetailTable>) => {
    return modals.openConfirmModal({
      title: "Bạn có chắc chắn xóa sản phẩm này?",
      children: (
        <Text size="lg" mt={15}>
          Bạn có chắc chắn muốn xóa ?
        </Text>
      ),
      labels: { confirm: "Xóa", cancel: "Thoát" },
      confirmProps: { color: "red" },
      onConfirm: () => {
        const data = listAdd.filter((value) => value.id !== row.original.id);
        setListAdd(data);

        setListAdd((prevData) => {
          // xoá sp
          const data = prevData.filter(
            (item) =>
              item.id !== row.original.id &&
              //xoá cả quà tặng kèm theo
              !row.original.listIdGift.includes(item.id)
          );

          return data;
        });
      },
    });
  };

  const openModalDeliveryInformation = () => {
    modals.openConfirmModal({
      title: <Title order={5}>Thông tin giao hàng</Title>,
      size: "600px",
      children: (
        <ModalDeliveryInformation
          shipping={shipping}
          customerCheck={customerCheck}
          dataDeliveryInformation={dataDeliveryInformation}
          setDataDeliveryInformation={setDataDeliveryInformation}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const openModalPaymentBase = async () => {
    // const isEditSuccess = await handleEditSalesOrder(true);
    // if (isEditSuccess) {
    modals.openConfirmModal({
      title: <Title order={5}>Xác nhận hoàn tất thanh toán</Title>,
      children: (
        <>
          <Flex justify={"end"} gap={20} mt={"sm"}>
            <Button
              color="gray"
              leftSection={<IconWindow size={18} />}
              onClick={() => {
                modals.closeAll();
              }}
            >
              Huỷ
            </Button>
            <Button
              leftSection={<IconCheck size={18} />}
              onClick={() => {
                handleCallApiPayment();
                modals.closeAll();
              }}
            >
              Xác nhận
            </Button>
          </Flex>
        </>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
      size: "550px",
    });
    // }
  };

  //#region Table sản phẩm
  const handleChangeRequestReduction = async (
    value: number | string,
    id: number
  ) => {
    if (branchId) {
      let priceFinal = 0;
      const callapi = await repositoryPos.post<
        MessageResponse<ItemDetailPriceModel>
      >(
        `/api/v1/TblPriceListDetail/detail-price?id=${id}&BranchId=${branchId}`
      );
      if (callapi?.success) {
        const callapiPromotion = await repositoryPos.post<
          MessageResponse<TblItemPromotionModel[]>
        >(`/api/v1/TblItem/item-promotion`, {
          id: id,
          price: callapi?.data?.listPrice || 0,
          branch: branchId,
        });
        const discountRate =
          (callapiPromotion?.data &&
            callapiPromotion?.data.length > 0 &&
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
              ?.discountPercent) ||
          0;
        const discountAmount =
          (callapiPromotion?.data &&
            callapiPromotion?.data.length > 0 &&
            callapiPromotion?.data[0]?.tblPromotionItemPrimary[0]
              ?.discountAmount) ||
          0;

        priceFinal = discountRate
          ? callapi.data?.listPrice * 1 * (1 - discountRate / 100)
          : callapi.data?.listPrice * 1 - (discountAmount || 0) || 0;
      }
      setListAdd((prevList) => {
        return prevList.map((item) => {
          if (item.id === id) {
            const newValue =
              typeof value === "number" ? value : parseFloat(value);
            const validNewValue = isNaN(newValue) ? 0 : newValue;
            const newPriceFinal = priceFinal - validNewValue;
            return {
              ...item,
              discountAdded: validNewValue,
              priceFinal: newPriceFinal,
            };
          }
          return item;
        });
      });
    }
  };

  const columns = React.useMemo<MRT_ColumnDef<dataOrderDetailTable>[]>(() => {
    return location?.state?.actionType === "doi"
      ? [
          {
            header: "STT",
            enableEditing: false,
            size: 30,
            Cell: ({ renderedCellValue, row }) => (
              <Text>{row.index === -1 ? "" : row.index + 1}</Text>
            ),
          },
          {
            accessorKey: "lineTyle",
            header: "Loại",
            enableEditing: false,
            Cell: ({ renderedCellValue, row }) => (
              <Text>
                <LineType status={row?.original?.lineTyle} />
              </Text>
            ),
            size: 70,
          },
          {
            accessorKey: "itemName",
            header: "Tên sản phẩm",
            enableEditing: false,
            Cell: ({ renderedCellValue, row }) => (
              <Box>
                <Tooltip label={renderedCellValue}>
                  <Text w={200} style={{ whiteSpace: "normal" }} lineClamp={2}>
                    {renderedCellValue}
                  </Text>
                </Tooltip>
              </Box>
            ),
          },
          {
            accessorKey: "attribute13",
            header: "Serial",
            enableEditing: false,
            size: 120,
            Cell: ({ renderedCellValue, row }) => (
              <>
                <TextInput
                  disabled={
                    // row?.original?.attribute11 === "G" ||
                    // row?.original?.attribute11 === "H" ||
                    // row?.original?.attribute11 === "T"
                    true
                  }
                  value={row.original.attribute13}
                ></TextInput>
              </>
            ),
          },

          {
            accessorKey: "itemCode",
            header: "Mã hàng",
            enableEditing: isCreating,
            enableSorting: false,
            size: 100,
            Edit: ({ cell, row, table, column }) => {
              return (
                <Menu
                  trapFocus={false}
                  trigger="hover"
                  shadow="md"
                  width={"800"}
                  position="bottom-start"
                >
                  <Menu.Target>
                    <TextInput
                      autoFocus
                      onChange={async (e) => {
                        const _key = e.target.value ?? "";
                        setValueDebounced(_key);
                      }}
                    />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <TableSelect
                      dataProducts={dataProducts}
                      onSelectProduct={(product) => {
                        handleSelectProduct(product, row?.index);
                      }}
                      rowIndex={row.index}
                    />
                  </Menu.Dropdown>
                </Menu>
              );
            },
          },

          {
            accessorKey: "primaryUomCode",
            header: "ĐVT",
            enableEditing: false,
            enableSorting: false,
            size: 50,
          },

          {
            accessorKey: "quality",
            header: "Số lượng",
            enableEditing: false,
            enableSorting: false,
            size: 80,
            Cell: ({ renderedCellValue, row }) => (
              <NumberInput
                disabled={
                  row.original.lineTyle === "KM" ||
                  row?.original?.attribute11 === "G" ||
                  row?.original?.attribute11 === "H" ||
                  row?.original?.attribute11 === "T"
                }
                min={1}
                max={10000}
                hideControls
                clampBehavior="strict"
                value={row.original.quality}
                onChange={(e) => {
                  handleChangeQuantity(e, row);
                }}
                onBlur={(e) => {
                  table.setEditingCell(null);
                }}
                onKeyDown={handleKeyDown}
                defaultValue={1}
              />
            ),
          },
          {
            accessorKey: "priceOnPriceList",
            header: "Giá bán lẻ",
            enableEditing: false,
            enableSorting: false,
            size: 0,
            Cell: ({ renderedCellValue, row }) => (
              <Flex direction={"column"}>
                <NumberFormatter
                  value={row.original.priceOnPriceList}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Flex>
            ),
          },
          {
            header: "KM",
            enableEditing: false,
            enableSorting: false,
            size: 50,
            Cell: ({ row }) => (
              <span style={{ color: "red" }}>
                {row.original.discountRate ? (
                  `-${row.original.discountRate}%`
                ) : row.original?.discountAmount ? (
                  <NumberFormatter
                    value={-row.original?.discountAmount || 0}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                ) : null}
              </span>
            ),
          },
          {
            accessorKey: "discount",
            header: "Xin giảm",
            enableEditing: false,
            enableSorting: false,
            size: 120,
            Cell: ({ renderedCellValue, row }) => (
              <>
                <NumberInput
                  disabled={
                    Number(orderHeader?.discountAddedTotal) > 0
                      ? true
                      : false ||
                        row?.original?.lineTyle !== "HH" ||
                        location?.state?.actionType === "edit" ||
                        location?.state?.actionType === "payment" ||
                        row?.original?.attribute11 === "G" ||
                        row?.original?.attribute11 === "H"
                  }
                  style={{ height: 35 }}
                  size="sm"
                  fw={"500"}
                  hideControls
                  thousandSeparator="."
                  decimalSeparator=","
                  value={row.original?.discountAdded}
                  onChange={(e) => {
                    handleChangeRequestReduction(e, row.original.id);
                  }}
                  min={1}
                  max={10000000}
                  clampBehavior="strict"
                  onKeyDown={handleKeyDown}
                />
              </>
            ),
          },
          {
            accessorKey: "priceFinal",
            header: "Thành tiền",
            size: 80,
            enableEditing: false,
            enableSorting: false,
            Cell: ({ renderedCellValue, row }) => {
              const { priceFinal, quality } = row.original;
              // Đảm bảo không có giá âm
              const displayedPrice = priceFinal * quality;

              return (
                <>
                  <NumberFormatter
                    value={displayedPrice}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </>
              );
            },
          },

          // {
          //   accessorKey: "chuabietluufieldnao",
          //   header: "TH bảo hành",
          //   enableEditing: false,
          //   Cell: ({ renderedCellValue, row }) => (
          //     <Box>
          //       <Tooltip label={renderedCellValue}>
          //         <Text w={100} style={{ whiteSpace: "normal" }}>
          //           {renderedCellValue}
          //         </Text>
          //       </Tooltip>
          //     </Box>
          //   ),
          // },

          {
            accessorKey: "note",
            header: "Ghi chú",
            enableSorting: false,
            size: 100,
            Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
            mantineEditTextInputProps: ({ cell, row }) => ({
              onBlur: (event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, note: event?.currentTarget?.value }
                      : item
                  );
                });
              },
            }),
          },
          {
            accessorKey: "attribute2",
            header: "Trường hợp Đổi/Trả",
            enableSorting: false,
            size: 200,
            Cell: ({ row }) => (
              <select
                disabled={row?.original?.attribute11 ? true : false}
                value={row.original.attribute2}
                onChange={(event) => {
                  setListAdd((prevData) => {
                    return prevData.map((item, i) =>
                      i === row.index
                        ? { ...item, attribute2: event?.currentTarget?.value }
                        : item
                    );
                  });
                }}
                style={selectStyle}
              >
                <option value="" style={optionStyle}>
                  Chọn
                </option>

                {location?.state?.actionType === "doi" &&
                  exchangeReasons.map((reason, index) => (
                    <option
                      key={index}
                      value={reason.value}
                      style={optionStyle}
                    >
                      {reason.label}
                    </option>
                  ))}

                {location?.state?.actionType === "doi" &&
                  row?.original?.lineTyle === "HT" &&
                  returnReasons.map((reason, index) => (
                    <option
                      key={index}
                      value={reason.value}
                      style={optionStyle}
                    >
                      {reason.label}
                    </option>
                  ))}
              </select>
            ),
          },
          {
            accessorKey: "attribute14",
            header: "Serial đổi",
            enableEditing: false,
            size: 140,
            Cell: ({ row }) => (
              <select
                disabled={
                  row?.original?.attribute11 === "G" ||
                  row?.original?.attribute11 === "H" ||
                  row?.original?.attribute11 === "T"
                }
                value={row?.original?.attribute14}
                onChange={(event) => {
                  setListAdd((prevData) => {
                    return prevData.map((item, i) =>
                      i === row.index
                        ? { ...item, attribute14: event?.currentTarget?.value }
                        : item
                    );
                  });
                }}
                style={selectStyle}
              >
                <option value="" style={optionStyle}>
                  Chọn
                </option>

                {location?.state?.actionType === "doi" &&
                  listSerial?.map((reason, index) => (
                    <option
                      key={index}
                      value={reason.value}
                      style={optionStyle}
                    >
                      {reason.label}
                    </option>
                  ))}
              </select>
            ),
          },
          {
            accessorKey: "attribute6",
            header: "Tình trạng",
            enableSorting: false,
            size: 150,
            Cell: ({ row }) =>
              row.original.lineTyle !== "HH" ? (
                <select
                  disabled={
                    row?.original?.attribute11 === "G" ||
                    row?.original?.attribute11 === "H" ||
                    row?.original?.attribute11 === "T"
                  }
                  value={row.original.attribute6}
                  onChange={(event) => {
                    setListAdd((prevData) => {
                      return prevData.map((item, i) =>
                        i === row.index
                          ? { ...item, attribute6: event?.currentTarget?.value }
                          : item
                      );
                    });
                  }}
                  style={selectStyle}
                >
                  <option value="" style={optionStyle}>
                    Chọn
                  </option>
                  <option value="new" style={optionStyle}>
                    Mới 100%
                  </option>
                  <option value="used" style={optionStyle}>
                    Đã sử dụng
                  </option>
                </select>
              ) : (
                <Input disabled></Input>
              ),
          },
          {
            accessorKey: "attribute7",
            header: "Phụ kiện",
            enableSorting: false,
            size: 110,
            Cell: ({ row }) =>
              row.original.lineTyle !== "HH" ? (
                <select
                  disabled={
                    row?.original?.attribute11 === "G" ||
                    row?.original?.attribute11 === "H" ||
                    row?.original?.attribute11 === "T"
                  }
                  value={row.original.attribute7}
                  onChange={(event) => {
                    setListAdd((prevData) => {
                      return prevData.map((item, i) =>
                        i === row.index
                          ? { ...item, attribute7: event?.currentTarget?.value }
                          : item
                      );
                    });
                  }}
                  style={selectStyle}
                >
                  <option value="" style={optionStyle}>
                    Chọn
                  </option>
                  <option value="du" style={optionStyle}>
                    Đủ
                  </option>
                  <option value="thieu" style={optionStyle}>
                    Thiếu
                  </option>
                </select>
              ) : (
                <Input disabled></Input>
              ),
          },
          {
            accessorKey: "attribute8",
            header: "Trạng thái",
            enableSorting: false,
            size: 180,
            Cell: ({ row }) =>
              row.original.lineTyle !== "HH" ? (
                <select
                  disabled={
                    row?.original?.attribute11 === "G" ||
                    row?.original?.attribute11 === "H" ||
                    row?.original?.attribute11 === "T"
                  }
                  value={row.original.attribute8}
                  onChange={(event) => {
                    setListAdd((prevData) => {
                      return prevData.map((item, i) =>
                        i === row.index
                          ? { ...item, attribute8: event?.currentTarget?.value }
                          : item
                      );
                    });
                  }}
                  style={selectStyle}
                >
                  <option value="" style={optionStyle}>
                    Chọn
                  </option>
                  <option value="loi" style={optionStyle}>
                    Lỗi
                  </option>
                  <option value="hoat_dong_tot" style={optionStyle}>
                    Hoạt động tốt
                  </option>
                </select>
              ) : (
                <Input disabled></Input>
              ),
          },
          {
            accessorKey: "attribute4",
            header: "Trừ theo quy định",
            enableSorting: false,
            size: 110,
            Cell: ({ row }) =>
              row.original.lineTyle !== "HH" ? (
                <select
                  disabled={
                    row?.original?.attribute11 === "G" ||
                    row?.original?.attribute11 === "H" ||
                    row?.original?.attribute11 === "T"
                  }
                  value={row.original.attribute4}
                  onChange={(event) => {
                    setListAdd((prevData) => {
                      return prevData.map((item, i) =>
                        i === row.index
                          ? { ...item, attribute4: event?.currentTarget?.value }
                          : item
                      );
                    });
                  }}
                  style={selectStyle}
                >
                  <option value="" style={optionStyle}>
                    Chọn
                  </option>
                  <option value="0" style={optionStyle}>
                    0%
                  </option>
                  <option value="5" style={optionStyle}>
                    5%
                  </option>
                  <option value="10" style={optionStyle}>
                    10%
                  </option>
                  <option value="15" style={optionStyle}>
                    15%
                  </option>
                  <option value="20" style={optionStyle}>
                    20%
                  </option>
                </select>
              ) : (
                <Input disabled></Input>
              ),
          },
          {
            accessorKey: "attribute5",
            header: "Trừ theo lỗi vật lý",
            size: 110,
            Cell: ({ row }) =>
              row.original.lineTyle !== "HH" ? (
                <NumberInput
                  disabled={
                    row?.original?.attribute11 === "G" ||
                    row?.original?.attribute11 === "H" ||
                    row?.original?.attribute11 === "T"
                  }
                  min={0}
                  max={10000000}
                  clampBehavior="strict"
                  hideControls
                  thousandSeparator="."
                  decimalSeparator=","
                  value={row.original.attribute5 || ""}
                  onChange={(value) => {
                    setListAdd((prevData) => {
                      return prevData.map((item, i) =>
                        i === row.index
                          ? { ...item, attribute5: value?.toString() }
                          : item
                      );
                    });
                  }}
                />
              ) : (
                <Input disabled></Input>
              ),
          },
          {
            accessorKey: "attribute12",
            header: "Trừ KM",
            size: 110,
            Cell: ({ row }) =>
              row.original.lineTyle !== "HH" ? (
                <NumberInput
                  min={0}
                  max={10000000}
                  clampBehavior="strict"
                  hideControls
                  thousandSeparator="."
                  decimalSeparator=","
                  value={row.original.attribute12 || ""}
                  onChange={(value) => {
                    setListAdd((prevData) => {
                      return prevData.map((item, i) =>
                        i === row.index
                          ? { ...item, attribute12: value?.toString() }
                          : item
                      );
                    });
                  }}
                />
              ) : (
                <Input disabled></Input>
              ),
          },

          {
            accessorKey: "attribute9",
            header: "Ghi chú kiểm tra",
            enableSorting: false,
            // enableEditing: false,
            size: 10,
            Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
            mantineEditTextInputProps: ({ cell, row }) => ({
              disabled: row.original.lineTyle === "HH",
              onBlur: (event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, attribute9: event?.currentTarget?.value }
                      : item
                  );
                });
              },
            }),
          },
        ]
      : [
          {
            header: "STT",
            enableEditing: false,
            size: 30,
            Cell: ({ renderedCellValue, row }) => (
              <Text>{row.index === -1 ? "" : row.index + 1}</Text>
            ),
          },
          {
            accessorKey: "lineTyle",
            header: "Loại",
            enableEditing: false,
            Cell: ({ renderedCellValue, row }) => (
              <Text>
                <LineType status={row?.original?.lineTyle} />
              </Text>
            ),
            size: 70,
          },
          {
            accessorKey: "itemName",
            header: "Tên sản phẩm",
            enableEditing: false,
            Cell: ({ renderedCellValue, row }) => (
              <Box>
                <Tooltip label={renderedCellValue}>
                  <Text w={200} style={{ whiteSpace: "normal" }} lineClamp={2}>
                    {renderedCellValue}
                  </Text>
                </Tooltip>
              </Box>
            ),
          },
          {
            accessorKey: "itemCode",
            header: "Mã hàng",
            enableEditing: isCreating,
            enableSorting: false,
            size: 100,
            Edit: ({ cell, row, table, column }) => {
              return (
                <Menu
                  trapFocus={false}
                  trigger="hover"
                  shadow="md"
                  width={"800"}
                  position="bottom-start"
                >
                  <Menu.Target>
                    <TextInput
                      autoFocus
                      onChange={async (e) => {
                        const _key = e.target.value ?? "";
                        setValueDebounced(_key);
                      }}
                    />
                  </Menu.Target>
                  <Menu.Dropdown>
                    <TableSelect
                      dataProducts={dataProducts}
                      onSelectProduct={(product) => {
                        handleSelectProduct(product, row?.index);
                      }}
                      rowIndex={row.index}
                    />
                  </Menu.Dropdown>
                </Menu>
              );
            },
          },

          {
            accessorKey: "primaryUomCode",
            header: "ĐVT",
            enableEditing: false,
            enableSorting: false,
            size: 50,
          },

          {
            accessorKey: "quality",
            header: "Số lượng",
            enableEditing: false,
            enableSorting: false,
            size: 80,
            Cell: ({ renderedCellValue, row }) => (
              <NumberInput
                disabled={row.original.lineTyle === "KM"}
                min={1}
                max={10000}
                hideControls
                clampBehavior="strict"
                value={row.original.quality}
                onChange={(e) => {
                  handleChangeQuantity(e, row);
                }}
                onBlur={(e) => {
                  table.setEditingCell(null);
                }}
                onKeyDown={handleKeyDown}
                defaultValue={1}
              />
            ),
          },
          {
            accessorKey: "priceOnPriceList",
            header: "Giá bán lẻ",
            enableEditing: false,
            enableSorting: false,
            size: 0,
            Cell: ({ renderedCellValue, row }) => (
              <Flex direction={"column"}>
                <NumberFormatter
                  value={row.original.priceOnPriceList}
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </Flex>
            ),
          },
          {
            header: "KM",
            enableEditing: false,
            enableSorting: false,
            size: 50,
            Cell: ({ row }) => (
              <span style={{ color: "red" }}>
                {row.original.discountRate ? (
                  `-${row.original.discountRate}%`
                ) : row.original?.discountAmount ? (
                  <NumberFormatter
                    value={-row.original?.discountAmount || 0}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                ) : null}
              </span>
            ),
          },
          {
            accessorKey: "discount",
            header: "Xin giảm",
            enableEditing: false,
            enableSorting: false,
            size: 120,
            Cell: ({ renderedCellValue, row }) => (
              <>
                <NumberInput
                  disabled={
                    Number(orderHeader?.discountAddedTotal) > 0
                      ? true
                      : false ||
                        row?.original?.lineTyle !== "HH" ||
                        location?.state?.actionType === "edit" ||
                        location?.state?.actionType === "payment"
                  }
                  style={{ height: 35 }}
                  size="sm"
                  fw={"500"}
                  hideControls
                  thousandSeparator="."
                  decimalSeparator=","
                  value={row.original?.discountAdded}
                  onChange={(e) => {
                    handleChangeRequestReduction(e, row.original.id);
                  }}
                  min={1}
                  max={10000000}
                  clampBehavior="strict"
                  onKeyDown={handleKeyDown}
                />
              </>
            ),
          },
          {
            accessorKey: "priceFinal",
            header: "Thành tiền",
            size: 80,
            enableEditing: false,
            enableSorting: false,
            Cell: ({ renderedCellValue, row }) => {
              const { priceFinal, quality } = row.original;
              // Đảm bảo không có giá âm
              const displayedPrice = Math.max(priceFinal * quality, 0);

              return (
                <>
                  <NumberFormatter
                    value={displayedPrice}
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </>
              );
            },
          },

          {
            accessorKey: "note",
            header: "Ghi chú",
            enableSorting: false,
            size: 100,
            Cell: ({ renderedCellValue, row, column }) => renderedCellValue,
            mantineEditTextInputProps: ({ cell, row }) => ({
              onBlur: (event) => {
                setListAdd((prevData) => {
                  return prevData.map((item, i) =>
                    i === row.index
                      ? { ...item, note: event?.currentTarget?.value }
                      : item
                  );
                });
              },
            }),
          },
        ];
  }, [listAdd, dataProducts, isCreating, orderHeader?.discountAddedTotal]);

  const table = useMantineReactTable<dataOrderDetailTable>({
    columns,
    data: listAdd,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "Thao tác",
      },
    },
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableRowActions: true,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableTopToolbar: false,
    enableBatchRowSelection: true,
    positionActionsColumn: "last",
    enableExpandAll: false,

    renderRowActions: ({ row, table }) => (
      <Flex gap="4">
        <Tooltip label="Chọn chính sách khuyến mãi">
          <ActionIcon
            color={orange.base}
            variant="outline"
            disabled={
              row.original?.listIdPromotion?.length < 1 ||
              !row.original?.listIdPromotion?.length
            }
            onClick={async () => {
              openFormVoucher(row);
            }}
          >
            <IconDiscount
              style={{
                color:
                  row.original?.listIdPromotion?.length < 1 ||
                  !row.original?.listIdPromotion?.length
                    ? "gray"
                    : orange.base,
              }}
            />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Xoá sản phẩm">
          <ActionIcon variant="outline" color="red">
            <IconTrash onClick={() => openDeleteConfirmModal(row)} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localizationSales,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    initialState: {
      expanded: true,
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-expand"],
        right: ["mrt-row-actions"],
      },
      columnVisibility: { id: true },
      density: "xs",
    },

    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualSorting: false,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        ...styleCellTable(row),
        ...getStyle(true),
      },
    }),
    mantineTableBodyRowProps: ({ row }) => ({
      style: {
        backgroundColor: handleCheckBackgroundColor(
          row?.original?.attribute11 || ""
        ),
      },
    }),
    enablePagination: false,
    enableBottomToolbar: false,
    state: {
      showAlertBanner: isError,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    enableColumnPinning: true,
    mantineTableProps: {},
  });

  //#region useEffect

  useHotkeys([
    ["mod+J", () => console.log("Toggle color scheme")],
    [
      "ctrl+F5",
      () => {
        //  openNew();
      },
    ],
    [
      "F5",
      () => {
        //  openNew();
      },
    ],
    ["alt+mod+shift+X", () => console.log("Rick roll")],
    [
      "F11",
      () => {
        // openNew(true);
      },
    ],
    ["ctrl+M", () => toggleCollapse()],
  ]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentTime(new Date());
  //   }, 1000); // Cập nhật mỗi giây

  //   return () => clearInterval(intervalId); // Dọn dẹp khi component unmount
  // }, []);

  // số tiền cần thanh toán = tổng tiền hàng - tổng giảm trừ
  // useEffect(() => {
  //   setPayableAmount(totalAmount - totalDeduction);
  // }, [totalAmount, totalDeduction]);

  // số tiền Tổng trả trước = tổng của Tiền mặt , chuyển khoản , VN pay , Cà thẻ
  useEffect(() => {
    const total = [cardPayment, cashPayment, vnpayPayment, bankTransfer]
      .filter((value) => value > 0) // Filter out values that are 0 or less
      .reduce((acc, curr) => acc + curr, 0); // Sum up the remaining values

    setTotalPaid(total);
  }, [cardPayment, cashPayment, vnpayPayment, bankTransfer]);

  useEffect(() => {
    setDeferredPayment(payableAmount - totalPaid);
  }, [payableAmount, totalPaid]);

  useEffect(() => {
    if (paymentDone?.length === 3) {
      setOrderStatus("completed");
    }
  }, [paymentDone]);

  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");

    if (userLogin) {
      const userLoginObject = JSON.parse(userLogin);
      setUserLogin(userLoginObject);
      setEmployeeSalesName(userLoginObject?.fullName);
      setDepartment(userLoginObject?.depName);
      setDepartmentId(userLoginObject?.departmentId);
      setEmployeeSalesId(userLoginObject?.id);
    }
    if (
      location?.state?.actionType !== "view" &&
      location?.state?.actionType !== "payment" &&
      location?.state?.actionType !== "edit"
    ) {
      fetchDataCreate();
    }
  }, [location]);

  useEffect(() => {
    if (branchId && branchName) return;
    setBranchId(orderHeader?.orgId || 0);
    const name = listBranch?.find(
      (item: any) => item?.value == orderHeader?.orgId
    );
    setBranchName(name?.text);
  }, [orderHeader, listBranch]);

  useEffect(() => {
    setListSerial(handleRenderOptionListAdd(listAdd));
    let priceTotal = handleCheckPayableAmount(listAdd);
    // listAdd?.forEach((item) => {
    //   priceTotal += (item?.priceOnPriceList || 0) * (item?.quality || 0);
    // });
    setTotalAmount(priceTotal);
    let totalXGDetail = listAdd.reduce((acc, item) => {
      const discountAdded = item.discountAdded || 0; // Lấy giá trị xin giảm, mặc định là 0 nếu không có
      return acc + discountAdded * (item.quality || 1); // Cộng dồn vào tổng, nhân với số lượng
    }, 0);
    const totalDiscount = listAdd.reduce((acc, item) => {
      const discountAmount = item.discountAmount || 0; // Lấy giá trị giảm giá, mặc định là 0 nếu không có
      return acc + discountAmount * (item.quality || 1); // Cộng dồn vào tổng, nhân với số lượng
    }, 0);

    const totalDiscountRate = listAdd.reduce((acc, item) => {
      const discountRate = item.discountRate || 0; // Giá trị phần trăm giảm giá
      const price = item.priceOnPriceList || 0; // Giá của sản phẩm
      const quality = item.quality || 1; // Số lượng sản phẩm
      const discountValue = (discountRate / 100) * price * quality; // Tính giá trị giảm giá
      return acc + discountValue; // Cộng dồn vào tổng
    }, 0);

    setTotalXGDetail(totalXGDetail); // Cập nhật tổng xin giảm
    setTotalKM(totalDiscount + totalDiscountRate);
    setPayableAmount(
      (priceTotal ?? 0) -
        (totalDiscount ?? 0) -
        (totalDiscountRate ?? 0) -
        (totalXGDetail ?? 0) -
        (orderHeader?.discountAddedTotal ?? 0)
    );
  }, [listAdd, orderHeader]);

  // hàm lấy data ban đầu
  useEffect(() => {
    const fetchDataBranch = async () => {
      const response = await repositoryPos.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmInventory/get-select-branch?auth=false");

      if (response && response.success) {
        let result = response.data;
        setListBranch(result);
      }
    };

    const fetchDataSaleChanelId = async () => {
      const response = await repositoryMdm.get<MessageResponse<any[]>>(
        "/api/v1/TblDmSaleChanel/get-all"
      );

      if (response && response.success) {
        let result = response.data;
        setSaleChanelList(result);
      }
    };

    const fetchAllDepartment = async () => {
      const response = await repositoryPos.get<
        MessageResponse<DepartmentModel[]>
      >(`/api/v1/TblDepartment/get-all`);

      if (response && response.success) {
        let result = response.data;
        setListDepartment(result);
      } else {
      }
    };

    Promise.all([
      fetchDataSaleChanelId(),
      fetchAllDepartment(),
      fetchDataBranch(),
    ]);
  }, []);

  useEffect(() => {
    if (valueDebounced && valueDebounced.length > 2) {
      fetchItemSell();
    } else {
      setDataProducts([]);
    }
  }, [valueDebounced]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (270 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  // Noti nếu thanh toán trước hơn thanh toán sau
  useEffect(() => {
    if (
      deferredPayment < 0 &&
      totalAmount !== 0 &&
      location?.state?.actionType !== "doi"
    ) {
      NotificationExtension.Warn(
        "Số tiền thanh toán lớn hơn tổng số tiền cần thanh toán của đơn!"
      );
    }
  }, [deferredPayment]);
  //region logic chọn kế toán
  const [isAccountant, setIsAccountant] = useState(false);
  const [dataAccountant, setDataAccountant] = useState<any[]>([]);
  const [accountantId, setAccountantId] = useState(0);
  const [accountantName, setAccountantName] = useState("");

  useEffect(() => {
    const fetchDataAccountant = async () => {
      try {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/get-list-accountant-employee?Skip=0&Take=99`
        );
        if (response && response.success) {
          let result = response.data;

          setDataAccountant(result);
        }
      } catch (error) {
        setIsAccountant(false);
      }
    };

    const handleCheckAccountant = async () => {
      if (
        orderHeader?.orderNumber &&
        location?.state?.actionType === "payment" &&
        location?.state?.actionType !== "view"
      ) {
        // const response = await repositoryPos.get<MessageResponse<any>>(
        //   `/api/v1/CreateSellItem/payment?orderNumber=${orderHeader?.orderNumber}`,
        //   true
        // );
        // if (response && response.success) {
        //   let result = response.data;
        //   setIsAccountant(result ? true : false);
        // }
      }
    };
    fetchDataAccountant();
    handleCheckAccountant();
  }, [orderHeader?.orderNumber]);

  useEffect(() => {
    const handleFetchDataByBranchId = async () => {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/get-list-accountant-employee?BranchId=${branchId}`
      );
      if (response && response.success) {
        let result = response.data;

        setDataAccountant(result);
      }
    };

    if (branchId) {
      handleFetchDataByBranchId();
    }
  }, [branchId]);

  //region logic chọn chi nhánh
  useEffect(() => {
    const fetchDepartmentByBanchId = async () => {
      if (branchId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDepartment/get-all?branchId=${branchId}`
        );
        if (response && response.success) {
          let result = response.data;
          setListDepartment(result);
        }
      }
    };
    fetchDepartmentByBanchId();
  }, [branchId]);

  useEffect(() => {
    const fetchEmployeeByDepartmentId = async () => {
      if (departmentId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/get-list-by-department?depId=${departmentId}`
        );
        if (response && response.success) {
          let result = response.data;
          setListEmployeeSales(result);
        }
      }
    };
    fetchEmployeeByDepartmentId();
  }, [departmentId]);

  //region  logic sửa đơn hàng

  const fetchDetailEmployeeSales = async (SaleId: number) => {
    if (SaleId) {
      const response = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail-employee?id=${SaleId}`
      );
      if (response && response.success) {
        let result = response.data;
        setEmployeeSalesId(SaleId.toString());
        setEmployeeSalesName(result?.fullName);
      }
    }
  };
  const fetchDepartment = async (DeptId: number) => {
    setEmployeeSalesId(DeptId.toString());
    const DeptName = listDepartment.find((item) => {
      return item.departmentId === DeptId;
    });
    if (DeptName?.name) {
      setDepartment(DeptName?.name || "");
    }
  };

  const fetchDetailSaleChanel = async (SaleChanelId: number) => {
    if (SaleChanelId) {
      const response = await repositoryMdm.get<MessageResponse<any>>(
        `/api/v1/TblDmSaleChanel/update?id=${SaleChanelId}`
      );
      if (response && response.success) {
        let result = response.data;
        setSaleChanel(result?.name);
      }
    }
  };

  console.log("LTAU0794", listAdd);

  const fetchDelivery = async (data: string) => {
    try {
      const parsedData = JSON.parse(data);
      setDataDeliveryInformation((prev: any) => ({
        ...prev,
        branchId: parsedData?.branchId,
        customerAddress: parsedData?.customerAddress,
        customerCommuneId: parsedData?.customerCommuneId,
        customerDistrictId: parsedData?.customerDistrictId,
        customerId: parsedData?.customerId,
        customerName: parsedData?.customerName,
        customerProvinceId: parsedData?.customerProvinceId,
        depId: parsedData?.depId,
        sourceType: parsedData?.sourceType,
        telephoneNumber: parsedData?.telephoneNumber,
        deliveryAppointmentTime: new Date(),
        deliveryFee: parsedData?.deliveryFee,
      }));
    } catch (error) {
      console.error("JSON parsing failed:", error);
    }
  };

  const handleGetNameAccountantById = async (
    accountantId: number | undefined
  ) => {
    try {
      if (accountantId) {
        const response = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmEmployee/detail-employee?id=${accountantId}`
        );
        if (response && response.success) {
          setAccountantName(response?.data?.fullName);
        }
      }
    } catch (error) {
      console.error("Error fetching accountant name:", error);
      return ""; // Trả về chuỗi rỗng nếu có lỗi
    }
  };

  useEffect(() => {
    if (
      !location?.state ||
      location?.state?.actionType === "view" ||
      location?.state?.actionType === "doi"
    )
      return;
    const fetchDataPaymentMethod = async () => {
      if (location.state?.orderNumber) {
        const url =
          location?.state?.actionType === "approved"
            ? `/api/v1/CreateSellItem/approve?orderNumber=${location.state?.orderNumber}`
            : location?.state?.actionType === "edit"
            ? `/api/v1/CreateSellItem/edit?orderNumber=${location.state?.orderNumber}`
            : `/api/v1/CreateSellItem/payment?orderNumber=${location.state?.orderNumber}`;

        const response = await repositoryPos.get<MessageResponse<any>>(url);

        if (response && response.success) {
          let result = response.data;
          setOrderHeader({ ...result.orderHeader });
          setOrderStatus(result?.orderHeader?.orderStatus);
          setBranchId(result?.orderHeader?.orgId);
          const branchName = listBranch?.find(
            (item: any) => item?.id == result?.orderHeader?.orgId
          );

          // Kiểm tra nếu branchName tồn tại trước khi set
          if (branchName) {
            setBranchName(branchName?.text);
          }
          if (!result?.orderDetail) return;
          setListAdd(result.orderDetail);
        }
      }
    };
    fetchDataPaymentMethod();
  }, [location, listBranch]);

  useEffect(() => {
    if (!location?.state) return;
    const handleFetchDataCustomer = async () => {
      if (orderHeader?.custId) {
        const customerReturn = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDmCustomer/get-detail?id=${orderHeader?.custId}`
        );
        setCustomerCheck((prev) => ({
          ...prev,
          active: customerReturn?.data?.active ?? false,
          address: customerReturn?.data?.address ?? "",
          birthOfDate: customerReturn?.data?.birthOfDate ?? null,
          code: customerReturn?.data?.code ?? "",
          contact: customerReturn?.data?.contact ?? "",
          description: customerReturn?.data?.description ?? "",
          email: customerReturn?.data?.email ?? "",
          fax: customerReturn?.data?.fax ?? "",
          groupId: customerReturn?.data?.groupId ?? 0,
          id: customerReturn?.data?.id ?? 0,
          name: customerReturn?.data?.name ?? "",
          sex: customerReturn?.data?.sex ?? null,
          taxCode: customerReturn?.data?.taxCode ?? "",
          telephoneNumber: customerReturn?.data?.telephoneNumber ?? "",
          type: customerReturn?.data?.type ?? 0,
        }));
      }
    };
    handleFetchDataCustomer();
  }, [orderHeader, location?.state]);

  useEffect(() => {
    if (!location?.state) return;
    const handleFetchDataDebt = async () => {
      if (orderHeader?.custId) {
        console.log(getGroupCode(orderHeader?.attribute8 || ""));
        const debtObjectReturn = await repositoryPos.get<MessageResponse<any>>(
          `/api/v1/TblDebtGroup/get-detail?code=${getGroupCode(
            orderHeader?.attribute8 || ""
          )}`
        );
        setDebtObject((prev) => ({
          ...prev,
          id: debtObjectReturn?.data?.id ?? "",
          groupCode: debtObjectReturn?.data?.groupCode ?? null,
          groupName: debtObjectReturn?.data?.groupName ?? null,
          taxCode: debtObjectReturn?.data?.taxCode ?? null,
          address: debtObjectReturn?.data?.address ?? null,
          phone: debtObjectReturn?.data?.telephoneNumber ?? null,
          contactPerson: debtObjectReturn?.data?.contact ?? null,
          classification: debtObjectReturn?.data?.classification ?? null,
          classificationName:
            debtObjectReturn?.data?.classificationName ?? null,
          debtTerm: debtObjectReturn?.data?.debtTerm ?? null,
          debtLimit: debtObjectReturn?.data?.debtLimit ?? null,
          type: debtObjectReturn?.data?.type ?? null,
          typeName: debtObjectReturn?.data?.typeName ?? null,
          createBy: debtObjectReturn?.data?.createBy ?? null,
          createByName: debtObjectReturn?.data?.createByName ?? null,
          lastUpdateBy: debtObjectReturn?.data?.lastUpdateBy ?? null,
          lastCreateByName: debtObjectReturn?.data?.lastCreateByName ?? null,
          lastUpdateDate: debtObjectReturn?.data?.lastUpdateDate ?? null,
          createDate: debtObjectReturn?.data?.createDate ?? null,
        }));
      }
    };
    handleFetchDataDebt();
  }, [orderHeader, location?.state]);

  useEffect(() => {
    if (location?.state && !location?.state?.orderNumberOld) {
      fetchDetailEmployeeSales(orderHeader?.saleId);
      fetchDepartment(orderHeader?.deptId);
      fetchDetailSaleChanel(orderHeader?.saleChanelId);
      setContractcode(orderHeader?.contractcode);
      fetchDelivery(orderHeader?.attribute1 || "");
      setShipping(orderHeader?.shipngType);
      handleGetNameAccountantById(orderHeader?.attribute2);
      setAccountantId(orderHeader?.attribute2 || 0);
    }
  }, [orderHeader, location]);

  // logic xem đơn

  useEffect(() => {
    const handleViewSalesOrder = async () => {
      if (location?.state?.actionType === "view") {
        const url = `/api/v1/CreateSellItem/details?orderNumber=${location.state.orderNumber}`;
        const response = await repositoryPos.get<MessageResponse<any>>(url);
        if (response && response?.success) {
          setOrderHeader({ ...response?.data?.orderHeader });
          setDataPayment(JSON.parse(response?.data?.orderHeader?.attribute9));
          setListAdd(response.data.orderDetail);
          setOrderStatus(response?.data?.orderHeader?.orderStatus);
        }
        console.log(
          "JSON.parse(response?.data?.orderHeader?.attribute9)",
          JSON.parse(response?.data?.orderHeader?.attribute9)
        );
      }
    };

    handleViewSalesOrder();
  }, [location]);

  // logic doi tra

  useEffect(() => {
    const handleViewSalesOrder = async () => {
      if (location?.state?.actionType === "doi") {
        const url = `/api/v1/CreateSellItem/details-return?orderNumber=${location.state.orderNumberOld}&check=false`;
        const response = await repositoryPos.get<MessageResponse<any>>(url);
        if (response && response?.success) {
          setOrderHeader({ ...response?.data?.orderHeader });
          setDataPayment(response?.data?.paymentCommands);
          setListAdd(response.data.orderDetail);
          setOrderStatus(response?.data?.orderHeader?.orderStatus);
        }
      }
    };

    handleViewSalesOrder();
  }, [location]);

  return (
    <Provider store={store}>
      <Box>
        <div
          style={{
            ...getStyle(true),
          }}
        >
          {titleSell()}
        </div>

        <PanelGroup direction="horizontal" id="group" style={{ width: "100%" }}>
          <Panel
            defaultSize={
              isCollapse ? 0 : location?.state?.actionType === "view" ? 35 : 45
            }
            minSize={isCollapse ? 0 : 35}
            maxSize={65}
            collapsible
            style={{
              transition: "0.3s ease",
              display: isCollapse ? "none" : "block",
            }}
          >
            <Collapse
              in={!isCollapse}
              transitionDuration={100}
              transitionTimingFunction="ease-in-out"
              h={"100%"}
            >
              <Card h="100%" p={0} shadow="sm" radius="md" withBorder>
                {formOrderHeader()}
              </Card>
            </Collapse>
          </Panel>

          {!isCollapse && <PanelResizeHandle id="resize-handle" />}

          <Panel
            defaultSize={isCollapse ? 100 : 55}
            minSize={35}
            maxSize={isCollapse ? 100 : 65}
            style={{
              width: isCollapse ? "100%" : "auto",
              transition: "width 0.3s ease",
            }}
          >
            <Flex direction={"column"}>
              <MantineReactTable table={table} />

              {/* Thêm thanh hiển thị thông tin đơn hàng ở đây */}
              {!containsDT(orderHeader?.orderNumber) && (
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  p="md"
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px",
                    padding: "20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Grid>
                    <Grid.Col span={2} style={orderInfoStyle}>
                      <Text fw={500} style={infoLabelStyle}>
                        Số lượng:
                      </Text>
                      <Text style={infoValueStyle}>
                        {getTotalQuality(listAdd)}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2} style={orderInfoStyle}>
                      <Text fw={500} style={infoLabelStyle}>
                        Tiền hàng:
                      </Text>
                      <Text fw={"bold"}>
                        <NumberFormatter
                          value={totalAmount}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2} style={orderInfoStyle}>
                      <Text fw={500} style={infoLabelStyle}>
                        Tổng khuyến mãi:
                      </Text>
                      <Text fw={"bold"}>
                        <NumberFormatter
                          value={totalKM}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2} style={orderInfoStyle}>
                      <Text fw={500} style={infoLabelStyle}>
                        Tổng xin giảm:
                      </Text>
                      <Text fw={"bold"}>
                        <NumberFormatter
                          value={
                            (totalXGDetail ?? 0) +
                            (orderHeader?.discountAddedTotal ?? 0)
                          }
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={2} style={orderInfoStyle}>
                      <Text fw={500} style={infoLabelStyle}>
                        Thanh toán:
                      </Text>
                      <Text fw={"bold"} style={{ color: "red" }}>
                        <NumberFormatter
                          value={payableAmount}
                          thousandSeparator="."
                          decimalSeparator=","
                        />
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Card>
              )}
            </Flex>
          </Panel>
        </PanelGroup>
        {idPrint && (
          <PrintInvoice
            idPrint={idPrint}
            setIdPrint={setIdPrint}
            isPrinting={isPrinting}
            setIsPrinting={setIsPrinting}
          />
        )}
      </Box>
    </Provider>
  );
};

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
  color: "#000",
};

const selectStyle = {
  padding: "8px",
  fontSize: "14px",
  borderRadius: "4px",
  border: "1px solid #ccc",
  backgroundColor: "#fff",
  width: "100%",
};

const optionStyle = {
  padding: "10px",
};

export default RetailOrder;
