import {
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { isEmail, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconCheck, IconWindow } from "@tabler/icons-react";
import { TblDmCustomer } from "../../../model/TblDmCustomer";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { sky_blue } from "../../../const/variables";
import { useEffect, useState } from "react";
import {
  getCommuneSelect,
  getDistrictSelect,
  getProvinceSelect,
  getTblDmCustomerGroupSelect,
} from "../../../service/getSelectApi";
import { DateInput } from "@mantine/dates";

const CreateDataView = ({
  handleChooseCustomer,
  onClose,
}: CreateDataViewProps) => {
  const entity = {
    id: 0,
    code: null,
    name: null,
    email: null,
    active: false,
    address: null,
    birthOfDate: null,
    contact: null,
    description: null,
    fax: null,
    parentId: null,
    sex: null,
    taxCode: null,
    telephoneNumber: null,
    type: null,
    groupId: null,
    groupName: null,
    attribute1: null,
    attribute2: null,
    attribute3: null,
    attribute4: null,
    attribute5: null,
  };

  const [visible, { close, open }] = useDisclosure(false);
  const [dataTblCustomerGroup, setDataTblCustomerGroup] = useState<
    ComboboxItem[]
  >([]);
  const [dataTblDebtGroup, setDataTblDebtGroup] = useState<ComboboxItem[]>([]);
  const [dataTblProvince, setDataTblProvince] = useState<ComboboxItem[]>([]);
  const [dataTblDistrict, setDataTblDistrict] = useState<ComboboxItem[]>([]);
  const [dataTblCommune, setDataTblCommune] = useState<ComboboxItem[]>([]);
  const [searchDebt, setSearchDebt] = useState("");

  const form = useForm<any>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    validate: {
      type: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn loại khách hàng !";
        }
      },
      province: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn Tỉnh/Thành phố !";
        }
      },
      district: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn Quận/Huyện !";
        }
      },
      commune: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn Phường/Xã !";
        }
      },
      groupId: (value: string | null) => {
        if (!value) {
          return "Vui lòng chọn nhóm khách hàng !";
        }
      },
      sex: (value: string | null, values: any) => {
        if (values.type?.toString() !== "1") {
          return null;
        }
        if (!value) {
          return "Vui lòng chọn giới tính !";
        }
      },
      companyName: (value: string | null) => {
        if (form.getValues().type?.toString() === "2" && !value) {
          return "Vui lòng nhập tên doanh nghiệp !";
        }
        return null;
      },
      taxCode: (value: string | null) => {
        if (form.getValues().type?.toString() === "2" && !value) {
          return "Vui lòng nhập mã số thuế !";
        }
        return null;
      },
      name: (value: any | null, values: any) => {
        if (values.type?.toString() !== "1") {
          return null;
        }
        const validChars = /^[a-zA-Z\s\u00C0-\u024F\u1E00-\u1EFF]+$/;
        if (!value) {
          return "Vui lòng nhập tên khách hàng !";
        }
        if (!validChars.test(value)) {
          return "Tên khách hàng không được chứa các ký tự đặc biệt, và số !";
        }
        if (value.length > 50) {
          return "Tên khách hàng không được dài hơn 50 ký tự !";
        }
        return null;
      },

      email: (value: string | null, values: any) => {
        if (values.type?.toString() !== "2") {
          return null;
        }
        if (!value) {
          return "Vui lòng nhập Email !";
        }
        if (value) {
          return isEmail("Email không đúng định dạng !")(value as string);
        }
      },
      telephoneNumber: (value?: any | null, values?: any) => {
        if (value && !/^\d{8,11}$/.test(value)) {
          return "Số điện thoại phải có từ 8 đến 11 chữ số!";
        }

        if (!value) {
          return "Vui lòng nhập số điện thoại!";
        }
        return null;
      },
    },
  });

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryPos.get<MessageResponse<TblDmCustomer>>(
      "/api/v1/TblDmCustomer/create?prefix=KH"
    );
    if (callApi?.success) {
      const dataApi = callApi?.data;
      await Promise.all([dataCustomerGrouplSelect()]);
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
      }
      close();
    } else {
      onClose("");
    }
  };

  const dataCustomerGrouplSelect = async () => {
    try {
      const getData = await getTblDmCustomerGroupSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblCustomerGroup(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataProvinceSelect = async () => {
    try {
      const getData = await getProvinceSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblProvince(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataDistrictlSelect = async (idProvince: string) => {
    try {
      const getData = await getDistrictSelect(idProvince);
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDistrict(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const dataCommuneSelect = async (idDistrict: string) => {
    try {
      const getData = await getCommuneSelect(idDistrict);
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblCommune(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const fetchDataDebt = async () => {
    const search =
      form?.getValues()?.attribute1?.toString() ?? searchDebt.toLowerCase();
    let url = `/api/v1/TblDebtGroup/get-list?Ship=0&Take=30`;
    if (search) {
      url += `&KeySearch=${search}`;
    }
    try {
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);
      if (response?.success && response?.data) {
        const data = response.data;
        const mappedData = data.map((value: any) => {
          return {
            value: value?.groupCode?.toString(),
            label: `${value?.groupCode?.toString()} - ${value?.groupName?.toString()}`,
          };
        });
        setDataTblDebtGroup(mappedData);
      } else {
        setDataTblDebtGroup([]);
      }
    } catch (error) {
      setDataTblDebtGroup([]);
      console.error("Error fetching data:", error);
    }
  };

  const handleCreateTblDMCustomer = async (dataSubmit: any) => {
    open();
    if (form.getValues().type?.toString() !== "2") {
      dataSubmit = { ...dataSubmit, companyName: null, taxCode: null };
    } else {
      dataSubmit = { ...dataSubmit, groupId: 142 };
    }
    const dataApi = await repositoryPos.post<MessageResponse<any>>(
      "/api/v1/TblDmCustomer/create",
      { ...dataSubmit, active: true }
    );
    if (dataApi?.success) {
      if (handleChooseCustomer) {
        handleChooseCustomer(dataApi?.data);
      }
      NotificationExtension.Success("Thêm thành công !");
      setTimeout(() => {
        onClose((prev: any) => !prev);
        modals.closeAll();
      }, 1000);
    }
    close();
  };

  useEffect(() => {
    async function callApi() {
      Promise.all([callApiGetData(), fetchDataDebt()]);
    }
    callApi();
  }, []);

  useEffect(() => {
    if (searchDebt && searchDebt.length > 4) {
      fetchDataDebt();
    } else setDataTblDebtGroup([]);
  }, [searchDebt]);

  useEffect(() => {
    const dataTblCustomerGroupNoCompamy = dataTblCustomerGroup?.filter(
      (item) => item.value !== "142"
    );
    if (form.getValues().type?.toString() === "1") {
      setDataTblCustomerGroup(dataTblCustomerGroupNoCompamy);
    } else {
      form.setValues((prev) => ({ ...prev, groupId: "142" }));
      setDataTblCustomerGroup([
        ...dataTblCustomerGroupNoCompamy,
        {
          value: "142",
          label: "Khách hàng Doanh nghiệp",
        },
      ]);
    }
  }, [form.getValues().type]);

  useEffect(() => {
    if (form.getValues().province) {
      dataDistrictlSelect(form.getValues().province);
    }
    if (!form.getValues().province) {
      setDataTblDistrict([]);
    }
  }, [form.getValues().province]);

  useEffect(() => {
    if (form.getValues().district) {
      dataCommuneSelect(form.getValues().district);
    }
    if (!form.getValues().district) {
      setDataTblCommune([]);
    }
  }, [form.getValues().district]);

  return (
    <>
      <Box
        component="form"
        mx="auto"
        w={{
          base: "300px",
          xs: "400px",
          sm: "500px",
          md: "650px",
          lg: "700px",
        }}
        onSubmit={form.onSubmit((e: any) => {
          handleCreateTblDMCustomer(e);
        })}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />

        <Grid mt={10}>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label={"MSKH"}
              type="text"
              disabled
              readOnly
              {...form.getInputProps("code")}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Đối tượng công nợ"}
              placeholder={"Chọn đối tượng công nợ"}
              searchable
              clearable
              value={form.getValues().attribute1?.toString() ?? null}
              data={dataTblDebtGroup}
              onSearchChange={(e) => setSearchDebt(e)}
              searchValue={searchDebt}
              {...form.getInputProps("attribute1")}
              onChange={(value) => {
                form.setValues((prev) => ({
                  ...prev,
                  attribute1: value?.toString(),
                }));
              }}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Loại KH"}
              placeholder={"Chọn loại KH"}
              data={[
                {
                  value: "1",
                  label: "Cá nhân",
                },
                {
                  value: "2",
                  label: "Doanh nghiệp",
                },
              ]}
              withAsterisk
              allowDeselect={false}
              value={form.getValues()?.type?.toString() ?? null}
              {...form.getInputProps("type")}
              onChange={(value) => {
                form.setValues((prev) => ({
                  ...prev,
                  type: value?.toString(),
                  groupId:
                    value === "2"
                      ? "142"
                      : prev.groupId === "142"
                      ? null
                      : prev.groupId,
                }));
              }}
            />
          </Grid.Col>

          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <Select
              label={"Nhóm khách hàng"}
              placeholder={"Chọn nhóm khách hàng"}
              withAsterisk
              searchable
              clearable
              disabled={form.getValues().type?.toString() === "2"}
              value={form.getValues().groupId?.toString() ?? null}
              data={dataTblCustomerGroup}
              {...form.getInputProps("groupId")}
              onChange={(value) => {
                form.setValues((prev) => ({
                  ...prev,
                  groupId: value?.toString(),
                  type: value?.toString() === "142" ? "2" : "1",
                }));
              }}
            />
          </Grid.Col>

          <Grid.Col span={12} py={0}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Grid>
                  <Grid.Col span={12}>
                    <TextInput
                      label={"Tên doanh nghiệp"}
                      placeholder={"Nhập tên doanh nghiệp"}
                      type="text"
                      withAsterisk={form.getValues().type?.toString() === "2"}
                      disabled={form.getValues().type?.toString() !== "2"}
                      {...form.getInputProps("companyName")}
                    />
                  </Grid.Col>
                </Grid>

                <Fieldset legend="Người mua hàng">
                  <Grid>
                    <Grid.Col span={12}>
                      <TextInput
                        label={"Họ và tên"}
                        placeholder={"Nhập họ và tên"}
                        type="text"
                        withAsterisk={form.getValues().type?.toString() === "1"}
                        {...form.getInputProps("name")}
                      />
                    </Grid.Col>

                    <Grid.Col span={12}>
                      <Select
                        label={"Giới tính"}
                        placeholder={"Chọn giới tính"}
                        type="text"
                        withAsterisk={form.getValues().type?.toString() === "1"}
                        clearable
                        data={[
                          {
                            value: "1",
                            label: "Nam",
                          },
                          {
                            value: "2",
                            label: "Nữ",
                          },
                          {
                            value: "3",
                            label: "Khác",
                          },
                        ]}
                        {...form.getInputProps("sex")}
                        onChange={(value) => {
                          form.setValues((prev) => ({
                            ...prev,
                            sex: Number(value),
                          }));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <DateInput
                        label={"Ngày sinh nhật"}
                        placeholder={"Chọn ngày sinh nhật"}
                        locale="vi"
                        clearable
                        maxDate={new Date()}
                        valueFormat="DD-MM-YYYY"
                        value={
                          form.getValues().birthOfDate
                            ? new Date(form.getValues().birthOfDate?.toString())
                            : undefined
                        }
                        {...form.getInputProps("birthOfDate")}
                        onChange={(value) => {
                          form.setValues((prev) => ({
                            ...prev,
                            birthOfDate: value?.toISOString(),
                          }));
                        }}
                      />
                    </Grid.Col>

                    <Grid.Col span={12}>
                      <TextInput
                        label={"Số điện thoại"}
                        placeholder={"Nhập số điện thoại"}
                        type="text"
                        withAsterisk
                        {...form.getInputProps("telephoneNumber")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
                <Grid>
                  <Grid.Col span={12}>
                    <TextInput
                      label={"Mã số thuế"}
                      placeholder={"Nhập mã số thuế"}
                      type="text"
                      withAsterisk={form.getValues().type?.toString() === "2"}
                      disabled={form.getValues().type?.toString() !== "2"}
                      {...form.getInputProps("taxCode")}
                    />
                  </Grid.Col>

                  <Grid.Col span={12}>
                    <TextInput
                      withAsterisk={form.getValues().type?.toString() === "2"}
                      label={"Email"}
                      placeholder={"Nhập email"}
                      type="text"
                      {...form.getInputProps("email")}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>

              <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                <Fieldset legend="Công nợ">
                  <Grid>
                    <Grid.Col span={{ base: 12 }}>
                      <NumberInput
                        label={"Thời hạn công nợ (ngày)"}
                        placeholder={"Nhập thời hạn công nợ"}
                        hideControls
                        {...form.getInputProps("attribute2")}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12 }}>
                      <NumberInput
                        label={"Hạn mức công nợ"}
                        placeholder={"Nhập hạn mức công nợ"}
                        thousandSeparator=","
                        hideControls
                        {...form.getInputProps("debtLimit")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>

                <Fieldset mt="7px" legend="Địa chỉ">
                  <Grid>
                    <Grid.Col span={{ base: 12 }}>
                      <Select
                        label={"Tỉnh/Thành phố"}
                        placeholder={"Chọn Tỉnh/Thành phố"}
                        searchable
                        clearable
                        withAsterisk
                        data={dataTblProvince}
                        onClick={async () => {
                          if (dataTblProvince.length === 0) {
                            await dataProvinceSelect();
                          }
                        }}
                        {...form.getInputProps("province")}
                        onChange={(value) => {
                          form.setValues((prev) => ({
                            ...prev,
                            district: null,
                            commune: null,
                            province: value?.toString(),
                          }));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12 }}>
                      <Select
                        label={"Quận/Huyện"}
                        placeholder={"Chọn Quận/Huyện"}
                        searchable
                        clearable
                        withAsterisk
                        disabled={dataTblDistrict?.length === 0}
                        data={dataTblDistrict}
                        value={form.getValues().district ?? null}
                        {...form.getInputProps("district")}
                        onChange={(value) => {
                          form.setValues((prev) => ({
                            ...prev,
                            commune: null,
                            district: value?.toString(),
                          }));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12 }}>
                      <Select
                        label={"Phường/Xã"}
                        placeholder={"Nhập Phường/Xã"}
                        searchable
                        clearable
                        withAsterisk
                        disabled={dataTblCommune?.length === 0}
                        data={dataTblCommune}
                        value={form.getValues().commune ?? null}
                        {...form.getInputProps("commune")}
                        onChange={(value) => {
                          form.setValues((prev) => ({
                            ...prev,
                            commune: value?.toString(),
                          }));
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12 }}>
                      <TextInput
                        label={"Địa chỉ cụ thể"}
                        h={"100%"}
                        placeholder={"Nhập địa chỉ cụ thể"}
                        {...form.getInputProps("address")}
                      />
                    </Grid.Col>
                  </Grid>
                </Fieldset>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Group
          justify="end"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Button
            type="button"
            color="gray"
            loading={visible}
            onClick={() => {
              modals.closeAll();
            }}
            leftSection={!visible ? <IconWindow size={18} /> : undefined}
          >
            Đóng
          </Button>
          <Button
            type="submit"
            color={sky_blue.base}
            loading={visible}
            leftSection={!visible ? <IconCheck size={18} /> : undefined}
          >
            Lưu
          </Button>
          <></>
        </Group>
      </Box>
    </>
  );
};

export default CreateDataView;

type CreateDataViewProps = {
  onClose: any;
  handleChooseCustomer?: any;
};
