import { useEffect, useState } from "react";

import {
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Card,
  Divider,
  Flex,
  Group,
  Image,
  Indicator,
  Menu,
  ScrollArea,
  Switch,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  rem,
} from "@mantine/core";
import "@mantine/core/styles.css";
import {
  randomId,
  useDisclosure,
  useDocumentTitle,
  useLocalStorage,
  useNetwork,
  useOs,
} from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { Spotlight, SpotlightActionData, spotlight } from "@mantine/spotlight";
import "@mantine/spotlight/styles.css";
import {
  IconBell,
  IconCheck,
  IconChevronRight,
  IconLogout,
  IconPin,
  IconPinnedOff,
  IconSearch,
  IconSignRight,
  IconStar,
  IconSwitch,
  IconUser,
  IconX,
} from "@tabler/icons-react";
import {
  Link,
  Outlet,
  RouteObject,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { _sideNavData } from "../../../_setup/navdata/_sideNavData";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDmEmployeeModel } from "../../../model/TblDmEmployeeModel";
import {
  repositoryLog,
  repositoryMaster,
  repositoryPos,
} from "../../_const/_constVar";
import classes from "../../_style/NavbarSegmented.module.css";
import { NotificationExtension } from "../../extension/NotificationExtension";
import { isNullOrEmpty } from "../../extension/StringExtension";
import { searchSideNavData } from "../../helper/FunctionHelper";
import { AuthProvider } from "../../helper/IAuthProvider";
import { LinksGroupProps } from "../../model/_base/LinksGroupProps";
import { TransferBranch } from "../_transferBranch/_transferBranch";
import _breadcrumb from "./_breadcrumb";
import { SkeletonBase } from "./_skeleton";
import { LinksGroup } from "./NavbarLinksGroup";
import style from "./style.module.css";
import { _socket, SocketExtension } from "../../socket/socket";
import { TypePushSocket } from "../../socket/IMessageSend";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { useQuery } from "@tanstack/react-query";
import { HistoryNotification } from "../../../model/HistoryNotification";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";

import { Tabs } from "@sinm/react-chrome-tabs";
import "@sinm/react-chrome-tabs/css/chrome-tabs.css";
import "@sinm/react-chrome-tabs/css/chrome-tabs-dark-theme.css";
import { List } from "../../linq";
import routes from "../../../_setup/router/routes";
import HomeView from "../../../views/home/HomeView";
import { BASE_SSO } from "../../../config";
import { red, teal } from "../../../const/variables";
const fb =
  "https://raw.githubusercontent.com/pansinm/react-chrome-tabs/master/demo/images/facebook-favicon.ico";
const google =
  "https://raw.githubusercontent.com/pansinm/react-chrome-tabs/master/demo/images/google-favicon.ico";
const _logo =
  "https://hanoicomputercdn.com/media/lib/30-05-2024/favicon-hacom-2024.png";
let _id = 1;
function uniqId() {
  return _id++;
}

// function createNewTab() {
//   const id = uniqId();
//   return {
//     id: `tab-id-${id}`,
//     title: `New Tabs ${id}`,
//     favicon: id % 2 ? fb : google,
//     active: true,
//   };
// }
function createNewTab(pathname: string, content: any, title: string) {
  const id = uniqId();
  return {
    id: "tab " + pathname + "-" + id,
    title: title,
    favicon: _logo,
    active: true,
    content: content,
  };
}
//context
interface EventMessage {
  id: string;
  time: number;
  expires: number;
  event: string;
  topic: string;
  title: string;
  message: string;
}
interface DataFake {
  id: string;
  data: any;
}
export const Layout1 = () => {
  // #region state

  const location = useLocation();
  const navigate = useNavigate();
  const [loadingSkeleton, setLoadingSkeleton] = useState(true);
  const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] =
    useDisclosure();
  const [
    desktopOpened,
    { open: openDeskstop, toggle: toggleDesktop, close: closeDesktop },
  ] = useDisclosure(true);
  const [sideNavs, setSideNavs] = useState<LinksGroupProps[]>([]);
  const [menuOpened, setMenuOpened] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [checkedPinMenu, setCheckedPinMenu] = useState(
    localStorage.getItem("checkedPinMenu") === "true"
  );
  const handleExpandClick = () => {
    setIsExpanded(true);
  };

  //#endregion
  useEffect(() => {
    const pathsCheck = [
      "/sell/",
      "/delivery-config/",
      "/config-system/",
      "/category-data/",
      "/warehouse-config/",
      "/authozire/",
      "/organization/",
      "/request-transfer/",
      "/warranty/",
      "/invoice",
      "/bill/",
      "/reduced-approval/",
      "/customer-management/",
      "/testing/testing-technique",
      "/department",
    ];

    const closeMenus = pathsCheck?.some((path) =>
      location.pathname.includes(path)
    );

    if (closeMenus && !checkedPinMenu) {
      closeDesktop();
      closeMobile();
    }
    // const queryParams = new URLSearchParams(location.search);
    // const jwt = queryParams.get("token");
    // console.log("check auth !");
    const token = AuthProvider.isAuthenticated();
    //  if (!token && !jwt) {
    if (!token) {
      navigate("/auth/login");
      // const _sso = BASE_SSO + "/" + "?callback=" + window.location.origin + "/";
      // console.log(_sso);
      // window.location.href = _sso;
    }

    // else {
    //   console.log(jwt);
    //   if (jwt) {
    //     const refreshToken = queryParams.get("refreshToken");
    //     const id = queryParams.get("id");
    //     const userName = queryParams.get("userName");
    //     const fullName = queryParams.get("fullName");
    //     const phone = queryParams.get("phone");
    //     const dateExpires = queryParams.get("dateExpires");
    //     const empId = queryParams.get("empId");
    //     const clientId = queryParams.get("clientId");

    //     // Gán các giá trị lấy được vào localStorage
    //     if (jwt) localStorage.setItem("token", jwt);
    //     if (userName) localStorage.setItem("userName", userName);
    //     if (fullName) localStorage.setItem("fullName", fullName);
    //     if (empId) localStorage.setItem("empId", empId);
    //     if (clientId) localStorage.setItem("clientId", clientId);
    //     if (dateExpires) localStorage.setItem("dateExpires", dateExpires);

    //     // Gọi API để lấy chi tiết nhân viên nếu cần
    //     const fetchEmployeeDetails = async () => {
    //       if (empId) {
    //         const response = await repositoryPos.get(
    //           `/api/v1/TblDmEmployee/detail-employee?id=${empId}`
    //         );

    //         if (response && response.success) {
    //           const result = response.data;
    //           localStorage.setItem("userLogin", JSON.stringify(result));
    //           SocketExtension.Connect();
    //         }
    //       }
    //     };

    //     fetchEmployeeDetails();

    //     // Cập nhật AuthProvider.username nếu cần
    //     AuthProvider.username = userName || "";
    //   }
    // }
    const _title = _sideNavData.find((item) =>
      item.links?.find((link) => link.link === location.pathname)
    )?.label;
    setTitle(_title ?? "Hacom");

    setLoadingSkeleton(false);
    window.scrollTo(0, 0);
    return () => {
      setLoadingSkeleton(true);
    };
  }, [location.pathname]);
  const [title, setTitle] = useState("");
  useDocumentTitle(title);
  const userName = localStorage.getItem("userName") || "";
  const empId = localStorage.getItem("empId") ?? "";

  // matine
  const [notifications, setNotifications] = useState<HistoryNotification[]>([]);

  const renderLogo = () => (
    <Image radius="md" h={60} w="auto" fit="contain" src={_logo} />
  );
  //randomColor
  const getColorByName = (name: string) => {
    if (typeof name !== "string" || name.length === 0) {
      return "black";
    }
    const colors = [
      "#70173c",
      "#004c3f",
      "#503372",
      "#17406c",
      "#354147",
      "#aeb3bd",
      "#ffb4c2",
    ];
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  };

  // Lấy ký tự đầu tiên và cuối cùng
  const firstLetter = userName.charAt(0).toUpperCase();
  // const lastLetter = userName.charAt(userName.length - 1).toUpperCase();

  const firstLetterColor = getColorByName(userName);
  // const lastLetterColor = getColorByName(userName);
  // search menu
  function searchDataSide(q: string) {
    if (isNullOrEmpty(q)) return setSideNavs(_sideNavData);
    return setSideNavs(searchSideNavData(sideNavs, q));
  }

  const actions: SpotlightActionData[] = sideNavs.flatMap((group, groupIndex) =>
    group.links
      ? group.links.map((link, linkIndex) => ({
          id: `action-${groupIndex}-${linkIndex}`,
          label: link.label,
          description: group.label,
          onClick: async () => {
            if (!link.link.includes("authozire/user-page")) {
              var resCheck = await repositoryMaster.get<
                MessageResponse<Array<string>>
              >("/api/v1/menugroup/check");
              if (resCheck && resCheck?.data && resCheck.success) {
                if (resCheck.data && resCheck.data.length > 0) {
                  //  console.log(resCheck.data.indexOf(link.link) >= 0);
                  if (
                    resCheck.data.findIndex((x) =>
                      link.link.toLowerCase().startsWith(x.toLowerCase())
                    ) >= 0
                  )
                    navigate(link.link);
                  else navigate("/");
                } else navigate("/");
              } else navigate("/");
            } else navigate(link.link);
          },
        }))
      : []
  );

  // const links = sideNavs.map((item) => (
  //   <LinksGroup {...item} key={item.label} />
  // ));

  const links = sideNavs
    .map((item) => ({
      ...item, // Giữ nguyên các thuộc tính của item
      links: item.links?.filter((link) => !link.hidden), // Lọc các link con có hidden là true
    }))
    .map((item) => (
      <LinksGroup
        {...item}
        key={item.label}
        isCollapsed={!desktopOpened}
        isPinned={checkedPinMenu}
        open={toggleDesktop}
      />
    ));
  const os = useOs();
  const [locationVC, setLocation] = useState("");

  //#endregion
  function success(position: { coords: { latitude: any; longitude: any } }) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation("latitude: " + latitude + "-" + " longitude: " + longitude);
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error1);
    } else {
      console.log("Geolocation not supported");
    }
  }, []);
  const handleLogout = async (noti: boolean = false) => {
    const userNameBG = localStorage.getItem("userName") || "";
    const clientId = localStorage.getItem("clientId") || "NULL";
    const empIdBG = localStorage.getItem("empId") ?? "";

    const repository = repositoryMaster;
    let urlCreate = `/api/v1/Auth/logout`;
    let callapi = await repository.post<MessageResponse<boolean>>(urlCreate, {
      username: userNameBG,
      os: os,
      clientId: clientId,
      location: locationVC,
    });
    if (callapi && callapi.success) {
      await AuthProvider.signout();
      if (AuthProvider.isAuthenticated()) {
        if (noti) NotificationExtension.Fails("Đăng xuất thất bại !");
        return;
      }
      if (noti) {
        NotificationExtension.Success("Đăng xuất thành công");
        setIsConnected(false);
        SocketExtension.Disconnect();
      }
      navigate("/auth/login");
    } else NotificationExtension.Fails("Đăng xuất thất bại !");
  };
  const [user, setUser] = useState<TblDmEmployeeModel | null>(null);

  useEffect(() => {
    getUser();
    getNoti();
  }, []);

  const getNoti = async () => {
    var resCheck = await repositoryLog.get<
      MessageResponse<Array<HistoryNotification>>
    >("/api/v1/Logging/list");

    if (resCheck && resCheck.success && resCheck.httpStatusCode === 200) {
      setNotifications(resCheck.data);
    }
  };

  const activeNoti = async () => {
    await repositoryLog.post<MessageResponse<Array<HistoryNotification>>>(
      "/api/v1/Logging/reads",
      notifications.map((x) => x.id)
    );
  };

  const getMenu = async () => {
    var resCheck = await repositoryMaster.get<MessageResponse<Array<string>>>(
      "/api/v1/menugroup/check"
    );
    if (resCheck && resCheck?.data && resCheck.success) {
      if (resCheck.data && resCheck.data.length > 0) {
        const _checkRouter = _sideNavData
          .map((item) => {
            const filteredLinks = item?.links?.filter(
              (linkObj) =>
                //  resCheck?.data.includes(linkObj.link)
                (resCheck?.data.findIndex((x) =>
                  linkObj.link.toLowerCase().startsWith(x.toLowerCase())
                ) ?? -1) >= 0
            );

            return {
              ...item,
              links: filteredLinks,
            };
          })
          .filter((item) => (item?.links?.length ?? 0) > 0);
        // loc menu show ra
        setSideNavs(_checkRouter);
        if (!resCheck.data.includes(location.pathname)) navigate("/");
      } else {
        navigate("/");
        setSideNavs([]);
      }
    }
  };

  const getUser = async () => {
    var res = await repositoryPos.get<MessageResponse<TblDmEmployeeModel>>(
      "/api/v1/TblDmEmployee/detail-seft"
    );
    if (res && res?.data && res.success) {
      setUser(res.data);
    } else {
      await handleLogout();
    }

    //
    if (userName === "hopnk1" || userName === "vinhnv")
      setSideNavs(_sideNavData);
    else {
      getMenu();
    }
  };

  // useEffect(() => {
  //   if (userName === "hopnk11" || userName === "vinhnv1") {
  //   } else getMenu();
  // }, [location.pathname]);

  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    function onConnect(e: any) {
      setIsConnected(true);
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    if (!isConnected) SocketExtension.On("connect", onConnect);
    if (isConnected) {
      SocketExtension.OnSeft((a: any) => {
        if (a.message) {
          NotificationExtension.PushBrowser(a.message);
        }
        getNoti();
      });
      SocketExtension.On("disconnect", onDisconnect);
      // navigator.serviceWorker.ready.then((registrationReady) => {
      //   if (registrationReady && registrationReady.active)
      //     if (AuthProvider.isAuthenticated()) {
      //       const _empId = localStorage.getItem("empId") ?? "";
      //       if (userName && _empId) {
      //         const postData = {
      //           userName: userName,
      //           empId: _empId,
      //           type: "listen",
      //           typeHandler: "login",
      //         };
      //         registrationReady.active?.postMessage(postData);
      //       }
      //     }
      // });
    }
    // console.log(isConnected);
    // SocketExtension.OnSeft((a: any) => {
    //   if (a.message) {
    //     //  NotificationExtension.Info(a.message);
    //     NotificationExtension.PushBrowser(a.message);
    //   }
    // });
    // SocketExtension.On("disconnect", onDisconnect);
    // console.log(isConnected);

    return () => {
      if (!isConnected) SocketExtension.Off("connect", onConnect);
      if (isConnected) SocketExtension.Off("disconnect", onDisconnect);
    };
    //   } else SocketExtension.Disconnect();
  }, [isConnected]);

  const openModalTransferBranch = () =>
    modals.openConfirmModal({
      title: <Title order={5}>Chuyển chi nhánh</Title>,
      children: <TransferBranch />,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => console.log("Confirmed"),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });

  const infoUser = () => {
    return (
      <UnstyledButton>
        <Indicator
          inline
          processing={networkStatus.online}
          size={16}
          offset={5}
          position="bottom-end"
          color={networkStatus.online ? "green" : "red"}
          withBorder
        >
          <AvatarUtils value={userName} show={false} />
        </Indicator>
      </UnstyledButton>
    );
  };

  const closeMenuAndOpenModal = async () => {
    // setMenuOpened(false);
    // openModalTransferBranch();
    const empId = localStorage.getItem("empId");
    const response = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/detail-employees`
    );

    if (response && response.success) {
      let result = response.data;
      localStorage.setItem("userLogin", JSON.stringify(result));
      await getUser();
      await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/remove-cache`
      );
      NotificationExtension.Success("Cập nhật thành công !");
    }
  };
  const { isPending, error, data } = useQuery({
    queryKey: ["repoData"],
    queryFn: () => {
      const clientId = localStorage.getItem("clientId") || "";
      if (clientId)
        repositoryMaster.get(
          "/api/v1/auth/update-user-online?clientId=" + clientId
        );
    },
    refetchInterval: 1000 * 60 * 10,
  });

  //
  const [tabs, setTabs] = useState([
    {
      id: "/",
      favicon: _logo,
      title: "Trang chủ",
      active: true,
      // routes:"/",
      content: <HomeView />,
    },
  ]);

  const [darkMode, setDarkMode] = useState(false);

  // const addTab = () => {
  //   setTabs([...tabs, createNewTab()]);
  // };
  const addTab = () => {
    // const newTab = createNewTab("");
    // setTabs([...tabs.map((tab) => ({ ...tab, active: false })), newTab]);
    // navigate(newTab.id); // Điều hướng đến tab mới
  };
  const active = (id: string) => {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        active: id === tab.id,
      }))
    );
  };

  const close = (id: string) => {
    setTabs(tabs.filter((tab) => tab.id !== id));
  };

  const reorder = (tabId: string, fromIndex: any, toIndex: number) => {
    const beforeTab = tabs.find((tab) => tab.id === tabId);
    if (!beforeTab) {
      return;
    }
    let newTabs = tabs.filter((tab) => tab.id !== tabId);
    newTabs.splice(toIndex, 0, beforeTab);
    setTabs(newTabs);
  };

  const findLabelByLink = (linkToFind: string) => {
    // Duyệt qua từng nhóm trong _sideNavData
    for (const group of _sideNavData) {
      // Kiểm tra từng link trong links
      const foundLink = group?.links?.find((link) => link.link === linkToFind);
      if (foundLink) {
        return foundLink.label; // Trả về label nếu tìm thấy
      }
    }
    return null; // Trả về null nếu không tìm thấy
  };
  useEffect(() => {
    // Kiểm tra nếu tab đã tồn tại cho route hiện tại, nếu không thì tạo tab mới
    const tabExists = tabs?.filter((tab) => tab.id === location.pathname);
    //   if (tabExists < 0) {

    if (tabExists.length < 5 && location.pathname.length > 1) {
      const _out = (
        routes.state.matches.find((x) => x.pathname === location.pathname)
          ?.route as any
      ).element;
      const label = findLabelByLink(location.pathname);
      setTabs((prevTabs) => [
        ...prevTabs.map((tab) => ({ ...tab, active: false })),
        createNewTab(location.pathname, _out, label ?? ""),
      ]);
    }

    //  }
    // else {
    //   setTabs((prevTabs) =>
    //     prevTabs.map((tab) => ({
    //       ...tab,
    //       active: tab.id === location.pathname,
    //     }))
    //   );
    // }
  }, [location]);
  // useEffect(() => {
  //   console.log(tabs);
  // }, [tabs]);
  const [checkedTabs, setCheckedTabs] = useState(
    localStorage.getItem("checkedTabs") === "true"
  );

  useEffect(() => {
    localStorage.setItem("checkedTabs", checkedTabs?.toString() ?? "false");
    localStorage.setItem(
      "checkedPinMenu",
      checkedPinMenu?.toString() ?? "false"
    );

    setTabs((prevTabs) => prevTabs.filter((tab) => tab.id !== ""));

    if (checkedPinMenu) {
      openDeskstop();
    }
  }, [checkedTabs, checkedPinMenu]);

  const networkStatus = useNetwork();

  return (
    <>
      <AppShell
        header={{ height: 50 }}
        navbar={{
          width: !desktopOpened && !checkedPinMenu ? 80 : 300,
          breakpoint: "md",
          // collapsed: { mobile: !mobileOpened },
        }}
        styles={{
          navbar: {
            transition: "width 0.3s ease-out ease-in",
            display: "flex",
            overflow: "hidden",
          },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Flex
            gap="md"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
            ml={"22px"}
            mr={"30px"}
          >
            <Group mt="-4">
              <Burger
                opened={desktopOpened && !checkedPinMenu}
                onClick={() => {
                  if (!checkedPinMenu) toggleDesktop();
                }}
                size="sm"
              />
              <Link to="/">{renderLogo()}</Link>
            </Group>

            {/* <Group>
            <_breadcrumb></_breadcrumb>
            </Group> */}
            {/* <Group>
              <Text>{dateTime}</Text>
            </Group> */}
            <Group justify="flex-start">
              <Menu position="bottom-start">
                <Flex pt={0} pb={2} gap={15} className={style.search}>
                  <Tooltip
                    transitionProps={{ transition: "pop", duration: 300 }}
                    refProp="rootRef"
                    label={checkedPinMenu ? "Bỏ ghim menu" : "Ghim menu"}
                  >
                    <Switch
                      size="lg"
                      checked={checkedPinMenu}
                      style={{ cursor: "pointer" }}
                      color={"teal"}
                      thumbIcon={
                        checkedPinMenu ? (
                          <IconPin
                            style={{
                              width: rem(12),
                              height: rem(12),
                              color: "teal",
                            }}
                            stroke={3}
                          />
                        ) : (
                          <IconPinnedOff
                            style={{
                              width: rem(12),
                              height: rem(12),
                              color: red.light,
                            }}
                            stroke={3}
                          />
                        )
                      }
                      onChange={(event) => {
                        setCheckedPinMenu(event.currentTarget.checked);
                      }}
                    />
                  </Tooltip>
                  {/* <Tooltip
                    transitionProps={{ transition: "pop", duration: 300 }}
                    refProp="rootRef"
                    label={"Dạng Tab"}
                  >
                    <Switch
                      size="lg"
                      checked={checkedTabs}
                      style={{ cursor: "pointer" }}
                      onLabel="Bật"
                      offLabel="Tắt"
                      onChange={(event) =>
                        setCheckedTabs(event.currentTarget.checked)
                      }
                    />
                  </Tooltip> */}
                  <Button
                    variant="default"
                    fullWidth
                    radius="xl"
                    justify="space-between"
                    onClick={spotlight.open}
                    leftSection={
                      <Flex align="center" gap={10}>
                        <IconSearch size={20} />
                        <Text fw={500} size="sm">
                          Tìm kiếm
                        </Text>
                      </Flex>
                    }
                    rightSection={
                      <Flex
                        bg="#f8f9fa"
                        p={6}
                        mr={-6}
                        align={"center"}
                        justify="center"
                        style={{
                          borderRadius: "100px",
                          border: "0.5px solid #ccc",
                        }}
                      >
                        <Text
                          style={{ whiteSpace: "nowrap" }}
                          fw={500}
                          size="xs"
                        >
                          Ctrl + K
                        </Text>
                      </Flex>
                    }
                  ></Button>
                  <Divider m="5px 0" orientation="vertical" />
                  <Menu.Target>
                    <Indicator
                      withBorder
                      position="bottom-end"
                      processing
                      disabled={
                        notifications.filter((x) => !x.read === true).length < 1
                      }
                      label={
                        notifications.filter((x) => !x.read === true).length
                      }
                      onClick={async () => {
                        if (
                          notifications.filter((x) => !x.read === true).length >
                          0
                        ) {
                          await activeNoti();
                        }
                        await getNoti();
                      }}
                      size={16}
                      offset={7}
                    >
                      <Avatar
                        style={{ cursor: "pointer" }}
                        color="blue"
                        radius="xl"
                      >
                        <IconBell size="1.5rem" />
                      </Avatar>
                    </Indicator>
                  </Menu.Target>
                  <Menu.Dropdown w={320}>
                    <Menu.Label>
                      <Text fw="bold" size="md">
                        Thông báo
                      </Text>
                    </Menu.Label>
                    <ScrollArea
                      pb={10}
                      h={
                        notifications.length >= 4 && !isExpanded
                          ? "320"
                          : "88vh"
                      }
                      style={{ transition: "500ms ease-in-out" }}
                      scrollbars={!isExpanded ? false : "y"}
                      offsetScrollbars
                    >
                      {notifications.map((notification) => (
                        <Menu.Item key={notification.id}>
                          <Flex align="center" gap={10}>
                            {/* <Image
                            radius="xl"
                            h={40}
                            w={40}
                            src={
                              "https://hanoicomputercdn.com/media/lib/09-08-2023/logo-hacom-since-2001.png"
                            }
                          /> */}
                            <AvatarUtils
                              value={
                                notification.notifiedBy
                                  ?.toString()
                                  .split("-")[0] ?? ""
                              }
                              show={false}
                            />
                            <Flex direction="column">
                              <Text size="md" fw={500} lineClamp={0}>
                                {notification.message}{" "}
                              </Text>
                              <Flex
                                gap={10}
                                align="center"
                                justify="space-between"
                                mt={5}
                              >
                                <Text size="sm">
                                  {formatDateTransfer(notification.createdAt)}
                                </Text>
                                <Text fw="bold" size="sm" c="red">
                                  {notification.createdBy}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                        </Menu.Item>
                      ))}
                    </ScrollArea>
                    {!isExpanded && notifications.length >= 4 && (
                      <Button
                        fullWidth
                        variant="transparent"
                        onClick={handleExpandClick}
                      >
                        Xem thêm
                      </Button>
                    )}
                  </Menu.Dropdown>
                  {user && (
                    <Menu
                      width={300}
                      shadow="md"
                      opened={menuOpened}
                      onChange={setMenuOpened}
                    >
                      <Menu.Target>
                        <Tooltip
                          transitionProps={{ transition: "pop", duration: 300 }}
                          label={userName}
                        >
                          {infoUser()}
                        </Tooltip>
                      </Menu.Target>
                      <Menu.Dropdown p={0}>
                        <Card
                          withBorder
                          p={0}
                          pb={10}
                          radius="md"
                          className={classes.card}
                          style={{
                            backgroundColor: `${firstLetterColor}od`,
                          }}
                        >
                          <Card.Section
                            h={100}
                            style={{
                              backgroundColor: `${firstLetterColor}`,
                              filter: "opacity(0.4)",
                            }}
                          />

                          <Avatar
                            className={classes.avatar}
                            style={{ cursor: "pointer" }}
                            radius="xl"
                            bg={firstLetterColor}
                            w={60}
                            h={60}
                            mx="auto"
                            mt={-30}
                          >
                            <Text size="xl" fw={700} c="#fff">
                              {firstLetter}
                            </Text>
                          </Avatar>
                          <Text ta="center" size="xl" fw={600} mt="sm">
                            {userName}
                          </Text>
                          <Text ta="center" fw={500} mt="sm">
                            {user?.departmentName}
                          </Text>
                          <Text ta="center" fw={500}>
                            {user?.fullname}
                          </Text>
                          <Text ta="center" fz="sm" c="dimmed">
                            {user?.roleName}
                          </Text>
                          {/* <Text ta="center" fz="sm" c="dimmed">
                            {networkStatus.effectiveType}
                          </Text> */}
                          {/* <Group mt="md" justify="center" gap={30}>
                      {items}
                    </Group> */}
                          <Divider mt={20} my="sm" />
                          <Flex pl={10} pr={10} pb={10} direction="column">
                            <Button
                              leftSection={<IconUser size={18} />}
                              variant="filled"
                              size="sm"
                              color="blue"
                              style={{
                                backgroundColor: `${firstLetterColor}`,
                                filter: "opacity(0.8)",
                              }}
                              onClick={() => {
                                navigate("/authozire/user-page");
                              }}
                            >
                              Trang cá nhân
                            </Button>
                            <Button
                              leftSection={<IconSwitch size={18} />}
                              variant="filled"
                              size="sm"
                              mt={10}
                              color="blue"
                              style={{
                                backgroundColor: `${firstLetterColor}`,
                                filter: "opacity(0.8)",
                              }}
                              onClick={closeMenuAndOpenModal}
                            >
                              Cập nhật dữ liệu
                            </Button>
                            <Button
                              leftSection={<IconSignRight size={18} />}
                              size="sm"
                              mt={10}
                              variant="outline"
                              color={firstLetterColor}
                              onClick={async () => {
                                await handleLogout(true);
                              }}
                            >
                              Đăng xuất
                            </Button>
                          </Flex>
                        </Card>
                      </Menu.Dropdown>
                    </Menu>
                  )}
                </Flex>
              </Menu>
            </Group>
          </Flex>
        </AppShell.Header>
        <AppShell.Navbar py="xs">
          <AppShell.Section>
            <Spotlight
              actions={actions}
              limit={5}
              nothingFound="Nothing found..."
              highlightQuery
              searchProps={{
                leftSection: (
                  <IconSearch
                    style={{ width: "20px", height: "20px" }}
                    stroke={1.5}
                  />
                ),
                placeholder: "Search...",
              }}
            />
          </AppShell.Section>
          <AppShell.Section grow component={ScrollArea}>
            <ScrollArea className={classes.links}>
              <div className={classes.linksInner}>{links}</div>
            </ScrollArea>
          </AppShell.Section>
          {/* <AppShell.Section>
            <a
              className={classes.link}
              style={{ cursor: "pointer" }}
              onClick={async () => {
                await handleLogout(true);
              }}
            >
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Đăng xuất</span>
            </a>
          </AppShell.Section> */}
        </AppShell.Navbar>
        <AppShell.Main>
          {loadingSkeleton ? (
            <SkeletonBase visible={loadingSkeleton}></SkeletonBase>
          ) : (
            <>
              {/* <Button
                onClick={() => {
                  // var res = SocketExtension.SendMessageToUserName<any>(
                  //   "BACKGROUP",
                  //   {
                  //     message: "test noti",

                  //     //  type: TypePushSocket.NotSelf,
                  //   }
                  // );
                  const user = "chien123";
                  var res = SocketExtension.SendMessageToEmp<any>(2284, {
                    message:
                      "Bắn message to nhân viên có mã 2284 !" + user,

                    //  type: TypePushSocket.NotSelf,
                  });
                  console.log(res);
                }}
              >
                Click push
              </Button> */}
              {checkedTabs ? (
                <div>
                  <Tabs
                    darkMode={darkMode}
                    onTabClose={close}
                    onTabReorder={reorder}
                    onTabActive={active}
                    tabs={tabs}
                  />

                  <div>
                    {tabs.map((tab, index) => (
                      <div
                        className="tabs-show-element"
                        key={index}
                        id={index.toString() + tab.id}
                        style={{ display: tab.active ? "block" : "none" }}
                      >
                        {tab.content}
                      </div>
                    ))}
                  </div>

                  {/* <button onClick={addTab}>Add Tab</button>
                <button onClick={closeAll}>Close All</button>
                <button onClick={toogleDarkMode}>Toogle Dark Mode</button> */}
                </div>
              ) : (
                <Outlet />
              )}
            </>
          )}
        </AppShell.Main>
      </AppShell>
    </>
  );
};
