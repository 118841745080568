import {
  Badge,
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  LoadingOverlay,
  Menu,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDebouncedState, useDisclosure } from "@mantine/hooks";
import { IconArrowBack, IconCheck } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { TblDmWarrantyReceiveTicket } from "../../../model/TblDmWarrantyReceiveTicket";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  getItemPositionSelect,
  getTblDmSaleChanelSelect,
} from "../../../service/getSelectApi";
import {
  getValueById,
  handleKeyDownPhone,
} from "../../../_base/helper/FunctionHelper";
import ReceiveWarrantyList from "./ReceiveWarrantyList";
import { TblWarrantyServiceRequestForm } from "../../../model/TblWarrantyServiceRequestForm";
import { DateTimePicker } from "@mantine/dates";
import ProductWarrantyList from "./ProductWarrantyList";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { CreateInWardWarranty } from "../../../model/CreateInWardWarranty";

const CreateReceiptForm = ({ onClose, onSuccess }: CreateReceiptFormProps) => {
  const entity = {
    id: "0",
    empId: null,
    requestId: null,
    errorNumber: null,
    dateReceive: new Date().toString(),
    inventoryCode: null,
    note: null,
    idItemPosition: null,
    branchId: null,
    idCustomerGroup: null,
    inventoryId: null,
    tblWarrantyReceiveTicketDetailCommands: null,
  };

  const entityCreateInWardWarranty = {
    sourceId: null,
    tblItemTransactionHeaderCommand: {
      transactionId: null,
      transactionCode: null,
      transactionDate: null,
      subSourceId: null,
      sourceCode: null,
      sourceType: "NHAP_KHO_BAO_HANH",
      branchId: null,
      subInvId: null,
      departmentId: null,
      status: null,
      description: null,
      atribute1: null,
      atribute2: null,
      atribute3: null,
      atribute4: null,
      atribute5: null,
      atribute6: null,
      atribute7: null,
      atribute8: null,
      atribute9: null,
      atribute10: null,
      createBy: null,
      createDate: null,
      lastUpdateDate: null,
      lastUpdateBy: null,
      type: null,
      finishDate: null,
    },
    serials: null,
    isLock: true,
  };

  const form = useForm<TblDmWarrantyReceiveTicket>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      idItemPosition: Number(values.idItemPosition),
    }),

    validate: {
      inventoryCode: (value: string | null) => {
        if (!value) {
          return "Vui lòng nhập tên trung tâm !";
        }
      },
      empId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên người tiếp nhận !";
        }
      },
    },
  });

  const formCreateInWardWarranty = useForm<CreateInWardWarranty>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entityCreateInWardWarranty,
    },
  });

  const [dataTblSaleChannel, setDataTblSaleChannel] = useState<ComboboxItem[]>(
    []
  );
  const [dataInventorySelect, setDataInventorySelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataItemPositionSelect, setDataItemPositionSelect] = useState<
    ComboboxItem[]
  >([]);
  const [dataEmployeeDetail, setDataEmployeeDetail] = useState<any>();
  const [dataDepartment, setDataDepartment] = useState<ComboboxItem[]>([]);
  const [dataRole, setDataRole] = useState<ComboboxItem[]>([]);
  const [dataRequestForm, setDataRequestForm] = useState<any>();
  const [dataRequestFormSelected, setDataRequestFormSelected] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useDebouncedState("", 300);

  const [editAndSave, setEditAndSave] = useState(false);
  const [isEdit, setIsEdit] = useState({
    amount: false,
    acessory: false,
    testResolution: false,
    serviceType: false,
    scheduleTime: false,
    daysReturn: false,
    note: false,
  });
  const [visible, { toggle, close, open }] = useDisclosure(false);

  const warrantyReceiveTicketDetail =
    form.getValues().tblWarrantyReceiveTicketDetailCommands ?? [];

  const handleChangeValue = (key: string, value: any, index: number) => {
    form.setValues((prevData: any) => {
      const prevDataSubmit = Array.isArray(
        prevData.tblWarrantyReceiveTicketDetailCommands
      )
        ? prevData.tblWarrantyReceiveTicketDetailCommands
        : [];

      if (prevDataSubmit[index]?.[key] === value) {
        return prevData;
      }

      const updatedModels = [...prevDataSubmit];
      updatedModels[index] = {
        ...updatedModels[index],
        [key]: value,
        itemId:
          dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels[
            index
          ].itemId,
        id: Number(index),
        warrantyTerm:
          dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels[
            index
          ].warrantyTerm,
        orderCode:
          dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels[
            index
          ].orderCode,
        buyingDate:
          dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels[
            index
          ].buyingDate,
        amount: 1,
        serviceType:
          dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels[
            index
          ].serviceType,
        isError: false,
        requestFormDetailId:
          dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels[
            index
          ].id,
        serial: dataRequestFormSelected.serial,
      };

      return {
        ...prevData,
        tblWarrantyReceiveTicketDetailCommands: updatedModels,
      };
    });
  };

  const callApiGetData = async () => {
    open();
    const callApi = await repositoryMdm.get<
      MessageResponse<TblDmWarrantyReceiveTicket>
    >("/api/v1/TblWarrantyReceiveTicket/create?prefix=PNHL");
    if (callApi?.success) {
      const dataApi = callApi?.data;
      if (dataApi !== null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        await Promise.all([
          saleChannelSelect(),
          getDataEmployeeForDep(),
          getDataDepartment(),
          getDataRole(),
        ]);
      }
      close();
    } else {
      onClose("");
    }
  };

  const handleCreateReceiptForm = async (
    dataSubmitReq1: TblDmWarrantyReceiveTicket
  ) => {
    open();
    const req1 = await repositoryMdm.post<
      MessageResponse<TblDmWarrantyReceiveTicket>
    >("/api/v1/TblWarrantyReceiveTicket/create", dataSubmitReq1);

    if (req1?.success) {
      NotificationExtension.Success("Tiếp nhận hàng lỗi thành công !");
      setTimeout(() => {
        onSuccess((prev: any) => !prev);
        onClose("");
      }, 1500);
    }

    close();
  };

  const fetchDataRequestForm = async () => {
    try {
      const response = await repositoryMdm.get<
        MessageResponse<TblWarrantyServiceRequestForm[]>
      >(
        `api/v1/TblWarrantyServiceRequestForm/get-list?isReceive=false&KeySearch=${phoneNumber}&Take=100`
      );
      if (response && response.success) {
        let result = response.data;
        setDataRequestForm(result);
        formCreateInWardWarranty.setValues((prev: any) => {
          const dataPrev = prev.tblItemTransactionHeaderCommand;
          const updatedModels = {
            ...dataPrev,
            branchId: result[0].branchId,
          };

          return {
            ...prev,
            tblItemTransactionHeaderCommand: updatedModels,
          };
        });
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const saleChannelSelect = async () => {
    try {
      const getData = await getTblDmSaleChanelSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null && item.value !== "-1"
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblSaleChannel(mappedData);
    } catch (error) {
      console.error("Error in dataTblLocation: ", error);
    }
  };

  const getInventorySelect = async () => {
    if (dataRequestFormSelected?.branchId) {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmInventory/get-select-by-branch?branchId=${dataRequestFormSelected?.branchId}`
      );

      if (res && res?.success) {
        const dataApi = res.data;
        setDataInventorySelect(
          dataApi
            ?.filter(
              (item: any) =>
                item.inventoryId != null &&
                item.inventoryName != null &&
                (item.inventoryName.toLowerCase().includes("lỗi") ||
                  item.inventoryName.toLowerCase().includes("bảo hành"))
            )
            ?.map((item: any) => ({
              value: item.inventoryCode?.toString(),
              label: `${item.inventoryName} - ${item.inventoryCode}`,
              inventoryId: item.inventoryId,
            }))
        );
      }
    } else {
      setDataInventorySelect([]);
    }
  };

  const getDataItemPosition = async () => {
    if (form.getValues().inventoryCode) {
      const getData = await getItemPositionSelect(
        form.getValues().inventoryCode
      );
      setDataItemPositionSelect(
        getData
          ?.filter((item: any) => item.id != null && item.positionName != null)
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.positionName,
          }))
      );
    }
  };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?Take=50`
    );

    if (res && res?.success) {
      setDataEmployeeSelect(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              item.roleName.toLowerCase().includes("bảo hành")
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const getDataDepartment = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDepartment/get-all"
    );

    if (res && res.success) {
      const result = res.data;
      setDataDepartment(
        result
          ?.filter((item: any) => item.name != null)
          ?.map((item: any) => ({
            id: item.departmentId,
            departmentName: item.name,
          }))
      );
    }
  };

  const getDataRole = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblRole/get-all"
    );

    if (res && res.success) {
      const result = res.data;
      setDataRole(
        result
          ?.filter((item: any) => item.name != null)
          ?.map((item: any) => ({
            id: item.id,
            roleName: item.name,
          }))
      );
    }
  };

  const getEmployeeDetail = async () => {
    if (dataRequestFormSelected?.empId) {
      const res = await repositoryPos.get<MessageResponse<any>>(
        `/api/v1/TblDmEmployee/detail?id=${dataRequestFormSelected?.empId}`
      );

      if (res && res.success) {
        const result = res.data;
        setDataEmployeeDetail(result);
      }
    } else {
      setDataEmployeeDetail(undefined);
    }
  };

  useEffect(() => {
    callApiGetData();
  }, []);

  useEffect(() => {
    if (phoneNumber.length >= 3) {
      fetchDataRequestForm();
    } else {
      setDataRequestForm([]);
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (
      dataRequestFormSelected?.empId !== null &&
      dataRequestFormSelected?.empId !== 0
    ) {
      getEmployeeDetail();
    } else {
      setDataEmployeeDetail(undefined);
    }
  }, [dataRequestFormSelected?.empId]);

  useEffect(() => {
    if (dataRequestFormSelected !== undefined) {
      form.setValues((prev) => ({
        ...prev,
        requestId: dataRequestFormSelected?.id,
        empId: dataRequestFormSelected?.empId,
        branchId: dataRequestFormSelected?.branchId,
        customerGroup: dataRequestFormSelected?.customerGroup,
      }));
    }
  }, [dataRequestFormSelected]);

  useEffect(() => {
    getInventorySelect();
  }, [dataRequestFormSelected?.branchId]);

  useEffect(() => {
    if (form.getValues().inventoryCode) {
      getDataItemPosition();
    } else {
      setDataItemPositionSelect([]);
    }
  }, [form.getValues().inventoryCode]);

  console.log(form.getValues());

  return (
    <>
      <Box
        component="form"
        mx="auto"
        p={"0px 10px"}
        onSubmit={form.onSubmit(() => {
          handleCreateReceiptForm(form.getValues());
        })}
        onMouseDown={() => {
          setIsEdit({
            amount: false,
            acessory: false,
            testResolution: false,
            serviceType: false,
            scheduleTime: false,
            daysReturn: false,
            note: false,
          });
          setEditAndSave(false);
        }}
        style={{ position: "relative" }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Flex justify={"space-between"} align={"center"}>
          <Badge
            variant="gradient"
            gradient={{ from: "blue", to: "cyan", deg: 90 }}
            w={225}
          >
            Lập phiếu nhận hàng lỗi
          </Badge>
          <Flex w={"100%"} justify={"flex-end"} gap={10}>
            <Button
              type="button"
              color="red"
              loading={visible}
              onClick={() => {
                onClose("");
              }}
              leftSection={!visible ? <IconArrowBack size={18} /> : undefined}
            >
              Thoát
            </Button>
            <Button
              type="submit"
              color={"teal"}
              loading={visible}
              leftSection={!visible ? <IconCheck size={18} /> : undefined}
            >
              Lưu
            </Button>
          </Flex>
        </Flex>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6, lg: 4.5 }}>
            <Fieldset h={"100%"} legend="Thông tin khách hàng hẹn gặp">
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <Menu
                    trigger="hover"
                    openDelay={100}
                    closeDelay={400}
                    width={1000}
                  >
                    <Menu.Target>
                      <TextInput
                        label="Tìm kiếm phiếu tiếp nhận yêu cầu"
                        placeholder="Nhập từ mã phiếu, số điện thoại, mã khách hàng"
                        defaultValue={phoneNumber ?? ""}
                        onKeyDown={handleKeyDownPhone}
                        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                      />
                    </Menu.Target>
                    <Menu.Dropdown>
                      <ReceiveWarrantyList
                        phoneNumber={phoneNumber}
                        dataRequestForm={dataRequestForm}
                        setDataRequestFormSelected={setDataRequestFormSelected}
                      />
                    </Menu.Dropdown>
                  </Menu>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
                  <Select
                    label="Kênh mua hàng"
                    placeholder="Kênh mua hàng"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.channel?.toString()
                        : null
                    }
                    rightSection={" "}
                    data={dataTblSaleChannel}
                    {...form.getInputProps("channel")}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                  <TextInput
                    label="Tên khách hàng"
                    placeholder="Tên khách hàng"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected?.custMeeting
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                  <TextInput
                    label="Nhóm khách hàng"
                    placeholder="Nhóm khách hàng"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected?.nameCustomerGroup
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                  <TextInput
                    label="Số điện thoại"
                    placeholder="Số điện thoại"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected?.customerPhone
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Địa chỉ"
                    placeholder="Tìm kiếm thông qua SĐT"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.meetingAddress
                        : null
                    }
                    readOnly
                    variant="filled"
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 7.5 }}>
            <Fieldset legend="Thông tin phiếu tiếp nhận hàng lỗi" h={"100%"}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận hàng lỗi"
                    value={form.getValues().errorNumber ?? ""}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Số phiếu tiếp nhận yêu cầu"
                    placeholder="Số yêu cầu"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.requestNum
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <DateTimePicker
                    label="Ngày tạo phiếu"
                    valueFormat="DD-MM-YYYY hh:mm A"
                    value={new Date()}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 3 }}>
                  <TextInput
                    label="Hình thức xử lý"
                    placeholder="Hình thức xử lý"
                    value={
                      dataRequestFormSelected !== undefined
                        ? dataRequestFormSelected.processingMethodName
                        : null
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Nhân viên tiếp nhận"
                    placeholder="Nhập tên nhân viên tiếp nhận"
                    value={dataRequestFormSelected?.empnameincharge}
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Chức danh"
                    placeholder="Chức danh của nhân viên tiếp nhận"
                    value={
                      dataEmployeeDetail !== undefined
                        ? getValueById(
                            dataEmployeeDetail?.roleId,
                            dataRole,
                            "roleName"
                          )
                        : ""
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Bộ phận/Phòng bạn"
                    placeholder="Bộ phận/Phòng ban của nhân viên tiếp nhận"
                    value={
                      dataEmployeeDetail !== undefined
                        ? getValueById(
                            dataEmployeeDetail?.departmentId,
                            dataDepartment,
                            "departmentName"
                          )
                        : ""
                    }
                    variant="filled"
                    readOnly
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <Select
                    label="Kho"
                    placeholder="Nhập mã hoặc tên kho"
                    data={dataInventorySelect?.map((item: any) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    value={
                      form.getValues().inventoryCode
                        ? form.getValues().inventoryCode?.toString()
                        : null
                    }
                    searchable
                    clearable
                    comboboxProps={{ width: 325 }}
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("inventoryCode")}
                    onChange={(e) => {
                      form.setValues((prev) => ({
                        ...prev,
                        inventoryCode: e ? e?.toString() : null,
                        inventoryId: e
                          ? Number(
                              getValueById(
                                e,
                                dataInventorySelect,
                                "inventoryId"
                              )
                            )
                          : null,
                      }));
                      formCreateInWardWarranty.setValues((prev) => {
                        const dataPrev = prev.tblItemTransactionHeaderCommand;

                        const updateSubInvId = {
                          ...dataPrev,
                          subInvId: e
                            ? Number(
                                getValueById(
                                  e?.toString(),
                                  dataInventorySelect,
                                  "inventoryId"
                                )
                              )
                            : null,
                        };

                        return {
                          ...prev,
                          tblItemTransactionHeaderCommand: updateSubInvId,
                        };
                      });
                    }}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <Select
                    label="Vị trí kho"
                    placeholder="Nhập vị trí kho"
                    data={dataItemPositionSelect}
                    searchable
                    clearable
                    nothingFoundMessage="Không tìm thấy dữ liệu !"
                    {...form.getInputProps("idItemPosition")}
                    withAsterisk
                  />
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                  <TextInput
                    label="Ghi chú"
                    placeholder="Nhập ghi chú"
                    onChange={(e) => {
                      form.setValues((prev) => ({
                        ...prev,
                        note: e.currentTarget.value,
                      }));
                      formCreateInWardWarranty.setValues((prev) => {
                        const dataPrev = prev.tblItemTransactionHeaderCommand;

                        const updateSubInvId = {
                          ...dataPrev,
                          description: e.currentTarget.value,
                        };

                        return {
                          ...prev,
                          tblItemTransactionHeaderCommand: updateSubInvId,
                        };
                      });
                    }}
                  />
                </Grid.Col>
              </Grid>
            </Fieldset>
          </Grid.Col>
          <Grid.Col span={12}>
            <Fieldset legend="Thông tin sản phẩm">
              <ProductWarrantyList
                dataRequestFormSelectedDetail={
                  dataRequestFormSelected?.tblWarrantyServiceRequestFormDetailModels
                }
                warrantyReceiveTicketDetail={warrantyReceiveTicketDetail}
                editAndSave={editAndSave}
                setEditAndSave={setEditAndSave}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                handleChangeValue={handleChangeValue}
              />
            </Fieldset>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
};

type CreateReceiptFormProps = {
  onClose?: any;
  onSuccess?: any;
};

export default CreateReceiptForm;
