import React, { useEffect, useState } from "react";
import { repositoryDelivery } from "../../_base/_const/_constVar";
import { MessageResponse } from "../../model/MessageResponse";
import { TblTestingTechniqueHeaderGetList } from "../../model/TblTestingTechniqueHeader";
import {
  Box,
  Button,
  Checkbox,
  ComboboxItem,
  Divider,
  Flex,
  Grid,
  Group,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import { gray } from "../../const/variables";
import { modals } from "@mantine/modals";
import { useDisclosure } from "@mantine/hooks";
import { IconWindow } from "@tabler/icons-react";
import ListProductDetail from "./ListProductDetail";
import { getTechnicalTestingDifficultySelect } from "../../service/getSelectApi";
import cx from "clsx";
import classes from "../../Styles/TableScrollArea.module.css";
import { getValueById } from "../../_base/helper/FunctionHelper";

const ModalDetailTestingTechnique = ({
  id,
  dataStatusOption,
  dataSourceStatus,
}: {
  id: string | number;
  dataStatusOption: any;
  dataSourceStatus: any;
}) => {
  const [visible, { toggle, close, open }] = useDisclosure(false);
  const [dataTestingTechnique, setDataTestingTechnique] = useState<any>(null);
  const [dataDeliverPriority] = useState<ComboboxItem[]>([
    { label: "Cao", value: "1" },
    { label: "Thấp", value: "2" },
  ]);
  const [
    dataTechnicalTestingDifficultySelect,
    setDataTechnicalTestingDifficultySelect,
  ] = useState<ComboboxItem[]>([]);
  const [dataSelectEmployee, setDataSelectEmployee] = useState<any[]>([]);
  const [scrolled, setScrolled] = useState(false);
  console.log(dataTestingTechnique);

  const fetchDataDetail = async () => {
    const url = `/api/v1/TblTestingTechniqueHeader/get-detail?id=${id}`;
    open();
    try {
      const response = await repositoryDelivery.get<
        MessageResponse<TblTestingTechniqueHeaderGetList>
      >(url);
      if (response?.success && response?.data) {
        setDataTestingTechnique(response.data);
        Promise.all([getDataTechnicalTestingDifficultySelect()]);
      } else setDataTestingTechnique(null);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataTestingTechnique(null);
    }
    close();
  };

  const getDataTechnicalTestingDifficultySelect = async () => {
    const res = await getTechnicalTestingDifficultySelect();
    setDataTechnicalTestingDifficultySelect(
      res
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    fetchDataDetail();
  }, [id]);

  return (
    <Box w={"80vw"} maw={1000}>
      {dataTestingTechnique?.tblTestingTechniqueAssignmentModels.length > 0 ? (
        <Grid>
          <Grid.Col span={{ base: 12, md: 12, lg: 3 }}>
            <Select
              label="Mức độ ưu tiên"
              placeholder="Chọn độ ưu tiên"
              data={dataDeliverPriority}
              value={
                dataTestingTechnique?.priorityId
                  ? dataTestingTechnique?.priorityId?.toString()
                  : null
              }
              rightSection={" "}
              variant="filled"
              readOnly
            />
            <Select
              label="Độ khó"
              placeholder="Chọn độ khó"
              data={dataTechnicalTestingDifficultySelect}
              value={
                dataTestingTechnique?.difficultyLevelId
                  ? dataTestingTechnique?.difficultyLevelId?.toString()
                  : null
              }
              rightSection={" "}
              variant="filled"
              readOnly
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 12, lg: 9 }}>
            <ScrollArea
              h={167.5}
              onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
              scrollbars="y"
              mt={7.5}
            >
              <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Thead
                  className={cx(classes.header, {
                    [classes.scrolled]: scrolled,
                  })}
                >
                  <Table.Tr>
                    <Table.Th>Người chịu trách nhiệm</Table.Th>
                    <Table.Th>Mã nhân viên</Table.Th>
                    <Table.Th>Tên nhân viên</Table.Th>
                    <Table.Th>Chức danh</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {dataTestingTechnique?.tblTestingTechniqueAssignmentModels &&
                    dataTestingTechnique?.tblTestingTechniqueAssignmentModels
                      .length > 0 &&
                    dataTestingTechnique?.tblTestingTechniqueAssignmentModels.map(
                      (emp: any, index: any) => (
                        <Table.Tr key={emp.id}>
                          <Table.Td>
                            <Checkbox
                              checked={
                                dataTestingTechnique?.responsibleEmployeeId ===
                                emp?.emloyeeId
                              }
                              readOnly
                            />
                          </Table.Td>
                          <Table.Td>{emp.emloyeeCode}</Table.Td>
                          <Table.Td>{emp.emloyeeName}</Table.Td>
                          <Table.Td>{emp.emloyeeRole}</Table.Td>
                        </Table.Tr>
                      )
                    )}
                </Table.Tbody>
              </Table>
            </ScrollArea>
          </Grid.Col>
        </Grid>
      ) : (
        <Flex
          align={"center"}
          w={"100%"}
          justify={"center"}
          p={"25px 0px 25px 0px"}
        >
          <Text fw={500} size="14px">
            Chưa phân công KTKT
          </Text>
        </Flex>
      )}

      <Divider
        mb={5}
        label={
          <Text fw={500} size="13px">
            Thông tin đơn hàng
          </Text>
        }
        labelPosition="center"
      />
      <Grid>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Mã kiểm tra"
            size="xs"
            variant="filled"
            readOnly
            value={dataTestingTechnique?.code || ""}
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Tên khách hàng"
            size="xs"
            variant="filled"
            readOnly
            value={dataTestingTechnique?.customerName || ""}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Số điện thoại"
            size="xs"
            variant="filled"
            readOnly
            value={dataTestingTechnique?.customerTelephone || ""}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Trạng thái đơn hàng"
            size="xs"
            variant="filled"
            readOnly
            value={getValueById(
              dataTestingTechnique?.sourceStatus?.toString(),
              dataSourceStatus,
              "label"
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Trạng thái kiểm tra"
            size="xs"
            variant="filled"
            readOnly
            value={getValueById(
              dataTestingTechnique?.testingStatus?.toString(),
              dataStatusOption,
              "label"
            )}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
          <TextInput
            label="Ghi chú"
            size="xs"
            variant="filled"
            readOnly
            value={dataTestingTechnique?.description || ""}
          />
        </Grid.Col>
      </Grid>
      <ListProductDetail
        dataDetail={dataTestingTechnique?.tblTestingTechniqueDetailModels}
        isDetail={true}
      />

      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color={gray.base}
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconWindow size={18} /> : undefined}
        >
          Đóng
        </Button>
      </Group>
    </Box>
  );
};

export default ModalDetailTestingTechnique;
