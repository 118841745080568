import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  ComboboxItem,
  Fieldset,
  Flex,
  Grid,
  Group,
  Menu,
  Select,
  Switch,
  Table,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useDebouncedState, useDisclosure, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconMaximize,
  IconMaximizeOff,
  IconPlus,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { anyAsciiCode } from "../../../_base/helper/AnyAscii";
import { _localization } from "../../../config/location";
import { sky_blue } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../model/SaleOrderModel";
import {
  TblInventorySerialItemDetailOnHand,
  TblInventorySerialOrderDetailOnHand,
} from "../../../model/TblInventorySerialOnHand";
import {
  detailOrderForOutWardModel,
  TblOutward,
} from "../../../model/TblOutWard";
import { getTblInventoryTransactionTypeOutWard } from "../../../service/getSelectApi";
import RequestProductionOutWard from "./GeneralInformation/RequestProductionOutWard";
import RequestTransferOutWard from "./GeneralInformation/RequestTransferOutWard";
import RequestVendorReturnOutWard from "./GeneralInformation/RequestVendorReturnOutWard";
import SaleOrderOutward from "./GeneralInformation/SaleOrderOutward";
import "./tab.component.css";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  poPrice1?: number;
}

const CreateWarehouseOutWard = () => {
  const navigate = useNavigate();
  const localtion = useLocation();
  const sourceCode = localtion.state && localtion.state?.sourceCode;
  const sourceType = localtion.state && localtion.state?.sourceType;
  const locker = localtion.state && localtion.state.isLocked;

  //#region render ui outward
  const getOutWardProps = (additionalProps = {}) => ({
    dataSearchSourceCodeOption,
    dataOutWard: dataOutWard?.tblItemTransactionHeaderCommand,
    dataOrderDetail: dataOrderDetail?.tblInventoryTransactionModel,
    userInfo,
    handleChangeValueInput,
    setSourceCode,
    handleKeyDownSourceCode,
    sourceCode,
    isSave,
    searchSourceCode,
    setSearchSourceCode,
    ...additionalProps,
  });

  // Thong tin chung xuat lien chi nhanh
  const requestTransfetOutWard = () => {
    return (
      <RequestTransferOutWard {...getOutWardProps({ haveStatus: true })} />
    );
  };

  // Thong tin chung xuat noi bo
  const requestTransferInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({ haveStatus: true, isInternal: true })}
      />
    );
  };

  // Thong tin chung xuat ban hang
  const orderOutWard = () => {
    return <SaleOrderOutward {...getOutWardProps()} />;
  };

  // Thong tin chung xuat huy
  const cancelOutWard = () => {
    return <RequestTransferOutWard {...getOutWardProps()} />;
  };

  // Thong tin chung xuat dieu chuyen serial
  const serialOutWard = cancelOutWard;

  // Thong tin chung xuat dieu chuyen noi bo serial
  const serialInternalOutWard = () => {
    return (
      <RequestTransferOutWard {...getOutWardProps({ isInternal: true })} />
    );
  };

  // Thong tin chung xuat kho dieu chinh
  const adjustmentOutWard = cancelOutWard;

  // Thong tin chung xuat kho tra ncc
  const returnVendorOutWard = () => {
    return <RequestVendorReturnOutWard {...getOutWardProps()} />;
  };

  // Thong tin chung xuat dieu chuyen lap rap
  const createProductOutWard = () => {
    return (
      <RequestProductionOutWard
        {...getOutWardProps({ haveSourceCode: true })}
      />
    );
  };

  //#endregion

  //#region state
  const outWardEntity = {
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: sourceCode || "",
      sourceType: sourceType || "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "NEW",
      description: "",
      atribute1: "",
      atribute2: "",
      atribute3: "",
      atribute4: "",
      atribute5: "",
      atribute6: "",
      atribute7: "",
      atribute8: "",
      atribute9: "",
      atribute10: "",
      createBy: 0,
      createName: "",
      createDate: moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss"),
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  };

  const [dataOutWard, setDataOutWard] = useState<TblOutward>(outWardEntity);

  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();

  //table state
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);

  const [serial, setSerial] = useState<string>("");
  const [keySearch, setKeySearch] = useDebouncedState("", 500);
  const [searchSourceCode, setSearchSourceCode] = useDebouncedState("", 500);
  const [dataItem, setDataItem] = useState<
    TblInventorySerialItemDetailOnHand[]
  >([]);
  const [dataSearchSourceCodeOption, setDataSearchSourceCodeOption] = useState<
    any[]
  >([]);
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<string[]>([]);
  const [isExceedQuantity, setIsExceedQuantity] = useState(false);
  const [transactionTempId, setTransactionTempId] = useState(0);
  const [isSave, setIsSave] = useState(false);
  const [sourceCodeRef, setSourceCode] = useState(
    localtion.state && localtion.state.sourceCode
  );
  const [isLockForOrder, setIsLockForOrder] = useState(true);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const sidePanelRef = React.useRef<HTMLDivElement>(null);
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);

  const [mainContentWidth, setMainContentWidth] = useState(0);

  //#endregion

  //#region function

  const renderMainOutWard = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    switch (_sourceType) {
      case "XUAT_BAN":
        return orderOutWard();
      case "XUAT_HUY":
        return cancelOutWard();
      case "XUAT_DIEU_CHINH":
        return adjustmentOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return requestTransfetOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return requestTransferInternalOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return serialOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return serialInternalOutWard();
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return createProductOutWard();
      case "XUAT_TRA_NCC":
        return returnVendorOutWard();
      case "XUAT_TRA_NCC-DCTT":
        return returnVendorOutWard();
      case "XUAT_MUON_BAO_HANH":
        return cancelOutWard();
      default:
        return requestTransfetOutWard();
    }
  };

  const getColumnVisibility = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;

    const defaultVisibility = {
      id: false,
      poPrice: true,
      subPrice: true,
      totalAmount: true,
      serial: false,
    };

    if (
      ["XUAT_DIEU_CHUYEN_CHI_NHANH", "XUAT_DIEU_CHUYEN_NOI_BO"].includes(
        _sourceType
      )
    ) {
      return {
        ...defaultVisibility,
        poPrice: false,
        subPrice: false,
        totalAmount: false,
      };
    }

    if (_sourceType?.includes("SERIAL")) {
      return { ...defaultVisibility, serial: true };
    }

    return defaultVisibility;
  };

  // func render ui => title
  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                w={200}
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled={
                  dataOutWard.tblItemTransactionHeaderCommand.status ===
                    "LOCK" ||
                  sourceCode ||
                  isSave
                }
                data={dataInventoryTransactionType}
                value={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                  ""
                }
                onChange={(value) => {
                  handleChangeValueInput(value || "", "sourceType");
                }}
              />
            </Group>
            <Tooltip
              label={isCollapse ? "Mở rộng" : "Thu gọn"}
              position="bottom"
            >
              <Group align="center">
                <Switch
                  size="sm"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onChange={toggleCollapse}
                  label={isCollapse ? "Thu gọn (Ctrl+M)" : "Mở rộng (Ctrl+M)"}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
              </Group>
            </Tooltip>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            <Button
              type="submit"
              color={sky_blue.base}
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker
              }
              onClick={() => {
                setIsSave(true);
                handleCreateOutWard("OPEN");
              }}
            >
              Lưu (Ctrl+S)
            </Button>
            <Button
              type="button"
              color="green"
              disabled={
                dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK" ||
                locker ||
                isLockForOrder
              }
              onClick={() => {
                if (dataSerialOnHand.some((item) => !item.type)) {
                  handleCreateOutWard("LOCK", true);
                } else {
                  NotificationExtension.Fails("Chưa có sản phẩm để xuất kho");
                  return;
                }
              }}
            >
              Hoàn thành (Ctrl+Q)
            </Button>
          </Flex>
        </Group>
      </>
    );
  };

  // func render ui => select item outward
  const TableSelect = () => (
    <Table.ScrollContainer minWidth={500} h={300} type="native">
      <Table striped highlightOnHover stickyHeader>
        <Table.Thead>
          <Table.Tr>
            <Table.Th />
            <Table.Th>Mã sản phẩm</Table.Th>
            <Table.Th>Tên sản phẩm</Table.Th>
            <Table.Th>Số serial</Table.Th>
            <Table.Th>Tuổi tồn</Table.Th>
            <Table.Th>Kho</Table.Th>
            <Table.Th>Đơn vị tính</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {dataItem?.map((item) => {
            return (
              <Table.Tr
                key={item.serial}
                bg={
                  checkDuplicateCode.some((select) => select === item.serial)
                    ? "var(--mantine-color-blue-light)"
                    : undefined
                }
              >
                <Table.Td>
                  <Button
                    size="xs"
                    variant="filled"
                    onClick={() => {
                      fetchDataSerialOnHand(item.serial);
                    }}
                    disabled={checkDuplicateCode.includes(item.serial)}
                  >
                    {checkDuplicateCode.includes(item.serial ?? "") ||
                    checkDuplicateCode.includes(item.itemCode ?? "")
                      ? "Đã chọn"
                      : "Chọn"}
                  </Button>
                </Table.Td>
                <Table.Td>{item.itemCode}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.itemName}>
                    <Text size="sm" w={250} lineClamp={2} truncate="end">
                      {item.itemName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>{item.serial}</Table.Td>
                <Table.Td>{item.itemAge}</Table.Td>
                <Table.Td>
                  <Tooltip label={item.invName}>
                    <Text size="sm" w={125} truncate="end">
                      {item.invName}
                    </Text>
                  </Tooltip>
                </Table.Td>
                <Table.Td>
                  {item.primaryUomName || item.primaryUomCode}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
        {dataItem.length < 1 && (
          <Table.Caption>Không tìm thấy sản phẩm!</Table.Caption>
        )}
      </Table>
    </Table.ScrollContainer>
  );

  const isValidSerial = (input: string): boolean => {
    const maxLength = 10;
    const pattern = /^[A-Z0-9]+$/;
    return input.length <= maxLength && pattern.test(input);
  };

  // func render ui => text input transaction header
  const TextTransactionHeader = () => (
    <TextInput
      flex={3}
      placeholder="Nhập mã vạch"
      size="xs"
      disabled={dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"}
      value={anyAsciiCode(serial).toLocaleUpperCase()}
      onKeyDown={(e) => {
        if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
          handleAddProduct();
        }
      }}
      error={isValidSerial(serial) ? "Serial không hợp lệ !" : undefined}
      onChange={(e) => setSerial(e?.currentTarget?.value)}
    />
  );

  // func render ui => select item transaction header
  const MenuDataOutWart = () => (
    <Menu
      trapFocus={false}
      trigger="hover"
      shadow="md"
      width={"800"}
      position="bottom-start"
    >
      <Menu.Target>
        <TextInput
          placeholder="Hoặc tìm kiếm mã sản phẩm"
          size="xs"
          disabled={
            dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
          }
          onChange={async (e) => {
            const _key = e.target.value ?? "";
            setKeySearch(_key);
          }}
        />
      </Menu.Target>
      <Menu.Dropdown>
        <TableSelect />
      </Menu.Dropdown>
    </Menu>
  );

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleKeyDownSourceCode = (e: any) => {
    if (e.key === "Enter" || e.key === "Tab") {
      if (dataSearchSourceCodeOption.length === 1) {
        handleChangeValueInput(searchSourceCode || "", "sourceCode");
      }
    }
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const handleAddProduct = () => {
    if (dataOutWard.tblItemTransactionHeaderCommand?.sourceCode) {
      if (checkDuplicateCode.length === 0) {
        fetchDataSerialOnHand();
        setSerial("");
      } else if (checkDuplicateCode.length > 0) {
        if (checkDuplicateCode.includes(serial)) {
          NotificationExtension.Fails("Mã Serial đã được quét !");
        } else {
          fetchDataSerialOnHand();
          setSerial("");
        }
      }
    } else {
      NotificationExtension.Fails("Chưa nhập số chứng từ");
    }
  };

  const deleteItemSerial = (serial: string) => {
    setDataSerialOnHand((prevData) => {
      return prevData.filter((item) => item.serial !== serial);
    });
    setCheckDuplicateCode((prevData) =>
      prevData.filter((item) => item !== serial)
    );
  };

  const checkVarianceItem = (items: detailOrderForOutWardModel[]) => {
    let variance = false;
    let variaceItems: { itemName: string; varianceQuantity: number }[] = [];
    items.forEach((item) => {
      const _varianceQuantity =
        (item.scannedQuantity || 0) - (item.primaryQuantity || 0);
      if (_varianceQuantity !== 0) {
        variaceItems = [
          ...variaceItems,
          {
            itemName: item.itemName,
            varianceQuantity: _varianceQuantity,
          },
        ];
        variance = true;
      }
    });
    return {
      isVariance: variance,
      variaceItems: variaceItems,
    };
  };

  const openCofirmModal = (
    variaceItems: any[],
    isLocked: boolean
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      modals.openConfirmModal({
        size: "auto",
        children: (
          <Box component="form" mx="auto" style={{ position: "relative" }}>
            <Text size="20px" fw={"bold"} my={10}>
              Có những sản phẩm sau có lệch? Bạn có chắc chắn{" "}
              {isLocked ? "hoàn thành" : "lưu"} phiếu?
            </Text>
            <Box mt={3}>
              <Table
                highlightOnHover
                striped
                withColumnBorders
                horizontalSpacing="md"
                verticalSpacing="sm"
                bd={"1px solid #E0E0E0"}
              >
                <thead>
                  <tr style={{ backgroundColor: "#F8F9FD" }}>
                    <th
                      style={{
                        textAlign: "left",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Tên sản phẩm
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        padding: "12px",
                        fontSize: "16px",
                        fontWeight: 600,
                        border: "1px solid #E0E0E0",
                      }}
                    >
                      Số lượng lệch
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {variaceItems?.map((item, index) => {
                    const varianceQuantity = item.varianceQuantity || 0;
                    return (
                      <tr key={index}>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "bold",
                            textAlign: "left",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {item.itemName}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "red",
                            border: "1px solid #E0E0E0",
                          }}
                        >
                          {varianceQuantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Box>

            <Group
              justify="end"
              mt="xs"
              style={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "white",
              }}
            >
              <Button
                type="button"
                color="gray"
                onClick={() => {
                  handleChangeValueInput("", "status");
                  modals.closeAll();
                  resolve(false);
                }}
                leftSection={<IconX size={18} />}
              >
                Hủy
              </Button>
              <Button
                onClick={() => {
                  handleChangeValueInput("LOCK", "status");

                  modals.closeAll();
                  resolve(true);
                }}
                color={sky_blue.base}
                leftSection={<IconCheck size={18} />}
              >
                Xác nhận
              </Button>
            </Group>
          </Box>
        ),
        confirmProps: { display: "none" },
        cancelProps: { display: "none" },
        onClose() {
          handleChangeValueInput("", "status");
        },
      });
    });
  };

  const checkExceedQuantityFound = (data: any[]) => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const _exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = data
            .filter((item) => {
              const checkItem = _sourceType?.includes("SERIAL")
                ? item.serial === order.serial
                : item.itemId === order.itemId ||
                  item.itemCode === order.itemCode;
              return checkItem && !item.type;
            })
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng
            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

            return remainQuantity < 0;
          }

          return false;
        }
      );
    if (_exceedQuantityFound) {
      return true;
    }
    return false;
  };

  const handleCreateOutWard = async (status: string, isLocked?: boolean) => {
    if (dataSerialOnHand.some((item) => item.warning)) {
      NotificationExtension.Fails("Có sản phẩm không hợp lệ");
      return;
    }

    if (isExceedQuantity) {
      NotificationExtension.Fails("Có sản phẩm vượt quá số lượng cần xuất");
      return;
    }
    if (
      dataOutWard?.tblItemTransactionHeaderCommand.description &&
      dataOutWard?.tblItemTransactionHeaderCommand.description.length > 300
    ) {
      NotificationExtension.Fails("Ghi chú phiếu xuất không quá 300 ký tự");
      return;
    }

    handleChangeValueInput(status, "status");

    const serials = dataSerialOnHand
      .filter((item) => !item.type)
      .map((item) => ({
        primaryQuantity: item.primaryQuantity,
        subQuantity: item.subQuantity,
        serial: item.serial,
        invId: item.invId,
      }));

    const attributes = getAttributes(
      dataOutWard.tblItemTransactionHeaderCommand.sourceType
    );

    const dataCreate = {
      ...dataOutWard,
      tblItemTransactionHeaderCommand: {
        transactionId:
          transactionTempId ||
          dataOutWard.tblItemTransactionHeaderCommand.transactionId,
        transactionCode:
          dataOutWard.tblItemTransactionHeaderCommand.transactionCode,
        transactionDate:
          dataOutWard.tblItemTransactionHeaderCommand.transactionDate,
        sourceCode:
          dataOutWard.tblItemTransactionHeaderCommand.sourceCode || sourceCode,
        sourceType: dataOutWard.tblItemTransactionHeaderCommand.sourceType,
        branchId: dataOrderDetail?.tblInventoryTransactionModel?.fromBranchId,
        subInvId:
          dataOrderDetail?.tblInventoryTransactionModel?.fromInvId ?? 6014,
        departmentId: dataOutWard.tblItemTransactionHeaderCommand?.departmentId,
        status,
        description: dataOutWard.tblItemTransactionHeaderCommand.description,
        parentSerial:
          dataOutWard?.tblItemTransactionHeaderCommand?.parentSerial,
        ...attributes,
      },
      serials,
      isLock: isLocked,
    };

    const url = getOutwardApiUrl(
      dataOutWard.tblItemTransactionHeaderCommand.sourceType
    );

    try {
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels
      );

      if (variance.isVariance) {
        const confirm = await openCofirmModal(
          variance.variaceItems,
          status === "LOCK"
        );
        if (!confirm) {
          handleChangeValueInput("OPEN", "status");
          return;
        }
      }
      if (status === "LOCK") {
        const lockResponse = await postOutwardRequest(url, dataCreate);
        if (lockResponse?.success) {
          NotificationExtension.Success("Khóa phiếu xuất kho thành công");
          navigate("/warehouse-config/warehouse-out-ward");
        }
        return;
      }

      const response = await postOutwardRequest(url, dataCreate);
      if (response && status === "OPEN") {
        setTransactionTempId(response.data);
        NotificationExtension.Success("Lưu phiếu xuất kho thành công");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const postOutwardRequest = async (url: string, dataCreate: any) => {
    const response = await repositoryPos.post<MessageResponse<any>>(
      url,
      dataCreate
    );
    if (response?.httpStatusCode === 200 && response?.success) {
      return response;
    } else {
      return null;
    }
  };

  const fetchDataSerialOnHand = async (serialCode?: string) => {
    setIsLoading(true);
    setIsRefetching(true);
    let url = `?SourceCode=${
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode || sourceCode
    }&Type=${dataOutWard.tblItemTransactionHeaderCommand.sourceType}&Serial=${
      serialCode || serial
    }`;

    try {
      const response = await repositoryPos.get<
        MessageResponse<TblInventorySerialOrderDetailOnHand>
      >(`api/v1/TblItemTransaction/search-serial-item-transaction${url}`);

      if (response && response.success) {
        const responseData = response.data;

        if (responseData.warning) {
          NotificationExtension.Fails(responseData.warning);
        } else {
          const dataDetail = {
            ...responseData,
            subInvName: responseData.invName,
          };
          const checkData = [...dataSerialOnHand, { ...dataDetail }];
          if (checkExceedQuantityFound(checkData)) {
            NotificationExtension.Warn(
              "Sản phẩm vượt quá số lượng cần xuất, vui lòng kiểm tra lại"
            );
          } else {
            setDataSerialOnHand((prevData) => [...prevData, { ...dataDetail }]);
            setCheckDuplicateCode((prev) => [...prev, serialCode || serial]);
          }
        }
      } else {
        NotificationExtension.Fails(response?.message || "");
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }

    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const getOutwardApiUrl = (sourceType: string | null): string => {
    switch (sourceType) {
      case "XUAT_BAN":
        return "/api/v1/Outward/create-sale-order-out-ward";
      case "XUAT_HUY":
        return "/api/v1/Outward/create-request-cancellation-outward";
      case "XUAT_DIEU_CHINH":
        return "/api/v1/Outward/create-request-adjustment-outward";
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return "/api/v1/Outward/create-request-transfer-outward";
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return "api/v1/Outward/create-request-transfer-internal-outward";
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return "/api/v1/Outward/create-request-transfer-serial-outward";
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return "/api/v1/Outward/create-request-transfer-internal-serial-outward";
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return "/api/v1/Outward/create-production-order-outward";
      case "XUAT_TRA_NCC":
        return "/api/v1/Outward/create-request-vendor-return-outward";
      default:
        throw new Error("Unknown source type");
    }
  };

  const getAttributes = (
    sourceType: string | null
  ): { attribute4: string; attribute5: string } => {
    const { fromBranchName, cusCode, cusName, toBranchName } =
      dataOrderDetail?.tblInventoryTransactionModel || {};

    switch (sourceType) {
      case "XUAT_BAN":
        return {
          attribute4: fromBranchName,
          attribute5: `${cusCode} - ${cusName}`,
        };
      case "XUAT_TRA_NCC":
        return {
          attribute4: fromBranchName,
          attribute5: cusName,
        };
      default:
        return {
          attribute4: fromBranchName,
          attribute5: toBranchName,
        };
    }
  };

  const fetchDataOutWard = async () => {
    const _sourceCode =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceCode ||
      searchSourceCode;
    const _sourceType =
      dataOutWard?.tblItemTransactionHeaderCommand?.sourceType || sourceType;

    let url = getOutwardApiUrl(_sourceType);
    url += `?prefix=XK&SourceCode=${_sourceCode}`;
    try {
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response?.success) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand: {
            ...prevData.tblItemTransactionHeaderCommand,
            transactionCode:
              response?.data?.tblInventoryTransactionModel?.transactionCode,
          },
        }));
        const dataDetail = response?.data;
        setDataOrderDetail(dataDetail);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useHotkeys([
    [
      "ctrl+S",
      () => {
        handleCreateOutWard("OPEN");
      },
    ],
    [
      "ctrl+Q",
      () => {
        if (dataSerialOnHand.some((item) => !item.type)) {
          handleCreateOutWard("LOCK", true);
        } else {
          NotificationExtension.Fails("Chưa có sản phẩm để xuất kho");
          return;
        }
      },
    ],
    ["ctrl+M", () => toggleCollapse()],
  ]);

  //#endregion

  const fetchdataInventoryTransactionType = async () => {
    const responseOutWard = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      responseOutWard.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  // Get Data source code
  const fetchDataSourceCode = async () => {
    try {
      const _sourceType =
        dataOutWard.tblItemTransactionHeaderCommand.sourceType;
      let url = `api/v1/RequestInventoryTransaction/get-list-code-transaction-request?Type=${_sourceType}&Page=30`;
      if (searchSourceCode && sourceCode) {
        url += `?Key=${searchSourceCode || sourceCode}`;
      }
      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response?.success) {
        setDataSearchSourceCodeOption(response.data.map((data) => data) || []);
      }
    } catch (error) {
      setDataSearchSourceCodeOption([]);
    }
  };

  // Get data sản phẩm
  const fetchDataItem = async () => {
    const _sourceType =
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType ?? "";
    const _sourceCode =
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode ?? sourceCodeRef;
    const _invId = dataOutWard.tblItemTransactionHeaderCommand?.subInvId ?? 0;

    if (keySearch && _sourceType && _sourceCode) {
      try {
        let url = `/api/v1/TblItemTransaction/search-list-serial-item-transaction?KeySearch=${keySearch}&SourceCode=${_sourceCode}&Page=30&Type=${_sourceType}`;
        if (_sourceType === "XUAT_LINH_KIEN_SAN_XUAT" && _invId) {
          url += `&InvId=${_invId}`;
        }
        const response = await repositoryPos.get(url);

        setDataItem(response?.data || []);
      } catch (error) {
        setDataItem([]);
      }
    }
  };

  // useScanDetection({
  //   onComplete: (code) => {
  //     setSerial(code.toString());
  //     fetchDataSerialOnHand(serial);
  //   },
  //   minLength: 5,
  // });

  //#endregion

  //#region useEffects
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchdataInventoryTransactionType(), // FetchData cho loại phiếu xuất
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const userLogin = localStorage.getItem("userLogin");
    if (userLogin) setUserInfo(JSON.parse(userLogin));

    if (sourceCode) {
      if (dataOutWard.tblItemTransactionHeaderCommand?.sourceCode) {
        fetchDataOutWard();
      }
    }
    fetchAllData();
  }, []);

  useEffect(() => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        departmentId: userInfo?.depId || null,
      },
    }));
  }, [userInfo]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels?.some(
        (order) => {
          const matchingOrder = dataSerialOnHand
            .filter((item) => {
              const checkItem = _sourceType?.includes("SERIAL")
                ? item.serial === order.serial
                : item.itemId === order.itemId ||
                  item.itemCode === order.itemCode;
              return checkItem && !item.type;
            })
            .map((item) => item.primaryQuantity || 0);

          if (matchingOrder) {
            const totalQuantity = matchingOrder.reduce(
              (acc, curr) => acc + curr,
              0
            ); // Tính tổng số lượng

            const orderQuantity = order.primaryQuantity ?? 0;
            const orderTransferQuantity = order.transactionQuantity ?? 0;
            const remainQuantity =
              orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
            return remainQuantity < 0;
          }

          return false;
        }
      );

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels?.map((order) => {
          const quantities = dataSerialOnHand
            ?.filter((item) => {
              const checkItem = _sourceType?.includes("SERIAL")
                ? item.serial === order.serial
                : item.itemId === order.itemId ||
                  item.itemCode === order.itemCode;
              return checkItem && !item.type;
            })
            ?.map((item) => item.primaryQuantity || 0);

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;
          const priceItem =
            _sourceType === "XUAT_BAN"
              ? order.soPrice1 ?? 0
              : order.poPrice ?? 0;

          return {
            ...order,
            // transactionQuantity: totalQuantity,
            scannedQuantity: totalQuantity,
            remainQuantity: remainQuantity,
            priceItem: priceItem * totalQuantity,
          };
        }),
    }));

    if (exceedQuantityFound) {
      setIsExceedQuantity(true);
    } else {
      setIsExceedQuantity(false);
    }
  }, [dataSerialOnHand]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const newColumnVisibility = getColumnVisibility();
    if (tableDetail) {
      tableDetail.setColumnVisibility(newColumnVisibility);
    }
    // Clear input field
    setDataSearchSourceCodeOption([]);
    setDataOutWard({
      ...outWardEntity,
      tblItemTransactionHeaderCommand: {
        ...outWardEntity.tblItemTransactionHeaderCommand,
        sourceType: _sourceType,
        sourceCode: null,
      },
    });
    setDataOrderDetail(entityOrderOutWard);
    setDataSerialOnHand([]);
    setCheckDuplicateCode([]);

    if (_sourceType) {
      fetchDataSourceCode();
      setIsLockForOrder(_sourceType === "XUAT_BAN");
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceType]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const _sourceCode = dataOutWard.tblItemTransactionHeaderCommand.sourceCode;
    // Clear input field
    setDataOutWard({
      ...outWardEntity,
      tblItemTransactionHeaderCommand: {
        ...outWardEntity.tblItemTransactionHeaderCommand,
        sourceType: _sourceType,
        sourceCode: _sourceCode,
      },
    });
    setDataOrderDetail(entityOrderOutWard);
    setDataSerialOnHand([]);
    setCheckDuplicateCode([]);
  }, [dataOutWard.tblItemTransactionHeaderCommand.sourceCode]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    if (
      _sourceType === "XUAT_BAN" &&
      dataOrderDetail?.inventoryTransactionRequestSourceModels.length > 0
    ) {
      const variance = checkVarianceItem(
        dataOrderDetail?.inventoryTransactionRequestSourceModels
      );
      if (!variance.isVariance) {
        setIsLockForOrder(false);
      } else {
        setIsLockForOrder(true);
      }
    }
  }, [dataOrderDetail?.inventoryTransactionRequestSourceModels]);

  useEffect(() => {
    if (
      dataOutWard.tblItemTransactionHeaderCommand?.sourceType &&
      dataOutWard.tblItemTransactionHeaderCommand?.sourceCode
    ) {
      fetchDataOutWard();
    }
  }, [dataOutWard.tblItemTransactionHeaderCommand?.sourceCode]);

  useEffect(() => {
    if (!searchSourceCode && searchSourceCode.length > 3) {
      fetchDataSourceCode();
    }
  }, [searchSourceCode]);

  useEffect(() => {
    if (keySearch && keySearch.length > 2) {
      fetchDataItem();
    } else {
      setDataItem([]);
    }
  }, [keySearch]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const updateMainContentWidth = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      const sidePanelWidth = sidePanelRef.current?.offsetWidth || 0;
      const calculatedMainWidth = isCollapse
        ? containerWidth
        : containerWidth - sidePanelWidth;
      setMainContentWidth(calculatedMainWidth);
    };

    const containerObserver = new ResizeObserver(updateMainContentWidth);
    const sidePanelObserver = new ResizeObserver(updateMainContentWidth);

    if (containerRef.current) containerObserver.observe(containerRef.current);
    if (sidePanelRef.current) sidePanelObserver.observe(sidePanelRef.current);

    updateMainContentWidth();

    return () => {
      if (containerRef.current)
        containerObserver.unobserve(containerRef.current);
      if (sidePanelRef.current)
        sidePanelObserver.unobserve(sidePanelRef.current);
    };
  }, [isCollapse]);
  //#endregion

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },
      {
        accessorKey: "primaryUomCode",
        header: "ĐVT1",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm" w={300}>
            1
          </Text>
        ),
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard?.tblItemTransactionHeaderCommand?.sourceType ===
          "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard?.tblItemTransactionHeaderCommand?.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice && row.original.soPrice?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 200,
        Cell: ({ renderedCellValue, row }) => <>{renderedCellValue}</>,
      },
      {
        header: "Ghi chú",
        size: 150,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 30,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  deleteItemSerial(row.original.serial);
                }}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [dataOutWard.tblItemTransactionHeaderCommand.sourceType]
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },
      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 30,
      },
      {
        header: "Ghi chú",
      },
      {
        header: "Số lượng đã xuất",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {row.original.scannedQuantity || 0}
            </Text>
          );
        },
        size: 30,
      },
      {
        header: "Lệch",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {(row.original.scannedQuantity || 0) -
                (row.original.primaryQuantity || 0)}
            </Text>
          );
        },
        size: 30,
      },
    ],
    [dataSerialOnHand, dataOutWard.tblItemTransactionHeaderCommand.sourceType]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    renderTopToolbarCustomActions: () => (
      <div ref={headerRef} style={{ width: "80%" }}>
        <Grid>
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            {TextTransactionHeader()}
          </Grid.Col>
          {
            <Grid.Col span={{ base: 6, md: 4, lg: 2.5 }}>
              <Button
                leftSection={<IconPlus size={14} />}
                size="xs"
                w={"100%"}
                onClick={handleAddProduct}
                disabled={
                  serial?.length < 10 ||
                  dataOutWard.tblItemTransactionHeaderCommand.status === "LOCK"
                }
              >
                Thêm barcode
              </Button>
            </Grid.Col>
          }
          <Grid.Col span={{ base: 6, md: 4, lg: 4 }}>
            {MenuDataOutWart()}
          </Grid.Col>
        </Grid>
      </div>
    ),
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action"],
      },
      columnVisibility: { id: false, phone: false, fax: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.55 - 20,
        minHeight: height * 0.55 - 20,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.inventoryTransactionRequestSourceModels ?? [],
    positionToolbarAlertBanner: "bottom",
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      // columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.45,
        minHeight: height * 0.45,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "max-width",
          }}
          ref={containerRef}
        >
          {!isCollapse && (
            <Box
              style={{
                flexShrink: 0,
                transition: "width 0.3s ease, max-width 0.3s ease",
                display: "flex",
                alignItems: "stretch",
                overflow: "hidden",
                height: "100%",
                width: "fit-content",
              }}
              ref={sidePanelRef}
            >
              <Collapse
                in={!isCollapse}
                transitionDuration={100}
                transitionTimingFunction="ease-in-out"
              >
                <Fieldset
                  w="fit-content"
                  h="100%"
                  legend="Thông tin chung"
                  p={5}
                >
                  {renderMainOutWard()}
                </Fieldset>
              </Collapse>
            </Box>
          )}

          <Box
            style={{
              flexGrow: 1,
              flexShrink: 0,
              width: `${mainContentWidth}px`,
              transition: "width 0.3s ease",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Fieldset w={"max-width"} p={10} pb={0} legend="Bên xuất">
              <MantineReactTable table={table} />
              <Group my="10px" justify="end" />
            </Fieldset>

            <Fieldset
              p={10}
              pb={0}
              w={"max-width"}
              legend={
                dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                "XUAT_BAN"
                  ? "Thông tin đơn hàng"
                  : "Yêu cầu xuất kho"
              }
            >
              <MantineReactTable table={tableDetail} />
            </Fieldset>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default CreateWarehouseOutWard;
