import React, { useEffect, useState } from "react";
import { repositoryMdm, repositoryPayment } from "../../_base/_const/_constVar";
import { NotificationExtension } from "../../_base/extension/NotificationExtension";
import { useReactToPrint } from "react-to-print";
import PrintedInvoice from "../Invoice/PrintedInvoice";

const PrintInvoice = ({
  idPrint,
  setIdPrint,
  isPrinting,
  setIsPrinting,
}: any) => {
  // Invoice
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
  });
  const [referencelabelcode, setReferencelabelcode] = useState(null);
  const [valueQRCode, setValueQRCode] = useState(null);
  const [invoiceData, setInvoiceData] = useState<any>(null);
  const fetchInvoiceData = async () => {
    // if (!idPrint) return;
    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblOrderHeader/print-order?ids=${idPrint}`
      );
      if (response && response.success) {
        let result = response.data;
        setInvoiceData(result || null);
      } else {
        setInvoiceData(null);
        setIsPrinting(false);
        NotificationExtension.Fails("In hóa đơn không thành công");
      }
    } catch (error) {
      setInvoiceData(null);
      console.error(error);
      return;
    }
  };
  // console.log("invoiceData", invoiceData);
  const CallApiQrCode = async (qrCodeData: any) => {
    try {
      const response = await repositoryPayment.post(
        `api/TblMBQRcode/gen-qr-mb`,
        qrCodeData
      );
      if (response && response.success !== false) {
        setReferencelabelcode(response.data.referencelabelcode);
      } else {
        setIsPrinting(false);
        NotificationExtension.Fails("In hóa đơn không thành công");
        console.log("Tạo qr code không thành công");
      }
    } catch (error) {
      setIsPrinting(false);
      console.log(error);
    }
  };
  const fetApiQrCodeValue = async () => {
    try {
      if (referencelabelcode === null) return;
      const response = await repositoryPayment.get(
        `/api/TblMBQRcode/get-qr-mb?key=${referencelabelcode}`
      );
      if (response && response.success) {
        setValueQRCode(response.data.qrcodevalue);
      } else {
        setIsPrinting(false);
      }
    } catch (error) {
      setIsPrinting(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const Process = async () => {
      try {
        if (invoiceData) {
          const qrCodeData = {
            id: 0,
            terminalID: "12345678",
            qrcodeType: 1,
            parterType: 2,
            initMethod: 1,
            transactionAmount:
              invoiceData[0]?.payAmount || invoiceData[0]?.amountTotal,
            billNumber: invoiceData[0]?.orderNumber,
            referenceLabelTime: invoiceData[0]?.createDate,
            referenceLabelCode: "",
            transactionPurpose: "Thanh toán đơn hàng",
            additionAddress: invoiceData[0]?.custName,
            additionMobile: invoiceData[0]?.telephoneNumber,
            additionEmail: "",
            createdBy: invoiceData[0]?.casherName,
            lastUpdateDate: invoiceData[0]?.approveDate,
            lastUpdatedBy: invoiceData[0]?.casherName,
            lastUpdateLogin: "",
            creationDate: invoiceData[0]?.createDate,
            qrcodevalue: "",
            ordercode: invoiceData[0]?.orderId,
          };

          await CallApiQrCode(qrCodeData);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    Process();
  }, [invoiceData]);
  useEffect(() => {
    if (referencelabelcode) {
      const Process = async () => {
        try {
          await fetApiQrCodeValue();
        } catch (error) {
          console.log("error", error);
        }
      };
      Process();
    }
  }, [referencelabelcode]);

  useEffect(() => {
    if (Array.isArray(idPrint) && idPrint.length === 0) {
      return;
    }
    fetchInvoiceData();
  }, [idPrint]);
  useEffect(() => {
    // Chỉ kích hoạt handlePrint khi cả invoiceData và valueQRCode đã sẵn sàng
    if (
      (invoiceData || null) &&
      (valueQRCode || null) &&
      (componentRef.current || null)
    ) {
      handlePrint();
      setIdPrint([]);
      setIsPrinting(false);
      setInvoiceData(null);
      setValueQRCode(null);
    }
  }, [invoiceData, valueQRCode]);
  return (
    <div>
      {invoiceData && valueQRCode && (
        <div style={{ display: "none" }}>
          <PrintedInvoice
            ref={componentRef}
            invoiceData={invoiceData}
            valueQRCode={valueQRCode}
          />
        </div>
      )}
    </div>
  );
};

export default PrintInvoice;
