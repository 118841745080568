import { ActionIcon, Badge, Box, Tooltip } from "@mantine/core";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryMdm,
} from "../../../_base/_const/_constVar";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import { _localization } from "../../../config/location";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { green } from "../../../const/variables";
import { AvatarUtils } from "../../../common/ColorByName/AvatarUtils";
import { IconEye, IconTransferOut } from "@tabler/icons-react";

const ReturnCustomers = () => {
  //#region State
  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any[]>([]);
  const [dataTemp, setDataTemp] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [height, setHeight] = useState(0);
  //table state
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  // const [keySearch, setKeySearch] = useState<string>("");
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  const [pagination, setPagination] = useState(paginationBase);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  // Search
  const [search, setSearch] = useState({
    requestNum: "",
    startDate: "",
    endDate: "",
    status: "",
    keySearch: "",
    processingMethod: "",
  });

  //#region  table
  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "Số phiếu xuất trả",
        header: "Số phiếu xuất trả",
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="xs"
            variant="dot"
            size="lg"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? "KHÔNG XÁC ĐỊNH" : renderedCellValue}
          </Badge>
        ),
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "Trạng thái",
        header: "Trạng thái",
        Cell: ({ renderedCellValue }) => (
          <Badge
            radius="xs"
            color={
              renderedCellValue === "Yêu cầu xuất trả" ? "red" : green.base
            }
          >
            {renderedCellValue}
          </Badge>
        ),
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "Nhân viên trả",
        header: "Nhân viên trả",
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "Chi nhánh trả",
        header: "Chi nhánh trả",
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "Địa điểm",
        header: "Địa điểm",
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "Người tạo phiếu",
        header: "Người tạo phiếu",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <AvatarUtils value={renderedCellValue} />
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "Ngày tạo phiếu",
        header: "Ngày tạo phiếu",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Xem chi tiết">
              <ActionIcon variant="light" aria-label="Settings" color="blue">
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Xuất trả">
              <ActionIcon variant="light" aria-label="Settings" color="green">
                <IconTransferOut size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-tiep-nhan-bao-hanh.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    try {
      const response = await repositoryMdm.get(
        `/api/v1/TblWarrantyReceiveTicket/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.length);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    fetchData();
  }, [deleteViewStatus]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (140 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },

    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "errorNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "500", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["10", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });
  //#endregion
  return (
    <>
      <MantineReactTable table={table} />
    </>
  );
};
export default ReturnCustomers;
