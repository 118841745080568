import {
  Box,
  Button,
  ComboboxItem,
  Grid,
  Group,
  LoadingOverlay,
  Select,
  TextInput,
} from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { getTblDmEmployeeSelect } from "../../../service/getSelectApi";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { modals } from "@mantine/modals";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import { sky_blue } from "../../../const/variables";
import { CreateWarrantyInformation } from "../../../model/WarratyInformation";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { SocketExtension } from "../../../_base/socket/socket";

const WarrantyAssignment = ({
  dataRequestFormSelect,
  selectIds,
  onClose,
}: {
  selectIds: any;
  onClose: any;
  dataRequestFormSelect: any;
}) => {
  const entity = {
    requestFormIds: null,
    code: null,
    empInchargeId: null,
    empSupportId: null,
    note: null,
    levelId: null,
    points: null,
    assignDate: new Date().toLocaleString(),
  };

  const form = useForm<CreateWarrantyInformation>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },

    transformValues: (values) => ({
      ...values,
      empInchargeId: Number(values.empInchargeId),
      empSupportId: Number(values.empSupportId),
    }),

    validate: {
      empInchargeId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập tên người nhận phân công !";
        }
      },
    },
  });

  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataEmployeeSelectForDep, setDataEmployeeSelectForDep] = useState<
    ComboboxItem[]
  >([]);

  const [visible, { close, open }] = useDisclosure(false);

  const handleWarrantyAssignment = async (
    dataSubmit: CreateWarrantyInformation
  ) => {
    open();
    dataSubmit = { ...dataSubmit, requestFormIds: [Number(selectIds)] };
    const req = await repositoryMdm.post<
      MessageResponse<CreateWarrantyInformation>
    >(`/api/v1/TblWarrantyServiceRequestForm/create-assignment`, dataSubmit);
    if (req?.success) {
      NotificationExtension.Success("Phân công thành công !");
      setTimeout(() => {
        onClose((prev: any) => !prev);
        modals.closeAll();
      });
    }
    close();
  };

  const getRequestNum = async () => {
    const res = await repositoryMdm.get<MessageResponse<any>>(
      "/api/v1/TblWarrantyServiceRequestForm/assign?prefix=SPC-"
    );

    if (res && res.success) {
      const data = res.data;
      form.setValues((prev) => ({ ...prev, code: data.requestNum }));
    }
  };

  const getDataEmployeeSelect = async () => {
    const get = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      get
        ?.filter((item) => item.value != null && item.text != null)
        ?.map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const getDataEmployeeForDep = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${dataRequestFormSelect[0]?.branchId}&Take=200`
    );

    if (res && res?.success) {
      setDataEmployeeSelectForDep(
        res.data
          ?.filter(
            (item: any) =>
              item.id != null &&
              item.fullname != null &&
              (item.roleName.toLowerCase().includes("bảo hành") ||
                item.roleName.toLowerCase().includes("giao vận"))
          )
          ?.map((item: any) => ({
            value: item.id?.toString(),
            label: item.fullname,
          }))
      );
    }
  };

  const callApi = async () => {
    try {
      open();
      await Promise.all([
        getDataEmployeeSelect(),
        getRequestNum(),
        getDataEmployeeForDep(),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      close();
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box
      component="form"
      mx="auto"
      w={"50vw"}
      maw={700}
      onSubmit={form.onSubmit((e: CreateWarrantyInformation) => {
        handleWarrantyAssignment(e);
        SocketExtension.SendMessageToEmp<any>(
          Number(form.getValues().empInchargeId),
          {
            message: "Có nhiệm vụ",
          }
        );
      })}
    >
      <LoadingOverlay
        visible={visible}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
      />
      <Grid>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <TextInput
            label="Số phân công"
            value={form.getValues().code ?? ""}
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <DateTimePicker
            label="Ngày phân công"
            placeholder="Chọn ngày phân công"
            value={new Date()}
            valueFormat="DD/MM/YYYY hh:mm A"
            clearable
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <TextInput
            label="Người phân công"
            value={
              JSON.parse(localStorage.getItem("userLogin") ?? "")?.fullName ||
              ""
            }
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 6 }}>
          <Select
            label="Người nhận phân công"
            placeholder="Nhập tên người nhận phân công"
            data={dataEmployeeSelectForDep?.filter(
              (item: any) =>
                item.value !== form.getValues().empInchargeId?.toString()
            )}
            value={
              form.getValues().empInchargeId
                ? form.getValues().empInchargeId?.toString()
                : null
            }
            searchable
            clearable
            {...form.getInputProps("empInchargeId")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                empInchargeId: e ? Number(e) : null,
              }))
            }
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            withAsterisk
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 6 }}>
          <Select
            label="Người hỗ trợ"
            placeholder="Nhập tên người hỗ trợ"
            data={dataEmployeeSelectForDep?.filter(
              (item: any) =>
                item.value !== form.getValues().empInchargeId?.toString()
            )}
            value={
              form.getValues().empSupportId
                ? form.getValues().empSupportId?.toString()
                : null
            }
            searchable
            clearable
            nothingFoundMessage="Không tìm thấy dữ liệu !"
            {...form.getInputProps("empSupportId")}
            onChange={(e) =>
              form.setValues((prev) => ({
                ...prev,
                empSupportId: e ? Number(e) : null,
              }))
            }
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            label="Nội dung phân công"
            placeholder="Nhập nội dung phân công"
            {...form.getInputProps("note")}
          />
        </Grid.Col>
      </Grid>
      <Group
        justify="end"
        mt="xs"
        style={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "white",
        }}
      >
        <Button
          type="button"
          color="gray"
          loading={visible}
          onClick={() => {
            modals.closeAll();
          }}
          leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
        >
          Quay lại
        </Button>
        <Button
          type="submit"
          color={sky_blue.base}
          loading={visible}
          leftSection={!visible ? <IconCheck size={18} /> : undefined}
        >
          Lưu
        </Button>
      </Group>
    </Box>
  );
};

export default WarrantyAssignment;
