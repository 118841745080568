import {
  Box,
  Button,
  Menu,
  Table,
  TextInput,
  Tooltip,
  Text,
  Grid,
  NumberInput,
  Flex,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { repositoryPos } from "../../../_base/_const/_constVar";
import { MessageResponse } from "../../../model/MessageResponse";
import { handleKeyDownNegativeNum } from "../../../_base/helper/FunctionHelper";
import { IconCheck } from "@tabler/icons-react";
import { modals } from "@mantine/modals";

const OutsideProduct = ({
  dataItem,
  setDataWarrantyItem,
  index,
}: {
  dataItem: any;
  setDataWarrantyItem: any;
  index: number;
}) => {
  const [infoProduct, setInfoProduct] = useDebouncedState("", 300);
  const [dataProductCallApi, setDataProductCallApi] = useState<any[]>([]);
  const [dataProductSelect, setDataProductSelect] = useState<any>();

  const getDataSerialOnHand = async () => {
    const res = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblInventorySerialOnHand/get-list?KeySearch=${infoProduct}&Take=100`
    );

    if (res && res?.success) {
      const result = res.data;
      setDataProductCallApi(result);
    }
  };

  useEffect(() => {
    if (infoProduct.length > 2) {
      getDataSerialOnHand();
    } else {
      setDataProductCallApi([]);
    }
  }, [infoProduct]);

  useEffect(() => {
    if (dataItem[index]) {
      setDataProductSelect(dataItem[index]);
    }
  }, [dataItem[index]]);

  return (
    <Box w={"40vw"} maw={500} pt={10}>
      <Menu trigger="hover" openDelay={100} closeDelay={400}>
        <Menu.Target>
          <TextInput
            label="Sản phẩm"
            placeholder="Nhập thông tin sản phẩm (Tối thiểu 3 kí tự)"
            defaultValue={infoProduct}
            onChange={(e) => setInfoProduct(e.currentTarget.value)}
          />
        </Menu.Target>
        <Menu.Dropdown>
          <Table.ScrollContainer minWidth={500} mah={480} type="native">
            <Table striped withTableBorder>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Thao tác</Table.Th>
                  <Table.Th>Mã sản phẩm</Table.Th>
                  <Table.Th>Tên sản phẩm</Table.Th>
                  <Table.Th>Đơn vị tính</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {dataProductCallApi && dataProductCallApi.length > 0 ? (
                  infoProduct.length > 0 ? (
                    dataProductCallApi?.map((item) => (
                      <Table.Tr>
                        <Table.Td>
                          <Button
                            color="green"
                            onClick={() =>
                              setDataProductSelect((prev: any) => ({
                                ...prev,
                                itemCode: item.maSp,
                                itemName: item.tenSp,
                              }))
                            }
                          >
                            Chọn
                          </Button>
                        </Table.Td>
                        <Table.Td>{item.maSp}</Table.Td>
                        <Table.Td>
                          <Box w={275}>
                            <Tooltip label={item.tenSp}>
                              <Text size="12.5px" truncate="end" h={15}>
                                {item.tenSp}
                              </Text>
                            </Tooltip>
                          </Box>
                        </Table.Td>
                        <Table.Td>{item.dvt}</Table.Td>
                      </Table.Tr>
                    ))
                  ) : (
                    <Table.Tr>
                      <Table.Td colSpan={4} ta={"center"}>
                        Không tìm thấy sản phẩm !
                      </Table.Td>
                    </Table.Tr>
                  )
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan={4} ta={"center"}>
                      Vui lòng nhập thông tin sản phẩm !
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>
        </Menu.Dropdown>
      </Menu>
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            label="Serial"
            placeholder="Serial"
            value={dataProductSelect?.serial ? dataProductSelect?.serial : null}
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            label="Mã sản phẩm"
            placeholder="Mã sản phẩm"
            value={
              dataProductSelect?.itemCode ? dataProductSelect?.itemCode : null
            }
            variant="filled"
            readOnly
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <TextInput
            label="Tên sản phẩm"
            placeholder="Tên sản phẩm"
            value={
              dataProductSelect?.itemName ? dataProductSelect?.itemName : null
            }
            variant="filled"
            readOnly
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 12, lg: 6 }}>
          <NumberInput
            label="Hạn bảo hành (tháng)"
            placeholder="Nhập hạn bảo hành"
            value={
              dataProductSelect?.warrantyTerm
                ? Number(dataProductSelect?.warrantyTerm)
                : 0
            }
            min={0}
            max={36}
            hideControls
            onKeyDown={handleKeyDownNegativeNum}
            onChange={(e) =>
              setDataProductSelect((prev: any) => ({
                ...prev,
                warrantyTerm: Number(e) <= 36 ? Number(e) : 0,
              }))
            }
          />
        </Grid.Col>
      </Grid>
      <Flex justify={"end"} gap={"md"} mt={5}>
        <Button
          variant="outline"
          color="teal"
          leftSection={<IconCheck size={"14px"} />}
          onClick={() => {
            setDataWarrantyItem((prev: any) => {
              const updated = [...prev];
              updated[index] = dataProductSelect;
              return updated;
            });
            modals.closeAll();
          }}
        >
          Lưu
        </Button>
      </Flex>
    </Box>
  );
};

export default OutsideProduct;
