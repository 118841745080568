import {
  ActionIcon,
  Box,
  Button,
  ComboboxItem,
  Flex,
  Menu,
  Select,
  TextInput,
  Title,
  Tooltip,
  rem,
  Grid,
  Badge,
  SelectProps,
  Group,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedState, useHotkeys } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconCalendar,
  IconCaretDown,
  IconCheck,
  IconEdit,
  IconEye,
  IconFileExport,
  IconFileUpload,
  IconPackageExport,
  IconPlus,
  IconSearch,
  IconStatusChange,
  IconTrash,
  IconTruck,
  IconTruckDelivery,
  IconX,
} from "@tabler/icons-react";
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as xlsx from "xlsx";
import {
  _timerDelayClick,
  repositoryDelivery,
} from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { paginationBase } from "../../../_base/model/_base/BaseTable";
import { _localization } from "../../../config/location";
import { TblDelivery } from "../../../model/TblDelivery";
import DeleteView from "./DeleteView";
import DetailDataView from "./DetailDataView";
import EditDataView from "./EditDataView";
import {
  getBranchSelectMdm,
  getDeliveryDepartmentSelect,
  getDeliveryStatusSelectMdm,
} from "../../../service/getSelectApi";
import {
  formatDateNotTimeZone,
  formatDateTransfer,
} from "../../../common/FormatDate/FormatDate";
import { beige, green, navy_blue, sky_blue } from "../../../const/variables";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import CreateDataView from "./CreateDataView";
import DeliveryAssignment from "./DeliveryAssignment";
import DetailProduct from "./DetailProduct";

const Delivery = () => {
  // Reference data from another table
  const [dataTblDeliveryStatusSelect, setDataTblDeliveryStatusSelect] =
    useState<ComboboxItem[]>([]);
  const [dataTblDeliveryDepartment, setDataTblDeliveryDepartment] = useState<
    ComboboxItem[]
  >([]);
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [dataDeliverySelect, setDataDeliverySelect] = useState<any[]>([]);

  //data and fetching state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<TblDelivery[]>([]);
  const [dataTemp, setDataTemp] = useState<TblDelivery[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [height, setHeight] = useState(0);
  const [toPageDetail, setToPageDetail] = useState(false);
  const [toPageEdit, setToPageEdit] = useState(false);
  const [toPageCreate, setToPageCreate] = useState(false);
  const [detailViewId, setDetailViewId] = useState<string | number | null>(
    null
  );
  const [editViewId, setEditViewId] = useState<string | number | null>(null);
  //table state
  const [rowCount, setRowCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [pagination, setPagination] = useState(paginationBase);
  const [selectIds, setSelectIds] = useState<string[]>([]);
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);
  //count
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCounting, setIsCounting] = useState(false);

  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    deliveryDepartmentId: "",
    statusId: "",
    branchId: "",
    customerId: "",
    customerProvinceId: "",
    customerDistrictId: "",
    customerCommuneId: "",
    type: "",
    empid: "",
    sourceType: "",
    sourceCode: "",
    transactionNumber: "",
  });

  const [keySearch, setKeySearch] = useDebouncedState("", 500);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "deliveryStatusName",
        header: "Trạng thái đơn giao hàng",
        size: 225,
        Cell: ({ renderedCellValue }) => (
          <Tooltip label={renderedCellValue}>
            <Badge
              color={getColorStatus(renderedCellValue?.toString() ?? "")}
              radius={"sm"}
              w={160}
            >
              {renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "sourceCode",
        header: "Số chứng từ gốc",
        Cell: ({ renderedCellValue }) =>
          renderedCellValue && renderedCellValue !== null ? (
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              color={renderedCellValue === null ? "red" : green.base}
            >
              {renderedCellValue}
            </Badge>
          ) : (
            <></>
          ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "sourceType",
        header: "Nguồn hàng",
        Cell: ({ row }) => (
          <Badge
            color={getColorSourceType(row.original.sourceType)}
            radius={"sm"}
          >
            {soureTypeText(row.original.sourceType)}
          </Badge>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "transactionNumber",
        header: "Số giao dịch",
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => detailItem(row.original.id)}
            >
              {renderedCellValue === null ? null : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "fromAddress",
        header: "Địa chỉ giao hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "toAddress",
        header: "Địa chỉ nhận hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "branchName",
        header: "Chi nhánh",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "deliveryDepartmentName",
        header: "BPGH",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "customerName",
        header: "Khách hàng",
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "telephoneNumber",
        header: "Số điện thoại",
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "fromDeliveryDate",
        header: "Ngày giao hàng",
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "createDate",
        header: "Ngày lập",
        size: 30,
        Cell: ({ renderedCellValue }: any) => (
          <>{renderedCellValue && formatDateTransfer(renderedCellValue)}</>
        ),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "description",
        header: "Nội dung giao",
        size: 30,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        size: 50,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chỉnh sửa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="orange"
                onClick={async () => {
                  editItem(row.original.id);
                }}
                disabled={
                  (row.original.statusId !== 1 &&
                    row.original.statusId !== 8) ||
                  row.original.sourceType !== 3
                }
              >
                <IconEdit size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Chi tiết">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="cyan"
                onClick={async () => {
                  detailItem(row.original.id);
                }}
              >
                <IconEye size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Xóa">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                onClick={async () => {
                  await deleteData([row.original.id]);
                }}
                disabled={
                  (row.original.statusId !== 1 &&
                    row.original.statusId !== 8) ||
                  row.original.sourceType !== 3
                }
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Box>
        ),
      },
    ],
    []
  );

  const startCountdown = () => {
    if (!isCounting) {
      setTimeLeft(_timerDelayClick);
      setIsCounting(true);
    }
  };

  function getColorSourceType(value: number) {
    switch (value) {
      case 1:
        return beige.base;
      case 2:
        return sky_blue.light_alt;
      case 3:
        return navy_blue.base;
    }
  }

  function getColorStatus(value: string) {
    switch (value) {
      case "Đã phân công":
        return "lime";
      case "Lập đơn giao hàng":
        return "green";
      case "Đang giao hàng":
        return "teal";
      case "Đơn hàng giao chưa thành công do Khách hàng":
        return "blue";
      case "Đơn hàng giao chưa thành công do Hacom":
        return "indigo";
      case "Hàng đã giao":
        return "violet";
      case "Hủy phân công":
        return "red";
      case "Đã đến nơi":
        return "#2bdd66";
      case null:
        return "rgba(0, 0, 0, 0)";
    }
  }

  const soureTypeText = (numberST: number) => {
    switch (numberST) {
      case 1:
        return "Đơn hàng";
        break;
      case 2:
        return "Điều chuyển";
        break;
      case 3:
        return "Khác";
        break;
    }
  };

  function modalDeliveryAssignment() {
    modals.openConfirmModal({
      title: (
        <>
          {selectIds.length > 1 ? (
            <Title order={5}>Phân công giao nhận (nhiều đơn)</Title>
          ) : (
            <Title order={5}>Phân công giao nhận</Title>
          )}
        </>
      ),
      size: "auto",
      children: (
        <DeliveryAssignment
          selectIds={selectIds}
          dataDeliverySelect={dataDeliverySelect}
          onClose={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  const handleCreate = () => {
    setToPageCreate(!toPageCreate);
    setDeleteViewStatus((prev) => !prev);
  };

  const handleDetail = () => {
    setToPageDetail(!toPageDetail);
    setDeleteViewStatus((prev) => !prev);
  };

  const handleEdit = () => {
    setToPageEdit(!toPageEdit);
    setDeleteViewStatus((prev) => !prev);
  };

  function detailItem(id: string | number) {
    setDetailViewId(id);
    handleDetail();
  }

  function editItem(id: string | number) {
    setEditViewId(id);
    handleEdit();
  }

  const handleExport = () => {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Data");
      xlsx.writeFile(workbook, "danh-sach-don-giao-hang.xlsx");
      toast.success("Export excel thành công", { autoClose: 1500 });
    } catch (error) {
      toast.error("Export excel thất bại", { autoClose: 1500 });
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (value !== "Invalid date")
      setSearch((prevData) => ({ ...prevData, [key]: value }));
    else {
      setSearch((prevData) => ({ ...prevData, [key]: "" }));
    }
  };

  const optionColorsAccount: Record<
    | "Đã phân công"
    | "Lập đơn giao hàng"
    | "Đang giao hàng"
    | "Đã đến nơi"
    | "Đơn hàng giao chưa thành công do Khách hàng"
    | "Đơn hàng giao chưa thành công do Hacom"
    | "Hàng đã giao"
    | "Hàng đã giao - nối chuyến"
    | "Hủy phân công",
    string
  > = {
    "Đã phân công": "lime",
    "Lập đơn giao hàng": "green",
    "Đang giao hàng": "teal",
    "Đã đến nơi": "cyan",
    "Đơn hàng giao chưa thành công do Khách hàng": "blue",
    "Đơn hàng giao chưa thành công do Hacom": "indigo",
    "Hàng đã giao": "violet",
    "Hàng đã giao - nối chuyến": "grape",
    "Hủy phân công": "red",
  };

  const renderSelectOptionStatus: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorsAccount[
            option.label as
              | "Đã phân công"
              | "Lập đơn giao hàng"
              | "Đang giao hàng"
              | "Đã đến nơi"
              | "Đơn hàng giao chưa thành công do Khách hàng"
              | "Đơn hàng giao chưa thành công do Hacom"
              | "Hàng đã giao"
              | "Hàng đã giao - nối chuyến"
              | "Hủy phân công"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const optionColorSourceType: Record<
    "Đơn hàng" | "Điều chuyển" | "Khác",
    string
  > = {
    "Đơn hàng": "#E2E2B6",
    "Điều chuyển": "#6EACDA",
    Khác: "#03346E",
  };

  const renderSelectOptionSourceType: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={
          optionColorSourceType[
            option.label as "Đơn hàng" | "Điều chuyển" | "Khác"
          ]
        }
        radius="sm"
        variant="filled"
      >
        {option.label}
      </Badge>
      {checked && (
        <IconCheck size={"14px"} style={{ marginInlineStart: "auto" }} />
      )}
    </Group>
  );

  const deleteData = async (ids: (string | number)[]) => {
    if (ids.length < 1) NotificationExtension.Warn("Bạn chưa chọn dữ liệu !");
    else
      try {
        modals.openConfirmModal({
          title: (
            <>
              <Title order={5}>Xóa đơn hàng!</Title>
            </>
          ),
          size: "550px",
          children: <DeleteView idItems={ids} onClose={setDeleteViewStatus} />,
          confirmProps: { display: "none" },
          cancelProps: { display: "none" },
        });
      } catch (error) {
        console.error(error);
        return;
      }
  };

  const dataDeliveryStatusSelect = async () => {
    try {
      const getData = await getDeliveryStatusSelectMdm();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDeliveryStatusSelect(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  const dataDeliveryDepartmentSelect = async () => {
    try {
      const getData = await getDeliveryDepartmentSelect();
      const filteredData = getData?.filter(
        (item) => item.value != null && item.text != null
      );
      const mappedData = filteredData?.map((item) => ({
        value: item.value,
        label: item.text,
      }));
      setDataTblDeliveryDepartment(mappedData);
    } catch (error) {
      console.error("Error in dataDeliveryStatusSelect: ", error);
    }
  };

  // async function getCustomerByListIds(ids: number[]) {
  //   if (ids.length > 0) {
  //     let url = "/api/v1/TblDmCustomer/get-customer-by-list-id";
  //     url += `?${ids
  //       ?.filter((id) => id !== -1)
  //       .map((id) => `ids=${id}`)
  //       .join("&")}`;
  //     const callapi = await repositoryPos.get<MessageResponse<TblDmCustomer[]>>(
  //       url
  //     );
  //     if (callapi?.success) return callapi?.data;
  //   }
  //   return [];
  // }

  // const dataCustomerByListIds = async (ids: []) => {
  //   try {
  //     const getData = await getCustomerByListIds(ids);
  //     const filteredData = getData?.filter(
  //       (item) => item.id != null && item.name != null
  //     );
  //     const mappedData = filteredData?.map((item) => ({
  //       value: item.id.toString(),
  //       label: item.name ?? "",
  //     }));
  //     setDataTblCustomer(mappedData);
  //   } catch (error) {
  //     console.error("Error in dataDeliveryStatusSelect: ", error);
  //   }
  // };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelectMdm();
    setDataBranchSelect(
      getData
        .filter(
          (item) =>
            item.value != null && item.text != null && item.value !== "-1"
        )
        .map((item) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  // const fetchDataCustomer = async () => {
  //   let url = `?Skip=0&Take=${100}`;

  //   if (customerId) {
  //     url += `&KeySearch=${customerId.trim()}`;
  //   }

  //   try {
  //     const response = await repositoryPos.get(
  //       `api/v1/TblDmCustomer/get-customer-for-delivery${url}`
  //     );
  //     if (response && response.success) {
  //       const filteredData = response.data.lists.filter(
  //         (customer: any) =>
  //           customer.customerCode !== null || customer.customerName !== null
  //       );
  //       const mappedData = filteredData.map((item: any) => ({
  //         value: item.customerId.toString(),
  //         label: item.customerName,
  //       }));
  //       setDataCustomer(mappedData);
  //     }
  //   } catch (error) {
  //     setDataCustomer([]);
  //     return;
  //   }
  // };

  useHotkeys([
    [
      "F11",
      () => {
        handleCreate();
      },
    ],
  ]);

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    setData([]);
    setRowCount(0);

    let url = `?Skip=${pagination?.pageIndex * pagination?.pageSize}&Take=${
      pagination.pageSize
    }`;

    if (search.startDate) {
      url += `&StartDate=${search.startDate}`;
    }

    if (search.endDate) {
      url += `&EndDate=${search.endDate}`;
    }

    if (search.deliveryDepartmentId) {
      url += `&DeliveryDepartmentId=${search.deliveryDepartmentId}`;
    }

    if (search.statusId) {
      url += `&StatusId=${search.statusId}`;
    }

    if (search.branchId) {
      url += `&BranchId=${search.branchId}`;
    }

    if (search.customerId) {
      url += `&CustomerId=${search.customerId}`;
    }

    if (search.sourceType) {
      url += `&SourceType=${search.sourceType}`;
    }

    if (search.sourceCode) {
      url += `&SourceCode=${search.sourceCode}`;
    }

    if (search.transactionNumber) {
      url += `&TransactionNumber=${search.transactionNumber}`;
    }

    if (keySearch.length > 0) {
      url += `&KeySearch=${keySearch}`;
    }

    try {
      const response = await repositoryDelivery.get(
        `/api/v1/TblDelivery/get-list${url}`
      );
      if (response && response.success) {
        let result = response.data;
        setData(result);
        setDataTemp(result);
        setRowCount(response.totalCount ?? 0);
        setSelectIds([]);
        table.resetRowSelection();
      }
    } catch (error) {
      setData([]);
      setIsError(true);
      console.error(error);
      return;
    } finally {
      if (dataTblDeliveryStatusSelect) setIsLoading(false);
      setIsRefetching(false);
    }
    startCountdown();
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval>;
    if (isCounting && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [isCounting, timeLeft]);

  useEffect(() => {
    const valuesList = Object.keys(rowSelection);
    setSelectIds(valuesList);
    if (valuesList.length < 1) setSelectIds([]);
    else {
      const valuesList = Object.keys(rowSelection);
      setSelectIds(valuesList);
    }
  }, [rowSelection]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 190 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (170 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [pagination, deleteViewStatus]);

  useEffect(() => {
    const numericIds = selectIds.map((id) => Number(id));

    const filtered = data.filter((item) =>
      numericIds.includes(Number(item.id))
    );

    setDataDeliverySelect(filtered);
  }, [selectIds, data]);

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => (
      <div ref={headerRef} style={{ width: "100%" }}>
        <Flex wrap={"wrap"} justify={"space-between"}>
          <_breadcrumb></_breadcrumb>
          <Flex
            gap="md"
            justify={{ sm: "flex-end", lg: "flex-end" }}
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Button
              leftSection={<IconPlus size={14} />}
              color="blue"
              variant="outline"
              onClick={() => {
                handleCreate();
              }}
            >
              Tạo mới (F11)
            </Button>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  //
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  color="violet"
                >
                  Phân công giao hàng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Phân công giao hàng</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconTruck style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={() => modalDeliveryAssignment()}
                  disabled={selectIds.length < 1}
                >
                  Phân công giao hàng
                </Menu.Item>
                <Menu.Item
                  leftSection={
                    <IconX style={{ width: rem(14), height: rem(14) }} />
                  }
                >
                  Đóng
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  // size="xs"
                  rightSection={
                    <IconCaretDown
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                >
                  Chức năng
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Application</Menu.Label>
                <Menu.Item
                  leftSection={
                    <IconFileExport
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={handleExport}
                >
                  Export Excel
                </Menu.Item>
                <Tooltip label={"Chức năng đang update"}>
                  <Menu.Item
                    leftSection={
                      <IconFileUpload
                        style={{ width: rem(14), height: rem(14) }}
                      />
                    }
                  >
                    Import Excel
                  </Menu.Item>
                </Tooltip>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Flex gap={"md"} align={"center"} wrap={"wrap"} mt={10}>
          <Grid gutter={"xs"} w={"100%"}>
            <Grid.Col span={{ base: 12, md: 12, lg: 2.4 }}>
              <DatePickerInput
                type="range"
                size="sm"
                placeholder="Từ ngày - Đến ngày"
                locale="vi"
                valueFormat="DD/MM/YYYY"
                onChange={(e) => {
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[0]) ?? "",
                    "startDate"
                  );
                  handleChangeSearchValue(
                    formatDateNotTimeZone(e[1]) ?? "",
                    "endDate"
                  );
                }}
                leftSection={<IconCalendar color="#15aabf" />}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Bộ phận giao vận"
                data={dataTblDeliveryDepartment}
                leftSection={<IconTruckDelivery color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(
                    e?.toString() ?? "",
                    "deliveryDepartmentId"
                  );
                }}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                onClick={() => {
                  if (dataTblDeliveryDepartment.length === 0) {
                    dataDeliveryDepartmentSelect();
                  }
                }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Chi nhánh - Địa điểm nhận hàng"
                data={dataBranchSelect}
                leftSection={<IconBrandBootstrap color="#15aabf" />}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
                onChange={(e) => {
                  handleChangeSearchValue(e?.toString() ?? "", "branchId");
                }}
                onClick={() => {
                  if (dataBranchSelect.length === 0) {
                    fetchDataBranchSelect();
                  }
                }}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Nguồn hàng"
                data={[
                  { label: "Đơn hàng", value: "1" },
                  { label: "Điều chuyển", value: "2" },
                  { label: "Khác", value: "3" },
                ]}
                leftSection={<IconPackageExport color="#15aabf" />}
                onChange={(e) =>
                  handleChangeSearchValue(e?.toString() ?? "", "sourceType")
                }
                clearable
                renderOption={renderSelectOptionSourceType}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <Select
                placeholder="Trạng thái"
                clearable
                data={dataTblDeliveryStatusSelect}
                leftSection={<IconStatusChange color="#15aabf" />}
                onChange={(e) => {
                  handleChangeSearchValue(e?.toString() ?? "", "statusId");
                }}
                onClick={() => {
                  if (dataTblDeliveryStatusSelect.length === 0) {
                    dataDeliveryStatusSelect();
                  }
                }}
                renderOption={renderSelectOptionStatus}
                comboboxProps={{ width: 400 }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2.4 }}>
              <TextInput
                placeholder="Nhập từ khóa"
                defaultValue={keySearch}
                leftSection={<IconSearch color="#15aabf" />}
                onChange={(e) => {
                  setKeySearch(e.currentTarget.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1 }}>
              <Button
                leftSection={<IconSearch size={14} />}
                color="blue"
                variant="outline"
                onClick={async () => {
                  await fetchData();
                }}
                w={"100%"}
              >
                Tìm kiếm
              </Button>
            </Grid.Col>
          </Grid>
        </Flex>
      </div>
    ),
    renderToolbarInternalActions: ({ table }) => <></>,
    enableExpandAll: false,
    renderDetailPanel: ({ row }) => (
      <>
        <DetailProduct
          id={row.original.id}
          sourceType={row.original.sourceType}
          sourceCode={row.original.sourceCode}
          isDetail={false}
        />
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    enableRowSelection: (row) =>
      row.original.deliveryStatusName === "Lập đơn giao hàng" ||
      row.original.deliveryStatusName === "Hủy phân công",
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "transactionNumber"],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    onRowSelectionChange: setRowSelection,
    manualFiltering: false,
    manualPagination: true,
    manualSorting: false,
    rowCount,
    onPaginationChange: setPagination,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "500",
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: false
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {toPageDetail ? (
        <DetailDataView id={detailViewId} handleDetail={handleDetail} />
      ) : toPageEdit && editViewId ? (
        <EditDataView id={editViewId} handleEdit={handleEdit} />
      ) : toPageCreate ? (
        <CreateDataView handleCreate={handleCreate} />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default Delivery;
