import React from "react";

interface TblPaymentReceipt {
  id: number;
  receiptNumber?: string | null;
  createdDate?: string | null;
  totalAmount?: string | null;
  creatorfullname?: string | null;
  creatordepartment?: number | null;
  creatorbranch?: string | null;
  creatorworkplace?: string | null;
  creatorsalesbranch?: string | null;
  payerfullname?: string | null;
  payertype: string;
  payerdescription?: string | null;
  payernote?: string | null;
  codeCustomer?: string | null;
  createby?: number | null;
  type?: number | null;
  funfullname?: string | null;
  fundbranch?: string | null;
  fundworkplace?: string | null;
  funddepartment?: string | null;
  fundsalesbranch?: string | null;
  accountfund?: string | null | number;
  codefund?: string | null;
  phoneNumber?: string | null;
  typeReceipt?: number | null;
  numberAdjust?: string | null;
  isAdjust?: number | null;
  lastUpdate?: string | null;
  anotherName?: string | null;
  lastUpdateBy?: string | null;
  codecustomer?: string | null;
  anothername?: string | null;
  phonenumber?: string | null;
  completiontime?: string | null;
  status?: string | null;
  bankname?: string | null;
  accountnumber?: string | null;
  createrolename?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
}

type OrderReceiptProps = {
  datafetch: TblPaymentReceipt;
};

export const OrderReceipt: React.FC<OrderReceiptProps> = ({ datafetch }) => {
  return <div>11111</div>;
};
