import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Input,
  NumberFormatter,
  Select,
  SelectProps,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconBrandVisa,
  IconCheck,
  IconCoin,
  IconDownload,
  IconEdit,
  IconEye,
  IconMenuOrder,
  IconPlus,
  IconSearch,
  IconSquareXFilled,
  IconStatusChange,
  IconTransformFilled,
  IconUserCode,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";

import { DatePickerInput } from "@mantine/dates";
import { modals } from "@mantine/modals";
import {
  IconBrandBootstrap,
  IconReceiptRefund,
  IconTimeDuration15,
} from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { repositoryPos } from "../../_base/_const/_constVar";
import _breadcrumb from "../../_base/component/_layout/_breadcrumb";
import { SocketExtension } from "../../_base/socket/socket";
import { CREATEORDERSUCCESS } from "../../_base/socket/ToplicList";
import { AvatarUtils } from "../../common/ColorByName/AvatarUtils";
import { formatDateTransfer } from "../../common/FormatDate/FormatDate";
import { _localization } from "../../config/location";
import { green, olive_green, sky_blue } from "../../const/variables";
import { MessageResponse } from "../../model/MessageResponse";
import { OrderDetail } from "../../model/SaleOrderModel";
import { SelectListItem } from "../../model/SelectListItem";
import SalesDeleteOrder from "./SalesDeleteOrder";

const SalesOrderView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //#region  table

  //data and fetching state
  const [data, setData] = useState<any[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [dataBranch, setDataBranch] = useState<SelectListItem[]>([]);
  const dataChannel = [
    {
      text: "Lazada",
      value: "304",
    },
    {
      text: "Tiktok",
      value: "341",
    },
    {
      text: "Chat Zalo",
      value: "343",
    },
    {
      text: "Facebook",
      value: "62",
    },
    {
      text: "Shopee Mall",
      value: "63",
    },
    {
      text: "Shopee",
      value: "342",
    },
    {
      text: "Tổng đài",
      value: "66",
    },
    {
      text: "Khách đến showroom",
      value: "67",
    },
    {
      text: "Khách đặt đơn Website",
      value: "68",
    },
    {
      text: "Khách gọi điện cho nhân viên",
      value: "69",
    },
    {
      text: "Khách chat qua website",
      value: "101",
    },
  ];
  const [dataListCustomer, setDataListCustomer] = useState<SelectListItem[]>(
    []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [deleteViewStatus, setDeleteViewStatus] = useState(false);

  //table state
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [height, setHeight] = useState(0);
  const debounceTimeoutRef = React.useRef<number | null>(null);
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [search, setSearch] = useState({
    invId: "",
    invOutwardId: "",
    customerId: "",
    startDate: "",
    endDate: "",
    orderStatus: "",
    orderType: "",
    orderNumber: "",
    paymentStatus: "",
    industry: "",
    keySearch: "",
    OutwardStatus: "",
  });

  const sizeGrid = { base: 6, sm: 4, md: 3, lg: 3, xl: 2 };

  const handleCheckPayment = async (row: any) => {
    if (row.original.orderType == "3") {
      // viết mới logic thanh toán cho đơn hàng trả
      const url = `/api/v1/CreateSellItem/payment-return?orderNumber=${row.original.orderNumber}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/return-order", {
          state: {
            id: row.original.id,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "payment",
            action: "tra",
          },
        });
      }
    } else {
      const url = `/api/v1/CreateSellItem/payment?orderNumber=${row.original.orderNumber}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);
      if (response && response.success) {
        navigate("/sell/payment-order", {
          state: {
            id: row.original.id,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "payment",
          },
        });
      }
    }
  };

  const handleCheckApproved = async (row: any) => {
    const url = `/api/v1/CreateSellItem/approve?orderNumber=${row.original.orderNumber}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);
    if (response && response.success) {
      navigate("/sell/approved-discount-order", {
        state: {
          orderNumber: row.original.orderNumber,
          orderType: row.original.orderType,
          actionType: "approved",
        },
      });
    }
  };

  const handleCheckEdit = async (row: any) => {
    const url = `/api/v1/CreateSellItem/edit?orderNumber=${row.original.orderNumber}`;
    const response = await repositoryPos.get<MessageResponse<any>>(url);

    if (response && response.success) {
      navigate("/sell/revise-order", {
        state: {
          orderNumber: row.original.orderNumber,
          orderType: row.original.orderType,
          actionType: "edit",
        },
      });
    }
  };

  const handleCheckView = async (row: any) => {
    if (row.original.orderType == "3") {
      const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.orderNumber}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/return-order", {
          state: {
            id: row.original.id,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "view",
            action: "tra",
          },
        });
      }
    } else {
      const url = `/api/v1/CreateSellItem/details?orderNumber=${row.original.orderNumber}`;
      const response = await repositoryPos.get<MessageResponse<any>>(url);

      if (response && response.success) {
        navigate("/sell/view-order", {
          state: {
            id: row.original.id,
            orderNumber: row.original.orderNumber,
            orderType: row.original.orderType,
            actionType: "view",
          },
        });
      }
    }
  };

  const getTextStatus = (row: any) => {
    return orderStatuses.find(
      (status) => status.value === row.original.orderStatus
    )?.label;
  };

  const getShippingVendorText = (shippingVendor: string) => {
    switch (shippingVendor) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "HACOM":
        return "Nội bộ";
      default:
        return shippingVendor;
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);
    try {
      const queryParams = {
        Skip: pagination.pageIndex * pagination.pageSize,
        Take: pagination.pageSize,
        InvId: search.invId,
        InvOutwardId: search.invOutwardId,
        CustomerId: search.customerId,
        StartDate: search.startDate,
        EndDate: search.endDate,
        OrderStatus: search.orderStatus,
        OrderType: search.orderType ? Number.parseInt(search.orderType) : 0,
        OrderNumber: search.orderNumber,
        PaymentStatus: search.paymentStatus,
        industry: search.industry,
        OutwardStatus: search?.OutwardStatus,
        KeySearch: search.keySearch,
      };

      const url =
        location?.pathname === "/sell/sales-order-list-return"
          ? Object.entries(queryParams)
              .reduce((acc, [key, value]) => {
                if (value !== undefined && value !== "") {
                  return `${acc}&${key}=${encodeURIComponent(value)}`;
                }
                return acc;
              }, "/api/v1/CreateSellItem/get-list-return-sale?")
              .replace("&", "")
          : Object.entries(queryParams)
              .reduce((acc, [key, value]) => {
                if (value !== undefined && value !== "") {
                  return `${acc}&${key}=${encodeURIComponent(value)}`;
                }
                return acc;
              }, "/api/v1/CreateSellItem/get-list?")
              .replace("&", "");

      const response = await repositoryPos.get<MessageResponse<any[]>>(url);

      if (response && response.success) {
        setData(response.data);
        setRowCount(response.totalCount);
        table.resetRowSelection();
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
    }
  };

  const handleChangeSearchValue = (value: string, key: string) => {
    if (key === "customerId") {
      setSelectedCustomer(value);
    }
    if (key === "OutwardStatus") {
      setSearch((prevData) => ({
        ...prevData,
        [key]: value,
        orderStatus: "DON_HANG_HOAN_THANH",
      }));
    }
    setSearch((prevData) => ({ ...prevData, [key]: value }));
  };

  //hàm hủy đơn hàng
  const DeleteCancelOrder = (value: any, status: any) => {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Hủy đơn hàng!</Title>
        </>
      ),
      size: "auto",
      children: (
        <SalesDeleteOrder
          idItems={value}
          status={status}
          onSearch={fetchData}
          onClose={setDeleteViewStatus}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  };

  const orderTypes = [
    { value: "1", label: "Online", color: "blue" },
    { value: "2", label: "Bán lẻ", color: "indigo" },
    { value: "3", label: "Đơn đổi trả", color: "green" },
  ];

  const orderStatuses = [
    { value: "DON_HANG_TRANG", label: "Đơn hàng trống" },
    { value: "DON_HANG_CHOT", label: "Đơn hàng chốt" },
    { value: "DON_HANG_CHUA_LUU", label: "Đơn hàng chưa lưu" },
    { value: "DON_HANG_TAM", label: "Đơn hàng tạm" },
    { value: "DON_HANG_DOI_TRA_TAM", label: "Đơn đổi trả tạm" },
    { value: "DUYET_GIAM_GIA_TIEP_NHAN", label: "Duyệt giảm giá tiếp nhận" },
    { value: "DUYET_GIAM_GIA_XONG", label: "Duyệt giảm giá xong" },
    { value: "DON_HANG_HOAN_THANH", label: "Đơn hàng hoàn thành" },
    { value: "KHO_XUAT_HANG", label: "Kho xuất hàng" },
    { value: "KY_THUAT_TIEP_NHAN", label: "Kỹ thuật tiếp nhận" },
    { value: "KY_THUAT_XONG", label: "Kỹ thuật xong" },
    { value: "DON_HANG_HUY", label: "Đơn hàng hủy" },
    { value: "DON_HANG_THANH_CONG", label: "Đơn hàng thành công" },
    { value: "GHNB_TIEP_NHAN", label: "GHNB tiếp nhận" },
    { value: "DHNB_XONG", label: "DHNB xong" },
    { value: "GHNB_THAT_BAI_L1", label: "GHNB thất bại lần 1" },
    { value: "DHNB_THAT_BAI", label: "DHNB thất bại" },
    { value: "DON_HANG_DOI_TRA", label: "Đơn đổi trả" },
    { value: "DON_HANG_CHO_DUYET_GIAM", label: "Đơn chờ duyệt giảm" },
    { value: "DON_HANG_DA_DUYET_GIAM", label: "Đơn đã duyệt giảm" },
  ];

  const warehouseStatus = [
    { value: "DA_YEU_CAU", label: "Đã yêu cầu" },
    { value: "DANG_XUAT", label: "Đang xuất" },
    { value: "DA_XUAT", label: "Đã xuất" },
    { value: "DANG_NHAP", label: "Đang nhập" },
    { value: "DA_NHAP", label: "Đã nhập" },
  ];

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        accessorKey: "STT",
        Cell: ({ row }) => <>{row?.index + 1}</>,
        size: 1,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnFilters: false,
      },
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, row }) => (
          <Tooltip label="Xem chi tiết">
            <Badge
              radius="sm"
              variant="dot"
              size="lg"
              style={{ cursor: "pointer" }}
              color={renderedCellValue === null ? "red" : green.base}
              onDoubleClick={() => handleCheckView(row)}
            >
              {renderedCellValue === null ? "KHÔNG HỢP LỆ" : renderedCellValue}
            </Badge>
          </Tooltip>
        ),
      },
      {
        accessorKey: "orderStatus",
        header: "Trạng thái đơn hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const orderStatus = row.original.orderStatus;
          let badgeColor;
          switch (orderStatus) {
            case "DON_HANG_TAM":
              badgeColor = "cyan";
              break;
            case "DON_HANG_CHOT":
              badgeColor = "violet";
              break;
            case "DON_HANG_DOI_TRA_TAM":
              badgeColor = "cyan";
              break;
            case "DON_HANG_HOAN_THANH":
              badgeColor = "green";
              break;
            case "DON_HANG_DOI_TRA":
              badgeColor = "green";
              break;
            case "THANH_TOAN_CHUA_HOAN_THANH":
              badgeColor = "red";
              break;
            case "pending":
              badgeColor = "yellow";
              break;
            case "DON_HANG_BAN_TAI_SHOP":
              badgeColor = "green";
              break;
            case "DON_HANG_CHO_DUYET_GIAM":
              badgeColor = olive_green.light;
              break;
            case "DON_HANG_DA_DUYET_GIAM":
              badgeColor = olive_green.medium;
              break;
            case "KHO_XUAT_HANG":
              badgeColor = sky_blue.medium;
              break;
            default:
              badgeColor = "gray";
          }
          return (
            <Badge color={badgeColor} fs={"12,5px"} fw={"bold"} radius="sm">
              {row.original.orderStatus !== null
                ? getTextStatus(row)
                : "Không HỢP LỆ"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "attribute3",
        header: "Trạng thái kho",
        Cell: ({ renderedCellValue, row }) => {
          const _status = warehouseStatus.find(
            (status) => status.value === row?.original?.attribute3?.toString()
          );

          return (
            <Badge size="sm" color="cyan">
              {_status?.label ? _status.label : "Chưa yêu cầu"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "orderType",
        header: "Loại đơn hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          const orderType = orderTypes.find(
            (type) => type.value === row.original.orderType?.toString()
          );

          return (
            <Badge color={orderType?.color || "gray"}>
              {orderType?.label || "KHÔNG HỢP LỆ"}
            </Badge>
          );
        },
      },
      {
        accessorKey: "saleChannelId",
        header: "Kênh bán",
        Cell: ({ renderedCellValue }) => {
          const saleChannel = dataChannel.find(
            (channel) => channel.value === renderedCellValue?.toString()
          );
          return saleChannel?.text || "KHÔNG HỢP LỆ";
        },
      },
      {
        accessorKey: "invOrgName",
        header: "Kho hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row, column }) => (
          <>{`[${row.original.invOrgCode}] ${row.original.invOrgName}`}</>
        ),
      },
      {
        accessorKey: "sale",
        header: "Nhân viên bán hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => (
          <>
            <AvatarUtils
              value={renderedCellValue?.toString().split("-")[0] ?? ""}
            />
          </>
        ),
      },
      {
        accessorKey: "creator",
        header: "Người lập",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }) => (
          <>
            <AvatarUtils
              value={renderedCellValue?.toString().split("-")[0] ?? ""}
            />
          </>
        ),
      },
      {
        accessorKey: "creationDate",
        header: "Ngày lập",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },
      {
        accessorKey: "orderDate",
        header: "Ngày hoàn thành",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue }: any) => {
          if (
            typeof renderedCellValue === "string" ||
            renderedCellValue instanceof Date
          ) {
            return <>{formatDateTransfer(renderedCellValue)}</>;
          }
          return <>{formatDateTransfer(undefined)}</>;
        },
      },
      {
        accessorKey: "address",
        header: "Địa chỉ",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "technicalChecked",
        header: "Dịch vụ kiểm tra",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            {
              <>
                {(() => {
                  if (row.original.technicalChecked === 0) {
                    return <Badge color="indigo">Không yêu cầu</Badge>;
                  } else if (row.original.technicalChecked === 1) {
                    return <Badge color="grape">Lắp ráp(mới)</Badge>;
                  } else if (row.original.technicalChecked === 2) {
                    return <Badge color="cyan">Kiểm tra(mới)</Badge>;
                  } else if (row.original.technicalChecked === 3) {
                    return (
                      <Badge color={sky_blue.medium}>
                        Lắp ráp(mới)+Kiểm tra(mới)
                      </Badge>
                    );
                  } else {
                    return null; // Or any fallback UI if needed
                  }
                })()}
              </>
            }
          </>
        ),
      },

      {
        accessorKey: "attribute11",
        header: "KT đã kiểm tra",
        Cell: ({ row }) => (
          <Badge color="blue">
            {row?.original?.attribute11
              ? row?.original?.attribute11
              : "Khách không yêu cầu kiểm tra"}
          </Badge>
        ),
      },
      {
        accessorKey: "shippingStatus",
        header: "Trạng thái giao vận",
        Cell: ({ renderedCellValue, row }) => (
          <Badge size="sm" color="cyan">
            Chưa thực hiện
          </Badge>
        ),
      },
      {
        accessorKey: "shipingVendor",
        header: "Bộ phận giao hàng",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row, column }) => (
          <Text fs={"12,5px"} fw={"bold"}>
            {getShippingVendorText(row?.original?.shipingVendor ?? "")}
          </Text>
        ),
      },
      {
        accessorKey: "customerPhone",
        header: "Điện thoại KH",
        enableColumnActions: false,
        enableColumnFilter: false,
        // Cell: ({ renderedCellValue, cell }) => (
        //   <Badge radius="xs" variant="light" leftSection={<IconPhoneCall />}>
        //     {renderedCellValue}
        //   </Badge>
        // ),
      },
      // {
      //   accessorKey: "htttPriority",
      //   header: "HTTT dự kiến",
      // },
      {
        accessorKey: "amount",
        header: "Số tiền",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },
      {
        accessorKey: "paid",
        header: "Đã trả",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "remaining",
        header: "Còn nợ",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix=" ₫"
            thousandSeparator=","
          />
        ),
      },

      {
        accessorKey: "printInvoiceCheck",
        header: "In phiếu kiểm tra hóa đơn",
        enableColumnActions: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            {row.original.printInvoiceCheck === "Không xuất hóa đơn" ? (
              <Badge radius={"xs"} color="indigo">
                Không xuất hóa đơn
              </Badge>
            ) : (
              <Badge radius={"xs"} color="green">
                Xuất hóa đơn
              </Badge>
            )}
          </>
        ),
      },
      {
        accessorKey: "lastEditor",
        header: "Người chỉnh sửa cuối cùng",
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action",
        header: "Thao tác",
        enableColumnActions: false,
        enableColumnFilter: false,
        size: 50,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Tooltip label="Chi tiết">
              <ActionIcon variant="light" aria-label="Settings" color="cyan">
                <IconEye size={20} onClick={() => handleCheckView(row)} />
              </ActionIcon>
            </Tooltip>

            {location.pathname == "/sell/sales-order-list-return" &&
              row.original?.orderStatus === "DON_HANG_HOAN_THANH" &&
              !row?.original?.t9 && (
                <>
                  <Tooltip label="Trả hàng">
                    <ActionIcon
                      variant="light"
                      aria-label="Settings"
                      color="red"
                    >
                      <IconTransformFilled
                        size={20}
                        onClick={() =>
                          navigate("/sell/return-order", {
                            state: {
                              orderNumber: row.original.orderNumber,
                              actionType: "return",
                              action: "tra",
                            },
                          })
                        }
                      />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}

            {location.pathname == "/sell/sales-order-list-return" &&
              row.original?.orderStatus === "DON_HANG_HOAN_THANH" &&
              row.original?.t9 && (
                <>
                  <Tooltip label="Đổi hàng">
                    <ActionIcon
                      variant="light"
                      aria-label="Settings"
                      color="lime"
                    >
                      <IconReceiptRefund
                        size={20}
                        onClick={() =>
                          navigate("/sell/exchange-order", {
                            state: {
                              orderNumberOld: row.original.orderNumber,
                              actionType: "doi",
                            },
                          })
                        }
                      />
                    </ActionIcon>
                  </Tooltip>
                </>
              )}

            {row.original.orderStatus === "DON_HANG_HOAN_THANH" ? (
              <>
                {/* <Tooltip label="Xuất kho">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="violet"
                  >
                    <IconBuildingWarehouse
                      size={20}
                      onClick={() =>
                        navigate(
                          "/warehouse-config/create-warehouse-out-ward",
                          {
                            state: {
                              sourceCode: row.original.orderNumber,
                              sourceType: "XUAT_BAN",
                            },
                          }
                        )
                      }
                    />
                  </ActionIcon>
                </Tooltip> */}
                {/* <Tooltip label="Chi tiết">
                  <ActionIcon
                    variant="light"
                    aria-label="Settings"
                    color="cyan"
                    onClick={async () => {
                      detailItem(row.original.orderNumber);
                    }}
                  >
                    <IconEye size={20} stroke={1.5} />
                  </ActionIcon>
                </Tooltip> */}
              </>
            ) : (
              <>
                {row.original.orderStatus !== "DON_HANG_HUY" &&
                row.original.orderStatus !== "KHO_XUAT_HANG" &&
                row.original.orderStatus !== "DON_HANG_DOI_TRA" &&
                row.original.orderStatus !== "DON_HANG_DOI_TRA_TAM" ? (
                  <>
                    {row.original.approveStatus != "1" &&
                      row.original.orderStatus !== "DON_HANG_CHOT" && (
                        <Tooltip label="Sửa đơn">
                          <ActionIcon
                            disabled={
                              row.original.orderStatus ===
                                "DON_HANG_HOAN_THANH" ||
                              row.original.orderStatus === null
                            }
                            variant="light"
                            aria-label="Settings"
                            color="yellow"
                          >
                            <IconEdit
                              size={20}
                              onClick={() => {
                                handleCheckEdit(row);
                              }}
                            />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    {(row.original.orderStatus === "DON_HANG_CHOT" ||
                      row.original.orderStatus ===
                        "DON_HANG_DA_DUYET_GIAM") && (
                      <Tooltip label="Thanh toán">
                        <ActionIcon
                          disabled={
                            row.original.orderStatus ===
                              "DON_HANG_HOAN_THANH" ||
                            row.original.orderStatus === null
                          }
                          variant="light"
                          aria-label="Settings"
                          color="green"
                        >
                          <IconBrandVisa
                            size={20}
                            onClick={() => {
                              handleCheckPayment(row);
                            }}
                          />
                        </ActionIcon>
                      </Tooltip>
                    )}
                    {(row.original.discountAdded > 0 ||
                      row.original.discountAddedTotal > 0) &&
                      row.original.approveStatus !== 1 &&
                      row.original.orderStatus ===
                        "DON_HANG_CHO_DUYET_GIAM" && (
                        <Tooltip label="Duyệt giảm">
                          <ActionIcon
                            disabled={
                              row.original.orderStatus ===
                                "DON_HANG_HOAN_THANH" ||
                              row.original.orderStatus === null
                            }
                            variant="light"
                            aria-label="Settings"
                            color="violet"
                            onClick={() => {
                              handleCheckApproved(row);
                            }}
                          >
                            <IconCoin size={20} onClick={() => {}} />
                          </ActionIcon>
                        </Tooltip>
                      )}
                  </>
                ) : null}

                {row.original.orderStatus !== "DON_HANG_HUY" &&
                row.original.orderStatus !== "KHO_XUAT_HANG" &&
                row.original.orderStatus !== "DON_HANG_DOI_TRA" ? (
                  <Tooltip
                    disabled={
                      row.original.orderStatus === "DON_HANG_HOAN_THANH"
                    }
                    label="Hủy đơn"
                  >
                    <ActionIcon
                      variant="light"
                      aria-label="Settings"
                      color="red"
                      disabled={
                        row.original.orderStatus === "DON_HANG_HOAN_THANH"
                      }
                      onClick={async () => {
                        DeleteCancelOrder(
                          row.original.orderNumber,
                          row.original.orderStatus
                        );
                      }}
                    >
                      <IconSquareXFilled size={20} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                ) : null}
              </>
            )}
          </Box>
        ),
      },
    ],
    [location.pathname]
  );

  const columnsDetail = React.useMemo<MRT_ColumnDef<OrderDetail[]>[]>(
    () => [
      {
        accessorKey: "itemCode",
        header: "Mã sản phẩm",
      },
      {
        accessorKey: "primaryUomCode",
        header: "Đơn vị tính",
      },
      {
        accessorKey: "quality",
        header: "Số lượng",
      },
      {
        accessorKey: "discountRate",
        header: "Chiết khấu (%)",
      },
      {
        accessorKey: "discountAmount",
        header: "Tiền chiết khấu",
      },
      {
        accessorKey: "discountAdded",
        header: "Giảm thêm đơn",
      },
      {
        accessorKey: "priceFinal",
        header: "Tiền sau CK",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "vatPercent",
        header: "VAT (%)",
      },
      {
        accessorKey: "vatAmount",
        header: "Tiền VAT",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "totalLineAmount",
        header: "Thành tiền",
        Cell: ({ renderedCellValue, cell }) => (
          <NumberFormatter
            value={(renderedCellValue as string | number) || 0}
            suffix="₫"
            thousandSeparator="."
            decimalSeparator=","
          />
        ),
      },
      {
        accessorKey: "lineTyle",
        header: "Loại mặt hàng",
      },
    ],
    []
  );

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const table = useMantineReactTable({
    columns,
    data,
    positionToolbarAlertBanner: "bottom",
    renderTopToolbarCustomActions: ({ table }) => <></>,

    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },
    localization: _localization,
    enableRowSelection: true,
    getRowId: (row) => row.id?.toString(),
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: [
          "STT",
          "mrt-row-select",
          "mrt-row-expand",
          "orderNumber",
          "orderStatus",
        ],
        right: ["action"],
      },
      columnVisibility: { id: false },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height, minHeight: height },
    },
    enableStickyHeader: true,
    enableExpanding: true,
    renderDetailPanel: ({ row }) => (
      <MantineReactTable
        columns={columnsDetail}
        data={row.original.orderDetail}
        mantineTableProps={{
          striped: false,
        }}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enableColumnPinning={true}
        initialState={{
          showColumnFilters: false,
          columnPinning: {
            left: ["itemCode"],
          },
          columnVisibility: { id: false },
          density: "xs",
          pagination: {
            pageSize: 50,
            pageIndex: 0,
          },
        }}
      />
    ),
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        fontWeight: "650", //conditional styling
        fontSize: "12px",
        padding: "3px 10px",
      },
    }),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    displayColumnDefOptions: {
      "mrt-row-select": {
        size: 1,
      },
      "mrt-row-expand": {
        size: 1,
      },
    },
  });

  // Hàm gọi API chi nhánh
  const fetchDataBranch = async () => {
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmInventory/get-select-branch"
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.slice(1);
      setDataBranch(filteredData);
    }
  };

  // Hàm lấy dữ liệu khách hàng

  const fetchDataCustomer = async (searchTerm: string) => {
    const query = searchTerm ? `KeySearch=${searchTerm}&` : "";
    const dataApi = await repositoryPos.get<MessageResponse<any>>(
      `/api/v1/TblDmCustomer/get-select?${query}Skip=${1}&Take=${30}`
    );

    if (dataApi && dataApi.success) {
      const filteredData = dataApi.data.filter(
        (item: any) => item.text !== null
      );
      setDataListCustomer(filteredData);
    }
  };

  const debouncedFetchDataCustomer = React.useCallback((value: string) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = window.setTimeout(() => {
      fetchDataCustomer(value);
    }, 300);
  }, []);

  useEffect(() => {
    debouncedFetchDataCustomer(searchTerm);
  }, [searchTerm, debouncedFetchDataCustomer]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (150 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    fetchData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    deleteViewStatus,
    location.pathname,
  ]);

  useEffect(() => {
    SocketExtension.On(CREATEORDERSUCCESS, (arg: any) => {
      fetchData();
    });
    return () => {
      SocketExtension.Off(CREATEORDERSUCCESS, () => {});
    };
  }, []);

  useEffect(() => {
    if (value && value.length > 0) {
      if (value[0]) {
        const startDate = value[0];
        const startDateISO = startDate
          ? new Date(
              startDate.getTime() - startDate.getTimezoneOffset() * 60000
            ).toISOString()
          : "";
        handleChangeSearchValue(startDateISO, "startDate");
      } else {
        handleChangeSearchValue("", "startDate");
      }
      if (value[1]) {
        const endDate = value[1];
        const endDateISO = endDate
          ? new Date(
              endDate.getTime() - endDate.getTimezoneOffset() * 60000
            ).toISOString()
          : "";

        handleChangeSearchValue(endDateISO, "endDate");
      } else {
        handleChangeSearchValue("", "endDate");
      }
    }
  }, [value]);

  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <Group flex="1" gap="xs">
      <Badge
        color={orderTypes.find((x) => x.value === option.value)?.color}
        radius="sm"
        variant="filled"
      ></Badge>
      {option.label}{" "}
      {checked && <IconCheck style={{ marginInlineStart: "auto" }} />}
    </Group>
  );

  return (
    <>
      <div
        style={{ border: "1px solid #dee2e6", padding: "5px" }}
        ref={headerRef}
      >
        <Flex
          gap="md"
          justify={"space-between"}
          align="center"
          direction="row"
          wrap="wrap"
          w={"100%"}
        >
          <_breadcrumb></_breadcrumb>
          <div>
            <Button
              leftSection={<IconPlus size={14} />}
              variant="outline"
              onClick={() => {
                navigate("/sell/retail-order");
              }}
              mr={"xs"}
            >
              Thêm mới (F11)
            </Button>
            <Button
              leftSection={<IconDownload size={14} />}
              color="violet"
              variant="outline"
            >
              Export Excel
            </Button>
          </div>
        </Flex>

        <Grid mt={"10px"}>
          <Grid.Col span={sizeGrid}>
            <Input
              placeholder="Từ khóa"
              type="text"
              leftSection={<IconSearch color="#15aabf" />}
              min={0}
              onChange={(e) => {
                handleChangeSearchValue(e.target.value ?? "", "keySearch");
              }}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              searchable
              clearable
              placeholder="Loại đơn hàng"
              comboboxProps={{
                transitionProps: { transition: "fade-up", duration: 200 },
              }}
              leftSection={<IconMenuOrder color="#15aabf" />}
              data={orderTypes}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "orderType");
              }}
              nothingFoundMessage="Dữ liệu không có !"
              renderOption={renderSelectOption}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              id="status"
              key={"status"}
              placeholder="Trạng thái đơn hàng"
              leftSection={<IconStatusChange color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={orderStatuses}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "orderStatus");
              }}
              searchable
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              flex={1}
              searchable
              clearable
              placeholder="Chi nhánh"
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              leftSection={<IconBrandBootstrap color="#15aabf" />}
              data={dataBranch.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onClick={async () => {
                if (!dataBranch || dataBranch.length < 1) {
                  await fetchDataBranch();
                }
                return;
              }}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "invId");
              }}
              nothingFoundMessage="Dữ liệu không có !"
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <DatePickerInput
              id="select2"
              key={"select2"}
              type="range"
              size="sm"
              leftSection={<IconTimeDuration15 color="#15aabf" />}
              placeholder="Chọn ngày"
              locale="vi"
              value={value}
              valueFormat="DD/MM/YYYY"
              onChange={(e) => setValue(e)}
              clearable
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Select
              placeholder="Khách hàng"
              id="select1"
              key={"select1"}
              searchable
              clearable
              value={selectedCustomer}
              leftSection={<IconUserCode color="#15aabf" />}
              comboboxProps={{
                transitionProps: { transition: "pop", duration: 200 },
              }}
              data={dataListCustomer.map((item) => {
                return {
                  label: item.text ?? "",
                  value: String(item.value ?? ""),
                };
              })}
              onChange={(value) => {
                handleChangeSearchValue(value ?? "", "customerId");
              }}
              searchValue={searchTerm}
              onSearchChange={handleSearchChange}
            />
          </Grid.Col>
          <Grid.Col span={sizeGrid}>
            <Button
              leftSection={<IconSearch size={14} />}
              color="blue"
              variant="outline"
              onClick={async () => {
                await fetchData();
              }}
            >
              Tìm kiếm
            </Button>
          </Grid.Col>
        </Grid>
      </div>
      <div className="mt-5" style={{ marginTop: "10px" }}>
        <MantineReactTable table={table} />
      </div>
    </>
  );
};

export default SalesOrderView;
