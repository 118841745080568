import { BarChart, LineChart } from "@mantine/charts";
import { Grid } from "@mantine/core";
import { Text } from "@mantine/core";
import { MessageResponse } from "../../model/MessageResponse";
import { repositoryPos } from "../../_base/_const/_constVar";
import { useEffect, useState } from "react";
import { ChartModelPosModel } from "../../model/ChartModelPosModel";

const HomeView = () => {
  const [dataSale, setDataSale] = useState<ChartModelPosModel>();

  const getDataSaleOrder = async () => {
    try {
      const res = await repositoryPos.get<MessageResponse<ChartModelPosModel>>(
        "/api/v1/CreateSellItem/get-chart-sale"
      );
      if (res && res.success && res.data) {
        setDataSale(res.data);
      }
    } catch (error) {
      console.error(error);
      return;
    }
  };
  useEffect(() => {
    getDataSaleOrder();
  }, []);
  return (
    <>
      <Grid h={"100vh"} mt={"xs"}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách đơn hàng theo tháng
          </Text>
          <BarChart
            h={300}
            yAxisProps={{ tickMargin: 15, orientation: "right" }}
            data={dataSale?.saleOrder ?? []}
            dataKey="month"
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            withBarValueLabel
            series={[
              { name: "onlineOrders", color: "violet.6", label: "Đơn Online" },
              //  { name: "officeOrders", color: "blue.6" },
              { name: "officeOrders", color: "teal.6", label: "Đơn Office" },
              { name: "returnOrders", color: "cyan.6", label: "Đơn đổi trả" },
            ]}
            tickLine="x"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 1 }}></Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách tổng tiền
          </Text>
          <LineChart
            h={300}
            data={dataSale?.saleOrderAmount ?? []}
            dataKey="month"
            series={[
              {
                name: "amountTotal",
                color: "indigo.6",
                label: "Tổng tiền thanh toán",
              },
              {
                name: "amountPaid",
                color: "blue.6",
                label: "Tổng tiền đã trả",
              },
              {
                name: "amountDiscount",
                color: "teal.6",
                label: "Tổng tiền chiết khấu",
              },
            ]}
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            // type="gradient"
            // gradientStops={[
            //   { offset: 0, color: "red.6" },
            //   { offset: 20, color: "orange.6" },
            //   { offset: 40, color: "yellow.5" },
            //   { offset: 70, color: "lime.5" },
            //   { offset: 80, color: "cyan.5" },
            //   { offset: 100, color: "blue.5" },
            // ]}
            // strokeWidth={5}
            curveType="linear"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách nhân viên xuất sắc
          </Text>
          <BarChart
            h={300}
            data={dataSale?.employeeList ?? []}
            dataKey="employeeName"
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            yAxisProps={{ tickMargin: 15, orientation: "right" }}
            withBarValueLabel
            series={[
              {
                name: "totalRevenue",
                color: "violet.6",
                label: "Tổng tiền bán được",
              },
              //  { name: "officeOrders", color: "blue.6" },
              //  { name: "officeOrders", color: "teal.6", label: "Đơn Office" },
            ]}
            tickLine="x"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 1 }}></Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }}>
          <Text fw={"bold"} mb="md" pl="md">
            Danh sách kho bán nhiều nhất
          </Text>
          <BarChart
            h={300}
            data={dataSale?.inventoryList ?? []}
            dataKey="warehouseName"
            valueFormatter={(value: any) =>
              new Intl.NumberFormat("en-US").format(value)
            }
            // yAxisProps={{ tickMargin: 15, orientation: "right" }}
            withBarValueLabel
            series={[
              {
                name: "totalOrders",
                color: "teal.6",
                label: "Tổng tiền bán được",
              },
              //  { name: "officeOrders", color: "blue.6" },
              //  { name: "officeOrders", color: "teal.6", label: "Đơn Office" },
            ]}
            tickLine="x"
            legendProps={{ verticalAlign: "top", height: 50 }}
            withLegend
          />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default HomeView;
