import { Flex } from "@mantine/core";
import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
import { forwardRef } from "react";
import QRCode from "react-qr-code";
import styles from "./style.module.css";

interface PrintedInvoiceProps {
  invoiceData: any;
  valueQRCode: any;
}
type Order = {
  orderId: number;
  orderNumber: string;
  createDate: string;
  casherCode: string | null;
  casherName: string | null;
  custCode: string;
  custName: string;
  telephoneNumber: string;
  amountDiscount: number;
  amountVoucher: number | null;
  pointExchangeAmout: number | null;
  amountTotal: number;
  payAmount: number | null;
  cashAmount: number | null;
  custAmountTransfer: number | null;
  cardAmount: number | null;
  codAmount: number | null;
  paymentMethod: string | null;
  discountAdded: number | null;
  discountAddedTotal: number;
  approveDate: string | null;
  approveStatus: string | null;
  orderStatus: string;
  items: [];
  invoiceNumber?: string; // Mã số mua hàng
  SalerCode?: string; // Mã nhân viên bán hàng
  shippingFree?: number; // Phí vận chuyển
  TotalQuantity?: number; // Tổng số lượng
};
const PrintedInvoice = forwardRef<HTMLDivElement, PrintedInvoiceProps>(
  ({ invoiceData, valueQRCode }, ref) => {
    const data = invoiceData[0] as Order;
    const currentTime = new Date();

    // Định dạng ngày giờ
    const formattedDateTime = currentTime
      .toLocaleString("en-US", {
        year: "numeric", // Lấy bốn chữ số của năm
        month: "2-digit", // Lấy tháng dưới dạng hai chữ số
        day: "2-digit", // Lấy ngày dưới dạng hai chữ số
        hour: "2-digit", // Lấy giờ dưới dạng hai chữ số
        minute: "2-digit", // Lấy phút dưới dạng hai chữ số
        hourCycle: "h23", // Sử dụng định dạng 24 giờ
      })
      .replace(/\//g, ".")
      .replace(/, /g, " ");
    const rearrangedDateTime = formattedDateTime.replace(
      /^(\d{2})\.(\d{2})\.(\d{4}) (.+)$/,
      "$2.$1.$3 $4"
    );
    const formatCurrency = (value: any) => {
      if (value == null) {
        return "";
      }
      // Định dạng số theo chuẩn Việt Nam
      return value.toLocaleString("vi-VN", {
        minimumFractionDigits: 0, // Không hiển thị phần thập phân
        maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
      });
    };

    function formatDate(dateString: string): string {
      const date = new Date(dateString);

      // Tạo các phần cần thiết cho định dạng
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // tháng bắt đầu từ 0, cần +1
      const year = date.getFullYear();

      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // Trả về chuỗi theo định dạng mong muốn
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    }
    return (
      <div ref={ref}>
        <div className={styles.container}>
          <div className={styles.textHeader}>
            <h4
              className={styles.text}
              style={{ fontSize: "11px", marginTop: "5px", fontWeight: "600" }}
            >
              Công ty Cổ phần đầu tư công nghệ HACOM
            </h4>
            <Flex direction="column" justify="flex-start">
              <span style={{ fontSize: "8.8px" }} className={styles.text}>
                <span>.</span> CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà
                Trưng, TP. Hà Nội
              </span>
              <div className={styles.text} style={{ fontSize: "10px" }}>
                <Flex
                  direction="row"
                  align="center"
                  justify="flex-start"
                  style={{ fontSize: "8.8px", paddingLeft: "5.8px" }}
                >
                  <span>.</span>
                  <IconPhone
                    stroke={2}
                    style={{ width: "10px", marginRight: "2px" }}
                  />
                  <span>19001903 |</span>
                  <IconMail
                    stroke={2}
                    style={{ width: "10px", marginRight: "2px" }}
                  />
                  <span> dichvukhachhang@hacom.vn |</span>
                  <IconWorldWww
                    stroke={2}
                    style={{ width: "10px", marginRight: "2px" }}
                  />
                  <span>Hacom.vn</span>
                </Flex>
              </div>
            </Flex>
          </div>
          <div>
            <h3
              className={styles.text}
              style={{
                textAlign: "center",
                fontSize: "13px",
                lineHeight: "normal",
                fontWeight: "700",
                marginBottom: "5px",
              }}
            >
              HÓA ĐƠN BÁN HÀNG
            </h3>
            <Flex
              direction={"row"}
              justify="center"
              gap="0"
              className={styles.textHeaderBottom}
            >
              <Flex
                direction={"column"}
                gap={0}
                style={{ flex: 1, fontSize: "9px", marginLeft: "8px" }}
              >
                <p className={styles.text}>
                  <span>Thời gian: {formatDate(data?.createDate)}</span>
                </p>
                <p className={styles.text}>
                  <span>NVBH: </span>
                  <span>{data?.SalerCode}</span>
                </p>
                <p className={styles.text}>
                  <span>Thu ngân: {data?.casherCode}</span>
                </p>
                <p className={styles.text}>
                  <span>Lần in: 01</span>
                </p>
              </Flex>
              <Flex
                direction={"column"}
                gap={0}
                style={{ flex: 1, fontSize: "9px", margin: "0" }}
              >
                <p className={styles.text}>
                  <span>Số HĐ: {data?.orderNumber}</span>
                </p>
                <p className={styles.text}>
                  <span>MSKH: {data?.custCode}</span>
                </p>{" "}
                <p className={styles.text}>
                  <span>Thời gian in: {rearrangedDateTime}</span>
                </p>
                <p className={styles.text}>
                  <span>Số điện thoại: {data?.telephoneNumber}</span>
                </p>
              </Flex>
            </Flex>
          </div>
          <div style={{ textAlign: "start" }} className={styles.contentItem}>
            <p className={styles.text} style={{ fontSize: "11px" }}>
              Tên hàng hóa| Mã hàng| SL | ĐVT | Giá bán| Thành tiền
            </p>
            {data?.items?.map((value: any, index: number) => (
              <div key={index} style={{ fontSize: "10.5px" }}>
                <div
                  className={styles.text}
                  style={{
                    margin: 0,
                    width: "100% !important",
                    textAlign: "justify",
                    textAlignLast: "left",
                  }}
                >
                  <p>{value.itemName}</p>
                </div>
                <Flex direction="row" justify="space-between" align="center">
                  <div style={{ fontWeight: 400 }}>
                    {value.itemCode} x {value.quantity}
                    {value.uom} x {formatCurrency(value.price)} =
                  </div>
                  <div>
                    <p className={styles.text}>
                      {formatCurrency(value.totalLineAmount)}
                    </p>
                  </div>
                </Flex>
              </div>
            ))}
          </div>
          <div className={styles.finance}>
            <div>
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tổng số lượng</p>
                <p className={styles.text}>{data?.TotalQuantity}</p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tổng tiền hàng</p>
                <p className={styles.text}>
                  {formatCurrency(data.amountTotal)}
                </p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                {/* phí vận chuyển chưa có */}
                <p className={styles.text}>Phí vận chuyển</p>
                <p className={styles.text}>
                  {formatCurrency(data.shippingFree)}
                </p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tổng giảm trừ</p>
                <p className={styles.text}>
                  {formatCurrency(data.amountDiscount)}
                </p>
              </Flex>{" "}
              {/* <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Mã số mua hàng</p>
                <p className={styles.text}>{data?.invoiceNumber}</p>
              </Flex>{" "} */}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Tiền tích lũy sử dụng</p>
                <p className={styles.text}>
                  {formatCurrency(data.amountVoucher)}
                </p>
              </Flex>{" "}
              <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Số tiền cần thanh toán</p>
                <p className={styles.text}>
                  {formatCurrency(data.amountTotal)}
                </p>
              </Flex>{" "}
              <div>
                <p className={styles.text} style={{ textAlign: "center" }}>
                  (Giá đã bao gồm thuếGTGT)
                </p>
              </div>{" "}
              {/* <Flex direction="row" justify="space-between" align="center">
                <p className={styles.text}>Số tiền tích lũy</p>
                <p className={styles.text}>
                  {formatCurrency(data.pointExchangeAmout)}
                </p>
              </Flex>{" "} */}
            </div>
          </div>
          <div className={styles.pay}>
            <div className={styles.text}>
              <p className={styles.text} style={{ fontWeight: "700" }}>
                Thanh toán trước
              </p>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>Tiền mặt</p>
                <p className={styles.text}>{formatCurrency(data.cashAmount)}</p>
              </Flex>{" "}
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>Chuyển khoản</p>
                <p className={styles.text}>
                  {formatCurrency(data.custAmountTransfer)}
                </p>
              </Flex>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>Thanh toán thẻ</p>
                <p className={styles.text}>{formatCurrency(data.cardAmount)}</p>
              </Flex>{" "}
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text} style={{ fontWeight: "700" }}>
                  Tổng
                </p>
                <p className={styles.text} style={{ fontWeight: "600" }}>
                  {formatCurrency(
                    (data.cashAmount ? data.cashAmount : 0) +
                      (data.custAmountTransfer ? data.custAmountTransfer : 0) +
                      (data.cardAmount ? data.cardAmount : 0)
                  )}
                </p>
              </Flex>
              <p className={styles.text} style={{ fontWeight: "700" }}>
                Thanh toán sau
              </p>
              <Flex
                direction="row"
                justify="space-between"
                align="center"
                style={{ marginLeft: "4px" }}
              >
                <p className={styles.text}>C.O.D</p>
                <p className={styles.text} style={{ fontWeight: "600" }}>
                  {formatCurrency(data.codAmount)}
                </p>
              </Flex>{" "}
            </div>
          </div>
          <div className={styles.warrantyPolicy}>
            <Flex direction="row" gap="sm" align="start">
              <p className={styles.text} style={{ flex: "60%" }}>
                Qúy khách vui lòng quét mã QR để thanh toán chuyển khoản
              </p>
              <QRCode
                value={valueQRCode}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                  marginBottom: "5px",
                }}
              />
            </Flex>
          </div>
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              Mọi góp ý, thắc mắc, khiếu nại, Qúy khách vui lòng liên hệ với bộ
              phận CSKH theo hotline <strong>19001903</strong> hoặc tại{" "}
              <a href="" style={{ cursor: "pointer" }}>
                hacom.vn/cskh
              </a>
            </p>
          </div>
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              <strong>HACOM</strong> chỉ xuất hóa đơn trong ngày.Quý khách tra
              cứu & tải hóa đơn tại:
              <a href="" style={{ cursor: "pointer" }}>
                hacom.vn/hddt
              </a>
            </p>
          </div>{" "}
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              Chính sách bảo hàng vui lòng xem tại <br />
              <a href="" style={{ cursor: "pointer" }}>
                hacom.vn/chinh-sach-bao-hanh
              </a>
            </p>
          </div>{" "}
          <div className={styles.warrantyPolicy}>
            <Flex direction="row" gap="sm" align="start">
              <p className={styles.text} style={{ flex: "60%" }}>
                Quý khách đánh giá chất lượng hàng hóa dịch vụ tại
              </p>
              <QRCode
                value={""}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "11px",
                  marginBottom: "3px",
                  marginTop: "3px",
                }}
              />
            </Flex>
          </div>
          <div className={styles.warrantyPolicy}>
            <p className={styles.text}>
              Cảm ơn Qúy khách đã mua sắm tại HACOM. <br />
              Hãy mua hàng tại <strong>hacom.vn</strong> để được hưởng ưu đãi
              giá hơn.
            </p>
          </div>{" "}
        </div>
      </div>
    );
  }
);

export default PrintedInvoice;
