import {
  Box,
  Button,
  Collapse,
  ComboboxItem,
  Fieldset,
  Flex,
  Group,
  Select,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";
import { useDisclosure, useHotkeys } from "@mantine/hooks";
import {
  IconArrowLeft,
  IconMaximize,
  IconMaximizeOff,
  IconPrinter,
} from "@tabler/icons-react";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  useMantineReactTable,
} from "mantine-react-table";
import * as React from "react";
import { useEffect, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { repositoryPos } from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { _localization } from "../../../config/location";
import { MessageResponse } from "../../../model/MessageResponse";
import { OrderDetailOutWardNew } from "../../../model/SaleOrderModel";
import { TblInventorySerialOrderDetailOnHand } from "../../../model/TblInventorySerialOnHand";
import { TblOutward, TblOutwardDetail } from "../../../model/TblOutWard";
import { getTblInventoryTransactionTypeOutWard } from "../../../service/getSelectApi";
import RequestTransferOutWard from "./GeneralInformation/RequestTransferOutWard";
import RequestVendorReturnOutWard from "./GeneralInformation/RequestVendorReturnOutWard";
import SaleOrderOutward from "./GeneralInformation/SaleOrderOutward";
import "./tab.component.css";
import PrintWarehouseOutWard from "./PrintWarehouseOutWard";

interface TblInventorySerialOrderDetailOnHandEdit
  extends TblInventorySerialOrderDetailOnHand {
  type?: string;
  subInvId?: number;
}

const DetailWarehouseOutWard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state && location.state.id;
  const sourceType = location.state && location.state.type;
  const isLocked = location.state && location.state.isLocked;
  // Print
  const [currentDate, setCurrentDate] = useState<string>("");
  const [idPrint, setIdPrint] = useState<string | number>("");
  const [typePrint, setTypePrint] = useState<string | null>("");
  const [dataPrint, setDataPrint] = useState(null);
  const componentRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
    // onBeforePrint: () => console.log("Before printing..."),
    // onAfterPrint: () => console.log("After printing..."),
  });
  const getOutWardProps = (additionalProps = {}) => ({
    dataOutWard: dataOutWard?.tblItemTransactionHeaderCommand,
    dataOrderDetail: dataOrderDetail?.tblInventoryTransactionModel,
    userInfo,
    isLocked,
    handleChangeValueInput,
    ...additionalProps,
  });

  // Thong tin chung xuat lien chi nhanh
  const requestTransfetOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({ haveStatus: true, isEdit: true })}
      />
    );
  };

  // Thong tin chung xuat noi bo
  const requestTransferInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({
          haveStatus: true,
          isInternal: true,
          isEdit: true,
        })}
      />
    );
  };

  // Thong tin chung xuat ban hang
  const orderOutWard = () => {
    return <SaleOrderOutward {...getOutWardProps({ isEdit: true })} />;
  };

  // Thong tin chung xuat huy
  const cancelOutWard = () => {
    return <RequestTransferOutWard {...getOutWardProps({ isEdit: true })} />;
  };

  // Thong tin chung xuat dieu chuyen serial
  const serialOutWard = cancelOutWard;

  // Thong tin chung xuat dieu chuyen noi bo serial
  const serialInternalOutWard = () => {
    return (
      <RequestTransferOutWard
        {...getOutWardProps({ isInternal: true, isEdit: true })}
      />
    );
  };

  // Thong tin chung xuat kho dieu chinh
  const adjustmentOutWard = cancelOutWard;

  // Thong tin chung xuat dieu chuyen lap rap
  const createProductOutWard = cancelOutWard;

  // Thong tin chung xuat kho tra ncc
  const returnVendorOutWard = () => {
    return (
      <RequestVendorReturnOutWard {...getOutWardProps({ isEdit: true })} />
    );
  };

  // Function render ui
  const renderMainOutWard = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    switch (_sourceType) {
      case "XUAT_BAN":
        return orderOutWard();
      case "XUAT_HUY":
        return cancelOutWard();
      case "XUAT_DIEU_CHINH":
        return adjustmentOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return requestTransfetOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return requestTransferInternalOutWard();
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return serialOutWard();
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return serialInternalOutWard();
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return createProductOutWard();
      case "XUAT_TRA_NCC":
        return returnVendorOutWard();
      case "XUAT_TRA_NCC-DCTT":
        return returnVendorOutWard();
      case "XUAT_MUON_BAO_HANH":
        return cancelOutWard();
      default:
        return requestTransfetOutWard();
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  const [dataOutWard, setDataOutWard] = useState<TblOutward>({
    sourceId: 39,
    tblItemTransactionHeaderCommand: {
      transactionId: 0,
      transactionCode: "",
      transactionDate: "",
      sourceCode: "",
      sourceType: "",
      inventoryId: null,
      subInvId: null,
      departmentId: 0,
      status: "1",
      description: "",
      // atribute1: "",
      // atribute2: "",
      // atribute3: "",
      // atribute4: "",
      // atribute5: "",
      // atribute6: "",
      // atribute7: "",
      // atribute8: "",
      // atribute9: "",
      // atribute10: "",
      createBy: 0,
      createDate: "",
      createName: "",
      lastUpdateDate: "",
      lastUpdateBy: 0,
    },
    serials: [],
  });
  const [height, setHeight] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const [userInfo, setUserInfo] = useState<any>();

  //table state

  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const entityOrderOutWard = {
    tblInventoryTransactionModel: {},
    inventoryTransactionRequestSourceModels: [],
    invCode: null,
    invId: null,
    invName: null,
    outWardCode: null,
    receiver: null,
    sourceCode: null,
    sourceId: null,
    subInvId: null,
    toAddess: null,
  };
  const [dataOrderDetail, setDataOrderDetail] =
    useState<OrderDetailOutWardNew>(entityOrderOutWard);
  const [dataSerialOnHand, setDataSerialOnHand] = useState<
    TblInventorySerialOrderDetailOnHandEdit[]
  >([]);
  const [checkDuplicateCode, setCheckDuplicateCode] = useState<any[]>([]);
  const [dataInventoryTransactionType, setDataInventoryTransactionType] =
    useState<ComboboxItem[]>([]);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const sidePanelRef = React.useRef<HTMLDivElement>(null);
  const [isCollapse, { toggle: toggleCollapse }] = useDisclosure(false);

  const [mainContentWidth, setMainContentWidth] = useState(0);

  //#region  table
  const headerRef = React.useRef<HTMLDivElement>(null);

  // useScanDetection({
  //   onComplete: (code) => {
  //     setSerial(code.toString());
  //     fetchDataSerialOnHand(serial);
  //   },
  //   minLength: 5,
  // });

  const titleSell = () => {
    return (
      <>
        <Group mt={-10} align="center" justify="space-between">
          <Flex
            gap="md"
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Group gap={20}>
              <Text c={"red"} fw={700}>
                Loại phiếu
              </Text>
              <Select
                searchable
                clearable
                size="xs"
                placeholder="Chọn loại phiếu"
                disabled
                data={dataInventoryTransactionType}
                value={
                  dataOutWard.tblItemTransactionHeaderCommand.sourceType?.toString() ||
                  null
                }
                rightSection={<></>}
              />
            </Group>
            <Tooltip
              label={isCollapse ? "Mở rộng" : "Thu gọn"}
              position="bottom"
            >
              <Group align="center">
                <Switch
                  size="sm"
                  color={isCollapse ? "red" : "green"}
                  checked={!isCollapse}
                  onChange={toggleCollapse}
                  label={isCollapse ? "Thu gọn (Ctrl+M)" : "Mở rộng (Ctrl+M)"}
                  thumbIcon={
                    !isCollapse ? (
                      <IconMaximize
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    ) : (
                      <IconMaximizeOff
                        style={{ width: 16, height: 16 }}
                        stroke={2.5}
                        color="white"
                      />
                    )
                  }
                />
              </Group>
            </Tooltip>
          </Flex>
          <Flex
            gap="md"
            justify={{ sm: "flex-start", lg: "flex-end" }}
            wrap="wrap"
            align="center"
            direction="row"
          >
            <Button
              leftSection={<IconArrowLeft size={14} />}
              color="red"
              onClick={() => handleReturnPage()}
            >
              Quay lại
            </Button>
            {(location.state && location.state.type === "XUAT_BAN") ||
            location.state.type === "XUAT_TRA_NCC" ? (
              <>
                <Button
                  leftSection={<IconPrinter size={14} />}
                  color="blue"
                  onClick={() =>
                    onhandlePrint(location.state.id, location.state.type)
                  }
                >
                  In Phiếu
                </Button>
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Group>
      </>
    );
  };

  const onhandlePrint = (id: string | number, type: string | null) => {
    if (id != null && type != null) {
      setIdPrint(id);
      setTypePrint(type);
    }
  };

  const fetchDataToPrint = async (id: number | string, type: string | null) => {
    let apiPath;
    if (type === "XUAT_BAN") {
      apiPath = `/api/v1/Outward/print-sale-order-out-ward?id=${id}`;
    } else if (type === "XUAT_TRA_NCC") {
      apiPath = `/api/v1/Outward/print-request-vendor-return-outward?id=${id}`;
    } else if (type === "XUAT_HUY") {
      apiPath = `/api/v1/Outward/print-request-cancellation-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHINH") {
      apiPath = `/api/v1/Outward/print-request-adjustment-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHUYEN_CHI_NHANH") {
      apiPath = `/api/v1/Outward/print-request-transfer-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHUYEN_NOI_BO") {
      apiPath = `/api/v1/Outward/print-request-transfer-internal-outward?id=${id}`;
    } else if (type === "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL") {
      apiPath = `/api/v1/Outward/print-request-transfer-serial-outward?id=${id}`;
    } else if (type === "XUAT_LINH_KIEN_SAN_XUAT") {
      apiPath = `/api/v1/Outward/print-production-order-outward?id=${id}`;
    } else {
      return;
    }

    try {
      const response = await repositoryPos.get<MessageResponse<any>>(apiPath);
      if (response?.success && response?.data) {
        setDataPrint(response?.data);
      } else {
        console.log("error fetch data to print");
        setDataPrint(null);
        NotificationExtension.Fails("error fetch data to print");
      }
    } catch (error) {
      console.log("error fetch data to print", error);
    }
  };

  const handleReturnPage = () => {
    navigate(-1);
  };

  const handleChangeValueInput = (
    value: null | string | boolean,
    key: string
  ) => {
    setDataOutWard((prevData) => ({
      ...prevData,
      tblItemTransactionHeaderCommand: {
        ...prevData.tblItemTransactionHeaderCommand,
        [key]: value,
      },
    }));
  };

  const fetchdataInventoryTransactionType = async () => {
    const response = await getTblInventoryTransactionTypeOutWard();

    setDataInventoryTransactionType(
      response.map((type) => ({ value: type.value, label: type.text }))
    );
  };

  const getOutwardApiUrl = (sourceType: string | null): string => {
    const action = "detail";
    switch (sourceType) {
      case "XUAT_BAN":
        return `/api/v1/Outward/${action}-sale-order-out-ward`;
      case "XUAT_HUY":
        return `/api/v1/Outward/${action}-request-cancellation-outward`;
      case "XUAT_DIEU_CHINH":
        return `/api/v1/Outward/${action}-request-adjustment-outward`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH":
        return `/api/v1/Outward/${action}-request-transfer-outward`;
      case "XUAT_DIEU_CHUYEN_NOI_BO":
        return `api/v1/Outward/${action}-request-transfer-internal-outward`;
      case "XUAT_DIEU_CHUYEN_CHI_NHANH_SERIAL":
        return `/api/v1/Outward/${action}-request-transfer-serial-outward`;
      case "XUAT_DIEU_CHUYEN_NOI_BO_SERIAL":
        return `/api/v1/Outward/${action}-request-transfer-internal-serial-outward`;
      case "XUAT_LINH_KIEN_SAN_XUAT":
        return `/api/v1/Outward/${action}-production-order-outward`;
      case "XUAT_TRA_NCC":
        return `/api/v1/Outward/${action}-request-vendor-return-outward`;
      default:
        throw new Error("Unknown source type");
    }
  };

  const getColumnVisibility = () => {
    const _sourceType =
      sourceType ?? dataOutWard.tblItemTransactionHeaderCommand.sourceType;

    const defaultVisibility = {
      id: false,
      poPrice: true,
      subPrice: true,
      totalAmount: true,
      serial: false,
    };

    if (
      ["XUAT_DIEU_CHUYEN_CHI_NHANH", "XUAT_DIEU_CHUYEN_NOI_BO"].includes(
        _sourceType
      )
    ) {
      return {
        ...defaultVisibility,
        poPrice: false,
        subPrice: false,
        totalAmount: false,
      };
    }

    if (_sourceType?.includes("SERIAL")) {
      return { ...defaultVisibility, serial: true };
    }

    return defaultVisibility;
  };

  const fetchDataDetail = async () => {
    const url = getOutwardApiUrl(sourceType);
    try {
      const response = await repositoryPos.get<
        MessageResponse<TblOutwardDetail>
      >(`${url}?id=${id}`);
      if (response?.success && response?.data) {
        setDataOutWard((prevData) => ({
          ...prevData,
          tblItemTransactionHeaderCommand:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataOrderDetail((prevData) => ({
          ...prevData,
          inventoryTransactionRequestSourceModels:
            response?.data?.inventoryTransactionRequestSourceModels?.map(
              (order) => ({
                ...order,
                scannedQuantity: 0,
                remainQuantity:
                  (order.primaryQuantity || 0) -
                  (order.transactionQuantity || 0),
              })
            ),
          tblInventoryTransactionModel:
            response?.data?.tblInventoryTransactionModel,
        }));
        setDataSerialOnHand(
          response.data.inventoryTransactionSerialModels.map((item) => ({
            ...item,
            type: "scanned",
          })) || []
        );
        setCheckDuplicateCode((prev) => [
          ...prev,
          ...response.data.inventoryTransactionSerialModels
            .map((item) => item.serial)
            .filter((serial): serial is string => serial !== null),
        ]);
      } else {
        setDataOrderDetail(entityOrderOutWard);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataOrderDetail(entityOrderOutWard);
    }
  };

  useHotkeys([["ctrl+M", () => toggleCollapse()]]);

  useEffect(() => {
    if (idPrint !== "") {
      fetchDataToPrint(idPrint, typePrint);
    }
  }, [idPrint]);
  useEffect(() => {
    if (dataPrint !== null) {
      handlePrint();
      setIdPrint("");
      setDataPrint(null);
    }
  }, [dataPrint]);
  useEffect(() => {
    const userLogin = localStorage.getItem("userLogin");
    const fullName = localStorage.getItem("fullName");
    if (userLogin) setUserInfo({ ...JSON.parse(userLogin), fullName });
    fetchdataInventoryTransactionType();
  }, []);
  useEffect(() => {
    const getCurrentDateFormatted = (): string => {
      const today = new Date();
      const day = today.getDate().toString().padStart(2, "0");
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const year = today.getFullYear();

      return `${day}.${month}.${year}`;
    };
    setCurrentDate(getCurrentDateFormatted());
  }, []);
  useEffect(() => {
    if (id) fetchDataDetail();
  }, [id]);

  useEffect(() => {
    const _sourceType = dataOutWard.tblItemTransactionHeaderCommand.sourceType;
    const exceedQuantityFound =
      dataOrderDetail?.inventoryTransactionRequestSourceModels.some((order) => {
        const matchingOrder = dataSerialOnHand
          .filter((item) => item.itemId === order.itemId)
          .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

        if (matchingOrder) {
          const totalQuantity = matchingOrder.reduce(
            (acc, curr) => acc + curr,
            0
          ); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const remainQuantity = orderQuantity - totalQuantity || 0;
          return remainQuantity < 0;
        }

        return false;
      });

    setDataOrderDetail((prevData) => ({
      ...prevData,
      inventoryTransactionRequestSourceModels:
        prevData.inventoryTransactionRequestSourceModels.map((order) => {
          const quantities = dataSerialOnHand
            .filter((item) => item.itemId === order.itemId)
            .map((item) => item.primaryQuantity || 0); // Lấy số lượng và mặc định là 0 nếu undefined

          const totalQuantity = quantities.reduce((acc, curr) => acc + curr, 0); // Tính tổng số lượng

          const orderQuantity = order.primaryQuantity ?? 0;
          const orderTransferQuantity = order.transactionQuantity ?? 0;
          const remainQuantity =
            orderQuantity - (orderTransferQuantity + totalQuantity) || 0;

          const priceItem =
            _sourceType === "XUAT_BAN"
              ? order.soPrice1 ?? 0
              : order.poPrice ?? 0;
          return {
            ...order,
            remainQuantity: remainQuantity,
            transactionQuantity: totalQuantity,
            priceItem: priceItem * totalQuantity,
          };
        }),
    }));

    // Ensure the state is set after the mapping is complete
  }, [dataSerialOnHand]);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const handleResize = () => {
      // 160 là chiều cao của phần phân trang
      // headerHeight là chiều cao của phần header
      setHeight(window.innerHeight - (310 + headerHeight));
    };

    handleResize(); // Set initial height
    window.addEventListener("resize", handleResize); // Update height on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Clean up event listener
    };
  }, []);

  useEffect(() => {
    const updateMainContentWidth = () => {
      const containerWidth = containerRef.current?.offsetWidth || 0;
      const sidePanelWidth = sidePanelRef.current?.offsetWidth || 0;
      const calculatedMainWidth = isCollapse
        ? containerWidth
        : containerWidth - sidePanelWidth;
      setMainContentWidth(calculatedMainWidth);
    };

    const containerObserver = new ResizeObserver(updateMainContentWidth);
    const sidePanelObserver = new ResizeObserver(updateMainContentWidth);

    if (containerRef.current) containerObserver.observe(containerRef.current);
    if (sidePanelRef.current) sidePanelObserver.observe(sidePanelRef.current);

    updateMainContentWidth();

    return () => {
      if (containerRef.current)
        containerObserver.unobserve(containerRef.current);
      if (sidePanelRef.current)
        sidePanelObserver.unobserve(sidePanelRef.current);
    };
  }, [isCollapse]);

  const columns = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "index",
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 100,
      },
      {
        accessorKey: "serial",
        header: "Serial",
        size: 100,
      },
      {
        accessorKey: "itemName",
        header: "Tên SP",
        Cell: ({ row }) => (
          <Tooltip label={row.original.itemName}>
            <Text size="sm" w={300}>
              {row.original.itemName}
            </Text>
          </Tooltip>
        ),
      },

      {
        accessorKey: "primaryUomCode",
        header: "ĐVT1",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
        Cell: () => <Text>1</Text>,
      },
      {
        accessorKey: "poPrice",
        header: "Giá nhập",
        size: 30,
        Cell: ({ renderedCellValue }) => (
          <Text size="sm" w={300}>
            {renderedCellValue && renderedCellValue?.toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUomName",
        header: "ĐVT2",
        size: 30,
      },
      {
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        header: "Thành tiền",
        size: 30,
      },
      {
        accessorKey: "status",
        header: "Tình trạng",
        size: 100,
        Cell: ({ row }) => (
          <Text size="sm" w={300}>
            {!row.original.serialStatus
              ? row.original.status
              : row.original.serialStatus}
          </Text>
        ),
      },
      {
        header: "Ghi chú",
        size: 150,
      },
      {
        accessorKey: "warning",
        header: "Cảnh báo",
        Cell: ({ row }) =>
          !row.original.isMapping ? (
            <Tooltip label={row.original.warning}>
              <Text size="sm" w={300} c={"red"}>
                {row.original.warning}
              </Text>
            </Tooltip>
          ) : null,
      },
    ],
    []
  );

  const columnsOrderDetail = React.useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        header: "STT",
        Cell: ({ row }) => row.index + 1,
        size: 30,
      },

      {
        accessorKey: "itemCode",
        header: "Mã hàng",
        size: 30,
      },
      {
        accessorKey: "itemName",
        header: "Tên sản phẩm",
        Cell: ({ renderedCellValue }) => (
          <Text size="sm">{renderedCellValue}</Text>
        ),
      },
      {
        accessorKey: "primaryUom",
        header: "ĐVT1",
        size: 30,
      },
      {
        accessorKey: "primaryQuantity",
        header: "SL1",
        size: 30,
      },
      {
        accessorKey: "poPrice",
        header:
          dataOutWard.tblItemTransactionHeaderCommand.sourceType === "XUAT_BAN"
            ? "Giá bán lẻ"
            : "Giá nhập",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 && row.original.soPrice1?.toLocaleString()
              : row.original.poPrice && row.original.poPrice?.toLocaleString()}
          </Text>
        ),
      },

      {
        accessorKey: "subQuantity",
        header: "SL2",
        size: 30,
      },
      {
        accessorKey: "subUom",
        header: "ĐVT2",
        size: 30,
      },
      {
        accessorKey: "subPrice",
        header: "Giá theo ĐVT2",
        size: 30,
      },
      {
        accessorKey: "totalAmount",
        header: "Thành tiền",
        size: 30,
        Cell: ({ row }) => (
          <Text size="sm">
            {dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
            "XUAT_BAN"
              ? row.original.soPrice1 &&
                (
                  Number(row.original.soPrice1) *
                  (row.original.primaryQuantity || 0)
                ).toLocaleString()
              : row.original.poPrice &&
                (
                  Number(row.original.poPrice) *
                  Number(row.original.primaryQuantity || 0)
                ).toLocaleString()}
          </Text>
        ),
      },
      {
        accessorKey: "itemStatus",
        header: "Tình trạng",
        size: 30,
      },
      {
        header: "Ghi chú",
      },
      {
        header: "Số lượng đã xuất",
        Cell: ({ row }) => {
          return (
            <Text fw={"bold"} c={"red"} size="sm">
              {row.original.scannedQuantity +
                row.original.transactionQuantity || 0}
            </Text>
          );
        },
        size: 30,
      },
      {
        header: "Lệch",
        Cell: ({ row }) => (
          <Text fw={"bold"} c={"red"} size="sm">
            {row.original.scannedQuantity +
              row.original.transactionQuantity -
              (row.original.primaryQuantity || 0)}
          </Text>
        ),
        size: 30,
      },
    ],
    [
      dataSerialOnHand,
      dataOrderDetail.inventoryTransactionRequestSourceModels,
      dataOutWard.tblItemTransactionHeaderCommand.sourceType,
    ]
  );

  const table = useMantineReactTable({
    columns,
    data: dataSerialOnHand,
    positionToolbarAlertBanner: "bottom",
    enableColumnActions: false,
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select", "index"],
        right: ["action"],
      },
      columnVisibility: {
        id: false,
        phone: false,
        fax: false,
        action: !isLocked,
      },
      density: "xs",
    },
    mantineTableContainerProps: {
      style: { maxHeight: height * 0.55 - 20, minHeight: height * 0.55 - 20 },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  const tableDetail = useMantineReactTable({
    columns: columnsOrderDetail,
    data: dataOrderDetail?.inventoryTransactionRequestSourceModels || [],
    positionToolbarAlertBanner: "bottom",
    //add custom action buttons to top-left of top toolbar
    enableTopToolbar: false,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    mantineTopToolbarProps: {
      style: {
        borderBottom: "3px solid rgba(128, 128, 128, 0.5)",
        marginBottom: 5,
      },
    },
    localization: _localization,
    // enableRowSelection: true,
    getRowId: (row) => row.id,
    initialState: {
      showColumnFilters: false,
      columnPinning: {
        left: ["mrt-row-select"],
        right: ["action"],
      },
      columnVisibility: getColumnVisibility(),
      density: "xs",
    },
    mantineTableContainerProps: {
      style: {
        maxHeight: height * 0.35,
        minHeight: height * 0.35,
      },
    },
    enableStickyHeader: true,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    // manualFiltering: true,
    // manualPagination: true,
    // manualSorting: true,
    rowCount,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    // onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: {
        //  fontWeight: row.getIsSelected() ? "bold" : "normal", //conditional styling
        fontWeight: "normal", //conditional styling
        fontSize: "12.5px",
        padding: "5px 15px",
      },
    }),
    state: {
      // columnFilters,
      // globalFilter,
      isLoading,
      //  pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading, //loading for the first time with no data
      //sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    // mantinePaginationProps: {
    //   showRowsPerPage: true,
    //   withEdges: true,
    //   rowsPerPageOptions: ["10", "50", "100"],
    // },
    // paginationDisplayMode: "pages",
    enableColumnPinning: true,
    mantineTableProps: {
      striped: false,
    },
    // columnFilterDisplayMode: "popover",
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: row.getToggleSelectedHandler(),
      sx: { cursor: "pointer" },
    }),
  });

  //#endregion
  return (
    <Box>
      <div ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Box my={"10px"}>{titleSell()}</Box>
      </div>
      <Box
        style={{
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "max-width",
          }}
          ref={containerRef}
        >
          {!isCollapse && (
            <Box
              style={{
                flexShrink: 0,
                transition: "width 0.3s ease, max-width 0.3s ease",
                display: "flex",
                alignItems: "stretch",
                overflow: "hidden",
                height: "100%",
                // width: "fit-content",
              }}
              ref={sidePanelRef}
            >
              <Collapse
                in={!isCollapse}
                transitionDuration={100}
                transitionTimingFunction="ease-in-out"
              >
                <Fieldset
                  w="fit-content"
                  h="100%"
                  legend="Thông tin chung"
                  p={5}
                >
                  {renderMainOutWard()}
                </Fieldset>
              </Collapse>
            </Box>
          )}

          <Box
            style={{
              flexGrow: 1,
              flexShrink: 0,
              width: `${mainContentWidth}px`,
              transition: "width 0.3s ease",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Fieldset w={"max-width"} p={10} pb={0} legend="Bên xuất">
              <MantineReactTable table={table} />
              <Group my="10px" justify="end" />
            </Fieldset>

            <Fieldset
              p={10}
              pb={0}
              w={"max-width"}
              legend={
                dataOutWard.tblItemTransactionHeaderCommand.sourceType ===
                "XUAT_BAN"
                  ? "Thông tin đơn hàng"
                  : "Yêu cầu xuất kho"
              }
            >
              <MantineReactTable table={tableDetail} />
            </Fieldset>
          </Box>
        </Box>
      </Box>
      {dataPrint && typePrint && (
        <div style={{ display: "none" }}>
          <PrintWarehouseOutWard
            dataPrint={dataPrint}
            typePrint={typePrint}
            userInfo={userInfo}
            currentDate={currentDate}
            ref={componentRef}
          />
        </div>
      )}
    </Box>
  );
};
export default DetailWarehouseOutWard;
