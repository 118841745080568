import {
  Box,
  Button,
  ComboboxItem,
  Fieldset,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Select,
  Table,
  TextInput,
  Tooltip,
  Text,
  ActionIcon,
  Flex,
  Badge,
} from "@mantine/core";
import { hasLength, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import {
  IconArrowLeft,
  IconCheck,
  IconCheckbox,
  IconDeviceFloppy,
  IconEdit,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { repositoryMdm, repositoryPos } from "../../../_base/_const/_constVar";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { MessageResponse } from "../../../model/MessageResponse";
import {
  FaultyItemListDetailCommand,
  TblFaultyItemList,
} from "../../../model/TblFaultyItemList";
import { DatePickerInput } from "@mantine/dates";
import {
  getBranchSelect,
  getTblDmEmployeeSelect,
  getVendorSelect,
} from "../../../service/getSelectApi";
import { TblWarrantyReceiveTicket } from "../../../model/TblWarrantyReceiveTicket";
import cx from "clsx";
import classes from "../../../Styles/TableScrollArea.module.css";
import { tblItem } from "../../../model/TblItem";
import { sky_blue } from "../../../const/variables";

const EditDataView = ({ id, onClose, handleEdit }: EditDataViewProps) => {
  const entity = {
    id: "0",
    code: null,
    branchId: null,
    employeeId: null,
    vendorId: null,
    vendorName: null,
    note: null,
    createBy: Number(localStorage.getItem("empId")),
    createDate: null,
    tblFaultyItemListDetailCommands: null,
  };

  const [listItem, setListItem] = useState<any[]>([]);
  const [listItemId, setListItemId] = useState<number[]>([]);
  const [dataWarrantyReceiveTicket, setDataWarrantyReceiveTicket] = useState<
    any[]
  >([]);
  const [dataVendorSelect, setDataVendorSelect] = useState<ComboboxItem[]>([]);

  const [faultyItemListDetailCommands, setFaultyItemListDetailCommands] =
    useState<FaultyItemListDetailCommand[]>([]);

  const [dataEmployeeSelect, setDataEmployeeSelect] = useState<ComboboxItem[]>(
    []
  );
  const [dataBranchSelect, setDataBranchSelect] = useState<ComboboxItem[]>([]);
  const [branchId, setBranchId] = useState<string | null>(null);

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isEditFILDC, setIsEditFILDC] = useState({
    note: false,
    description: false,
    accessory: false,
    warrantyStatus: false,
    serviceType: false,
  });
  const [editAndSave, setEditAndSave] = useState(false);
  const [scrolled1, setScrolled1] = useState(false);
  const [scrolled2, setScrolled2] = useState(false);
  const [visible, { close, open }] = useDisclosure(false);
  const [enable, setEnable] = useState(false);
  const [toFieldSetProduct, setToFieldSetProduct] = useState(true);

  const form = useForm<TblFaultyItemList>({
    mode: "uncontrolled",
    validateInputOnChange: true,
    initialValues: {
      ...entity,
    },
    validate: {
      employeeId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập nhân viên thực hiện !";
        }
      },
      vendorId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập nhà cung cấp !";
        }
      },
      branchId: (value: number | null) => {
        if (!value) {
          return "Vui lòng nhập trung tâm !";
        }
      },
      note: (value: string | null) => {
        if (value) {
          return hasLength(
            { max: 200 },
            "Ghi chú không được vượt quá 200 kí tự !"
          )(value as string);
        }
      },
    },
  });

  const handleSelect = (item: any) => {
    const selectedItem = {
      id: Math.floor(Math.random() * 1000).toString(),
      itemId: item.itemId,
      warrantyReceiveTicketId: item.id,
      warrantyReceiveTicketCode: item.errorNumber,
      orgSerial: item.parentSerial,
      serial: item.serial,
      serviceType: "",
      note: "",
      description: "",
      accessory: "",
      warrantyStatus: "",
    };
    setDataWarrantyReceiveTicket(
      dataWarrantyReceiveTicket.map((data) =>
        data.errorNumber === selectedItem.warrantyReceiveTicketCode
          ? { ...data, disabled: true }
          : data
      )
    );
    setFaultyItemListDetailCommands([
      ...faultyItemListDetailCommands,
      selectedItem,
    ]);
  };

  const handleRemove = (item: any) => {
    setFaultyItemListDetailCommands(
      faultyItemListDetailCommands?.filter((data) => data.id !== item.id)
    );
    setDataWarrantyReceiveTicket(
      dataWarrantyReceiveTicket.map((data) =>
        data.errorNumber === item.warrantyReceiveTicketCode
          ? { ...data, disabled: false }
          : data
      )
    );
  };

  const handleChangeValue = (key: string, value: any, index: number) => {
    setFaultyItemListDetailCommands((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: value } : item
      )
    );
  };

  const getItemDetailsById = (itemId: number, value: string) => {
    const item = listItem.find((d) => d.itemId === itemId);
    if (item) {
      return `${item[value]}`;
    }
    return "";
  };

  const handleEditTblFaultyItemList = async (dataSubmit: TblFaultyItemList) => {
    open();
    const dataApi = await repositoryMdm.post<
      MessageResponse<TblFaultyItemList>
    >("/api/v1/TblFaultyItemList/update", dataSubmit);
    if (dataApi?.success) {
      onClose((prev: any) => !prev);
      handleEdit();
      NotificationExtension.Success("Cập nhật thành công!!!");
    }
    close();
  };

  const callApiGetData = async () => {
    open();
    const callapi = await repositoryMdm.get<MessageResponse<TblFaultyItemList>>(
      `/api/v1/TblFaultyItemList/update?id=${id}`
    );
    if (!callapi?.success) {
      NotificationExtension.Fails("Bạn không có quyền sửa!!!");
      modals.closeAll();
      return;
    }
    if (callapi) {
      const dataApi = callapi?.data;
      if (dataApi != null) {
        form.setValues(dataApi);
        form.resetDirty(dataApi);
        setBranchId(dataApi.branchId?.toString() ?? "");
        setFaultyItemListDetailCommands(
          dataApi.tblFaultyItemListDetailCommands ?? []
        );
        Promise.all([
          fetchDataBranchSelect(),
          fetchDataEmployeeSelect(),
          fetchDataVendorSelect(),
        ]);
      }
      close();
    } else {
      modals.closeAll();
    }
  };

  const getWarrantyReceiveTicket = async () => {
    // if (keySearch) {
    //   url += `?KeySearch=${keySearch}`;
    // }

    const dataApi = await repositoryMdm.get<
      MessageResponse<TblWarrantyReceiveTicket[]>
    >(`api/v1/TblWarrantyReceiveTicket/get-list?Take=${50}`);

    if (dataApi && dataApi.data) {
      const data = dataApi.data.map((ticket) => ({
        ...ticket,
        disabled: faultyItemListDetailCommands.some(
          (command: any) =>
            command.warrantyReceiveTicketCode === ticket.errorNumber
        ),
      }));

      setDataWarrantyReceiveTicket(data);
    }
  };

  const getNameProduct = async () => {
    if (listItemId.length > 0) {
      let url = "";
      for (var i = 0; i < listItemId.length; i++) {
        url += `Ids=${listItemId[i]}&`;
      }
      const res = await repositoryPos.get<MessageResponse<tblItem[]>>(
        `/api/v1/TblItem/get-list-for-warranty-service-request?${url}`
      );

      if (res && res.success) {
        const data = res.data;
        setListItem(data);
      } else {
        setListItem([]);
      }
    }
  };

  const fetchDataEmployeeSelect = async () => {
    const getData = await getTblDmEmployeeSelect();
    setDataEmployeeSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataBranchSelect = async () => {
    const getData = await getBranchSelect();
    setDataBranchSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  const fetchDataVendorSelect = async () => {
    const getData = await getVendorSelect();
    setDataVendorSelect(
      getData
        ?.filter((item: any) => item.value != null && item.text != null)
        ?.map((item: any) => ({
          value: item.value,
          label: item.text,
        }))
    );
  };

  useEffect(() => {
    if (id) {
      callApiGetData();
    }
  }, [id]);

  useEffect(() => {
    if (form.isDirty()) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [form]);

  useEffect(() => {
    if (branchId && branchId !== null && branchId !== "") {
      getWarrantyReceiveTicket();
      setToFieldSetProduct(false);
    } else {
      setDataWarrantyReceiveTicket([]);
      setToFieldSetProduct(false);
    }
  }, [branchId]);

  useEffect(() => {
    getNameProduct();
  }, [listItemId]);

  useEffect(() => {
    const updateItemId = () => {
      const uniqueItemId = Array.from(
        new Set(
          dataWarrantyReceiveTicket
            .map((item) => item.itemId)
            .filter((itemId) => itemId !== null)
        )
      ).map(Number);
      setListItemId(uniqueItemId);
    };

    updateItemId();
  }, [dataWarrantyReceiveTicket]);

  useEffect(() => {
    const updateItemId = () => {
      const uniqueItemId = Array.from(
        new Set(
          faultyItemListDetailCommands
            .map((item) => item.itemId)
            .filter((itemId) => itemId !== null)
        )
      ).map(Number);
      setListItemId((prev) => [...prev, ...uniqueItemId]);
    };

    updateItemId();
  }, [faultyItemListDetailCommands]);

  useEffect(() => {
    form.setValues((prev) => ({
      ...prev,
      tblFaultyItemListDetailCommands: faultyItemListDetailCommands,
    }));
  }, [faultyItemListDetailCommands]);

  return (
    <>
      <Box
        mx="auto"
        component="form"
        onSubmit={form.onSubmit((e: TblFaultyItemList) => {
          handleEditTblFaultyItemList(e);
        })}
        style={{ position: "relative" }}
        onMouseDown={() => {
          setIsEditFILDC({
            note: false,
            description: false,
            accessory: false,
            warrantyStatus: false,
            serviceType: false,
          });
          setEditAndSave(false);
        }}
      >
        <LoadingOverlay
          visible={visible}
          zIndex={1000}
          overlayProps={{ radius: "sm", blur: 2 }}
        />
        <Group
          justify="space-between"
          mt="xs"
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "white",
          }}
        >
          <Badge>Chỉnh sửa bảng kê hàng lỗi</Badge>
          <Flex gap={"md"}>
            <Button
              type="button"
              color="red"
              loading={visible}
              onClick={() => {
                handleEdit();
              }}
              leftSection={!visible ? <IconArrowLeft size={18} /> : undefined}
            >
              Quay lại
            </Button>
            <Button
              type="submit"
              color={sky_blue.base}
              disabled={!enable}
              loading={visible}
              leftSection={!visible ? <IconCheck size={18} /> : undefined}
            >
              Lưu
            </Button>
          </Flex>
        </Group>
        <Fieldset legend="Thông tin bảng kê hàng lỗi">
          <Grid>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
              <TextInput
                label="Số phiếu kê"
                {...form.getInputProps("code")}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
              <DatePickerInput
                label="Ngày lập"
                value={
                  form.getValues().createDate
                    ? new Date(form.getValues().createDate ?? "")
                    : null
                }
                valueFormat="DD/MM/YYYY"
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
              <TextInput
                label="Nhân viên lập"
                value={localStorage.getItem("userName") ?? ""}
                variant="filled"
                readOnly
                size="xs"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 1.5 }}>
              <Select
                label="Tên trung tâm"
                placeholder="Nhập tên trung tâm"
                data={dataBranchSelect}
                value={form.getValues().branchId?.toString() ?? ""}
                onChange={(e) =>
                  form.setValues((prev) => ({
                    ...prev,
                    branchId: Number(e),
                  }))
                }
                size="xs"
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <Select
                label="Nhân viên thực hiện"
                placeholder="Nhập nhân viên thực hiện"
                data={dataEmployeeSelect}
                value={form.getValues().employeeId?.toString() ?? ""}
                onChange={(e) =>
                  form.setValues((prev) => ({
                    ...prev,
                    employeeId: Number(e),
                  }))
                }
                size="xs"
                withAsterisk
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <Select
                label="Xuất tới NCC"
                placeholder="Nhập nhà cung cấp"
                data={dataVendorSelect}
                value={
                  form.getValues().vendorId
                    ? form.getValues().vendorId?.toString()
                    : null
                }
                onChange={(e) =>
                  form.setValues((prev) => ({ ...prev, vendorId: Number(e) }))
                }
                searchable
                size="xs"
                withAsterisk
                nothingFoundMessage={"Không tìm thấy dự liệu !"}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6, lg: 2 }}>
              <TextInput
                label="Ghi chú"
                placeholder="Ghi chú"
                {...form.getInputProps("note")}
                size="xs"
              />
            </Grid.Col>
          </Grid>
        </Fieldset>
        {!toFieldSetProduct ? (
          <Fieldset legend="Danh sách sản phẩm bảng kê hàng lỗi" mt={10}>
            <ScrollArea
              h={360}
              onScrollPositionChange={({ y }) => setScrolled2(y !== 0)}
              scrollbars="x"
            >
              <Table
                striped
                highlightOnHover
                withTableBorder
                withColumnBorders
                w={1920}
              >
                <Table.Thead
                  className={cx(classes.header, {
                    [classes.scrolled]: scrolled2,
                  })}
                >
                  <Table.Tr>
                    <Table.Th>Thao tác</Table.Th>
                    <Table.Th>Số phiếu biên nhận</Table.Th>
                    <Table.Th>Mã sản phẩm</Table.Th>
                    <Table.Th>Tên sản phẩm</Table.Th>
                    <Table.Th>Serial gốc</Table.Th>
                    <Table.Th>Phụ kiện</Table.Th>
                    <Table.Th>Phiếu bảo hành</Table.Th>
                    <Table.Th>Loại dịch vụ</Table.Th>
                    <Table.Th>Thời hạn bảo hành</Table.Th>
                    <Table.Th>Mô tả lỗi</Table.Th>
                    <Table.Th>Ghi chú nhận</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {faultyItemListDetailCommands?.map((item, index) => (
                    <Table.Tr key={item.id}>
                      <Table.Th>
                        <Flex gap={"xs"}>
                          {!editAndSave ? (
                            <Tooltip label="Chỉnh sửa">
                              <ActionIcon
                                onClick={() => {
                                  setIsEditFILDC({
                                    note: true,
                                    description: true,
                                    accessory: true,
                                    warrantyStatus: true,
                                    serviceType: true,
                                  });
                                  setEditIndex(Number(index));
                                  setEditAndSave(true);
                                }}
                                size={"24px"}
                              >
                                <IconEdit size={"18px"} />
                              </ActionIcon>
                            </Tooltip>
                          ) : (
                            <Tooltip label="Lưu">
                              <ActionIcon
                                onClick={() => {
                                  setIsEditFILDC({
                                    note: false,
                                    description: false,
                                    accessory: false,
                                    warrantyStatus: false,
                                    serviceType: false,
                                  });
                                  setEditAndSave(false);
                                }}
                                size={"24px"}
                              >
                                <IconDeviceFloppy size={"18px"} />
                              </ActionIcon>
                            </Tooltip>
                          )}
                          <Tooltip label="Xóa">
                            <ActionIcon
                              onClick={() => handleRemove(item)}
                              color="red"
                              size={"24px"}
                            >
                              <IconTrash size={"18px"} />
                            </ActionIcon>
                          </Tooltip>
                        </Flex>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.warrantyReceiveTicketCode}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {getItemDetailsById(item.itemId ?? 0, "itemCode")}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {getItemDetailsById(item.itemId ?? 0, "itemName")}
                        </Text>
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {item.orgSerial}
                        </Text>
                      </Table.Th>
                      <Table.Th
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditFILDC.accessory ? (
                          <TextInput
                            value={item.accessory ?? ""}
                            onChange={(value: any) =>
                              handleChangeValue(
                                "accessory",
                                value.currentTarget.value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <Text fw={"bold"} size="xs">
                            {item.accessory}
                          </Text>
                        )}
                      </Table.Th>
                      <Table.Th
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditFILDC.warrantyStatus ? (
                          <Select
                            data={[
                              {
                                label: "Có phiếu bảo hành",
                                value: "Có phiếu bảo hành",
                              },
                              {
                                label: "Đã chuyển lên trước",
                                value: "Đã chuyển lên trước",
                              },
                              {
                                label: "Không có phiếu bảo hành",
                                value: "Không có phiếu bảo hành",
                              },
                            ]}
                            value={item.warrantyStatus ?? ""}
                            onChange={(value: any) =>
                              handleChangeValue(
                                "warrantyStatus",
                                value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <Text fw={"bold"} size="xs">
                            {item.warrantyStatus}
                          </Text>
                        )}
                      </Table.Th>
                      <Table.Th
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditFILDC.serviceType ? (
                          <Select
                            data={[
                              {
                                label: "Bảo hành",
                                value: "Bảo hành",
                              },
                              {
                                label: "Hỗ trợ",
                                value: "Hỗ trợ",
                              },
                              {
                                label: "Tạm nhận",
                                value: "Tạm nhận",
                              },
                            ]}
                            value={item.serviceType ?? ""}
                            onChange={(value: any) =>
                              handleChangeValue(
                                "serviceType",
                                value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <Text fw={"bold"} size="xs">
                            {item.serviceType}
                          </Text>
                        )}
                      </Table.Th>
                      <Table.Th>
                        <Text size="xs" fw={"bold"}>
                          {getItemDetailsById(
                            item.itemId ?? 0,
                            "warrantyVendor"
                          )}
                        </Text>
                      </Table.Th>
                      <Table.Th
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) &&
                        isEditFILDC.description ? (
                          <TextInput
                            value={item.description ?? ""}
                            onChange={(value: any) =>
                              handleChangeValue(
                                "description",
                                value.currentTarget.value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <Text fw={"bold"} size="xs">
                            {item.description}
                          </Text>
                        )}
                      </Table.Th>
                      <Table.Th
                        onMouseDown={(event: React.MouseEvent) =>
                          event.stopPropagation()
                        }
                      >
                        {editIndex === Number(index) && isEditFILDC.note ? (
                          <TextInput
                            value={item.note ?? ""}
                            onChange={(value: any) =>
                              handleChangeValue(
                                "note",
                                value.currentTarget.value,
                                Number(index)
                              )
                            }
                            size="xs"
                          />
                        ) : (
                          <Text fw={"bold"} size="xs">
                            {item.note}
                          </Text>
                        )}
                      </Table.Th>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </ScrollArea>
            <Flex w={"100%"} justify={"end"} mt={10}>
              <Button
                color="blue"
                size="xs"
                leftSection={<IconPlus size={"14px"} />}
                onClick={() => setToFieldSetProduct(!toFieldSetProduct)}
              >
                Thêm phiếu
              </Button>
            </Flex>
          </Fieldset>
        ) : (
          <Fieldset legend="Danh sách phiếu nhận hàng lỗi" mt={10}>
            {branchId && branchId !== null && branchId !== "" ? (
              <>
                <ScrollArea
                  h={360}
                  onScrollPositionChange={({ y }) => setScrolled1(y !== 0)}
                  scrollbars="x"
                >
                  <Table
                    striped
                    highlightOnHover
                    withTableBorder
                    withColumnBorders
                    w={1920}
                  >
                    <Table.Thead
                      className={cx(classes.header, {
                        [classes.scrolled]: scrolled1,
                      })}
                    >
                      <Table.Tr>
                        <Table.Th>Thao tác</Table.Th>
                        <Table.Th>Ngày nhận</Table.Th>
                        <Table.Th>Số phiếu</Table.Th>
                        <Table.Th>Mã sản phẩm</Table.Th>
                        <Table.Th>Tên sản phẩm</Table.Th>
                        <Table.Th>Mã vạch</Table.Th>
                        <Table.Th>Serial gốc</Table.Th>
                        <Table.Th>Hạn NCC</Table.Th>
                        <Table.Th>Nguồn từ NCC</Table.Th>
                        <Table.Th>Thời hạn bảo hành</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {dataWarrantyReceiveTicket?.map((item) => (
                        <Table.Tr key={item.id}>
                          <Table.Th>
                            <Tooltip label="Chọn">
                              <ActionIcon
                                onClick={(event) => {
                                  item.disabled
                                    ? event.preventDefault()
                                    : handleSelect(item);
                                }}
                                color={item.disabled ? "gray" : "teal"}
                                size={"24px"}
                              >
                                <IconCheckbox size={"18px"} />
                              </ActionIcon>
                            </Tooltip>
                          </Table.Th>
                          <Table.Th>
                            <DatePickerInput
                              value={
                                item.receivedDate
                                  ? new Date(item.receivedDate)
                                  : null
                              }
                              valueFormat="DD/MM/YYYY"
                              variant="unstyled"
                              size="xs"
                              readOnly
                            />
                          </Table.Th>
                          <Table.Th>
                            <Text size="xs" fw={"bold"}>
                              {item.errorNumber}
                            </Text>
                          </Table.Th>
                          <Table.Th>
                            <Text size="xs" fw={"bold"}>
                              {getItemDetailsById(item.itemId ?? 0, "itemCode")}
                            </Text>
                          </Table.Th>
                          <Table.Th>
                            <Text size="xs" fw={"bold"}>
                              {getItemDetailsById(item.itemId ?? 0, "itemName")}
                            </Text>
                          </Table.Th>
                          <Table.Th>
                            <Text size="xs" fw={"bold"}>
                              {item.serial}
                            </Text>
                          </Table.Th>
                          <Table.Th>
                            <Text size="xs" fw={"bold"}>
                              {item.parentSerial}
                            </Text>
                          </Table.Th>
                          <Table.Th></Table.Th>
                          <Table.Th></Table.Th>
                          <Table.Th>
                            <Text size="xs" fw={"bold"}>
                              {getItemDetailsById(
                                item.itemId ?? 0,
                                "warrantyVendor"
                              )}
                            </Text>
                          </Table.Th>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>
                </ScrollArea>
                <Flex w={"100%"} justify={"end"} mt={10}>
                  <Button
                    color="teal"
                    size="xs"
                    leftSection={<IconCheck size={"14px"} />}
                    disabled={faultyItemListDetailCommands.length === 0}
                    onClick={() => setToFieldSetProduct(!toFieldSetProduct)}
                  >
                    Xác nhận
                  </Button>
                </Flex>
              </>
            ) : (
              <Flex align={"center"} w={"100%"} h={370} justify={"center"}>
                <Text size="12.5px" fw={500} c={"gray"}>
                  Hãy chọn trung tâm để lấy danh sách phiếu nhận hàng lỗi
                </Text>
              </Flex>
            )}
          </Fieldset>
        )}
      </Box>
    </>
  );
};

export default EditDataView;

type EditDataViewProps = {
  id: string | number;
  onClose: any;
  handleEdit: any;
};
