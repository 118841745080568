import { Center, Flex, Grid, Image } from "@mantine/core";
import { IconMail, IconPhone, IconWorldWww } from "@tabler/icons-react";
import { forwardRef, useEffect, useState } from "react";
import styles from "./PrintStyle.module.css";
interface PrintedInvoiceProps {
  dataPrint: any;
  typePrint: string;
  userInfo: any;
  currentDate: any;
}

const PrintWarehouseOutWard = forwardRef<HTMLDivElement, PrintedInvoiceProps>(
  ({ dataPrint, typePrint, userInfo, currentDate }, ref) => {
    const dataTransaction = dataPrint?.tblInventoryTransactionModel;
    const dataSerials = dataPrint?.inventoryTransactionSerialModels;
    const totalQuantity = dataSerials.reduce(
      (total: number, item: any) => total + item.primaryQuantity,
      0
    );

    const totalAmount = dataSerials.reduce(
      (total: number, item: any) => total + item.soPrice * item.primaryQuantity,
      0
    );
    const formatCurrency = (value: any) => {
      if (value == null) {
        return "";
      }
      // Định dạng số theo chuẩn Việt Nam
      return value.toLocaleString("vi-VN", {
        minimumFractionDigits: 0, // Không hiển thị phần thập phân
        maximumFractionDigits: 0, // Đảm bảo không có chữ số sau dấu phẩy
      });
    };
    function formatDateTransfer(dateString: string): string {
      const date = new Date(dateString);

      // Lấy ngày, tháng, năm từ đối tượng Date
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng bắt đầu từ 0
      const year = date.getFullYear();

      // Tạo chuỗi định dạng `DD.MM.YYYY`
      return `${day}.${month}.${year}`;
    }

    return (
      <div ref={ref}>
        <div className={styles.containerPrint}>
          <div className={styles.contentMain} style={{ paddingTop: "5px" }}>
            <Grid>
              <Grid.Col span={4}>
                <Flex direction="column">
                  <h6 style={{ margin: 0, fontSize: "9px", fontWeight: 600 }}>
                    Công Ty Cổ Phần Đầu Tư Công Nghệ HACOM
                  </h6>
                  <p style={{ margin: 0, fontSize: "8px" }}>
                    CN: 129 Lê Thanh Nghị, P. Đồng Tâm, Q. Hai Bà Trưng, TP. Hà
                    Nội
                  </p>
                  <Flex direction="row" gap="3px" align="top">
                    <Flex align="flex-start" gap="3px">
                      <IconPhone
                        stroke={2}
                        style={{ width: "8px", paddingBottom: "13px" }}
                      />
                      <p
                        style={{ fontSize: "8px", margin: 0 }}
                        className={styles.text}
                      >
                        19001903{" |"}
                      </p>
                    </Flex>
                    <Flex align="flex-start" className={styles.text} gap="3px">
                      <IconMail
                        stroke={2}
                        style={{ width: "8px", paddingBottom: "13px" }}
                      />
                      <p style={{ margin: 0, fontSize: "8px" }}>
                        dichvukhachhang@hacom.vn{" |"}
                      </p>
                    </Flex>
                    <Flex align="flex-start" className={styles.text} gap="3px">
                      <IconWorldWww
                        stroke={2}
                        style={{ width: "8px", paddingBottom: "13px" }}
                      />
                      <p style={{ margin: 0, fontSize: "7px" }}>Hacom.vn</p>
                    </Flex>
                  </Flex>
                </Flex>
              </Grid.Col>
              <Grid.Col span={4} style={{ paddingTop: "20px" }}>
                <h1
                  className={styles.text}
                  style={{
                    fontWeight: "700",
                    lineHeight: "1",
                    fontSize: "18px",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  PHIẾU XUẤT KHO
                </h1>
              </Grid.Col>
              <Grid.Col span={4}>
                <Flex
                  direction="row"
                  justify="flex-end"
                  className={styles.warehouseCode}
                >
                  <p>Mã Xuất Kho: </p>
                  <p>{dataTransaction?.transactionCode}</p>
                </Flex>
                <Flex
                  direction="row"
                  justify="flex-end"
                  className={styles.warehouseCode}
                >
                  <p>Mã Yêu Cầu Xuất Kho: </p>
                  <p>{dataTransaction?.sourceCode}</p>
                </Flex>
              </Grid.Col>
            </Grid>
            {/* bảng thông tin bên xuất, bên nhận */}
            <div className={styles.customerInformation}>
              <table>
                <thead>
                  <tr>
                    <th colSpan={2} style={{ width: "50%" }}>
                      BÊN XUẤT
                    </th>
                    <th colSpan={2} style={{ width: "50%" }}>
                      BÊN NHẬN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Phòng ban
                    </td>
                    <td>{dataTransaction?.fromBranchName}</td>
                    {typePrint === "XUAT_BAN" ? (
                      <>
                        {" "}
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Họ và tên
                        </td>
                        <td> {dataTransaction?.cusName}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Tên Nhà Cung Cấp
                        </td>
                        <td>{dataTransaction?.cusName}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Địa điểm làm việc
                    </td>
                    <td>{dataTransaction?.fromBranchAddress}</td>
                    {typePrint === "XUAT_BAN" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Số điện thoại
                        </td>
                        <td> {dataTransaction?.cusTelephone}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Mã Nhà Cung Cấp
                        </td>
                        <td>{dataTransaction?.cusCode}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Bộ phận
                    </td>
                    <td>{userInfo?.depName}</td>
                    {typePrint === "XUAT_BAN" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Tỉnh / Thành
                        </td>
                        <td>{dataTransaction?.cusProvinceName}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Người nhận
                        </td>
                        <td>{dataTransaction?.toEmpName}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Chức danh
                    </td>
                    <td>{userInfo?.roleName}</td>
                    {typePrint === "XUAT_BAN" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Quận / Huyện
                        </td>
                        <td>{dataTransaction?.cusDestrictName}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Số điện thoại
                        </td>
                        <td>{dataTransaction?.cusTelephone}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Họ và tên
                    </td>
                    <td>{userInfo?.fullName}</td>
                    {typePrint === "XUAT_BAN" ? (
                      <>
                        {" "}
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Phường / Xã
                        </td>
                        <td>{dataTransaction?.cusCommuneName}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {""}
                        </td>
                        <td>{""}</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "16%",
                        maxWidth: "30%",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Kho
                    </td>
                    <td>{dataTransaction?.fromInvName}</td>
                    {typePrint === "XUAT_BAN" ? (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Địa chỉ cụ thể
                        </td>
                        <td>{dataTransaction?.cusAddress}</td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{
                            width: "16%",
                            maxWidth: "30%",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {""}
                        </td>
                        <td>{""}</td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>

            {/* lý do */}
            <div style={{ margin: "5px 0" }}>
              <Flex direction="row">
                <p style={{ margin: 0, marginRight: "5px" }}>Lý do:</p>
                <p style={{ margin: 0 }}>
                  {typePrint === "XUAT_BAN"
                    ? "Xuất Bán"
                    : "Xuất trả Nhà Cung Cấp"}
                </p>
              </Flex>
              <div>
                <p style={{ margin: 0 }}>Ghi chú:</p>
              </div>
            </div>
            {/* table sản phẩm */}
            <div className={styles.ProductMain}>
              <table>
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Mã Hàng</th>
                    <th style={{ width: "10px !important" }}>Serial</th>
                    <th className={styles.thNameProduct}>Tên sản phẩm</th>
                    <th>ĐVT</th>
                    <th>SL</th>
                    {typePrint === "XUAT_TRA_NCC" ? (
                      <>
                        <th>ĐVT2</th>
                        <th>SL2</th>
                      </>
                    ) : (
                      <>
                        <th>Giá bán lẻ</th>
                        <th>Thành tiền</th>
                      </>
                    )}

                    {/* <th>Tình trạng</th> */}
                    <th>Ghi chú</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colSpan={6}
                      style={{
                        textAlign: "end",
                        marginRight: "3px",
                        border: "none",
                      }}
                    >
                      {totalQuantity}
                    </td>
                    <td style={{ border: "none" }}></td>
                    {typePrint === "XUAT_TRA_NCC" ? (
                      <>
                        <td style={{ border: "none" }}></td>
                      </>
                    ) : (
                      <>
                        <td style={{ textAlign: "end", border: "none" }}>
                          {formatCurrency(totalAmount)}
                        </td>
                      </>
                    )}
                    <td style={{ border: "none" }} colSpan={2}></td>
                  </tr>
                  {dataSerials?.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item?.itemCode}</td>
                        <td
                          style={{ width: "10px" }}
                          className={styles.tdSerial}
                        >
                          {item?.serial}
                        </td>
                        <td>{item?.itemName}</td>
                        <td>{item?.primaryUomCode} </td>
                        <td style={{ textAlign: "center" }}>
                          {item?.primaryQuantity}
                        </td>
                        {typePrint === "XUAT_TRA_NCC" ? (
                          <>
                            <td>{item?.subUomCode}</td>
                            <td>{item?.subQuantity}</td>
                          </>
                        ) : (
                          <>
                            <td style={{ textAlign: "end" }}>
                              {formatCurrency(item?.soPrice)}
                            </td>
                            <td style={{ textAlign: "end" }}>
                              {formatCurrency(
                                item?.soPrice * item?.primaryQuantity
                              )}
                            </td>
                          </>
                        )}

                        {/* <td>{item.serialStatus}</td> */}
                        {/* <td>Mới 100%</td> */}
                        <td>{item?.note}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/*  */}
            <div style={{ marginTop: "5px" }}>
              <Flex
                direction="row"
                justify="flex-end"
                style={{ width: "100%", fontSize: "14px" }}
              >
                <div>
                  Hà Nội ngày{" "}
                  <strong style={{ fontStyle: "italic" }}>{currentDate}</strong>{" "}
                </div>
              </Flex>
              <div>
                <Grid>
                  <Grid.Col span={4}>
                    <Center style={{ fontWeight: "700" }}>BÊN XUẤT</Center>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Center style={{ fontWeight: "700" }}>GIAO VẬN</Center>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Center style={{ fontWeight: "700" }}>BIÊN NHẬN</Center>
                  </Grid.Col>
                </Grid>
              </div>
            </div>
            <div className={styles.backgroudHacom}>
              <Image
                radius="md"
                src="https://hanoicomputercdn.com/media/lib/17-10-2024/logo-hacom-since-2001_new.png"
                className={styles.logo}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PrintWarehouseOutWard;
