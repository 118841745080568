import React from "react";
import { Select, Text, TextInput, Tooltip } from "@mantine/core";
import RenderTable from "./RenderTable";
import { formatDateTransfer } from "../../../../common/FormatDate/FormatDate";
import { tblInventoryTransaction } from "../../../../model/TblOutWard"; // Assuming RenderTable is in the same directory

const RequestTransferOutWard = ({
  dataSearchSourceCodeOption,
  dataOutWard,
  sourceCode,
  setSourceCode,
  handleKeyDownSourceCode,
  dataOrderDetail,
  handleChangeValueInput,
  userInfo,
  isSave,
  searchSourceCode,
  setSearchSourceCode,
  isEdit,
  isLocked,
  haveStatus,
  isInternal,
  haveSourceCode,
}: RequestTransferOutWardProps) => {
  const headers = [
    { label: "", style: { width: "22%" } },
    { label: "Bên xuất", style: { width: "38%" } },
    { label: "Bên nhận", style: { width: "38%" } },
  ];

  const getDeliveryText = (delivery: string) => {
    switch (delivery) {
      case "KHONG":
        return "Nhận tại cửa hàng";
      case "NOI_BO":
      case "HA_COM":
        return "Nội bộ";
      default:
        return delivery;
    }
  };

  const rows = [
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian tạo
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOutWard.createDate ?? "")}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Thời gian hoàn thành
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {formatDateTransfer(dataOutWard?.finishDate)}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu xuất kho
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOutWard?.transactionCode}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Mã phiếu yêu cầu xuất kho
          </Text>
        ),
      },
      {
        content:
          !isEdit && !haveSourceCode ? (
            <Tooltip
              label={
                dataOutWard?.sourceCode
                  ? dataOutWard?.sourceCode
                  : "Vui lòng chọn phiếu"
              }
            >
              <Select
                w={"160px"}
                placeholder="Mã phiếu yêu cầu xuất kho"
                data={dataSearchSourceCodeOption}
                size="sm"
                disabled={
                  !dataOutWard.sourceType ||
                  dataOutWard.status === "LOCK" ||
                  sourceCode ||
                  isSave
                }
                searchable
                clearable
                onSearchChange={(e) => {
                  if (setSearchSourceCode) {
                    setSearchSourceCode(e);
                  }
                }}
                onKeyDown={handleKeyDownSourceCode}
                value={sourceCode || dataOutWard.sourceCode}
                onChange={(value) => {
                  if (handleChangeValueInput) {
                    handleChangeValueInput(value || "", "sourceCode");
                  }
                  if (setSourceCode) {
                    setSourceCode(value);
                  }
                }}
                nothingFoundMessage={"Không tìm thấy dữ liệu !"}
              />
            </Tooltip>
          ) : (
            <Text
              w={"150px"}
              size="sm"
              style={{ whiteSpace: "normal" }}
              lineClamp={2}
              truncate
            >
              {dataOutWard.sourceCode}{" "}
            </Text>
          ),
      },
      { content: null },
    ],
    haveStatus
      ? [
          {
            content: (
              <Text
                fw={700}
                w={"100px"}
                size="sm"
                style={{ whiteSpace: "normal" }}
                lineClamp={2}
                truncate
              >
                Tình trạng
              </Text>
            ),
          },
          { content: null },
          { content: null },
        ]
      : [],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chi nhánh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromBranchName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toBranchName}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Địa điểm làm việc
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromBranchAddress}
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toBranchAddress}
          </Text>
        ),
      },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Kho
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.fromInvName}
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {dataOrderDetail.toInvName}
          </Text>
        ),
      },
    ],
    isInternal
      ? [
          {
            content: (
              <Text fw={700} size={"sm"}>
                Vị trí
              </Text>
            ),
          },
          {
            content: (
              <Text size={"sm"}>{dataOrderDetail.fromPositionName}</Text>
            ),
          },
          {
            content: <Text size={"sm"}>{dataOrderDetail.toPositionName}</Text>,
          },
        ]
      : [],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Họ & tên
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {userInfo?.fullName || ""}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Chức danh
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {userInfo?.roleName || ""}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Giao hàng
          </Text>
        ),
      },
      {
        content: (
          <Text
            w={"150px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            {getDeliveryText(dataOrderDetail?.delivery || "")}
          </Text>
        ),
      },
      { content: null },
    ],
    [
      {
        content: (
          <Text
            fw={700}
            w={"100px"}
            size="sm"
            style={{ whiteSpace: "normal" }}
            lineClamp={2}
            truncate
          >
            Ghi chú
          </Text>
        ),
      },
      {
        content: (
          <TextInput
            error={
              dataOutWard?.description && dataOutWard.description.length > 300
                ? "Ghi chú không được vượt quá 300 kí tự!"
                : null
            }
            value={dataOutWard?.description ?? ""}
            onChange={(e) => {
              handleChangeValueInput(e.target.value, "description");
            }}
            disabled={isLocked}
            placeholder={isLocked ? "" : "Vui lòng nhập"}
          />
        ),
        colSpan: 2,
      },
    ],
  ];

  return (
    <RenderTable
      headers={headers}
      rows={rows.filter((item) => item.length !== 0)}
    />
  );
};

type RequestTransferOutWardProps = {
  dataSearchSourceCodeOption?: any[];
  dataOutWard: tblInventoryTransaction;
  dataOrderDetail: any;
  userInfo: any;
  handleChangeValueInput: (value: string | boolean | null, key: string) => void;
  setSourceCode?: (value: any) => void;
  handleKeyDownSourceCode?: (e: any) => void;
  sourceCode?: any;
  isSave?: boolean;
  searchSourceCode?: string;
  setSearchSourceCode?: (newValue: React.SetStateAction<string>) => void;
  isEdit?: boolean;
  isLocked?: boolean;
  haveStatus?: boolean;
  isInternal?: boolean;
  haveSourceCode?: boolean;
};

export default RequestTransferOutWard;
