import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridCol,
  Group,
  Modal,
  NumberFormatter,
  NumberInput,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
  Title,
  Tooltip,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { useDebouncedValue, useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import {
  IconBellCheck,
  IconBellQuestion,
  IconBellRinging,
  IconBellX,
  IconBrandCashapp,
  IconCheck,
  IconMinus,
  IconPlus,
  IconSearch,
  IconTrash,
  IconX,
} from "@tabler/icons-react";
import cx from "clsx";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_RowSelectionState,
  MRT_SortingState,
  useMantineReactTable,
} from "mantine-react-table";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  repositoryDelivery,
  repositoryMdm,
  repositoryPos,
} from "../../../_base/_const/_constVar";
import _breadcrumb from "../../../_base/component/_layout/_breadcrumb";
import classes from "../../../_base/component/_layout/TableScrollArea.module.css";
import { NotificationExtension } from "../../../_base/extension/NotificationExtension";
import { styleCellTable } from "../../../_setup/navdata/baseStyleCellTable";
import { formatDateTransfer } from "../../../common/FormatDate/FormatDate";
import EditableTextWithOptions from "../../../common/selectValue";
import { _localization } from "../../../config/location";
import { cobalt_blue, green } from "../../../const/variables";
import { MessageResponse } from "../../../model/MessageResponse";
import { TblDebtGroup } from "../../../model/TblDebtGroup";
import { TblDMPaymentMethod } from "../../../model/TblDMPaymentMethod";
import AddFormDebt from "../AddFormDebt/AddFormDebt";
import { EmployeeModel } from "../../../model/Employee";

interface DetailsOrderHeader {
  stt: number;
  orderNumber: string | null;
  //ma doi tuong
  attribute2: string;
  // ten doi tuong
  attribute7: string;
  attribute8?: string;
  amountTotal: number;
  amountPaid: number;
  amountRemaining: number;
  key?: string;
  allocatedAmount: number;
  note: string;
  action?: string;
}
interface Command {
  payType: string | null;
  casherId: number | null;
  payeeId: number | null;
  note: string | null;
  payAmount: number | null;
  orderId: number | null;
}

interface PaymentReceiptDetailCommand {
  id: number;
  receiptNumber: string;
  receiptDate: string | null;
  customerName: string | null;
  receiptType: string | null;
  paymentMethod: string | null;
  paymentTerms: string | null;
  cashier: string;
  createdDate: string;
  payerName: string | null;
  payerAddress: string | null;
  fundAccount: string | null;
  bankName: string | null;
  expenseCode: string | null;
  totalAmount: number | null;
  statusCode: string | null;
}

interface Item {
  orderNumber: string;
  orderDate: string;
  amountTotal: number;
  amountPaid: number;
  amountRemaining: number;
  allocatedAmount: number; // Thay đổi từ string sang number
  note: string;
  id: number;
}

interface CreateReceiptParentRequest {
  receiptCommand: Command[];
  paymentReceiptDetailCommand: FundDetails;
  save: boolean | null | undefined;
}

interface Receipt {
  id: number;
  orderNumber: string;
  orderTypeId: number;
  orderDate: string; // Có thể dùng Date nếu bạn muốn chuyển đổi
  orderStatus: string;
  note?: string | null;
  orgId: number;
  invOrgId: number;
  deptId?: number | null;
  divisionId?: number | null;
  saleChanelId?: number | null;
  sourceOrderNumber?: string | null;
  sourceDocument?: string | null;
  sourceInvoices?: string | null;
  custId: number;
  custGroupId?: number | null;
  contactPersonId?: number | null;
  billToLocationId?: number | null;
  shipToLocationId?: number | null;
  invoiceSerial?: string | null;
  invoiceNumber?: string | null;
  invoiceDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  taxCode?: string | null;
  taxAddr?: string | null;
  amountTotal: number;
  amountDiscount: number;
  amountVoucher?: number | null;
  amountVat: number;
  currentcyCode: string;
  currentcyRate?: number | null;
  amountPaid: number;
  amountRemaining: number;
  paymentType?: string | null;
  paymentStatus?: string | null;
  paymentMethod?: string | null;
  paymentDue: number;
  saleId: number;
  managerId?: number | null;
  approveId?: number | null;
  approveNote?: string | null;
  rejectNote?: string | null;
  shipngType?: string | null;
  shipingVendor?: string | null;
  shippingCode?: string | null;
  shippingStatus?: string | null;
  shippingPriority?: string | null;
  shippingDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  shippingNote?: string | null;
  technicalChecked: number;
  technicalNote?: string | null;
  attribute1?: string | null;
  attribute2?: string | null;
  attribute3?: string | null;
  attribute4?: string | null;
  attribute5?: string | null;
  attribute6?: string | null;
  attribute7?: string | null;
  attribute8?: string | null;
  attribute9?: string | null;
  attribute10?: string | null;
  attribute11?: string | null;
  attribute12?: string | null;
  attribute13?: string | null;
  attribute14?: string | null;
  attribute15?: string | null;
  clientId?: number | null;
  createBy: number;
  createDate: string; // Có thể dùng Date nếu bạn muốn chuyển đổi
  lastUpdateBy?: number | null;
  lastUpdateDate?: string | null; // Có thể dùng Date nếu bạn muốn chuyển đổi
  orderStatusId: number;
  salesShift?: string | null;
  printInvoiceCheck?: string | null;
  value?: any;
  allocatedAmount?: number;
}

interface FundDetails {
  accountfund: number | null;
  codefund: string | null;
  createby: number;
  createdDate: string;
  creatorbranch: string | null | undefined;
  creatordepartment: number | null | undefined;
  creatorfullname: string | null;
  creatorsalesbranch: string | null;
  creatorworkplace: string | null;
  fundbranch: string | null;
  funddepartment: string | null;
  fundsalesbranch: string | null;
  fundworkplace: string | null;
  funfullname: string | null;
  id: number;
  payerdescription: string | null;
  payerfullname: string | null;
  payernote: string | null;
  payertype: string | null;
  receiptNumber: string;
  status?: string | null;
  bankname?: string | null;
  accountnumber?: string | null;
  completiontime?: string | null;
  createrolename?: number | null;
  attribute2?: string | null;
  attribute5?: string | null;
  totalAmount: number;
  type: number;
  typeReceipt: number;
}

interface AccountingCenter {
  accountingCenterId: number;
  active: boolean;
  address: string;
  brachName: string | null;
  branchId: number | null;
  code: string;
  createDate: string;
  currentCardId: number | null;
  dateOfBirth: string;
  departmentCode: string | null;
  departmentId: number | null;
  departmentName: string;
  description: string | null;
  email: string;
  fullname: string;
  gender: number;
  id: number;
  isCrm: boolean;
  listBranch: any[] | null;
  listDepartment: any[] | null;
  listGroup: any[] | null;
  listInventory: any[] | null;
  managerId: number;
  managerName: string | null;
  partCode: string | null;
  phoneNumber: string | null;
  roleId: number;
  roleName: string;
  workAddress: string | null;
}

interface SearchParams {
  searchKey: string;
  searchFrom: string;
  searchTo: string;
  handeDateStart: Date | null;
  handeDateEnd: Date | null;
  searchFromFull: string;
  searchToFull: string;
}

interface Department {
  id: number;
  name: string;
}

interface DepartmentUser {
  departmentId: number;
  name: string;
  description: string | null;
  active: boolean;
  departmentCode: string;
  creationDate: string;
  createdBy: string;
  lastUpdateDate: string | null;
  lastUpdatedBy: string | null;
  address: string;
  branchId: number;
}

interface Payment {
  payType: string | null;
  casherId: number | null;
  payeeId: number | null;
  note: string | null;
  payAmount: number | null;
  orderId: number | null;
  payCode: string | null;
  attribute1: string | null;
  attribute2: string | null;
  attribute3: string | null;
  attribute4: string | null;
  attribute5: string | null;
  attribute6: string | null;
  attribute7: string | null;
  attribute8: string | null;
  attribute9: string | null;
  attribute10: string | null;
  attribute11: string | null;
  attribute12: string | null;
  attribute13: string | null;
  attribute14: string | null;
  attribute15: string | null;
}

interface UserData {
  id: number;
  code: string;
  depName: string;
  branchId: number;
  branchName: string;
  managerId: number | null;
  departmentId: number;
  fullName: string;
  roleId: number;
  roleName: string;
  managerName: string | null;
}

interface ReceiptCommand {
  receiptCommand: any[];
  paymentReceiptDetailCommand: PaymentReceiptDetailCommand;
}

export const PaymentReceipt = () => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [height, setHeight] = useState(0);
  const [rowCount] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [heightContent, setHeightContent] = useState(0);
  const [, setEmployeeSalesName] = useState("");
  const headerRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const previousDataListBranchOrder = useRef<string | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [options, setOptions] = useState("488");

  const [dataListBranchOrder, setDataListBranchOrder] = useState<any>();
  const [dataAlloation, setDataAlloation] = useState(false);
  const [totalAllocatedAmount, setTotalAllocatedAmount] = useState<any>(0);
  const [totalAmountRemaining, setTotalAmountRemaining] = useState<number>(0);
  const [dataListReceipt, setDataListReceipt] = useState<Receipt[]>([]);
  const [dataListBranch, setDataListBranch] = useState<Receipt[]>([]);

  const [tempSelectedItems, setTempSelectedItems] = useState<Receipt[]>([]);
  const [finalSelectedItems, setFinalSelectedItems] = useState<Receipt[]>([]);

  const [searchKey, setSearchKey] = useState<any>();
  const [searchTO, setSearchTo] = useState<any>();
  const [searchFrom, setSearchFrom] = useState<any>();
  const [completeTime, setCompleteTime] = useState<Date | null>(null);

  const [handelKey, setHandelKey] = useState<any>();
  const [paymenBankAccount, setPaymenBankAccount] = useState<any[]>();
  const [selectPayertype, setSelectPayertype] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<TblDMPaymentMethod[]>();
  const [paymentTypes, setPaymentTypes] = useState<any[]>();
  const [listBranch, setListBranch] = useState<any[]>([]);
  const [listEmployee, setListEmployee] = useState<AccountingCenter[]>([]);
  const [valueTotalAmount, setValueTotalAmout] = useState<any>(null);
  const [valueTotalAmountPaid, setValueTotalAmoutPaid] = useState<any>(null);
  const [valueTotalRemaining, setValueTotalRemaining] = useState<any>(null);
  const [handleState, setHandleState] = useState<boolean>(false);
  const [changeValueTotal, setChangeValueTatal] = useState<any>(0);
  const [dataGetReceiptParent, setDataGetReceiptParent] = useState<any>();
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [badgeContent, setBadgeContent] = useState<string>(
    "Số tiền không xác định"
  );
  const [badgeColor, setBadgeColor] = useState<string>("gray");
  const [badIcon, setBadIcon] = useState<React.ReactNode>(null);
  const today = new Date();
  const [isError] = useState(false);
  const [isLoading] = useState(false);
  const [isRefetching] = useState(false);
  const [sorting] = useState<MRT_SortingState>([]);
  const userName = localStorage.getItem("userLogin") || "";
  const [statusReponse, setStatusReponse] = useState<any>();
  const [listEmployeeID, setListEmployeeID] = useState<EmployeeModel[]>([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [scrolled, setScrolled] = useState(false);
  const [inputValues, setInputValues] = useState<{ [key: number]: any }>({});
  const [newDate, setNewDate] = useState<Date | null>(new Date());
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [stoppedTime, setStoppedTime] = useState<Date | null>();
  const [branchDeparment, setBranchDeparment] = useState<DepartmentUser>();
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [handeDateStart, setHanDateStart] = useState<any>();
  const [handeDateEnd, setHanDateEnd] = useState<any>();
  const [debouncedChangeValueTotal] = useDebouncedValue(changeValueTotal, 500);
  const [debouncedChangeValueAmout] = useDebouncedValue(
    totalAllocatedAmount,
    500
  );
  const [showBadge, setShowBadge] = useState<boolean>(true);
  const [fundepartmentId, setFundepartmentId] = useState<number | null>(null);
  console.log(fundepartmentId);
  const [handelDateCurrent, setHandelDateCurrent] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const [groupData, setGroupData] = useState({
    id: dataGetReceiptParent?.attribute2?.id || "",
    groupName: dataGetReceiptParent?.attribute2?.groupName || "",
    groupCreateByName: dataGetReceiptParent?.attribute2?.createByName || "",
  });

  const [currentTimeDelay, setCurrentTimeDelay] = useState<Date | null>();
  const [currentTime, setCurrentTime] = useState<Date | null>();
  const [inputValue, setInputValue] = useState("");

  const [searchParams, setSearchParams] = useState<SearchParams>({
    searchKey: "",
    searchFrom: "",
    searchTo: "",
    handeDateStart: null,
    handeDateEnd: null,
    searchFromFull: "",
    searchToFull: "",
  });

  const [formErrors, setFormErrors] = useState({
    payerfullname: "",
    accountfund: 0,
    codefund: "",
    createby: 0,
    createdDate: "",
    creatorbranch: "",
    creatordepartment: "424",
    creatorfullname: "",
    creatorsalesbranch: "",
    creatorworkplace: "",
    fundbranch: "",
    funddepartment: "",
    fundsalesbranch: "",
    fundworkplace: "",
    funfullname: "",
    id: 0,
    payerdescription: "",
    payernote: "",
    payertype: "",
    receiptNumber: "",
    totalAmount: 0,
    type: 0,
  });

  const columns = React.useMemo<MRT_ColumnDef<DetailsOrderHeader>[]>(
    () => [
      {
        accessorKey: "STT",
        header: "STT",
        enableColumnActions: false,
        enableEditing: false,
        enableSorting: false,
        size: 50,
        Cell: ({ renderedCellValue, row }: any) => (
          <Text fw={600}>{row.index + 1}</Text>
        ),
      },
      {
        accessorKey: "orderNumber",
        header: "Số đơn hàng",
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, cell }) => (
          <Badge
            radius="sm"
            variant="dot"
            size="md"
            color={renderedCellValue === null ? "red" : green.base}
          >
            {renderedCellValue === null ? null : renderedCellValue}
          </Badge>
        ),
      },
      {
        accessorKey: "attribute2",
        header: "Mã đối tượng công nợ",
        size: 200,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const value = row?.original?.attribute8 ?? "";
          const code = value.split("||")[1] || "";
          const text = code === "null" ? "" : code;
          return (
            <Text fw={600} w={150}>
              {text}
            </Text>
          );
        },
        // Cell: ({ cell, row, table, column }) => {
        //   return (
        //     <TextInput
        //       placeholder="Vui lòng nhập"
        //       flex={1}
        //       value={
        //         inputValues[row.index]?.attribute1 ?? row?.original?.attribute1
        //       }
        //       disabled={statusReponse}
        //       w={185}
        //       onBlur={(e) => {
        //         handleInputChange(row, "attribute1", e.currentTarget.value);
        //         handleInputChange(
        //           inputValues[row.index]?.attribute2 ??
        //             row?.original?.attribute2,
        //           "attribute2",
        //           e
        //         );
        //       }}
        //       onDoubleClick={() => openFormDebt("attribute1", row)}
        //     />
        //   );
        // },
      },
      {
        accessorKey: "attribute7",
        header: "Tên đối tượng công nợ",
        size: 200,
        enableEditing: false,
        enableSorting: false,
        enableColumnActions: false,
        Cell: ({ row }) => {
          const value = row?.original?.attribute8 ?? "";
          const code = value.split("||")[0] || "";
          const text = code === "null" ? "" : code;
          return (
            <Text fw={600} w={150}>
              {text}
            </Text>
          );
        },
        // Cell: ({ row }) => {
        //   return (
        //     <Text>
        //       {inputValues[row.index]?.attribute2 ?? row?.original?.attribute2}
        //     </Text>
        //   );
        // },
      },
      {
        accessorKey: "amountTotal",
        header: "Tổng tiền hóa đơn",
        enableEditing: false,
        enableSorting: false,

        enableColumnActions: false,

        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              style={{ textAlign: "center" }}
              thousandSeparator
              value={row.original.amountTotal}
            ></NumberFormatter>
          </>
        ),
      },
      {
        accessorKey: "amountPaid",
        header: "Số đã thanh toán",
        enableColumnActions: false,
        enableSorting: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <>
            <NumberFormatter
              thousandSeparator
              value={row.original.amountPaid}
            ></NumberFormatter>
          </>
        ),
      },
      {
        accessorKey: "amountRemaining",
        header: "Số còn phải TT",
        enableColumnActions: false,
        enableSorting: false,
        size: 100,
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => {
          return (
            <>
              <NumberFormatter
                thousandSeparator
                value={row.original.amountRemaining}
              />
            </>
          );
        },
      },
      {
        accessorKey: "key",
        header: "Số tiền phân bổ",
        enableSorting: false,
        enableColumnActions: false,
        size: 150,
        enableEditing: true,
        Cell: ({ cell, row }) => {
          return (
            <NumberInput
              hideControls
              disabled={statusReponse ? statusReponse : !dataAlloation}
              placeholder="Nhập số tiền..."
              value={
                dataAlloation &&
                row.original.allocatedAmount <= row.original.amountRemaining
                  ? row.original.allocatedAmount
                  : ""
              }
              thousandSeparator
              onChange={(value) =>
                handleInputChange(
                  row.index,
                  "allocatedAmount",
                  typeof value === "number" &&
                    value <= row.original.amountRemaining
                    ? value.toString()
                    : ""
                )
              }
            />
          );
        },
        Edit: ({ cell, row }) => {
          return (
            <NumberInput
              hideControls
              disabled={statusReponse ? statusReponse : !dataAlloation}
              placeholder="Nhập số tiền..."
              value={
                dataAlloation &&
                row.original.allocatedAmount <= row.original.amountRemaining
                  ? row.original.allocatedAmount
                  : ""
              }
              thousandSeparator
              onChange={(value) =>
                handleInputChange(
                  row.index,
                  "allocatedAmount",
                  typeof value === "number" &&
                    value <= row.original.amountRemaining
                    ? value.toString()
                    : ""
                )
              }
            />
          );
        },
      },
      {
        accessorKey: "note",
        header: "Diễn giải",
        enableSorting: false,
        enableColumnActions: false,
        enableEditing: true,
        Edit: ({ row }) => {
          const initialValue = row.original.orderNumber;
          const [value, setValue] = React.useState(initialValue);

          const handleChange = (newValue: string) => {
            setValue(newValue);
            handleInputChange(row.index, "note", newValue);
          };
          return (
            <TextInput
              disabled={statusReponse}
              placeholder="Nhập ghi chú..."
              value={value ?? ""}
              onChange={(event) => handleChange(event.currentTarget.value)}
            />
          );
        },
        Cell: ({ row }) => {
          const initialValue = row.original.orderNumber;
          const [value, setValue] = React.useState(initialValue);

          const handleChange = (newValue: string) => {
            setValue(newValue);
            handleInputChange(row.index, "note", newValue);
          };
          return (
            <TextInput
              disabled={statusReponse}
              placeholder="Nhập ghi chú..."
              value={value ?? ""}
              onChange={(event) => handleChange(event.currentTarget.value)}
            />
          );
        },
      },
      {
        accessorKey: "action",
        header: "Xóa",
        enablePinning: false,
        size: 60,
        enableSorting: false,
        enableColumnActions: false,
        enableEditing: false,
        Cell: ({ renderedCellValue, row, table }) => (
          <Flex justify="center">
            <Tooltip label="Xóa đơn">
              <ActionIcon
                variant="light"
                aria-label="Settings"
                color="red"
                disabled={statusReponse}
                onClick={() => deleteInvoice(row.original.orderNumber)}
              >
                <IconTrash size={20} stroke={1.5} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    [
      validationErrors,
      finalSelectedItems,
      dataAlloation,
      inputValues,
      statusReponse,
    ]
  );

  console.log(finalSelectedItems);
  const table = useMantineReactTable<any>({
    columns,
    data: finalSelectedItems,
    positionToolbarAlertBanner: "bottom",
    createDisplayMode: "row",
    editDisplayMode: "cell",
    enableEditing: true,
    enableBatchRowSelection: true,
    enableBottomToolbar: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <>
        <Flex
          gap="md"
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          mb={"xs"}
          style={{ opacity: 0 }}
        ></Flex>
      </>
    ),
    mantineTopToolbarProps: {
      style: {
        display: "none",
      },
    },

    mantineBottomToolbarProps: {
      style: {
        display: "none",
      },
    },

    localization: _localization,
    enableRowSelection: false,
    getRowId: (row) => row.id?.toString(),
    mantineTableContainerProps: {
      style: {
        maxHeight: height - 95,
        minHeight: height - 95,
        // borderTop: `5px solid #ec1c24`,
      },
    },
    mantineTableProps: {
      striped: true,
    },
    enablePinning: true,
    initialState: {
      columnPinning: {
        left: ["mrt-row-select", "mrt-row-actions"],
        right: ["amountRemaining", "key", "action"],
      },
      showColumnFilters: false,
      columnVisibility: { id: false },
      density: "xs",
    },
    positionActionsColumn: "last",
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    rowCount,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    mantineTableBodyCellProps: ({ row }) => ({
      style: styleCellTable(row),
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      showSkeletons: isLoading,
      sorting,
      rowSelection,
    },
    mantineToolbarAlertBannerProps: isError
      ? { color: "red", children: "Lỗi tải dữ liệu !" }
      : undefined,
    mantinePaginationProps: {
      showRowsPerPage: true,
      withEdges: true,
      rowsPerPageOptions: ["20", "50", "100"],
    },
    enableColumnPinning: true,
  });

  const formatReceiptNumber = (receiptNumber: string): string => {
    const prefix = receiptNumber.slice(0, 2);
    const year = receiptNumber.slice(2, 4);
    const month = receiptNumber.slice(5, 7);
    const day = receiptNumber.slice(8, 10);
    const suffix = receiptNumber.slice(11);

    return `${prefix}${day}.${month}.${year}.${suffix}`;
  };

  const formatOrderNumber = (orderNumber: string): string => {
    const prefix = orderNumber.slice(0, 2);
    const year = orderNumber.slice(2, 6);
    const month = orderNumber.slice(6, 8);
    const day = orderNumber.slice(8, 10);
    const sequence = orderNumber.slice(10);

    const formattedYear = year.slice(2);

    return `${prefix}${day}-${month}-${formattedYear}.${sequence}`;
  };

  const getDataFromLocalStorage = (key: string): UserData | null => {
    const data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  };

  const formatFullName = (value: string) => {
    return value
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const managerOrder = () => (
    <>
      <ScrollArea pr={16} scrollbars="y" h={height + 60} offsetScrollbars>
        <Grid>
          <GridCol pb={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Mã phiếu thu
              </Text>
              <Text fw={500}>{dataGetReceiptParent?.receiptNumber || ""}</Text>
            </Flex>
          </GridCol>
          <GridCol pb={0} span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}>
            <Flex mt={10} align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Thời gian tạo
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                <>{moment(currentTime).format("DD-MM-YYYY HH:mm:ss")}</>
              </Text>
            </Flex>
          </GridCol>
          <GridCol
            pb={0}
            offset={{ base: 0, xs: 0, sm: 0, md: 0, lg: 6, lx: 6 }}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex align="center" gap="xs">
              <Text style={{ flex: "0 0 100px", whiteSpace: "nowrap" }}>
                Thời gian HT
              </Text>
              <Text fw={500} style={{ whiteSpace: "nowrap" }}>
                {handelDateCurrent && statusReponse ? (
                  <>{moment(completeTime).format("DD-MM-YYYY HH:mm:ss")}</>
                ) : null}
              </Text>
            </Flex>
          </GridCol>
          <Grid.Col pt={0} pb={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người lập phiếu thu
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Phòng ban
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {`${userData?.branchName}`}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Họ và tên
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {`${userData?.fullName ?? ""}`}
              </Text>
            </Flex>
          </Grid.Col>

          <GridCol
            pt={0}
            pb={0}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                ĐĐ làm việc
              </Text>
              <Tooltip label={branchDeparment?.address ?? ""}>
                <Text fw="600" lineClamp={1}>
                  {branchDeparment?.address ?? ""}
                </Text>
              </Tooltip>
              {/* <TextInput
                  size="sm"
                  disabled
                  flex={1}
                  error={formErrors.creatorworkplace}
                  value={formData.creatorworkplace}
                  placeholder="Nhập..."
                  onChange={(e) =>
                    handleChange("creatorworkplace", e.currentTarget.value)
                  }
                  rightSection={<IconMinus size={"12px"} />}
                /> */}
            </Flex>
          </GridCol>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Chức danh
              </Text>
              <Text fw="600">{userData?.roleName}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Bộ phận
              </Text>
              <Text fw={600} style={{ whiteSpace: "nowrap" }}>
                {`${userData?.depName}`}
              </Text>
            </Flex>
          </Grid.Col>
          {/* <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 6 }}>
            <Flex>
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                Bộ phận
              </Text>
              <Text fw="600">{userData?.roleName}</Text>
            </Flex>
          </Grid.Col> */}
          {/* <Grid.Col pt={0} span={{ base: 12, md: 12, lg: 8 }}>
            <Flex>
              <Text style={{ flex: "0 0 130px", whiteSpace: "nowrap" }}>
                Mã nhóm công nợ
              </Text>
              <Box style={{ position: "relative", maxWidth: "200px" }}>
                {groupData.id ? (
                  <Tooltip label={groupData.id + " - " + groupData.groupName}>
                    <Text fw={500} truncate>
                      {groupData.id
                        ? groupData.id + " - " + groupData.groupName
                        : "Chọn mã đối tượng công nợ"}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text fw={500} truncate>
                    {groupData.id
                      ? groupData.id + " - " + groupData.groupName
                      : "Chọn mã đối tượng công nợ"}
                  </Text>
                )}
                <IconSearch
                  style={{
                    position: "absolute",
                    right: -30,
                    top: 0,
                    cursor: "pointer",
                    color: cobalt_blue.base,
                  }}
                  onClick={() => openFormDebt("attribute2")}
                />
              </Box>
            </Flex>
          </Grid.Col> */}
          {/* <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}>
            <EditableTextWithOptions
              required
              label="CN bán"
              value={
                listBranch?.find(
                  (item: any) => item.id === formData.creatorbranch
                )?.name || "Vui lòng chọn"
              }
              readOnly={statusReponse}
              options={
                listBranch
                  ?.map((e: any) => e.name)
                  .filter((name): name is string => !!name) ?? []
              }
              onChange={(selectedDescription) => {
                const selectedOption = listBranch?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("creatorbranch", selectedOption.id);
                }
              }}
            />
            <Select
              clearable
              searchable
              placeholder="Vui lòng chọn CN bán"
              disabled={statusReponse}
              data={
                listBranch
                  ?.map((e: any) => e.name)
                  .filter((name): name is string => !!name) ?? []
              }
              value={
                listBranch?.find(
                  (item: any) => item.id === formData.creatorbranch
                )?.name || "Vui lòng chọn"
              }
              onChange={(selectedDescription) => {
                const selectedOption = listBranch?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("creatorbranch", selectedOption.id);
                }
              }}
              allowDeselect={false}
            />
          </GridCol> */}

          <Grid.Col pb={0} pt={0} span={12}>
            <Divider
              my="xs"
              label={
                <Text size="lg" fw={600} c="#000">
                  Người nộp tiền
                </Text>
              }
              labelPosition="left"
            />
          </Grid.Col>
          <GridCol
            pt={0}
            style={{ display: "flex", alignItems: "center" }}
            span={{ base: 6, xs: 6, sm: 6, md: 6, lg: 6, lx: 6 }}
          >
            <EditableTextWithOptions
              required
              label="Loại"
              readOnly={statusReponse ? true : false}
              value={formData.payertype}
              options={["Khách hàng", "Nhân viên"]}
              onChange={(value) => {
                handleChange("payertype", value);
                handleChange("creatorbranch", null);
                setInputValue("");
              }}
              width="100px"
            />
          </GridCol>
          <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}>
            {/* <EditableTextWithOptions
              required
              label="CN bán"
              value={
                listBranch?.find(
                  (item: any) => item.id === formData.creatorbranch
                )?.name || "Vui lòng chọn"
              }
              readOnly={statusReponse}
              options={
                listBranch
                  ?.map((e: any) => e.name)
                  .filter((name): name is string => !!name) ?? []
              }
              onChange={(selectedDescription) => {
                const selectedOption = listBranch?.find(
                  (item: any) => item.name === selectedDescription
                );
                if (selectedOption) {
                  handleChange("creatorbranch", selectedOption.id);
                }
              }}
            /> */}
            <Flex align="center">
              <Text style={{ flex: "0 0 110px", whiteSpace: "nowrap" }}>
                CN bán <span style={{ color: "red" }}>*</span>
              </Text>
              <Select
                searchable
                flex={1}
                placeholder="Vui lòng chọn CN bán"
                disabled={statusReponse}
                data={
                  listBranch
                    ?.sort((a: any, b: any) =>
                      a.name === userData?.branchName
                        ? -1
                        : b.name === userData?.branchName
                        ? 1
                        : 0
                    )
                    .map((e: any) => e.name) ?? []
                }
                value={
                  listBranch?.find(
                    (item: any) => item.id === formData.creatorbranch
                  )?.name || "Vui lòng chọn"
                }
                onChange={(selectedDescription) => {
                  const selectedOption = listBranch?.find(
                    (item: any) => item.name === selectedDescription
                  );
                  if (selectedOption) {
                    console.log(selectedOption);
                    handleChange("creatorbranch", selectedOption.id);
                    setFundepartmentId(Number(selectedOption?.id));
                  }
                }}
                allowDeselect={false}
              />
            </Flex>
          </GridCol>
          <GridCol
            pt={0}
            span={{ base: 12, xs: 12, sm: 7, md: 7, lg: 7, lx: 7 }}
          >
            <Group style={{ flexWrap: "nowrap" }}>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Họ và tên <span style={{ color: "red" }}>*</span>
              </Text>
              {selectPayertype ? (
                <TextInput
                  disabled={statusReponse}
                  size="sm"
                  flex={1}
                  placeholder="Nhập..."
                  value={inputValue}
                  error={formErrors.payerfullname}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    handleChange("payerfullname", e.target.value);
                  }}
                  rightSection={<IconMinus size={"12px"} />}
                />
              ) : (
                <Select
                  searchable
                  clearable
                  flex={1}
                  disabled={statusReponse || !finalSelectedItems.length}
                  placeholder="Vui lòng chọn tên"
                  comboboxProps={{
                    transitionProps: { transition: "pop", duration: 200 },
                  }}
                  limit={50}
                  data={listEmployeeID.map((item) => {
                    const formatUnder = formatFullName(item.fullname);
                    return {
                      label: formatUnder + " - " + item.code,
                      value: String(item.id ?? null),
                    };
                  })}
                  error={formErrors.payerfullname}
                  // onClick={async () => {
                  //   if (!listEmployeeID || listEmployeeID.length < 1) {
                  //     await fetchDataEmployee();
                  //   }
                  //   return;
                  // }}
                  onChange={(selectedDescription) => {
                    if (!selectedDescription) {
                      handleChange("payerfullname", "");
                    } else {
                      const selectedOption = listEmployeeID.find(
                        (item) =>
                          String(item.id) === String(selectedDescription)
                      );
                      if (selectedOption) {
                        handleChange("payerfullname", selectedOption.id);
                      }
                    }
                  }}
                />
              )}
            </Group>
          </GridCol>

          <GridCol pt={0} span={{ base: 12, md: 6, lg: 6 }}></GridCol>
          <GridCol span={{ base: 12, md: 12, lx: 12, lg: 12 }}>
            <Group>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Nội dung <span style={{ color: "red" }}>*</span>
              </Text>
              <Textarea
                size="sm"
                flex={1}
                disabled={statusReponse}
                error={formErrors.payerdescription}
                placeholder="Nhập nội dung"
                onChange={(e) =>
                  handleChange("payerdescription", e.currentTarget.value)
                }
              />
            </Group>
          </GridCol>
          {/* <GridCol span={{ base: 12, md: 12, lx: 12, lg: 12 }}>
            <Group>
              <Text style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}>
                Ghi chú
              </Text>
              <Textarea
                disabled={statusReponse}
                size="sm"
                flex={1}
                placeholder="Nhập ghi chú"
                // value={formData.payerName}
                onChange={(e) =>
                  handleChange("payernote", e.currentTarget.value)
                }
              />
            </Group>
          </GridCol> */}
          <GridCol>
            <Group>
              <Text
                style={{ flex: "0 0 90px", whiteSpace: "nowrap" }}
                fw={600}
                c="red"
              >
                Tổng tiền <span style={{ color: "red" }}>*</span>
              </Text>
              <NumberInput
                size="sm"
                disabled={statusReponse}
                width={300}
                allowNegative={false}
                thousandSeparator=","
                suffix=" VND"
                placeholder="Nhập số tiền"
                hideControls
                value={changeValueTotal}
                rightSection={<IconMinus size={12} />}
                onChange={(value) => {
                  if (value !== undefined && value !== null) {
                    const numericValue = Number(value);

                    if (numericValue < 1) {
                      handleChangeValueTotal(0); // Set to 0 if less than 1
                    } else {
                      handleChangeValueTotal(numericValue); // Accept values between 1 and 9
                    }
                  } else {
                    handleChangeValueTotal(0); // Default to 0 if input is empty or undefined
                  }
                }}
              />
            </Group>
          </GridCol>
        </Grid>
        <Flex mt={20} align="end" gap={15}>
          <Button
            variant="outline"
            color="green"
            disabled={
              statusReponse ||
              (selectPayertype //true là khác hàng, false là nhân viên
                ? changeValueTotal !== valueTotalRemaining ||
                  dataAlloation !== true ||
                  valueTotalRemaining === 0 ||
                  changeValueTotal === 0
                : changeValueTotal > valueTotalRemaining ||
                  dataAlloation !== true ||
                  valueTotalRemaining === 0 ||
                  changeValueTotal === 0)
            }
            onClick={() => {
              handleComplete();
              fetchCreateReceiptParent(true);
              onChangeTimeCurrent();
            }}
            leftSection={<IconCheck size={14} />}
          >
            Hoàn thành
          </Button>
          {/* <Button
                    disabled={
                      statusReponse ||
                      (selectPayertype
                        ? changeValueTotal !== totalAllocatedAmount ||
                          dataAlloation !== true ||
                          totalAllocatedAmount === 0 ||
                          changeValueTotal === 0
                        : changeValueTotal > totalAllocatedAmount ||
                          dataAlloation !== true ||
                          totalAllocatedAmount === 0 ||
                          changeValueTotal === 0)
                    }
                    leftSection={<IconNotes size={14} />}
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      fetchCreateReceiptParent();
                      setStoppedTime(currentTime);
                    }}
                    color="#5a47b0"
                  >
                    Lưu
                  </Button> */}
        </Flex>
      </ScrollArea>
    </>
  );

  const updateSearchParams = (
    key: keyof SearchParams,
    value: string | Date | null
  ) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      [key]: value,
    }));
  };

  //search Chọn hóa đơn
  const handleSearchKey = (value: string) =>
    updateSearchParams("searchKey", value);

  const [searchFromDisplay, setSearchFromDisplay] = useState(
    searchParams.searchFromFull || searchParams.searchFrom || ""
  );
  const [searchToDisplay, setSearchToDisplay] = useState(
    searchParams.searchToFull || searchParams.searchTo || ""
  );

  const handleSearchFrom = (value: string) => {
    const result = value.match(/\d+$/);
    const extractedValue = result ? result[0] : value;

    setSearchFromDisplay(value);
    updateSearchParams("searchFromFull", value);
    updateSearchParams("searchFrom", extractedValue);
  };

  const handleSearchTo = (value: string) => {
    const result = value.match(/\d+$/);
    const extractedValue = result ? result[0] : value;

    setSearchToDisplay(value);
    updateSearchParams("searchToFull", value);
    updateSearchParams("searchTo", extractedValue);
  };

  // const handleDateChange = (dates: [Date | null, Date | null]) => {
  //   const [startDate, endDate] = dates;
  //   setDateRange(dates);
  //   updateSearchParams("handeDateStart", startDate);
  //   updateSearchParams("handeDateEnd", endDate);
  // };

  const handleDateChange = (date: Date | null, type: "start" | "end") => {
    if (type === "start") {
      setStartDate(date);
      if (endDate && date && endDate < date) {
        setEndDate(date);
        updateSearchParams("handeDateEnd", date);
      }
      updateSearchParams("handeDateStart", date);
    } else {
      if (startDate && date && date < startDate) {
        return;
      }
      setEndDate(date);
      updateSearchParams("handeDateEnd", date);
    }
  };

  const fetchData = async () => {
    await fetchDataListReceipt();
  };

  const allSum = () => {
    const totalAmount = finalSelectedItems.reduce((sum, item) => {
      return sum + (item.amountTotal || 0);
    }, 0);

    const totalAmountPaid = finalSelectedItems.reduce((sum, item) => {
      return sum + (item.amountPaid || 0);
    }, 0);

    const totalAmountRemaining = finalSelectedItems.reduce((sum, item) => {
      return sum + (item.amountRemaining || 0);
    }, 0);
    setValueTotalAmout(totalAmount);
    setValueTotalAmoutPaid(totalAmountPaid);
    setValueTotalRemaining(totalAmountRemaining);
  };

  useEffect(() => {
    allSum();
  }, [finalSelectedItems]);

  // const formatFullName = (value: string) => {
  //   return value
  //     .toLowerCase()
  //     .split(" ")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  const Alloation = () => {
    setDataAlloation(true);
  };

  const handleChangeValueTotal = (val: any) => {
    setChangeValueTatal(val);
  };

  const handleComplete = () => {
    setCompleteTime(new Date());
  };

  const StateHandleSave = () => {
    setHandleState(true);
  };
  const fetchDataEmployee = async () => {
    const response = await repositoryPos.get<MessageResponse<any>>(
      "/api/v1/TblDmEmployee/get-all"
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployee(result);
    }
  };

  const fetchDataEmployeeBranchID = async () => {
    const response = await repositoryPos.get<MessageResponse<EmployeeModel[]>>(
      `/api/v1/TblDmEmployee/get-list?BranchId=${fundepartmentId}&Skip=0&Take=9999`
    );

    if (response && response.success) {
      let result = response.data;
      setListEmployeeID(result);
    }
  };

  const validateData = (): boolean => {
    return finalSelectedItems.every((item: any) => item.allocatedAmount > 0);
  };

  const deleteInvoice = (value: any) => {
    const filterItems = (items: any[]) =>
      items.filter((item) => item.orderNumber !== value);

    const updatedFinalItems = filterItems(finalSelectedItems);
    const updatedTempItems = filterItems(tempSelectedItems);

    setFinalSelectedItems(updatedFinalItems);
    setTempSelectedItems(updatedTempItems);

    const newTotal = updatedFinalItems.reduce(
      (acc, item) => acc + (item.allocatedAmount || 0),
      0
    );
    setTotalAllocatedAmount(newTotal);
  };

  const onChangeTimeCurrent = () => {
    setHandelDateCurrent(true);
  };

  const handleInputChange = (index: any, field: string, value: any) => {
    const updatedItems = [...finalSelectedItems];
    const numericValue =
      field === "allocatedAmount"
        ? parseFloat(value.replace(/,/g, "")) || 0
        : value;

    updatedItems[index] = {
      ...updatedItems[index],
      [field]: numericValue,
    };
    setFinalSelectedItems(updatedItems);

    const newTotal = updatedItems.reduce(
      (acc, item) => acc + (item.allocatedAmount || 0),
      0
    );
    setTotalAllocatedAmount(newTotal);
  };

  // useEffect(() => {
  //   const totalAmount = finalSelectedItems.reduce((sum, item) => {
  //     return sum + (item.allocatedAmount || 0);
  //   }, 0);
  //   setTotalAllocatedAmount(totalAmount);
  // }, [finalSelectedItems, finalSelectedItems]);

  // console.log(totalAllocatedAmount);

  const [formData, setFormData] = useState({
    accountfund: 0,
    codefund: "",
    createby: 0,
    createdDate: moment(stoppedTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    completiontime: moment(currentTime, "DD-MM-YYYY HH:mm:ss")
      .add(7, "hours")
      .toISOString(),
    creatorbranch: "",
    creatordepartment: 424,
    creatorfullname: `${userName} - H567`,
    creatorsalesbranch: "",
    creatorworkplace: `${branchDeparment?.address ?? ""}`,
    fundbranch: null,
    funddepartment: null,
    fundsalesbranch: null,
    fundworkplace: null,
    funfullname: null,
    payerdescription: "",
    payerfullname: "",
    payernote: "",
    payertype: "Nhân viên",
    receiptNumber: dataGetReceiptParent?.receiptNumber,
    totalAmount: changeValueTotal,
    type: 1,
    typeReceipt: dataGetReceiptParent?.typeReceipt,
  });

  const formatDate = (date: Date | null): string => {
    if (date === null) return "";
    return moment(date).format("YYYY-MM-DD");
  };

  const validateForm = () => {
    const hasErrors = Object.values(validationErrors).some(
      (error) => error !== undefined
    );
    setIsFormValid(!hasErrors);
  };

  const handleChange = (
    field: string,
    value: string | number | Date | null
  ) => {
    if (field === "payerfullname" && formErrors.payerfullname) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        payerfullname: value ? "" : "Vui lòng nhập họ và tên",
      }));
    }

    if (field === "payerdescription" && formErrors.payerdescription) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        payerdescription: value ? "" : "Vui lòng nhập thông tin",
      }));
    }

    if (field === "creatorbranch" && value) {
      open();
      setDataListBranchOrder(value);
    }

    if (field === "payertype") {
      if (value === "Khách hàng") {
        setSelectPayertype(true);
      } else {
        setSelectPayertype(false);
      }
    }

    if (field === "branch") {
      const matchedObject = dataListBranch.find(
        (element: any) => element.text === value
      );
      if (matchedObject) {
        setOptions(matchedObject.value);
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: value instanceof Date ? value.toISOString() : value,
    }));
    validateForm();
  };

  const filteredDataList = useMemo(
    () =>
      dataListReceipt.filter((receipt) =>
        receipt.orderNumber.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [searchQuery, dataListReceipt]
  );

  // const handleCheckboxChange = (item: Receipt) => {
  //   setTempSelectedItems((prevSelectedItems) => {
  //     const isItemSelected = prevSelectedItems.some(
  //       (selectedItem) => selectedItem.id === item.id
  //     );
  //     return isItemSelected
  //       ? prevSelectedItems.filter(
  //           (selectedItem) => selectedItem.id !== item.id
  //         )
  //       : [...prevSelectedItems, item];
  //   });
  // };

  const handleCheckboxChange = (item: Receipt) => {
    setTempSelectedItems((prevSelectedItems) => {
      if (selectPayertype) {
        return [item];
      } else {
        const isItemSelected = prevSelectedItems.some(
          (selectedItem) => selectedItem.id === item.id
        );
        return isItemSelected
          ? prevSelectedItems.filter(
              (selectedItem) => selectedItem.id !== item.id
            )
          : [...prevSelectedItems, item];
      }
    });
  };

  const handleConfirm = () => {
    const updatedItems = tempSelectedItems.map((item) => ({
      ...item,
      allocatedAmount: item.amountRemaining,
      attribute1: groupData.id,
      attribute2: groupData.groupName,
    }));

    setInputValue(
      tempSelectedItems.length > 0 ? tempSelectedItems[0].attribute1 ?? "" : ""
    );
    setFinalSelectedItems(updatedItems);

    const newTotal = tempSelectedItems.reduce(
      (acc, item) => acc + (item.amountRemaining || 0),
      0
    );
    setTotalAllocatedAmount(newTotal);
    // setTempSelectedItems([]);
    close();
  };

  const fetchCreateReceiptParent = async (handleSave?: boolean) => {
    let isValid = true;
    const errors = {
      payerfullname: "",
      accountfund: 0,
      codefund: "",
      createby: 0,
      createdDate: "",
      creatorbranch: "",
      creatordepartment: "",
      creatorfullname: "",
      creatorsalesbranch: "",
      creatorworkplace: "",
      fundbranch: "",
      funddepartment: "",
      fundsalesbranch: "",
      fundworkplace: "",
      funfullname: "",
      id: 0,
      payerdescription: "",
      payernote: "",
      payertype: "",
      receiptNumber: "",
      totalAmount: 0,
      type: 0,
    };

    if (!formData.payerfullname) {
      errors.payerfullname = "Vui lòng nhập họ và tên!";
      isValid = false;
    }

    // if(selectPayertype) { //true là khác hàng, false là nhân viên  (khách hàng phải nhập đủ, nhân viên có thể nộp thiếu)
    //   if()
    // }
    if (!formData.payerdescription) {
      errors.payerdescription = "Vui lòng nhập nội dung!";
      isValid = false;
    }

    setFormErrors(errors);

    if (!isValid) {
      notifications.show({
        title: "Thông báo",
        message: "Vui lòng nhập đầy đủ thông tin.",
        autoClose: 3000,
        color: "red",
        icon: <IconX />,
      });
      return;
    }

    if (!validateData()) {
      NotificationExtension.Fails(
        "Vui lòng nhập số tiền phân bổ cho tất cả các mục."
      );
      return;
    }

    notifications.show({
      title: "Thông báo",
      message: "Lưu phiếu thu thành công",
      autoClose: 3000,
      color: "green",
      icon: <IconCheck />,
    });

    setIsRunning(false);

    const requestData: CreateReceiptParentRequest = {
      receiptCommand: Object.values(finalSelectedItems).map((e: any) => ({
        payType: "string",
        casherId: 0,
        payeeId: 0,
        note: e.note ?? e.orderNumber,
        payAmount: e.allocatedAmount,
        orderId: e.id,
        attribute1: e.attribute1,
        attribute2: e.attribute2,
      })),
      paymentReceiptDetailCommand: {
        id: 0,
        accountfund: formData.accountfund,
        codefund: formData.codefund,
        createdDate: formData.createdDate,
        createby: formData.createby,
        completiontime: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
          .add(7, "hours")
          .toISOString(),
        // createdDate: new Date(
        //   moment(dataGetReceiptParent?.createdDate)
        //     .subtract(7, "hours")
        //     .format()
        // ).toISOString(),
        //  completiontime: currentTime,
        status:
          debouncedChangeValueTotal === debouncedChangeValueAmout
            ? "Đủ tiền"
            : debouncedChangeValueTotal < debouncedChangeValueAmout
            ? "Thiếu tiền"
            : undefined,
        creatorbranch: formData.creatorbranch,
        creatordepartment: userData?.departmentId,
        creatorfullname: `${userName}-${userData?.code}`,
        createrolename: userData?.roleId,
        creatorsalesbranch: userData?.branchId.toString() ?? "",
        creatorworkplace: String(branchDeparment?.address),
        fundbranch: formData.fundbranch,
        funddepartment: formData.funddepartment,
        fundsalesbranch: formData.fundsalesbranch,
        fundworkplace: formData.fundworkplace,
        funfullname: formData.funfullname,
        payerdescription: formData.payerdescription,
        payerfullname: formData.payerfullname,
        payernote: formData.payernote,
        payertype: formData.payertype,
        receiptNumber: dataGetReceiptParent?.receiptNumber,
        totalAmount: changeValueTotal,
        attribute2: dataGetReceiptParent?.attribute2?.id,
        type: formData.type,
        typeReceipt: dataGetReceiptParent?.typeReceipt,
      },
      save: handleSave,
    };

    // convert data về model chuẩn của be
    const dataConvent: Array<DetailsOrderHeader> = [];
    finalSelectedItems.forEach((item) => {
      if (item.orderNumber && item.amountTotal > 0) {
        const model: DetailsOrderHeader = {
          stt: 0,
          orderNumber: item.orderNumber ?? "",
          attribute2: item?.attribute8?.split("||")[1] ?? "",
          attribute7: item?.attribute8?.split("||")[0] ?? "",
          amountTotal: item.amountTotal ?? "",
          amountPaid: item.amountPaid ?? "",
          amountRemaining: item.amountRemaining ?? "",
          // allocationAmount: Number(item.allocationAmount) ?? null,
          allocatedAmount: Number(item.allocatedAmount) ?? null,
          note: item.note ?? "",
        };
        dataConvent.push(model);
        // console.log(JSON.stringify(dataConvent));
        // requestData.paymentReceiptDetailCommand.attribute2 =
        //   JSON.stringify(dataConvent);
      }
    });

    requestData.paymentReceiptDetailCommand.attribute5 =
      JSON.stringify(dataConvent);
    try {
      const response = await repositoryPos.post<
        MessageResponse<CreateReceiptParentRequest>
      >("/api/v1/CreateSellItem/create-receipt-parent", requestData);

      if (response && response.success) {
        if (handleSave) {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được xác nhận thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        } else {
          notifications.show({
            title: "Thành công",
            message: "Phiếu thu đã được lưu thành công!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
        }
        setStatusReponse(response.success);
      } else {
        notifications.show({
          title: "Thất bại",
          message: "Lưu phiếu thu không thành công. Vui lòng thử lại!",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      }
    } catch (error) {
      console.error("Error creating receipt:", error);
    }
  };

  const fetchDataDepartments = async () => {
    try {
      const response = await repositoryPos.post<
        MessageResponse<DepartmentUser>
      >(`/api/v1/TblDepartment/detail?id=${userData?.departmentId}`);

      if (response && response.success) {
        const result = response.data;
        setBranchDeparment(result);
      } else {
        console.error("Failed to fetch department details", response?.message);
      }
    } catch (error) {
      console.error("Error fetching department details", error);
    }
  };

  useEffect(() => {
    if (fundepartmentId) {
      fetchDataEmployeeBranchID();
    }
  }, [fundepartmentId]);

  useEffect(() => {
    if (userData?.departmentId) {
      fetchDataDepartments();
    }
  }, [userData?.departmentId]);

  useEffect(() => {
    if (formData.payertype) {
      setFormData((prevData) => ({
        ...prevData,
        payerfullname: "",
      }));
    }
  }, [formData.payertype]);

  useEffect(() => {
    if (finalSelectedItems.length === 0) {
      setDataAlloation(false);
    }
  }, [finalSelectedItems]);

  useEffect(() => {
    validateForm();
  }, [totalAllocatedAmount]);

  useEffect(() => {
    const total = finalSelectedItems.reduce(
      (acc, row) => acc + row.amountRemaining,
      0
    );
    setTotalAmountRemaining(total);
  }, [finalSelectedItems]);

  useEffect(() => {
    const badgeConfig = {
      content: "",
      color: "",
      icon: null as React.ReactNode,
    };

    // Update badgeConfig based on conditions
    if (debouncedChangeValueTotal > valueTotalRemaining) {
      badgeConfig.content = "Số tiền nộp lớn hơn số tiền trong đơn hàng!";
      badgeConfig.color = "#fb4f4f";
      badgeConfig.icon = <IconBellX size={20} />;
      setShowBadge(true); // Show badge
    } else if (debouncedChangeValueTotal === valueTotalRemaining) {
      badgeConfig.content = "Nộp đủ so với số tiền trong đơn hàng.";
      badgeConfig.color = "green";
      badgeConfig.icon = <IconBellCheck size={20} />;
      setShowBadge(false); // Hide badge
    } else if (debouncedChangeValueTotal === totalAllocatedAmount) {
      badgeConfig.content = "Nộp đủ so với số với số tiền đã phân bổ.";
      badgeConfig.color = "green";
      badgeConfig.icon = <IconBellCheck size={20} />;
      setShowBadge(false); // Hide badge
    } else if (
      debouncedChangeValueTotal < valueTotalRemaining &&
      changeValueTotal !== 0
    ) {
      badgeConfig.content = "Số tiền nộp nhỏ hơn số tiền trong đơn hàng!";
      badgeConfig.color = "#fb4f4f";
      badgeConfig.icon = <IconBellRinging size={20} />;
      setShowBadge(true); // Show badge
    } else if (debouncedChangeValueTotal === 0) {
      badgeConfig.content = "Số tiền không xác định!";
      badgeConfig.color = "#fb4f4f";
      badgeConfig.icon = <IconBellQuestion size={20} />;
      setShowBadge(true); // Show badge
    }

    setBadgeContent(badgeConfig.content);
    setBadgeColor(badgeConfig.color);
    setBadIcon(badgeConfig.icon);
  }, [
    debouncedChangeValueTotal,
    dataAlloation,
    valueTotalRemaining,
    totalAllocatedAmount,
  ]);
  const fetchDataListReceipt = async () => {
    if (dataListBranchOrder) {
      let url = `/api/v1/CreateSellItem/get-list-receipt?invId=${dataListBranchOrder}`;

      if (searchParams.searchKey) {
        url += `&key=${searchParams.searchKey}`;
      }

      if (searchParams.handeDateStart) {
        url += `&start=${formatDate(searchParams.handeDateStart)}`;
      }

      if (searchParams.handeDateEnd) {
        url += `&end=${formatDate(searchParams.handeDateEnd)}`;
      }

      if (searchParams.searchTo) {
        url += `&To=${searchParams.searchTo}`;
      }

      if (searchParams.searchFrom) {
        url += `&From=${searchParams.searchFrom}`;
      }

      try {
        const response = await repositoryPos.get<MessageResponse<Receipt[]>>(
          url
        );

        if (response && response.success) {
          setDataListReceipt(response.data);
        }
      } catch (error) {
        console.error("Error fetching data list receipt:", error);
      }
    }
  };

  const handleChooseDebt = (
    value: TblDebtGroup,
    fieldName: string,
    row: any
  ) => {
    if (fieldName === "attribute2") {
      setDataGetReceiptParent((prevState: any) => ({
        ...prevState,
        [fieldName]: {
          id: value?.id || "",
          groupCode: value?.groupCode || "",
          groupName: value?.groupName || "",
          taxCode: value?.taxCode || "",
          address: value?.address || "",
          phone: value?.phone || "",
          contactPerson: value?.contactPerson || "",
          classification: value?.classification || "",
          classificationName: value?.classificationName || "",
          debtTerm: value?.debtTerm || "",
          debtLimit: value?.debtLimit || "",
          createBy: value?.createBy || "",
          createByName: value?.createByName || "",
          createDate: value?.createDate || "",
          lastUpdateBy: value?.lastUpdateBy || "",
          lastCreateByName: value?.lastCreateByName || "",
          lastUpdateDate: value?.lastUpdateDate || "",
          type: value?.type || "",
          typeName: value?.typeName || "",
        },
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [row.index]: {
          attribute1: value?.id,
          attribute2: value?.groupName,
        },
      }));
    }
  };

  //#region openConfirmModal
  function openFormDebt(fieldName: string, row?: any) {
    modals.openConfirmModal({
      title: (
        <>
          <Title order={5}>Danh Sách Đối Tượng Công Nợ</Title>
        </>
      ),
      size: "90vw",
      children: (
        <AddFormDebt
          handleChooseDebt={(value: any) =>
            handleChooseDebt(value, fieldName, row)
          }
          navigate={navigate}
        />
      ),
      confirmProps: { display: "none" },
      cancelProps: { display: "none" },
    });
  }

  useEffect(() => {
    setSearchKey("");
    setSearchFrom("");
    setSearchTo("");
    setHanDateEnd(null);
    setHanDateStart(new Date());
    setFinalSelectedItems([]);
    setTempSelectedItems([]);
    setSearchToDisplay("");
    setSearchFromDisplay("");
  }, [dataListBranchOrder]);

  useEffect(() => {
    setTempSelectedItems([]);
    setFinalSelectedItems([]);
  }, [selectPayertype]);

  useEffect(() => {
    setCurrentTime(new Date());
  }, []);

  useEffect(() => {
    setGroupData({
      id: dataGetReceiptParent?.attribute2?.id || "",
      groupName: dataGetReceiptParent?.attribute2?.groupName || "",
      groupCreateByName: dataGetReceiptParent?.attribute2?.createByName || "",
    });
  }, [dataGetReceiptParent]);

  useEffect(() => {
    // setFinalSelectedItems(groupData.groupName, null, "debtObjectName");
    // setFinalSelectedItems(groupData.id, null, "debtObjectCode");
    setFinalSelectedItems((prevData) =>
      prevData.map((item) => ({
        ...item,
        attribute2: groupData.groupName,
        attribute1: groupData.id,
      }))
    );
  }, [groupData]);

  useEffect(() => {
    fetchDataListReceipt();
  }, [dataListBranchOrder, handelKey, handeDateStart, handeDateEnd]);

  useEffect(() => {
    setSearchParams({
      searchKey: "",
      searchFrom: "",
      searchTo: "",
      handeDateStart: null,
      handeDateEnd: null,
      searchToFull: "",
      searchFromFull: "",
    });

    setDataListReceipt([]);
  }, [dataListBranchOrder]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      payerfullname: inputValue,
    }));
  }, [inputValue]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      createdDate: moment(new Date(), "DD-MM-YYYY HH:mm:ss")
        .add(7, "hours")
        .toISOString(),
    }));
  }, []);

  useEffect(() => {
    const fetchDataListBranch = async () => {
      const response = await repositoryDelivery.get<MessageResponse<Receipt[]>>(
        "/api/v1/TblDmMasterOrg/get-select"
      );

      if (response && response.success) {
        let result = response.data;
        setDataListBranch(result);
      }
    };

    const fetchDataPaymentMethod = async () => {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmPaymentMethod/get-all");

      if (response && response.success) {
        let result = response.data;
        setPaymentMethod(result);
      }
    };

    const fetchDataPaymentType = async () => {
      const response = await repositoryMdm.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmPaymentType/get-select");

      if (response && response.success) {
        let result = response.data;
        setPaymentTypes(result);
      }
    };

    const fetchDataBranch = async () => {
      const response = await repositoryDelivery.get<
        MessageResponse<TblDMPaymentMethod[]>
      >("/api/v1/TblDmMasterOrg/get-all");

      if (response && response.success) {
        let result = response.data;
        setListBranch(result);
      }
    };

    Promise.all([
      fetchDataPaymentMethod(),
      fetchDataPaymentType(),
      fetchDataListBranch(),
      fetchDataBranch(),
    ]);
  }, []);

  const fetchDataReceiptParent = async () => {
    const response = await repositoryPos.get<
      MessageResponse<TblDMPaymentMethod[]>
    >("/api/v1/CreateSellItem/create-receipt-parent?typeReceipt=TH");

    if (response && response.success) {
      let result = response.data;
      setDataGetReceiptParent(result);
    }
  };

  useEffect(() => {
    const fetchDataBankAccount = async () => {
      const response = await repositoryPos.get<
        MessageResponse<TblDMPaymentMethod[]>
      >(`/api/v1/TblDmBranchBankAccount/get-select?branchId=${options}`);

      if (response && response.success) {
        let result = response.data;
        setPaymenBankAccount(result);
      }
    };
    fetchDataBankAccount();
  }, [options]);

  useEffect(() => {
    fetchDataReceiptParent();
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    const contentHeight = contentRef.current?.offsetHeight || 0;
    const handleResize = () => {
      setHeight(window.innerHeight - (145 + headerHeight));
      setHeightContent(window.innerHeight - (105 + contentHeight));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightContent, height]);

  useEffect(() => {
    const data = getDataFromLocalStorage("userLogin");
    if (data) {
      setUserData(data);
    }
  }, []);

  const isAllSelected =
    filteredDataList.length > 0 &&
    tempSelectedItems.length === filteredDataList.length;

  const handleSelectAllChange = () => {
    if (selectPayertype) {
      return;
    }
    if (isAllSelected) {
      setTempSelectedItems([]);
    } else {
      setTempSelectedItems(filteredDataList);
    }
  };

  return (
    <>
      <header ref={headerRef}>
        <_breadcrumb></_breadcrumb>
        <Grid pb={15}>
          <GridCol span={{ base: 12, md: 12, lg: 4 }}>
            <Flex direction="column" gap={5}>
              <>
                <Button
                  size="sm"
                  w={100}
                  color="blue"
                  leftSection={<IconPlus size={14} />}
                  variant="filled"
                  onClick={() => {
                    navigate("/bill/payment-receipt");
                    window.location.reload();
                  }}
                >
                  Tạo mới
                </Button>
              </>
            </Flex>
          </GridCol>
          <GridCol
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            span={{ base: 12, md: 6, lg: 4 }}
          >
            {dataAlloation && showBadge && (
              <Badge
                p={20}
                pt={15}
                pb={15}
                leftSection={badIcon}
                color={badgeColor}
              >
                {badgeContent}
              </Badge>
            )}
          </GridCol>
          <GridCol
            style={{ display: "flex", justifyContent: "end" }}
            span={{ base: 12, md: 6, lg: 4 }}
          >
            <Group style={{ flexWrap: "nowrap" }} align="center">
              <Tooltip
                label={`${
                  changeValueTotal > 0
                    ? "Kích hoạt"
                    : "Vui lòng chọn chi nhánh và nhập tổng tiền"
                }`}
              >
                <Button
                  variant="filled"
                  leftSection={<IconBrandCashapp size={14} />}
                  disabled={
                    !dataAlloation &&
                    finalSelectedItems.length > 0 &&
                    changeValueTotal > 0
                      ? false
                      : true
                  }
                  onClick={() => Alloation()}
                  size="sm"
                >
                  Phân bổ
                </Button>
              </Tooltip>
            </Group>
          </GridCol>
        </Grid>
      </header>
      <Modal
        size="55rem"
        opened={opened}
        onClose={close}
        p={0}
        title={<Title order={5}>Chọn hóa đơn</Title>}
      >
        <Grid align="center" mt={10}>
          <GridCol span={{ base: 12, md: 8, lg: 10 }}>
            {/* <TextInput
              w={200}
              label="Từ khóa"
              placeholder="Từ khóa"
              onChange={(event) => handleSearchKey(event.currentTarget.value)}
            /> */}
            <Group grow wrap="nowrap">
              <Grid>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <DatePickerInput
                    locale="vi"
                    flex={1}
                    size="sm"
                    label="Từ ngày"
                    clearable
                    value={searchParams.handeDateStart}
                    valueFormat="DD-MM-YYYY"
                    placeholder="Vui lòng chọn ngày"
                    maxDate={endDate || undefined}
                    onChange={(date) => handleDateChange(date, "start")}
                  />
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <DatePickerInput
                    locale="vi"
                    flex={1}
                    size="sm"
                    label="Đến ngày"
                    clearable
                    value={searchParams.handeDateEnd}
                    valueFormat="DD-MM-YYYY"
                    placeholder="Vui lòng chọn ngày"
                    minDate={startDate || undefined}
                    maxDate={today} // hận em
                    onChange={(date) => handleDateChange(date, "end")}
                  />
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <TextInput
                    value={searchFromDisplay}
                    flex={1}
                    label="Số hóa đơn từ"
                    placeholder="Nhập mã hóa đơn"
                    onChange={(event) =>
                      handleSearchFrom(event.currentTarget.value)
                    }
                  />
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <TextInput
                    value={searchToDisplay}
                    flex={1}
                    label="Số hóa đơn đến"
                    placeholder="Nhập mã hóa đơn"
                    onChange={(event) =>
                      handleSearchTo(event.currentTarget.value)
                    }
                  />
                </GridCol>
              </Grid>
            </Group>
          </GridCol>
          <GridCol span={{ base: 12, md: 4, lg: 2 }}>
            <Group align="flex-end" justify="flex-end" mt={10}>
              <Button
                leftSection={<IconSearch size={12} />}
                onClick={fetchData}
              >
                Tìm kiếm
              </Button>
            </Group>
          </GridCol>
        </Grid>
        <ScrollArea
          h={filteredDataList.length > 0 ? 350 : 0}
          onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        >
          <Table striped horizontalSpacing="sm" withColumnBorders>
            {filteredDataList.length > 0 ? null : (
              <>
                <Table.Caption mt={40} mb={20}>
                  <Text size="md">Chưa có đơn để hiển thị</Text>
                </Table.Caption>
              </>
            )}
            <Table.Thead
              className={cx(classes.header, { [classes.scrolled]: scrolled })}
            >
              <Table.Tr>
                <Table.Th ta="center">
                  <Checkbox
                    checked={isAllSelected}
                    onChange={handleSelectAllChange}
                  />
                </Table.Th>
                <Table.Th ta="center">Số đơn hàng</Table.Th>
                <Table.Th ta="center">Tổng tiền (VND)</Table.Th>
                <Table.Th ta="center">Đã thanh toán (VND)</Table.Th>
                <Table.Th ta="center">Số tiền còn lại (VND)</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {filteredDataList?.map((value) => (
                <Table.Tr key={value.orderNumber}>
                  <Table.Td>
                    <Checkbox
                      checked={tempSelectedItems.some(
                        (selectedItem) => selectedItem.id === value.id
                      )}
                      onChange={() => handleCheckboxChange(value)}
                    />
                  </Table.Td>
                  <Table.Td ta="start">
                    {" "}
                    {formatReceiptNumber(value.orderNumber)}
                  </Table.Td>
                  {/* <Table.Td  ta="center">{formatDateTransfer(value.orderDate)}</Table.Td> */}
                  <Table.Td ta="end">
                    <NumberFormatter
                      thousandSeparator
                      value={value.amountTotal}
                    ></NumberFormatter>
                  </Table.Td>
                  <Table.Td ta="end">
                    <NumberFormatter
                      thousandSeparator
                      value={value.amountPaid}
                    ></NumberFormatter>
                  </Table.Td>
                  <Table.Td ta="end">
                    <NumberFormatter
                      thousandSeparator
                      value={value.amountRemaining}
                    ></NumberFormatter>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </ScrollArea>
        <Group mt={15} justify="flex-end">
          <Button
            size="sm"
            variant="filled"
            key="confirm"
            onClick={handleConfirm}
            // disabled={tempSelectedItems.length === 0 || !validateForm()}
          >
            Tiếp tục
          </Button>
        </Group>
      </Modal>
      <Grid gutter="xs">
        <GridCol span={{ base: 12, md: 6, lg: 4.5 }}>
          <Card
            shadow="sm"
            radius="md"
            withBorder
            pr={0}
            pt={0}
            p="md"
            style={{
              maxHeight: height + 60,
              minHeight: height + 60,
            }}
          >
            {managerOrder()}
          </Card>
        </GridCol>
        <GridCol span={{ base: 12, md: 6, lg: 7.5 }}>
          <MantineReactTable table={table} />
          <Card
            shadow="sm"
            radius="md"
            withBorder
            p="md"
            mt={5}
            style={{
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Divider
              pb={20}
              mt={0}
              my="xs"
              label={<Text size="sm">Tổng Kết Số Tiền</Text>}
              labelPosition="left"
              variant="dashed"
            />
            <Grid>
              <GridCol span={9.5}>
                <Grid style={{ position: "relative", bottom: 0, left: 0 }}>
                  <GridCol
                    span={{ base: 12, md: 6, lg: 2.5 }}
                    style={orderInfoStyle}
                  >
                    <Text
                      size="lg"
                      c="#000"
                      fw={500}
                      lineClamp={1}
                      style={infoLabelStyle}
                    >
                      Tổng tiền hóa đơn
                    </Text>
                    <Text fw={600} size="lg">
                      <NumberFormatter
                        value={valueTotalAmount}
                        thousandSeparator
                        style={infoValueStyle}
                      />
                    </Text>
                  </GridCol>
                  <GridCol
                    span={{ base: 12, md: 6, lg: 2.5 }}
                    style={orderInfoStyle}
                  >
                    <Text fw={500} lineClamp={1} style={infoLabelStyle}>
                      Số đã thanh toán
                    </Text>
                    <Text fw={600} size="lg">
                      <NumberFormatter
                        value={valueTotalAmountPaid}
                        thousandSeparator
                        style={infoValueStyle}
                      />
                    </Text>
                  </GridCol>
                  <GridCol
                    span={{ base: 12, md: 6, lg: 2.5 }}
                    style={orderInfoStyle}
                  >
                    <Text fw={500} lineClamp={1} style={infoLabelStyle}>
                      Số còn phải TT
                    </Text>
                    <Text fw={600} size="lg">
                      <NumberFormatter
                        value={valueTotalRemaining}
                        thousandSeparator
                        style={infoValueStyle}
                      />
                    </Text>
                  </GridCol>
                  <GridCol
                    span={{ base: 12, md: 6, lg: 2.5 }}
                    style={orderInfoStyle}
                  >
                    <Flex direction="column">
                      <Text lineClamp={1} style={infoLabelStyle} fw={500}>
                        Tổng tiền phân bổ
                      </Text>
                    </Flex>
                    <Text c={"red"} fw={600} size="lg">
                      <NumberFormatter
                        value={dataAlloation ? totalAllocatedAmount : 0}
                        thousandSeparator
                        style={infoValueStyle}
                      />
                    </Text>
                  </GridCol>
                </Grid>
              </GridCol>
              <GridCol
                span={2.5}
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Flex mt={20} align="end" gap={15}>
                  {/* <Button
                    variant="filled"
                    disabled={
                      statusReponse ||
                      (selectPayertype //true là khác hàng, false là nhân viên
                        ? changeValueTotal !== totalAllocatedAmount ||
                          dataAlloation !== true ||
                          totalAllocatedAmount === 0 ||
                          changeValueTotal === 0
                        : changeValueTotal > totalAllocatedAmount ||
                          dataAlloation !== true ||
                          totalAllocatedAmount === 0 ||
                          changeValueTotal === 0)
                    }
                    onClick={() => {
                      fetchCreateReceiptParent(true);
                      onChangeTimeCurrent();
                    }}
                    leftSection={<IconCheck size={14} />}
                    color="#5a47b0"
                  >
                    Hoàn thành
                  </Button> */}
                  {/* <Button
                    disabled={
                      statusReponse ||
                      (selectPayertype
                        ? changeValueTotal !== totalAllocatedAmount ||
                          dataAlloation !== true ||
                          totalAllocatedAmount === 0 ||
                          changeValueTotal === 0
                        : changeValueTotal > totalAllocatedAmount ||
                          dataAlloation !== true ||
                          totalAllocatedAmount === 0 ||
                          changeValueTotal === 0)
                    }
                    leftSection={<IconNotes size={14} />}
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      fetchCreateReceiptParent();
                      setStoppedTime(currentTime);
                    }}
                    color="#5a47b0"
                  >
                    Lưu
                  </Button> */}
                </Flex>
              </GridCol>
            </Grid>
          </Card>
        </GridCol>
      </Grid>
    </>
  );
};

const orderInfoStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as "center",
  padding: "10px",
  border: "1px solid #e0e0e0",
  borderRadius: "8px",
  margin: "5px",
};

const infoLabelStyle = {
  fontSize: "14px",
  color: "#555",
  marginBottom: "5px",
};

const infoValueStyle = {
  fontSize: "14px",
  fontWeight: "bold" as "bold",
};
